import _ from 'lodash';
import {
    FETCHED_USER_CRM_STATS,
    UPLOADED_CSV_DATA_SUCCESSFULLY,
} from '../types';

const initialState = {};

const userStatisticsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCHED_USER_CRM_STATS:
            return {
                ...state,
                userStatistics: _.uniqBy(
                    [
                        ...(state.userStatistics || []),
                        ...action.userCRMStats.users,
                    ],
                    'id'
                ),
            };
        case UPLOADED_CSV_DATA_SUCCESSFULLY:
            return {
                ...state,
                success: true,
            };
        default:
            return state;
    }
};

export default userStatisticsReducer;
