import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import CookieConsent from 'react-cookie-consent';
import { connect } from 'react-redux';
import _ from 'lodash';
import { actions as cookieActions } from '../state/actions/cookie';

let CookieConsentMessage = ({
    intl,
    setCookieConsent,
    acceptedCookie,
    getCookieConsent,
}) => {
    const [isPaytabs, setIsPaytabs] = useState(false);

    useEffect(() => {
        getCookieConsent();
    }, []);

    useEffect(() => {
        // Function to update state based on query parameter
        const updateIsPaytabs = () => {
            const params = new URLSearchParams(window.location.search);
            const isPaytabs = params.get('isPaytabs') === 'true';
            setIsPaytabs(isPaytabs);
        };

        // Initial call to set the state when the component mounts
        updateIsPaytabs();

        // Add an event listener for popstate to handle back/forward navigation
        window.addEventListener('popstate', updateIsPaytabs);

        // Optional: Add a listener for 'pushState' and 'replaceState' if programmatically updating state
        const originalPushState = window.history.pushState;
        const originalReplaceState = window.history.replaceState;

        // Monkey-patch pushState to trigger our update
        window.history.pushState = function(...args) {
            originalPushState.apply(window.history, args);
            updateIsPaytabs();
        };

        // Monkey-patch replaceState to trigger our update
        window.history.replaceState = function(...args) {
            originalReplaceState.apply(window.history, args);
            updateIsPaytabs();
        };

        // Cleanup event listeners on component unmount
        return () => {
            window.removeEventListener('popstate', updateIsPaytabs);
            window.history.pushState = originalPushState;
            window.history.replaceState = originalReplaceState;
        };
    }, []);

    return (
        <CookieConsent
            style={{
                background: isPaytabs ? 'black' : '#be2045',
                alignItems: 'center',
            }}
            buttonStyle={{
                background: isPaytabs ? '#f3a536' : '#35c2db',
                color: '#ffffff',
                fontWeight: '700',
                borderRadius: '5px',
            }}
            visible={acceptedCookie ? 'hidden' : 'byCookieValue'}
            expires={365}
            buttonText={intl.formatMessage({
                id: 'cookie_consent_button',
                defaultMessage: 'I Undestand',
            })}
            onAccept={() => {
                setCookieConsent();
            }}
        >
            <div>
                {isPaytabs
                    ? intl.formatMessage({
                          id: 'cookie_consent_paytabs',
                          defaultMessage:
                              'TapN\'Go uses Cookies to enhance your experience. By continuing to browse our website, or clicking "I Understand" you agree to the storing of cookies on your device. To learn more about how we use cookies, please visit our',
                      })
                    : intl.formatMessage({
                          id: 'cookie_consent',
                          defaultMessage:
                              'MenuDelite uses Cookies to enhance your experience. By continuing to browse our website, or clicking "I Understand" you agree to the storing of cookies on your device. To learn more about how we use cookies, please visit our',
                      })}{' '}
                <a
                    href={
                        isPaytabs
                            ? 'https://site.paytabs.com/en/privacy-policy/'
                            : '/privacy-policy'
                    }
                >
                    {intl.formatMessage({
                        id: 'cookie_consent_link',
                        defaultMessage: 'Cookies Policy',
                    })}
                </a>
                .
            </div>
        </CookieConsent>
    );
};

CookieConsentMessage = connect(
    (state, props) => {
        return {
            acceptedCookie: _.get(state, ['cookie', 'cookieConsent'], false),
        };
    },
    { ...cookieActions }
)(CookieConsentMessage);

export default injectIntl(CookieConsentMessage);
