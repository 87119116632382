import _ from 'lodash';
import { FETCHED_SOCIAL_TRACKS, ERROR_FETCHING_SOCIAL_TRACKS } from '../types';

const initialState = {};

const socialTracksReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCHED_SOCIAL_TRACKS:
            return {
                ...state,
                socialStats: action.socialStats,
            };
        case ERROR_FETCHING_SOCIAL_TRACKS:
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
};

export default socialTracksReducer;
