/* eslint-disable no-unused-expressions */

import { io } from 'socket.io-client';
import { axios } from './axiosInstance';
import {
    NEW_SOCKET_RESERVATION,
    NEW_SOCKET_ORDER,
    NEW_WAITER_REQUEST,
    NEW_SOCKET_PAYMENT,
    NEW_AMENITY_REQUEST,
    NEW_CHAT_MESSAGE,
} from '../types';
import { getToken } from '../../utils/tokenFetcher';
import { updateTokens } from './auth';

const newSocketOrder = (order, filterOptions) => ({
    type: NEW_SOCKET_ORDER,
    order,
    filterOptions,
});

const newSocketReservation = reservation => ({
    type: NEW_SOCKET_RESERVATION,
    reservation,
});

const newWaiterRequest = request => ({
    type: NEW_WAITER_REQUEST,
    request,
});

const newSocketPayment = payment => ({
    type: NEW_SOCKET_PAYMENT,
    payment,
});

const newAmenityRequest = request => ({
    type: NEW_AMENITY_REQUEST,
    request,
});

const newChatMessage = chat => ({
    type: NEW_CHAT_MESSAGE,
    chat,
});

const disconnectFromSocket = async (dispatch, getState) => {};

// const errorFetchingOrders = error => ({ type: ERROR_FETCHING_ORDERS, error });

// const getOrders = () => async (dispatch, getState) => {
//     const token = getToken(getState());
//     await axios
//         .get(`${process.env.REACT_APP_API_URL}/api/restaurant/orders`, {
//             headers: {
//                 Authorization: `Bearer ${token}`,
//             },
//         })
//         .then(data => {
//             if (data.status === 200) {
//                 dispatch(updateTokens(data.headers));
//                 dispatch(fetchedOrders(data.data));
//             } else {
//                 dispatch(
//                     errorFetchingOrders(
//                         `Status code incorrect - ${data.status}`
//                     )
//                 );
//             }
//         })
//         .catch(error => {
//             console.log('ERROR FETCHING ORDERS', error);
//             dispatch(errorFetchingOrders(error));
//         });
// };

export const actions = {
    newSocketOrder,
    newSocketReservation,
    newWaiterRequest,
    newSocketPayment,
    newAmenityRequest,
    newChatMessage,
};
