import {
    FETCHED_MENU_BASKET_ITEMS,
    ADD_MENU_BASKET_ITEM_SUCCESSFUL,
    DELETE_BASKET_ITEM_SUCCESSFUL,
    UPDATE_TIP_AMOUNT_SUCCESSFUL,
    UPDATE_TABLE_CODE_SUCCESSFUL,
    UPDATE_USER_EMAIL_SUCCESSFUL,
    UPDATE_USER_REQUEST_SUCCESSFUL,
    UPDATE_ORDER_NOTES_SUCCESSFUL,
    UPDATE_ROOM_NUMBER_SUCCESSFUL,
    UPDATE_PHONE_NUMBER_SUCCESSFUL,
    UPDATE_IS_VISITOR_SUCCESSFUL,
    UPDATE_USER_ADDRESS_SUCCESSFUL,
    UPDATE_USER_NAME_SUCCESSFUL,
} from '../types';

const initialState = {};

const menuReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCHED_MENU_BASKET_ITEMS:
        case ADD_MENU_BASKET_ITEM_SUCCESSFUL:
        case DELETE_BASKET_ITEM_SUCCESSFUL:
        case UPDATE_TIP_AMOUNT_SUCCESSFUL:
        case UPDATE_TABLE_CODE_SUCCESSFUL:
        case UPDATE_USER_EMAIL_SUCCESSFUL:
        case UPDATE_USER_REQUEST_SUCCESSFUL:
        case UPDATE_ORDER_NOTES_SUCCESSFUL:
        case UPDATE_ROOM_NUMBER_SUCCESSFUL:
        case UPDATE_IS_VISITOR_SUCCESSFUL:
        case UPDATE_PHONE_NUMBER_SUCCESSFUL:
        case UPDATE_USER_ADDRESS_SUCCESSFUL:
        case UPDATE_USER_NAME_SUCCESSFUL:
            return {
                ...state,
                [action.restaurantId]: action.basketItems,
            };

        default:
            return state;
    }
};

export default menuReducer;
