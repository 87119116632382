import {
    ERROR_FETCHING_STRIPE_ACCOUNT,
    FETCHED_STRIPE_ACCOUNT,
} from '../types';

const initialState = {};

const stripeAccountReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCHED_STRIPE_ACCOUNT:
            return {
                ...state,
                stripeAccount: action.stripeAccountInfo,
            };
        case ERROR_FETCHING_STRIPE_ACCOUNT:
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
};

export default stripeAccountReducer;
