import { LOADING, END_LOADING, DELETE_LOADING } from '../types';

const initialState = {};

const loadingReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADING:
            return {
                ...state,
                [action.key]: true,
            };
        case END_LOADING:
            return {
                ...state,
                [action.key]: false,
            };
        case DELETE_LOADING:
            const returnState = state;
            delete returnState[action.key];
            return {
                ...returnState,
            };
        default:
            return state;
    }
};

export default loadingReducer;
