import {
    ERROR_FETCHING_RESTAURANT,
    FETCHED_RESTAURANT,
    ERROR_UPDATING_PRICE_LEVEL,
    UPDATE_PRICE_LEVEL_SUCCESSFUL,
    UPDATE_COLORS_SUCCESSFUL,
    UPLOAD_PICTURE_SUCCESSFUL,
    LOADING,
    UPDATE_PRICE_LEVEL,
    UPDATE_ONLINE_STATUS_SUCCESSFUL,
    UPDATE_RESERVATION_STATUS_SUCCESSFUL,
    LOG_OUT,
    EDIT_DETAILS,
    EDIT_DETAILS_SUCCESSFUL,
    UPDATE_ORDER_FEE_SUCCESSFUL,
    UPDATE_SUBSCRIPTION_SUCCESSFUL,
    UPDATE_SUBSCRIPTION,
    UPDATE_SUBSCRIPTION_SUCCESS_FALSE,
    SIGN_UP_SUCCESSFUL,
    UPDATE_REVIEW_SETTINGS_SAMPLE_EMAIL,
    UPDATE_REVIEW_SETTINGS_SAMPLE_EMAIL_SUCCESSFUL,
    UPDATE_REVIEW_SETTINGS,
    UPDATE_REVIEW_SETTINGS_SUCCESSFUL,
    UPDATE_PAY_LATER_SETTINGS_SUCCESSFUL,
    UPDATE_PAY_LATER_SETTINGS,
    UPDATE_MODIFIER_SETTINGS,
    UPDATE_MODIFIER_SETTINGS_SUCCESSFUL,
    UPDATE_ORDER_INFORMATION_SETTINGS,
    UPDATE_ORDER_INFORMATION_SETTINGS_SUCCESSFUL,
    UPDATE_DELIVERY_LOCATION_SETTINGS,
    UPDATE_DELIVERY_LOCATION_SETTINGS_SUCCESSFUL,
    UPDATE_PICK_UP_SETTINGS,
    UPDATE_PICK_UP_SETTINGS_SUCCESSFUL,
    FETCH_PICK_UP_TABLE_SUCCESSFUL,
    UPDATE_REMOTE_PICK_UP_SETTINGS_SUCCESSFUL,
    UPDATE_REMOTE_PICK_UP_SETTINGS,
    AUTH_WITH_ORACLE_SUCCESSFUL,
    UPDATE_WAITER_REQUEST_SETTINGS,
    UPDATE_WAITER_REQUEST_SETTINGS_SUCCESSFUL,
    UPDATE_REQUIRES_EMPLOYEE_ASSIGNMENT,
    UPDATE_REQUIRES_EMPLOYEE_ASSIGNMENT_SUCCESSFUL,
    UPDATE_AUTO_CLOSE_SETTINGS,
    UPDATE_AUTO_CLOSE_SETTINGS_SUCCESSFUL,
    UPDATE_SOCIAL_SETTINGS_SUCCESSFUL,
    UPDATE_SOCIAL_SETTINGS,
    UPDATE_TAX_SETTINGS_SUCCESSFUL,
    UPDATE_TAX_SETTINGS,
    SAVE_DELIVERECT_CREDS_SUCCESSFUL,
    SAVE_NEW_POS_SUCCESSFUL,
    SAVE_NEW_POS_SYSTEM,
    SAVE_DELIVERECT_CREDS,
    SAVE_CLORDER_CREDS,
    SAVE_CLORDER_CREDS_SUCCESSFUL,
    UPDATE_DEFAULT_TIP,
    UPDATE_DEFAULT_TIP_SUCCESSFUL,
    UPDATE_CTA_TEXT,
    UPDATE_CTA_TEXT_SUCCESSFUL,
    UPDATE_FEATURED_ITEMS_SETTINGS,
    UPDATE_FEATURED_ITEMS_SETTINGS_SUCCESSFUL,
    UPDATE_EMAIL_COLLECTION_SETTINGS,
    UPDATE_EMAIL_COLLECTION_SETTINGS_SAMPLE_EMAIL,
    UPDATE_EMAIL_COLLECTION_SETTINGS_SUCCESSFUL,
    UPDATE_EMAIL_COLLECTION_SETTINGS_SAMPLE_EMAIL_SUCCESSFUL,
    UPDATE_ORDER_EMAIL_NOTIFICATION_SETTINGS,
    UPDATE_ORDER_EMAIL_NOTIFICATION_SETTINGS_SUCCESSFUL,
    EDIT_WALLET_PASS_SETTINGS,
    EDIT_WALLET_PASS_SETTINGS_SUCCESSFUL,
    UPDATE_DELITEFUL_AI_SETTINGS_SUCCESSFUL,
    UPDATE_BOTTOM_MENU_TEXT,
    UPDATE_BOTTOM_MENU_TEXT_SUCCESSFUL,
    SUBSCRIBE_STICKY_HEADER,
    SUBSCRIBE_STICKY_HEADER_SUCCESSFUL,
    ERROR_SUBSCRIBING_STICKY_HEADER,
    UPDATE_WEBSITE_SETTINGS,
    UPDATE_MENU_VIEW_SETTINGS,
    UPDATE_MENU_VIEW_SETTINGS_SUCCESSFUL,
    UPDATE_MENU_SORT_SETTINGS,
    UPDATE_MENU_SORT_SETTINGS_SUCCESSFUL,
    UPDATE_DELIVERY_SETTINGS_SUCCESSFUL,
    UPDATE_WELCOME_SCREEN_SETTINGS_SUCCESSFUL,
    UPDATE_WELCOME_SCREEN_SETTINGS,
    UPDATE_PROMO_BANNER_SETTINGS,
    UPDATE_PROMO_BANNER_SETTINGS_SUCCESSFUL,
    UPDATE_DELITEPAY_SETTINGS_SUCCESSFUL,
    UPDATE_DELITEPAY_SETTINGS,
    UPDATE_DELITECLUB_SETTINGS_SUCCESSFUL,
    UPDATE_DELITECLUB_SETTINGS,
    UPDATE_POPUP_SETTINGS,
    UPDATE_POPUP_SETTINGS_SUCCESSFUL,
    UPDATE_USER_ACTIONS_SETTINGS_SUCCESSFUL,
    UPDATE_USER_ACTIONS_SETTINGS,
    UPDATE_MERCHANT_DIRECTORY_SETTINGS,
    UPDATE_MERCHANT_DIRECTORY_SETTINGS_SUCCESSFUL,
    UPDATE_LOYALTY_APP_SETTINGS_SUCCESSFUL,
    UPDATE_LOYALTY_APP_SETTINGS,
    UPDATE_RECOMMENDED_ITEM_SETTINGS,
    UPDATE_RECOMMENDED_ITEM_SETTINGS_SUCCESSFUL,
    UPDATE_PAYMENT_SETTINGS,
    UPDATE_PAYMENT_SETTINGS_SUCCESSFUL,
    UPDATE_ORDER_GROUPING_SETTINGS,
    UPDATE_ORDER_GROUPING_SETTINGS_SUCCESSFUL,
} from '../types';

const initialState = {};

const restaurantReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADING:
            if (action.key === UPDATE_PRICE_LEVEL) {
                return {
                    ...state,
                    priceLevelUpdate: false,
                };
            }
            if (action.key === EDIT_DETAILS) {
                return {
                    ...state,
                    editDetailsUpdate: false,
                };
            }
            if (action.key === UPDATE_SOCIAL_SETTINGS) {
                return {
                    ...state,
                    socialMediaSyncSuccess: false,
                };
            }
            if (action.key === UPDATE_PAYMENT_SETTINGS) {
                return {
                    ...state,
                    paymentSettingsUpdateSuccess: false,
                };
            }
            if (action.key === UPDATE_DELIVERY_SETTINGS_SUCCESSFUL) {
                return {
                    ...state,
                    deliveryUpdate: false,
                };
            }
            if (action.key === UPDATE_SUBSCRIPTION) {
                return {
                    ...state,
                    planSelectSuccess: false,
                };
            }
            if (action.key === UPDATE_FEATURED_ITEMS_SETTINGS) {
                return {
                    ...state,
                    didUpdateFeaturedItems: false,
                };
            }
            if (action.key === UPDATE_WAITER_REQUEST_SETTINGS) {
                return {
                    ...state,
                    waiterRequestUpdate: false,
                };
            }
            if (action.key === UPDATE_AUTO_CLOSE_SETTINGS) {
                return {
                    ...state,
                    autoCloseUpdate: false,
                };
            }
            if (action.key === UPDATE_ORDER_INFORMATION_SETTINGS) {
                return {
                    ...state,
                    orderInformationSuccess: false,
                };
            }
            if (action.key === UPDATE_DELIVERY_LOCATION_SETTINGS) {
                return { ...state, deliveryLocationSuccess: false };
            }
            if (action.key === UPDATE_REVIEW_SETTINGS) {
                return {
                    ...state,
                    reviewsUpdate: false,
                    reviewsEmailSent: false,
                };
            }
            if (action.key === SUBSCRIBE_STICKY_HEADER) {
                return {
                    ...state,
                    stickyHeaderSuccess: false,
                    stickyHeaderError: false,
                };
            }
            if (action.key === UPDATE_EMAIL_COLLECTION_SETTINGS) {
                return {
                    ...state,
                    emailCollectionUpdate: false,
                    emailCollectionEmailSent: false,
                };
            }
            if (action.key === EDIT_WALLET_PASS_SETTINGS) {
                return {
                    ...state,
                    walletSettingsUpdate: false,
                };
            }
            if (action.key === UPDATE_TAX_SETTINGS) {
                return {
                    ...state,
                    taxUpdate: false,
                };
            }
            if (action.key === UPDATE_WEBSITE_SETTINGS) {
                return {
                    ...state,
                    websiteUpdate: false,
                };
            }
            if (action.key === UPDATE_DEFAULT_TIP) {
                return {
                    ...state,
                    tipUpdate: false,
                };
            }
            if (action.key === UPDATE_CTA_TEXT) {
                return {
                    ...state,
                    ctaUpdate: false,
                };
            }
            if (action.key === UPDATE_BOTTOM_MENU_TEXT) {
                return {
                    ...state,
                    btmUpdate: false,
                };
            }
            if (action.key === UPDATE_REVIEW_SETTINGS_SAMPLE_EMAIL) {
                return {
                    ...state,
                    reviewsUpdate: false,
                    reviewsEmailSent: false,
                };
            }
            if (action.key === UPDATE_EMAIL_COLLECTION_SETTINGS_SAMPLE_EMAIL) {
                return {
                    ...state,
                    emailCollectionUpdate: false,
                    emailCollectionEmailSent: false,
                };
            }
            if (action.key === UPDATE_PAY_LATER_SETTINGS) {
                return {
                    ...state,
                    payLaterUpdate: false,
                };
            }
            if (action.key === UPDATE_ORDER_GROUPING_SETTINGS) {
                return {
                    ...state,
                    orderGroupingUpdate: false,
                };
            }
            if (action.key === UPDATE_DELITEPAY_SETTINGS) {
                return {
                    ...state,
                    delitePayUpdate: false,
                };
            }
            if (action.key === UPDATE_DELITECLUB_SETTINGS) {
                return {
                    ...state,
                    deliteClubUpdate: false,
                };
            }
            if (action.key === UPDATE_MENU_SORT_SETTINGS) {
                return {
                    ...state,
                    menuPriceUpdate: false,
                };
            }
            if (action.key === UPDATE_MENU_VIEW_SETTINGS) {
                return {
                    ...state,
                    menuViewUpdate: false,
                };
            }
            if (action.key === UPDATE_WELCOME_SCREEN_SETTINGS) {
                return {
                    ...state,
                    welcomeScreenUpdate: false,
                };
            }
            if (action.key === UPDATE_POPUP_SETTINGS) {
                return {
                    ...state,
                    popupScreenUpdate: false,
                };
            }
            if (action.key === UPDATE_PROMO_BANNER_SETTINGS) {
                return {
                    ...state,
                    promoBannerUpdate: false,
                };
            }
            if (action.key === UPDATE_MERCHANT_DIRECTORY_SETTINGS) {
                return { ...state, merchantDirectoryUpdate: false };
            }
            if (action.key === UPDATE_LOYALTY_APP_SETTINGS) {
                return { ...state, loyaltyPlatformUpdate: false };
            }
            if (action.key === UPDATE_USER_ACTIONS_SETTINGS) {
                return {
                    ...state,
                    userActionUpdate: false,
                };
            }
            if (action.key === UPDATE_MODIFIER_SETTINGS) {
                return {
                    ...state,
                    modifierSettingUpdate: false,
                };
            }
            if (action.key === UPDATE_RECOMMENDED_ITEM_SETTINGS) {
                return {
                    ...state,
                    recommendedItemUpdate: false,
                };
            }
            if (action.key === UPDATE_ORDER_EMAIL_NOTIFICATION_SETTINGS) {
                return {
                    ...state,
                    orderEmailNotificationUpdate: false,
                };
            }
            if (action.key === UPDATE_PICK_UP_SETTINGS) {
                return {
                    ...state,
                    pickUpUpdate: false,
                };
            }
            if (action.key === UPDATE_REMOTE_PICK_UP_SETTINGS) {
                return {
                    ...state,
                    remotePickUpUpdate: false,
                };
            }
            if (action.key === UPDATE_REQUIRES_EMPLOYEE_ASSIGNMENT) {
                return {
                    ...state,
                    employeeAssignmentUpdate: false,
                };
            }
            if (
                action.key === SAVE_NEW_POS_SYSTEM ||
                action.key === SAVE_DELIVERECT_CREDS ||
                action.key === SAVE_CLORDER_CREDS
            ) {
                return {
                    ...state,
                    posSettingsUpdate: true,
                };
            }
            return state;
        case SUBSCRIBE_STICKY_HEADER_SUCCESSFUL:
            return {
                ...state,
                stickyHeaderSuccess: true,
                stickyHeaderError: false,
            };
        case ERROR_SUBSCRIBING_STICKY_HEADER:
            return {
                ...state,
                stickyHeaderSuccess: false,
                stickyHeaderError: action.error,
            };
        case UPDATE_REQUIRES_EMPLOYEE_ASSIGNMENT_SUCCESSFUL:
            return {
                ...state,
                employeeAssignmentUpdate: true,
            };

        case UPDATE_SOCIAL_SETTINGS_SUCCESSFUL:
            return {
                ...state,
                socialMediaSyncSuccess: true,
                ...action.data,
            };
        case UPDATE_PAYMENT_SETTINGS_SUCCESSFUL:
            return {
                ...state,
                paymentSettingsUpdateSuccess: true,
                ...action.data,
            };
        case UPDATE_FEATURED_ITEMS_SETTINGS_SUCCESSFUL:
            return {
                ...state,
                didUpdateFeaturedItems: true,
            };
        case SAVE_DELIVERECT_CREDS_SUCCESSFUL:
            return {
                ...state,
                restaurant: {
                    ...state.restaurant,
                    deliverectChannelId: action.changes.channelId,
                    deliverectLocationId: action.changes.locationId,
                },
                posSettingsUpdate: false,
                posSettingsSuccess: true,
            };
        case SAVE_CLORDER_CREDS_SUCCESSFUL:
            return {
                ...state,
                restaurant: {
                    ...state.restaurant,
                    clorderClientId: action.clientId,
                    clorderStoreId: action.storeId,
                },
                posSettingsUpdate: false,
                posSettingsSuccess: true,
            };
        case UPDATE_DELITEFUL_AI_SETTINGS_SUCCESSFUL:
            return {
                ...state,
                deliteAiUpdateSuccess: true,
            };
        case SAVE_NEW_POS_SUCCESSFUL:
            return {
                ...state,
                restaurant: {
                    ...state.restaurant,
                    posSystem: action.posSystem.posSystem,
                },
                posSettingsUpdate: false,
                posSettingsSuccess: true,
            };
        case UPDATE_WAITER_REQUEST_SETTINGS_SUCCESSFUL:
            return {
                ...state,
                waiterRequestUpdate: true,
            };
        case UPDATE_AUTO_CLOSE_SETTINGS_SUCCESSFUL:
            return {
                ...state,
                autoCloseUpdate: true,
            };
        case EDIT_DETAILS_SUCCESSFUL:
            return {
                ...state,
                editDetailsUpdate: true,
            };
        case UPLOAD_PICTURE_SUCCESSFUL:
            return {
                ...state,
                restaurant: {
                    ...state.restaurant,
                    coverImage: action.pictureUrl,
                },
            };
        case AUTH_WITH_ORACLE_SUCCESSFUL:
            return {
                ...state,
                restaurant: {
                    ...state.restaurant,
                    posAuthToken: action.posAuthToken,
                },
            };
        case UPDATE_ONLINE_STATUS_SUCCESSFUL:
            return {
                ...state,
                restaurant: {
                    ...state.restaurant,
                    isOnline: action.status,
                },
            };
        case UPDATE_RESERVATION_STATUS_SUCCESSFUL:
            return {
                ...state,
                restaurant: {
                    ...state.restaurant,
                    acceptingReservations: action.status,
                },
            };
        case UPDATE_PAY_LATER_SETTINGS_SUCCESSFUL:
            return {
                ...state,
                payLaterUpdate: true,
                restaurant: {
                    ...state.restaurant,
                    allowsPayLater: action.payLaterUpdate,
                    allowsOfflinePayments: action.allowsOfflinePayments,
                },
            };
        case UPDATE_ORDER_GROUPING_SETTINGS_SUCCESSFUL:
            return {
                ...state,
                orderGroupingUpdate: true,
                restaurant: {
                    ...state.restaurant,
                    usesTableView: action.usesTableView,
                    usesTableViewByDefault: action.usesTableViewByDefault,
                },
            };
        case UPDATE_DELITEPAY_SETTINGS_SUCCESSFUL:
            return {
                ...state,
                delitePayUpdate: true,
                restaurant: {
                    ...state.restaurant,
                    allowsDelitePay: action.data.allowsDelitePay,
                    usesCustomDelitePayBannerImage:
                        action.data.usesCustomDelitePayBannerImage,
                    usesCustomDelitePayPassImage:
                        action.data.usesCustomDelitePayPassImage,
                    usesCustomDelitePayColors:
                        action.data.usesCustomDelitePayColors,
                    usesAutoExpandedDelitePay:
                        action.data.usesAutoExpandedDelitePay,
                    delitePayColorSettings: action.data.delitePayColorSettings,
                    delitePayCustomFooterText:
                        action.data.delitePayCustomFooterText,
                    usesCustomDelitePayFooterText:
                        action.data.usesCustomDelitePayFooterText,
                    delitePayPassImageUrl: action.data.delitePayPassImageUrl,
                    delitePayBannerImageUrl:
                        action.data.delitePayBannerImageUrl,
                },
            };

        case UPDATE_DELITECLUB_SETTINGS_SUCCESSFUL:
            return {
                ...state,
                deliteClubUpdate: true,
                restaurant: {
                    ...state.restaurant,
                    allowsDeliteClub: action.data.allowsDeliteClub,
                    usesCustomDeliteClubBannerImage:
                        action.data.usesCustomDeliteClubBannerImage,
                    usesCustomDeliteClubPassImage:
                        action.data.usesCustomDeliteClubPassImage,
                    usesCustomDeliteClubColors:
                        action.data.usesCustomDeliteClubColors,
                    usesAutoExpandedDeliteClub:
                        action.data.usesAutoExpandedDeliteClub,
                    deliteClubColorSettings:
                        action.data.deliteClubColorSettings,
                    deliteClubCustomFooterText:
                        action.data.deliteClubCustomFooterText,
                    usesCustomDeliteClubFooterText:
                        action.data.usesCustomDeliteClubFooterText,
                    deliteClubPassImageUrl: action.data.deliteClubPassImageUrl,
                    deliteClubBannerImageUrl:
                        action.data.deliteClubBannerImageUrl,
                    usesDeliteClubPricingBanner:
                        action.data.usesDeliteClubPricingBanner,
                },
            };
        case UPDATE_MENU_SORT_SETTINGS_SUCCESSFUL:
            return {
                ...state,
                menuPriceUpdate: true,
                restaurant: {
                    ...state.restaurant,
                    sortMenuPrice: action.sortMenuPrice,
                    sortByCustomOrdering: action.sortByCustomOrdering,
                },
            };
        case UPDATE_MENU_VIEW_SETTINGS_SUCCESSFUL:
            return {
                ...state,
                menuViewUpdate: true,
                restaurant: {
                    ...state.restaurant,
                    isUsingScrollMenu: action.allowsMenuView,
                    isHorizontal: action.isHorizontal,
                    isHidingTableCode: action.isHidingTableCode,
                    customFontName: action.customFontName,
                },
            };
        case UPDATE_WELCOME_SCREEN_SETTINGS_SUCCESSFUL:
            return {
                ...state,
                welcomeScreenUpdate: true,
                restaurant: {
                    ...state.restaurant,
                    welcomeBackgroundImage: action.welcomeBackgroundImage,
                    welcomeLogoImage: action.welcomeLogoImage,
                    welcomeText: action.welcomeText,
                    isUsingWelcomeScreen: action.isUsingWelcomeScreen,
                    showMenuTypes: action.showMenuTypes,
                    showServiceTypes: action.showServiceTypes,
                    showMenuLanguages: action.showMenuLanguages,
                },
            };
        case UPDATE_POPUP_SETTINGS_SUCCESSFUL:
            return {
                ...state,
                popupScreenUpdate: true,
                restaurant: {
                    ...state.restaurant,
                    popupBackgroundImage: action.popupBackgroundImage,
                    popupTitle: action.popupTitle,
                    popupText: action.popupText,
                    popupButtonText: action.popupButtonText,
                    popupDelay: action.popupDelay,
                    popupBackgroundColor: action.popupBackgroundColor,
                    popupButtonColor: action.popupButtonColor,
                    popupButtonTextColor: action.popupButtonTextColor,
                    isUsingPopup: action.isUsingPopup,
                    popupTextColor: action.popupTextColor,
                },
            };
        case UPDATE_PROMO_BANNER_SETTINGS_SUCCESSFUL:
            return {
                ...state,
                promoBannerUpdate: true,
                restaurant: {
                    ...state.restaurant,
                    showPromoBanner: action.showPromoBanner,
                    showPromoBanner2: action.showPromoBanner2,
                    showPromoBanner3: action.showPromoBanner3,
                    showPromoBanner4: action.showPromoBanner4,
                    showPromoBanner5: action.showPromoBanner5,
                    promoBannerImage: action.promoBannerImage,
                    promoBannerImage2: action.promoBannerImage2,
                    promoBannerImage3: action.promoBannerImage3,
                    promoBannerImage4: action.promoBannerImage4,
                    promoBannerImage5: action.promoBannerImage5,
                    promoBannerUrl: action.promoBannerUrl,
                    promoBannerUrl2: action.promoBannerUrl2,
                    promoBannerUrl3: action.promoBannerUrl3,
                    promoBannerUrl4: action.promoBannerUrl4,
                    promoBannerUrl5: action.promoBannerUrl5,
                },
            };
        case UPDATE_MERCHANT_DIRECTORY_SETTINGS_SUCCESSFUL:
            return {
                ...state,
                merchantDirectoryUpdate: true,
                restaurant: {
                    ...state.restaurant,
                    showMerchantDirectory: action.showMerchantDirectory,
                    showMerchantDirectory2: action.showMerchantDirectory2,
                    showMerchantDirectory3: action.showMerchantDirectory3,
                    showMerchantDirectory4: action.showMerchantDirectory4,
                    showMerchantDirectory5: action.showMerchantDirectory5,
                    merchantDirectoryImage: action.merchantDirectoryImage,
                    merchantDirectoryImage2: action.merchantDirectoryImage2,
                    merchantDirectoryImage3: action.merchantDirectoryImage3,
                    merchantDirectoryImage4: action.merchantDirectoryImage4,
                    merchantDirectoryImage5: action.merchantDirectoryImage5,
                    merchantDirectoryUrl: action.merchantDirectoryUrl,
                    merchantDirectoryUrl2: action.merchantDirectoryUrl2,
                    merchantDirectoryUrl3: action.merchantDirectoryUrl3,
                    merchantDirectoryUrl4: action.merchantDirectoryUrl4,
                    merchantDirectoryUrl5: action.merchantDirectoryUrl5,
                    merchantDirectoryTitle1: action.merchantDirectoryTitle1,
                    merchantDirectoryTitle2: action.merchantDirectoryTitle2,
                    merchantDirectoryTitle3: action.merchantDirectoryTitle3,
                    merchantDirectoryTitle4: action.merchantDirectoryTitle4,
                    merchantDirectoryTitle5: action.merchantDirectoryTitle5,
                    merchantDirectoryDescription1:
                        action.merchantDirectoryDescription1,
                    merchantDirectoryDescription2:
                        action.merchantDirectoryDescription2,
                    merchantDirectoryDescription3:
                        action.merchantDirectoryDescription3,
                    merchantDirectoryDescription4:
                        action.merchantDirectoryDescription4,
                    merchantDirectoryDescription5:
                        action.merchantDirectoryDescription5,
                },
            };
        case UPDATE_LOYALTY_APP_SETTINGS_SUCCESSFUL:
            return {
                ...state,
                loyaltyPlatformUpdate: true,
                restaurant: {
                    ...state.restaurant,
                    isUsingLoyaltyPlatform: action.isUsingLoyaltyPlatform,
                    hasLoyaltyPlatformReservations:
                        action.hasLoyaltyPlatformReservations,
                    loyaltyPlatformReservationsUrl:
                        action.loyaltyPlatformReservationsUrl,
                    hasLoyaltyPlatformMenu: action.hasLoyaltyPlatformMenu,
                    loyaltyPlatformImage1Url: action.loyaltyPlatformImage1Url,
                    loyaltyPlatformImage2Url: action.loyaltyPlatformImage2Url,
                    loyaltyPlatformImage3Url: action.loyaltyPlatformImage3Url,
                    loyaltyPlatformImage4Url: action.loyaltyPlatformImage4Url,
                    loyaltyPlatformImage5Url: action.loyaltyPlatformImage5Url,
                    isAllowingCustomerCreditVouchers:
                        action.isAllowingCustomerCreditVouchers,
                },
            };
        case UPDATE_USER_ACTIONS_SETTINGS_SUCCESSFUL:
            return {
                ...state,
                userActionUpdate: true,
                restaurant: {
                    ...state.restaurant,
                    isShowingUserMenuActions: action.isShowingUserMenuActions,
                    actionEnabled1: action.actionEnabled1,
                    actionEnabled2: action.actionEnabled2,
                    actionEnabled3: action.actionEnabled3,
                    actionEnabled4: action.actionEnabled4,
                    actionEnabled5: action.actionEnabled5,
                    actionEnabled6: action.actionEnabled6,
                    actionTitle1: action.actionTitle1,
                    actionTitle2: action.actionTitle2,
                    actionTitle3: action.actionTitle3,
                    actionTitle4: action.actionTitle4,
                    actionTitle5: action.actionTitle5,
                    actionTitle6: action.actionTitle6,
                    actionIcon1: action.actionIcon1,
                    actionIcon2: action.actionIcon2,
                    actionIcon3: action.actionIcon3,
                    actionIcon4: action.actionIcon4,
                    actionIcon5: action.actionIcon5,
                    actionIcon6: action.actionIcon6,
                    emailList1: action.emailList1,
                    emailList2: action.emailList2,
                    emailList3: action.emailList3,
                    emailList4: action.emailList4,
                    emailList5: action.emailList5,
                    emailList6: action.emailList6,
                    textEnabled1: action.textEnabled1,
                    textEnabled2: action.textEnabled2,
                    textEnabled3: action.textEnabled3,
                    textEnabled4: action.textEnabled4,
                    textEnabled5: action.textEnabled5,
                    textEnabled6: action.textEnabled6,
                },
            };
        case UPDATE_DELIVERY_SETTINGS_SUCCESSFUL:
            return {
                ...state,
                deliveryUpdate: true,
                restaurant: {
                    ...state.restaurant,
                    isAcceptingDelivery: action.isAcceptingDelivery,
                    feeType: action.feeType,
                    fixedFee: action.fixedFee,
                    percentFee: action.percentFee,
                    minOrderValue: action.minOrderValue,
                    pickupInstr: action.pickupInstr,
                    deliveryOrderPrepTime: action.deliveryOrderPrepTime,
                },
            };
        case UPDATE_MODIFIER_SETTINGS_SUCCESSFUL:
            return {
                ...state,
                modifierSettingUpdate: true,
                restaurant: {
                    ...state.restaurant,
                    allowsModifiersOnMenu: action.allowsModifiersOnMenu,
                    expandModifierGroups: action.expandModifierGroups,
                    showsPlusSignOnModifierPrice:
                        action.showsPlusSignOnModifierPrice,
                },
            };
        case UPDATE_RECOMMENDED_ITEM_SETTINGS_SUCCESSFUL:
            return {
                ...state,
                recommendedItemUpdate: true,
                restaurant: {
                    ...state.restaurant,
                    allowsRecommendedItemsOnMenu:
                        action.allowsRecommendedItemsOnMenu,
                    expandRecommendedItems: action.expandRecommendedItems,
                },
            };
        case UPDATE_ORDER_EMAIL_NOTIFICATION_SETTINGS_SUCCESSFUL:
            return {
                ...state,
                orderEmailNotificationUpdate: true,
                restaurant: {
                    ...state.restaurant,
                    sendOrderConfirmationEmails:
                        action.sendOrderConfirmationEmails,
                    notificationEmailList: action.notificationEmailList,
                },
            };
        case UPDATE_PICK_UP_SETTINGS_SUCCESSFUL:
            return {
                ...state,
                pickUpUpdate: true,
                pickUpTable: action.restaurantTable,
                restaurant: {
                    ...state.restaurant,
                    allowsPickUp: action.allowsPickup,
                    acceptsOrdersOutsideOpeningHours:
                        action.acceptsOrdersOutsideOpeningHours,
                    requiresPhoneNumber: action.requiresPhoneNumber,
                },
            };
        case UPDATE_REMOTE_PICK_UP_SETTINGS_SUCCESSFUL:
            return {
                ...state,
                remotePickUpUpdate: true,
                restaurant: {
                    ...state.restaurant,
                    allowsRemotePickUp: action.allowsRemotePickUp,
                    orderPrepTime: action.orderPrepTime,
                },
            };
        case FETCH_PICK_UP_TABLE_SUCCESSFUL:
            return {
                ...state,
                pickUpTable: action.data,
            };
        case UPDATE_ORDER_INFORMATION_SETTINGS_SUCCESSFUL:
            return {
                ...state,
                orderInformationSuccess: true,
                restaurant: {
                    ...state.restaurant,
                    allowsGuestCheckout: action.allowsGuestCheckout,
                    requiresCheckoutName: action.requiresCheckoutName,
                    requiresCheckoutPhoneNo: action.requiresCheckoutPhoneNo,
                },
            };
        case FETCHED_RESTAURANT:
            const restaurant =
                action.restaurant.length === 1 && action.restaurant[0];
            return {
                ...state,
                restaurant,
                restaurantFetchComplete: true,
                restaurantFetchError: false,
                didLogOut: false,
            };
        case LOG_OUT:
            return {
                ...state,
                restaurant: null,
                restaurantFetchComplete: null,
                restaurantFetchError: null,
                didLogOut: true,
            };
        case UPDATE_TAX_SETTINGS_SUCCESSFUL:
            return {
                ...state,
                taxUpdate: true,
            };
        case UPDATE_WEBSITE_SETTINGS:
            return {
                ...state,
                websiteUpdate: true,
            };
        case UPDATE_DEFAULT_TIP_SUCCESSFUL:
            return {
                ...state,
                tipUpdate: true,
            };
        case UPDATE_CTA_TEXT_SUCCESSFUL:
            return {
                ...state,
                ctaUpdate: true,
            };
        case UPDATE_BOTTOM_MENU_TEXT_SUCCESSFUL:
            return { ...state, btmUpdate: true };
        case UPDATE_REVIEW_SETTINGS_SUCCESSFUL:
            return {
                ...state,
                reviewsUpdate: true,
                reviewsEmailSent: false,
            };
        case UPDATE_REVIEW_SETTINGS_SAMPLE_EMAIL_SUCCESSFUL:
            return {
                ...state,
                reviewsUpdate: false,
                reviewsEmailSent: true,
            };
        case UPDATE_EMAIL_COLLECTION_SETTINGS_SUCCESSFUL:
            return {
                ...state,
                emailCollectionUpdate: true,
                emailCollectionEmailSent: false,
            };
        case EDIT_WALLET_PASS_SETTINGS_SUCCESSFUL:
            return {
                ...state,
                walletSettingsUpdate: true,
            };
        case UPDATE_EMAIL_COLLECTION_SETTINGS_SAMPLE_EMAIL_SUCCESSFUL:
            return {
                ...state,
                emailCollectionUpdate: false,
                emailCollectionEmailSent: true,
            };
        case ERROR_FETCHING_RESTAURANT:
            return {
                ...state,
                error: action.error,
                restaurantFetchComplete: false,
                restaurantFetchError: true,
            };
        case UPDATE_PRICE_LEVEL_SUCCESSFUL:
            return {
                ...state,
                restaurant: {
                    ...state.restaurant,
                    priceLevel: action.priceLevel,
                },
                priceLevelUpdate: true,
            };
        case UPDATE_DELIVERY_LOCATION_SETTINGS_SUCCESSFUL:
            return {
                ...state,
                deliveryLocationSuccess: true,
            };
        case UPDATE_SUBSCRIPTION_SUCCESSFUL:
            return {
                ...state,
                restaurant: {
                    ...state.restaurant,
                    selectedPlan: action.subscription,
                },
                planSelectSuccess: true,
            };
        case UPDATE_SUBSCRIPTION_SUCCESS_FALSE:
            return {
                ...state,
                planSelectSuccess: false,
            };
        case UPDATE_ORDER_FEE_SUCCESSFUL:
            return {
                ...state,
                restaurant: {
                    ...state.restaurant,
                    orderFee: action.orderFee.orderFee,
                },
                orderFeeUpdate: true,
            };
        case UPDATE_COLORS_SUCCESSFUL:
            return {
                ...state,
                restaurant: {
                    ...state.restaurant,
                    primaryColor: action.colors.primaryColor,
                    secondaryColor: action.colors.secondaryColor,
                    backgroundColor: action.colors.backgroundColor,
                },
                colorUpdate: true,
            };
        case SIGN_UP_SUCCESSFUL:
            return {
                ...state,
                restaurant: action.data ? action.data.restaurant : {},
            };
        case ERROR_UPDATING_PRICE_LEVEL:
            return {
                ...state,
                priceLevelUpdate: false,
                priceLevelError: true,
            };
        default:
            return state;
    }
};

export default restaurantReducer;
