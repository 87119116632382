import { SET_ORDERCARD_HEIGHT } from '../types';

const initialState = {};

const orderCardHeightReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORDERCARD_HEIGHT:
      return {
        ...state,
        [action.cardType]: Math.max(
          state[action.cardType] || 0,
          action.height,
        ),
      };
    default:
      return state;
  }
};

export default orderCardHeightReducer;
