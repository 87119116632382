import _ from 'lodash';
import {
    FETCHED_HOTEL_AMENITY_REQUESTS,
    ERROR_FETCHING_HOTEL_AMENITY_REQUESTS,
    MARK_AMENITY_REQUEST_AS_COMPLETED,
    CANCEL_AMENITY_REQUEST_SUCCESSFUL,
    MARK_AMENITY_REQUEST_AS_COMPLETED_SUCCESSFUL,
    NEW_AMENITY_REQUEST,
    FETCHED_HOTEL_CHAT,
    ERROR_FETCHING_HOTEL_CHAT,
    NEW_CHAT_MESSAGE,
} from '../types';

const initialState = {};

const roomPlusReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCHED_HOTEL_AMENITY_REQUESTS:
            return {
                ...state,
                requests: _.uniqBy(
                    [
                        ...(state.requests || []),
                        ...action.amenityRequests.amenityRequests,
                    ],
                    'id'
                ).sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
                requestCounts: {
                    ...(state.requestCounts || {}),
                    [action.status]: action.amenityRequests.orderCount,
                },
            };
        case MARK_AMENITY_REQUEST_AS_COMPLETED_SUCCESSFUL:
            return {
                ...state,
                requests: _.uniqBy(
                    [
                        ...(state.requests || []).filter(
                            o => o.id !== parseInt(action.requestId, 10)
                        ),
                        {
                            ...(state.requests || []).find(
                                o => o.id === parseInt(action.requestId, 10)
                            ),
                            status: 'completed',
                            completedTime: new Date().toISOString(),
                        },
                    ],
                    'id'
                ).sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
                requestCounts: {
                    ...(state.requestCounts || {}),
                    new: (state.requestCounts.new || 0) - 1,
                    completed: (state.requestCounts.completed || 0) + 1,
                },
            };
        case CANCEL_AMENITY_REQUEST_SUCCESSFUL:
            // console.log();
            return {
                ...state,
                requests: _.uniqBy(
                    [
                        ...(state.requests || []).filter(
                            o => o.id !== parseInt(action.requestId, 10)
                        ),
                        {
                            ...(state.requests || []).find(
                                o => o.id === parseInt(action.requestId, 10)
                            ),
                            status: 'cancelled',
                        },
                    ],
                    'id'
                ).sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
                requestCounts: {
                    ...(state.requestCounts || {}),
                    new: (state.requestCounts.new || 0) - 1,
                    cancelled: (state.requestCounts.cancelled || 0) + 1,
                },
            };
        case ERROR_FETCHING_HOTEL_AMENITY_REQUESTS:
            return {
                ...state,
                error: action.error,
            };
        case NEW_AMENITY_REQUEST:
            return {
                ...state,
                requests: _.uniqBy(
                    [
                        ...(state.requests || []),
                        {
                            ...action.request.amenityRequest,
                            amenityRequestItems:
                                action.request.amenityRequestItems,
                            user: _.get(action, ['request', 'user'], {}),
                        },
                    ],
                    'id'
                ).sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
                requestCounts: {
                    ...(state.requestCounts || {}),
                    new: _.get(state, ['requestCounts', 'new'], 0) + 1,
                },
            };
        case FETCHED_HOTEL_CHAT:
            return {
                ...state,
                chat: action.chat,
            };
        case ERROR_FETCHING_HOTEL_CHAT:
            return {
                ...state,
                chatError: action.error,
            };
        case NEW_CHAT_MESSAGE:
            let currChat = (state.chat || []).find(
                c => c.id === action.chat.chatMessage.chatId
            );
            // console.log('HERE', action.chat, currChat);
            if (currChat) {
                currChat = {
                    ...currChat,
                    lastMessageDate: new Date().toISOString(),
                    chatMessages: [
                        ...currChat.chatMessages,
                        action.chat.chatMessage,
                    ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
                };
            } else {
                currChat = {
                    ...action.chat.chat,
                    chatMessages: [action.chat.chatMesage],
                };
            }
            return {
                ...state,
                chat: [
                    ...(state.chat || []).filter(
                        c => c.id !== action.chat.chatMessage.chatId
                    ),
                    currChat,
                ].sort((a, b) =>
                    a.lastMessageDate > b.lastMessageDate ? -1 : 1
                ),
            };
        default:
            return state;
    }
};

export default roomPlusReducer;
