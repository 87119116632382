import {
    FETCHED_DELITECLUB_SUBSCRIPTIONS,
    ERROR_FETCHING_DELITECLUB_SUBSCRIPTIONS,
    TOGGLE_DELITECLUB_SUBSCRIPTION_AVAILABILITY_SUCCESSFUL,
    DELETE_DELITECLUB_SUBSCRIPTION_SUCCESSFUL,
    FETCHED_PUBLIC_DELITECLUB_SUBSCRIPTIONS,
    ADD_DELITECLUB_SUBSCRIPTION_SUCCESSFUL,
    CANCEL_DELITECLUB_SUBSCRIPTION_SUCCESSFUL,
} from '../types';

const initialState = {};

const updateOrderStatus = (data, orderId, cancelImmediately) => {
    for (const subscriptionId in data) {
        const orders = data[subscriptionId];
        const orderToUpdate = orders.find(order => order.id === orderId);

        if (orderToUpdate) {
            return {
                ...data,
                [subscriptionId]: orders.map(order =>
                    order.id === orderId
                        ? {
                              ...order,
                              isActive: cancelImmediately
                                  ? 'CANCELLED'
                                  : 'PENDING CANCELLATION',
                          }
                        : order
                ),
            };
        }
    }

    return data; // Indicate that the orderId was not found
};

const deliteClubReducer = (state = initialState, action) => {
    switch (action.type) {
        case CANCEL_DELITECLUB_SUBSCRIPTION_SUCCESSFUL:
            return {
                ...state,
                subscriptionsPerOrder: updateOrderStatus(
                    state.subscriptionsPerOrder,
                    action.orderId,
                    action.cancelImmediately
                ),
            };
        case FETCHED_DELITECLUB_SUBSCRIPTIONS:
            return {
                ...state,
                deliteClubSubscriptions: action.deliteClubSubscriptions.ret.sort(
                    (a, b) => (a.createdAt > b.createdAt ? -1 : 1)
                ),
                soldSubscriptions:
                    action.deliteClubSubscriptions.soldSubscriptions,
                totalRevenue: action.deliteClubSubscriptions.totalRevenue,
                totalRevenueSpent:
                    action.deliteClubSubscriptions.totalRevenueSpent,
                mostOrderedSubscriptionOrderCount:
                    action.deliteClubSubscriptions
                        .mostOrderedSubscriptionOrderCount,
                mostOrderedSubscriptionId:
                    action.deliteClubSubscriptions.mostOrderedSubscriptionId,
                bannerClicks: action.deliteClubSubscriptions.bannerClicks,
                subscriptionsPerOrder:
                    action.deliteClubSubscriptions.subscriptionsPerOrder,
            };
        case ADD_DELITECLUB_SUBSCRIPTION_SUCCESSFUL:
            return {
                ...state,
                deliteClubSubscriptions: [
                    ...(state.deliteClubSubscriptions || []).filter(
                        o => o.id !== action.item.id
                    ),
                    action.item,
                ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
            };
        case FETCHED_PUBLIC_DELITECLUB_SUBSCRIPTIONS:
            return {
                ...state,
                publicDeliteClubSubscriptions: {
                    ...(state.publicDeliteClubSubscriptions || []),
                    [action.restaurantId]: action.deliteClubSubscriptions,
                },
            };
        case ERROR_FETCHING_DELITECLUB_SUBSCRIPTIONS:
            return {
                ...state,
                error: action.error,
            };
        case TOGGLE_DELITECLUB_SUBSCRIPTION_AVAILABILITY_SUCCESSFUL:
            const deliteClubSubscription = (
                state.deliteClubSubscriptions || []
            ).find(
                o =>
                    o.id ===
                    action.deliteClubSubscription.deliteClubSubscriptionId
            );
            return {
                ...state,
                deliteClubSubscriptions: [
                    ...(state.deliteClubSubscriptions || []).filter(
                        o =>
                            o.id !==
                            action.deliteClubSubscription
                                .deliteClubSubscriptionId
                    ),
                    {
                        ...deliteClubSubscription,
                        isActive:
                            action.deliteClubSubscription.changes.isActive,
                    },
                ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
            };
        case DELETE_DELITECLUB_SUBSCRIPTION_SUCCESSFUL:
            const deliteClubSubscription2 = (
                state.deliteClubSubscriptions || []
            ).find(
                o =>
                    o.id ===
                    action.deliteClubSubscription.deliteClubSubscriptionId
            );
            return {
                ...state,
                deliteClubSubscriptions: [
                    ...(state.deliteClubSubscriptions || []).filter(
                        o =>
                            o.id !==
                            action.deliteClubSubscription
                                .deliteClubSubscriptionId
                    ),
                    {
                        ...deliteClubSubscription2,
                        isDeleted: true,
                    },
                ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
            };
        default:
            return state;
    }
};

export default deliteClubReducer;
