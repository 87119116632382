import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

Sentry.init({
    dsn:
        'https://ab28f2d3b0acf9f846412aa3a5df1e5d@o652401.ingest.sentry.io/4505702751010816',
    environment:
        window.location.origin === 'https://www.menudelite.io'
            ? 'production_web'
            : 'staging_web',
    integrations: [new Integrations.BrowserTracing(), new Sentry.Replay()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.8,
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
});

export default Sentry;
