import {
    CONTACT_US_SUCCESSFUL,
    ERROR_CONTACTING_US,
    LOADING,
    CONTACT_US,
} from '../types';

const initialState = {};

const contactUsReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADING:
            if (action.key === CONTACT_US) {
                return {
                    ...state,
                    success: false,
                    error: null,
                };
            }

            return state;
        case CONTACT_US_SUCCESSFUL:
            return {
                ...state,
                success: true,
                error: null,
            };
        case ERROR_CONTACTING_US:
            return {
                ...state,
                error: action.error,
                success: false,
            };
        default:
            return state;
    }
};

export default contactUsReducer;
