import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import { css } from 'styled-components/macro'; //eslint-disable-line

const Container = styled.div`
    ${tw`relative bg-gray-200 -mx-8 -mb-8 px-8`};
    padding-left: 4rem;
    padding-right: 4rem;
`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 flex flex-wrap justify-between`;

const Column = tw.div`md:w-1/5`;
const WideColumn = tw(
    Column
)`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const StyledLink = styled.a`
    ${tw`border-b-2 border-transparent pb-1 transition duration-300`};
    ${({ isPaytabsWhiteLabel }) =>
        isPaytabsWhiteLabel
            ? css`
                  &:hover {
                      color: #0075c9;
                      border-color: #0075c9;
                  }
              `
            : css`
                  ${tw`hocus:text-primary-500 hocus:border-primary-500`};
              `}
`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`text-xl font-black text-primary-500`;

const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto md:mx-0 md:mr-4 `;

const SocialLinksContainer = tw.div`mt-4 `;
const SocialLink = styled.a`
    ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-700 text-gray-100 hover:bg-gray-900 transition duration-300 mr-4`}
    svg {
        ${tw`w-4 h-4`}
    }
`;

const StyledPowered = styled.div`
    color: #be2045;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    flex-direction: column;
`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-500`;

export default ({ small, isPaytabsWhiteLabel, isLoadedRestaurant }) => {
    const intl = useIntl();
    if (small)
        return (
            <StyledPowered style={{ zIndex: 1000000 }}>
                <a
                    href={
                        isPaytabsWhiteLabel
                            ? 'https://site.paytabs.com/en/tapngo/'
                            : '/'
                    }
                    style={{ alignSelf: 'center' }}
                >
                    {isPaytabsWhiteLabel ? (
                        <div
                            style={{
                                display: 'flex',
                                color: isPaytabsWhiteLabel && '#AB885A',
                            }}
                        >
                            <span style={{ marginTop: '2px' }}>
                                Powered by{' '}
                            </span>
                            <img
                                style={{
                                    width: '100px !important',
                                    marginLeft: '10px',
                                    alignSelf: 'center',
                                }}
                                width="100px"
                                src="http://hot-table-app.herokuapp.com/api/fetchStorage/tapngo-logo.png"
                            />{' '}
                        </div>
                    ) : (
                        isLoadedRestaurant && 'Powered by MenuDelite'
                    )}
                </a>
                {isPaytabsWhiteLabel && (
                    <a
                        href={'https://site.paytabs.com/en/tapngo/'}
                        style={{
                            color: 'black',
                            fontWeight: 'normal',
                            fontSize: '12px',
                            marginBottom: '40px',
                            marginTop: '10px',
                        }}
                    >
                        <b>a PayTabs Company</b>
                    </a>
                )}
                <a
                    href={
                        isPaytabsWhiteLabel
                            ? 'https://site.paytabs.com/en/privacy-policy/'
                            : '/privacy-policy'
                    }
                    style={{
                        color: isPaytabsWhiteLabel ? 'white' : 'black',
                        fontWeight: 'normal',
                        fontSize: '12px',
                    }}
                >
                    <b>Privacy Policy</b>
                </a>
            </StyledPowered>
        );
    return (
        <Container>
            <div
                style={{
                    justifyContent: 'center',
                    display: 'flex',
                    paddingTop: '50px',
                }}
            >
                {isPaytabsWhiteLabel ? (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <img
                            style={{
                                width: '200px !important',
                                marginLeft: '10px',
                                alignSelf: 'center',
                            }}
                            width="200px"
                            src="http://hot-table-app.herokuapp.com/api/fetchStorage/tapngo-logo.png"
                        />
                        <LogoText
                            style={{ marginTop: '15px', color: '#0075c9' }}
                        >
                            a PayTabs Company.
                            <CopywrightNotice style={{ marginTop: '10px' }}>
                                {intl.formatMessage({
                                    id: 'copyright_line_paytabs',
                                    defaultMessage:
                                        'Copyrights © 2024, PayTabs. All Rights Reserved.',
                                })}
                            </CopywrightNotice>
                        </LogoText>
                    </div>
                ) : (
                    <LogoText>
                        MenuDelite by HotTable App Ltd.
                        <CopywrightNotice style={{ marginTop: '10px' }}>
                            &copy;
                            {intl.formatMessage({
                                id: 'copyright_line',
                                defaultMessage:
                                    '2024 HotTable App Ltd. All Rights Reserved.',
                            })}
                        </CopywrightNotice>
                    </LogoText>
                )}
            </div>
            <FiveColumns>
                <Column style={{ width: '50%' }}>
                    <ColumnHeading
                        style={{ color: isPaytabsWhiteLabel && '#0075c9' }}
                    >
                        {isPaytabsWhiteLabel
                            ? intl.formatMessage({
                                  id: 'company',
                                  defaultMessage: 'Company',
                              })
                            : intl.formatMessage({
                                  id: 'application',
                                  defaultMessage: 'Application',
                              })}
                    </ColumnHeading>
                    <LinkList>
                        <LinkListItem>
                            <StyledLink
                                isPaytabsWhiteLabel={isPaytabsWhiteLabel}
                                href={
                                    isPaytabsWhiteLabel
                                        ? 'https://site.paytabs.com/en/about/'
                                        : '/signin'
                                }
                            >
                                {isPaytabsWhiteLabel
                                    ? intl.formatMessage({
                                          id: 'our_story',
                                          defaultMessage: 'Our Story',
                                      })
                                    : intl.formatMessage({
                                          id: 'log_in',
                                          defaultMessage: 'Log In',
                                      })}
                            </StyledLink>
                        </LinkListItem>
                        <LinkListItem>
                            <StyledLink
                                isPaytabsWhiteLabel={isPaytabsWhiteLabel}
                                href={
                                    isPaytabsWhiteLabel
                                        ? 'https://site.paytabs.com/en/blog/'
                                        : '/signup'
                                }
                            >
                                {isPaytabsWhiteLabel
                                    ? intl.formatMessage({
                                          id: 'paytabs_blog',
                                          defaultMessage: 'PayTabs Blog',
                                      })
                                    : intl.formatMessage({
                                          id: 'sign_up',
                                          defaultMessage: 'Sign Up',
                                      })}
                            </StyledLink>
                        </LinkListItem>
                        <LinkListItem>
                            <StyledLink
                                isPaytabsWhiteLabel={isPaytabsWhiteLabel}
                                href={
                                    isPaytabsWhiteLabel
                                        ? 'https://site.paytabs.com/en/newsroom/'
                                        : '/businesses'
                                }
                            >
                                {isPaytabsWhiteLabel
                                    ? intl.formatMessage({
                                          id: 'newsroom',
                                          defaultMessage: 'Newsroom',
                                      })
                                    : intl.formatMessage({
                                          id: 'join_us',
                                          defaultMessage: 'Join Us',
                                      })}
                            </StyledLink>
                        </LinkListItem>
                        <LinkListItem>
                            <StyledLink
                                isPaytabsWhiteLabel={isPaytabsWhiteLabel}
                                href={
                                    isPaytabsWhiteLabel
                                        ? 'https://site.paytabs.com/en/contact/'
                                        : '/pricing'
                                }
                            >
                                {isPaytabsWhiteLabel
                                    ? intl.formatMessage({
                                          id: 'get_in_touch',
                                          defaultMessage: 'Get In Touch',
                                      })
                                    : intl.formatMessage({
                                          id: 'pricing',
                                          defaultMessage: 'Pricing',
                                      })}
                            </StyledLink>
                        </LinkListItem>
                        <LinkListItem>
                            <StyledLink
                                isPaytabsWhiteLabel={isPaytabsWhiteLabel}
                                href={
                                    isPaytabsWhiteLabel
                                        ? 'https://site.paytabs.com/en/career/'
                                        : '/customers'
                                }
                            >
                                {isPaytabsWhiteLabel
                                    ? intl.formatMessage({
                                          id: 'careers',
                                          defaultMessage: 'Careers',
                                      })
                                    : intl.formatMessage({
                                          id: 'browse_restaurants',
                                          defaultMessage: 'For Customers',
                                      })}
                            </StyledLink>
                        </LinkListItem>
                    </LinkList>
                </Column>
                <Column style={{ width: '50%' }}>
                    <ColumnHeading
                        style={{ color: isPaytabsWhiteLabel && '#0075c9' }}
                    >
                        {intl.formatMessage({
                            id: 'legal',
                            defaultMessage: 'Legal',
                        })}
                    </ColumnHeading>
                    <LinkList>
                        <LinkListItem>
                            <StyledLink
                                isPaytabsWhiteLabel={isPaytabsWhiteLabel}
                                href={
                                    isPaytabsWhiteLabel
                                        ? 'https://site.paytabs.com/en/privacy-policy/'
                                        : '/privacy-policy'
                                }
                            >
                                {intl.formatMessage({
                                    id: 'privacy_policy',
                                    defaultMessage: 'Privacy & Cookie Policy',
                                })}
                            </StyledLink>
                        </LinkListItem>
                        <LinkListItem>
                            <StyledLink
                                isPaytabsWhiteLabel={isPaytabsWhiteLabel}
                                href={
                                    isPaytabsWhiteLabel
                                        ? 'https://site.paytabs.com/en/terms-of-use/'
                                        : '/terms-of-service'
                                }
                            >
                                {intl.formatMessage({
                                    id: 'terms_of_service',
                                    defaultMessage: 'Terms of Service',
                                })}
                            </StyledLink>
                        </LinkListItem>
                    </LinkList>
                </Column>
            </FiveColumns>
        </Container>
    );
};
