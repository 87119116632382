import {
    FETCHED_DELITEPAY_GIFT_CARDS,
    ERROR_FETCHING_DELITEPAY_GIFT_CARDS,
    TOGGLE_DELITEPAY_GIFT_CARD_AVAILABILITY_SUCCESSFUL,
    DELETE_DELITEPAY_GIFT_CARD_SUCCESSFUL,
    FETCHED_PUBLIC_DELITEPAY_GIFT_CARDS,
    ADD_DELITEPAY_GIFT_CARD_SUCCESSFUL,
} from '../types';

const initialState = {};

const delitePaysReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCHED_DELITEPAY_GIFT_CARDS:
            return {
                ...state,
                delitePayGiftCards: action.delitePayGiftCards.ret.sort((a, b) =>
                    a.createdAt > b.createdAt ? -1 : 1
                ),
                soldGiftCards: action.delitePayGiftCards.soldGiftCards,
                totalRevenue: action.delitePayGiftCards.totalRevenue,
                totalRevenueSpent: action.delitePayGiftCards.totalRevenueSpent,
                mostOrderedGiftCardOrderCount:
                    action.delitePayGiftCards.mostOrderedGiftCardOrderCount,
                mostOrderedGiftCardId:
                    action.delitePayGiftCards.mostOrderedGiftCardId,
                bannerClicks: action.delitePayGiftCards.bannerClicks,
            };
        case ADD_DELITEPAY_GIFT_CARD_SUCCESSFUL:
            return {
                ...state,
                delitePayGiftCards: [
                    ...(state.delitePayGiftCards || []),
                    action.item,
                ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
            };
        case FETCHED_PUBLIC_DELITEPAY_GIFT_CARDS:
            return {
                ...state,
                publicDelitePayGiftCards: {
                    ...(state.publicDelitePayGiftCards || []),
                    [action.restaurantId]: action.delitePayGiftCards,
                },
            };
        case ERROR_FETCHING_DELITEPAY_GIFT_CARDS:
            return {
                ...state,
                error: action.error,
            };
        case TOGGLE_DELITEPAY_GIFT_CARD_AVAILABILITY_SUCCESSFUL:
            const delitePayGiftCard = (state.delitePayGiftCards || []).find(
                o => o.id === action.delitePayGiftCard.delitePayGiftCardId
            );
            return {
                ...state,
                delitePayGiftCards: [
                    ...(state.delitePayGiftCards || []).filter(
                        o =>
                            o.id !==
                            action.delitePayGiftCard.delitePayGiftCardId
                    ),
                    {
                        ...delitePayGiftCard,
                        isActive: action.delitePayGiftCard.changes.isActive,
                    },
                ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
            };
        case DELETE_DELITEPAY_GIFT_CARD_SUCCESSFUL:
            const delitePayGiftCard2 = (state.delitePayGiftCards || []).find(
                o => o.id === action.delitePayGiftCard.delitePayGiftCardId
            );
            return {
                ...state,
                delitePayGiftCards: [
                    ...(state.delitePayGiftCards || []).filter(
                        o =>
                            o.id !==
                            action.delitePayGiftCard.delitePayGiftCardId
                    ),
                    {
                        ...delitePayGiftCard2,
                        isDeleted: true,
                    },
                ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
            };
        default:
            return state;
    }
};

export default delitePaysReducer;
