const SET_ACTIVE = 'SET_ACTIVE';
const GET_DYNAMIC_CONTENT = 'GET_DYNAMIC_CONTENT';
const FETCHED_DYNAMIC_CONTENT = 'FETCHED_DYNAMIC_CONTENT';
const ERROR_FETCHING_DYNAMIC_CONTENT = 'ERROR_FETCHING_DYNAMIC_CONTENT';
const SIGN_UP = 'SIGN_UP';
const SIGN_UP_SUCCESSFUL = 'SIGN_UP_SUCCESSFUL';
const SIGN_UP_FAILED = 'SIGN_UP_FAILED';
const LOADING = 'LOADING';
const END_LOADING = 'END_LOADING';
const SET_ORDERCARD_HEIGHT = 'SET_ORDERCARD_HEIGHT';
const ERROR_FETCHING_ORDERS = 'ERROR_FETCHING_ORDERS';
const FETCHED_ORDERS = 'FETCHED_ORDERS';
const UPLOAD_RESTAURANT_PICTURE = 'UPLOAD_RESTAURANT_PICTURE';
const UPLOAD_PICTURE_SUCCESSFUL = 'UPLOAD_PICTURE_SUCCESSFUL';
const ERROR_UPLOADING_PICTURE = 'ERROR_UPLOADING_PICTURE';
const UPDATE_PICTURE = 'UPDATE_PICTURE';
const DISMISS_PICTURE_SUCCESS = 'DISMISS_PICTURE_SUCCESS';
const FETCHED_MENU_ITEMS = 'FETCHED_MENU_ITEMS';
const ERROR_FETCHING_MENU_ITEMS = 'ERROR_FETCHING_MENU_ITEMS';
const FETCHED_SECTIONS = 'FETCHED_SECTIONS';
const ERROR_FETCHING_SECTIONS = 'ERROR_FETCHING_SECTIONS';
const CREATE_SECTION = 'CREATE_SECTION';
const CREATE_SECTION_SUCCESSFUL = 'CREATE_SECTION_SUCCESSFUL';
const CREATE_SECTION_FAILED = 'CREATE_SECTION_FAILED';
const ADD_MENU_ITEM = 'ADD_MENU_ITEM';
const ADD_MENU_ITEM_SUCCESSFUL = 'ADD_MENU_ITEM_SUCCESSFUL';
const ERROR_ADDING_MENU_ITEM = 'ERROR_ADDING_MENU_ITEM';
const EDIT_MENU_ITEM = 'EDIT_MENU_ITEM';
const EDIT_MENU_ITEM_SUCCESSFUL = 'EDIT_MENU_ITEM_SUCCESSFUL';
const ERROR_EDITING_MENU_ITEM = 'ERROR_EDITING_MENU_ITEM';
const DELETE_LOADING = 'DELETE_LOADING';
const DELETE_SECTION = 'DELETE_SECTION';
const DELETE_SECTION_FAILED = 'DELETE_SECTION_FAILED';
const DELETE_SECTION_SUCCESSFUL = 'DELETE_SECTION_SUCCESSFUL';
const DELETE_ITEM_SUCCESSFUL = 'DELETE_ITEM_SUCCESSFUL';
const DELETE_ITEM_FAILED = 'DELETE_ITEM_FAILED';
const DELETE_ITEM = 'DELETE_ITEM';
const TOGGLE_ITEM_AVAILABILITY = 'TOGGLE_ITEM_AVAILABILITY';
const TOGGLE_ITEM_AVAILABILITY_SUCCESSFUL =
    'TOGGLE_ITEM_AVAILABILITY_SUCCESSFUL';
const TOGGLE_ITEM_AVAILABILITY_FAILED = 'TOGGLE_ITEM_AVAILABILITY_FAILED';
const FETCHED_OPENING_HOURS = 'FETCHED_OPENING_HOURS';
const ERROR_FETCHING_OPENING_HOURS = 'ERROR_FETCHING_OPENING_HOURS';
const EDIT_OPENING_HOUR = 'EDIT_OPENING_HOUR';
const EDIT_OPENING_HOUR_SUCCESSFUL = 'EDIT_OPENING_HOUR_SUCCESSFUL';
const ERROR_EDITING_OPENING_HOUR = 'ERROR_EDITING_OPENING_HOUR';
const SIGN_IN_SUCCESSFUL = 'SIGN_IN_SUCCESSFUL';
const SIGN_IN_FAILED = 'SIGN_IN_FAILED';
const SIGN_IN = 'SIGN_IN';
const UPDATE_TOKENS = 'UPDATE_TOKENS';
const FETCHED_RESTAURANT = 'FETCHED_RESTAURANT';
const ERROR_FETCHING_RESTAURANT = 'ERROR_FETCHING_RESTAURANT';
const UPDATE_PRICE_LEVEL = 'UPDATE_PRICE_LEVEL';
const ERROR_UPDATING_PRICE_LEVEL = 'ERROR_UPDATING_PRICE_LEVEL';
const UPDATE_PRICE_LEVEL_SUCCESSFUL = 'UPDATE_PRICE_LEVEL_SUCCESSFUL';
const RESET_PASSWORD_EMAIL = 'RESET_PASSWORD_EMAIL';
const ERROR_RESETTING_PASSWORD_EMAIL = 'ERROR_RESETTING_PASSWORD_EMAIL';
const RESET_PASSWORD_EMAIL_SUCCESSFUL = 'RESET_PASSWORD_EMAIL_SUCCESSFUL';
const RESET_PASSWORD = 'RESET_PASSWORD';
const ERROR_RESETTING_PASSWORD = 'ERROR_RESETTING_PASSWORD';
const RESET_PASSWORD_SUCCESSFUL = 'RESET_PASSWORD_SUCCESSFUL';
const CREATING_UPDATING_TABLES_SUCCESSFUL =
    'CREATING_UPDATING_TABLES_SUCCESSFUL';
const ERROR_CREATING_UPDATING_TABLES = 'ERROR_CREATING_UPDATING_TABLES';
const CREATE_UPDATE_TABLES = 'CREATE_UPDATE_TABLES';
const ERROR_FETCHING_TABLES = 'ERROR_FETCHING_TABLES';
const FETCH_TABLES_SUCCESSFUL = 'FETCH_TABLES_SUCCESSFUL';
const FETCHED_RESERVATIONS = 'FETCHED_RESERVATIONS';
const ERROR_FETCHING_RESERVATIONS = 'ERROR_FETCHING_RESERVATIONS';
const NEW_SOCKET_ORDER = 'NEW_SOCKET_ORDER';
const NEW_SOCKET_RESERVATION = 'NEW_SOCKET_RESERVATION';
const CANCEL_ORDER = 'CANCEL_ORDER';
const MARK_AS_COMPLETED = 'MARK_AS_COMPLETED';
const CANCEL_ORDER_SUCCESSFUL = 'CANCEL_ORDER_SUCCESSFUL';
const ERROR_CANCELLING_ORDER = 'ERROR_CANCELLING_ORDER';
const MARK_AS_COMPLETED_SUCCESSFUL = 'MARK_AS_COMPLETED_SUCCESSFUL';
const ERROR_MARKING_AS_COMPLETE = 'ERROR_MARKING_AS_COMPLETE';
const FETCHED_STRIPE_ACCOUNT = 'FETCHED_STRIPE_ACCOUNT';
const ERROR_FETCHING_STRIPE_ACCOUNT = 'ERROR_FETCHING_STRIPE_ACCOUNT';
const FETCHED_PUBLIC_RESTAURANT = 'FETCHED_PUBLIC_RESTAURANT';
const ERROR_FETCHING_PUBLIC_RESTAURANT = 'ERROR_FETCHING_PUBLIC_RESTAURANT';
const FETCHED_MENU_BASKET_ITEMS = 'FETCHED_MENU_BASKET_ITEMS';
const ERROR_FETCHING_MENU_BASKET_ITEMS = 'ERROR_FETCHING_MENU_BASKET_ITEMS';
const ADD_MENU_BASKET_ITEM = 'ADD_MENU_BASKET_ITEM';
const ADD_MENU_BASKET_ITEM_SUCCESSFUL = 'ADD_MENU_BASKET_ITEM_SUCCESSFUL';
const ERROR_ADDING_MENU_BASKET_ITEM = 'ERROR_ADDING_MENU_BASKET_ITEM';
const DELETE_BASKET_ITEM_SUCCESSFUL = 'DELETE_BASKET_ITEM_SUCCESSFUL';
const DELETE_BASKET_ITEM_FAILED = 'DELETE_BASKET_ITEM_FAILED';
const DELETE_BASKET_ITEM = 'DELETE_BASKET_ITEM';
const CREATE_CHECKOUT_SESSION = 'CREATE_CHECKOUT_SESSION';
const CREATE_CHECKOUT_SESSION_SUCCESSFUL = 'CREATE_CHECKOUT_SESSION_SUCCESSFUL';
const ERROR_CREATING_CHECKOUT_SESSION = 'ERROR_CREATING_CHECKOUT_SESSION';
const UPDATE_TIP_AMOUNT_SUCCESSFUL = 'UPDATE_TIP_AMOUNT_SUCCESSFUL';
const ERROR_UPDATING_TIP_AMOUNT = 'ERROR_UPDATING_TIP_AMOUNT';
const UPDATE_TABLE_CODE_SUCCESSFUL = 'UPDATE_TABLE_CODE_SUCCESSFUL';
const ERROR_UPDATING_TABLE_CODE = 'ERROR_UPDATING_TABLE_CODE';
const UPDATE_USER_EMAIL_SUCCESSFUL = 'UPDATE_USER_EMAIL_SUCCESSFUL';
const ERROR_UPDATING_USER_EMAIL = 'ERROR_UPDATING_USER_EMAIL';
const UPDATE_TIP_AMOUNT = 'UPDATE_TIP_AMOUNT';
const UPDATE_TABLE_CODE = 'UPDATE_TABLE_CODE';
const UPDATE_USER_EMAIL = 'UPDATE_USER_EMAIL';
const FETCHED_RESTAURANTS_FOR_SEARCH = 'FETCHED_RESTAURANTS_FOR_SEARCH';
const ERROR_FETCHING_RESTAURANTS_FOR_SEARCH =
    'ERROR_FETCHING_RESTAURANTS_FOR_SEARCH';
const ERROR_FETCHING_CHECKOUT_SESSION = 'ERROR_FETCHING_CHECKOUT_SESSION';
const FETCH_CHECKOUT_SESSION_SUCCESSFUL = 'FETCH_CHECKOUT_SESSION_SUCCESSFUL';
const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
const UPDATE_USER_REQUEST_SUCCESSFUL = 'UPDATE_USER_REQUEST_SUCCESSFUL';
const ERROR_UPDATING_USER_REQUEST = 'ERROR_UPDATING_USER_REQUEST';
const CREATE_USER_WAITER_REQUEST = 'CREATE_USER_WAITER_REQUEST';
const CREATE_USER_WAITER_REQUEST_SUCCESSFUL =
    'CREATE_USER_WAITER_REQUEST_SUCCESSFUL';
const ERROR_CREATING_USER_WAITER_REQUEST = 'ERROR_CREATING_USER_WAITER_REQUEST';
const UPDATE_ORDER_NOTES_SUCCESSFUL = 'UPDATE_ORDER_NOTES_SUCCESSFUL';
const ERROR_UPDATING_ORDER_NOTES = 'ERROR_UPDATING_ORDER_NOTES';
const UPDATE_ORDER_NOTES = 'UPDATE_ORDER_NOTES';
const FETCHED_USER = 'FETCHED_USER';
const ERROR_FETCHING_USER = 'ERROR_FETCHING_USER';
const ERROR_EDITING_DETAILS = 'ERROR_EDITING_DETAILS';
const EDIT_DETAILS = 'EDIT_DETAILS';
const EDIT_DETAILS_SUCCESSFUL = 'EDIT_DETAILS_SUCCESSFUL';
const FETCHED_BOOKINGS = 'FETCHED_BOOKINGS';
const ERROR_FETCHING_BOOKINGS = 'ERROR_FETCHING_BOOKINGS';
const CREATE_BOOKING = 'CREATE_BOOKING';
const CREATE_BOOKING_SUCCESSFUL = 'CREATE_BOOKING_SUCCESSFUL';
const ERROR_CREATING_BOOKING = 'ERROR_CREATING_BOOKING';
const ERROR_FETCHING_BOOKING = 'ERROR_FETCHING_BOOKING';
const FETCH_BOOKING_SUCCESSFUL = 'FETCH_BOOKING_SUCCESSFUL';
const FETCH_BOOKING = 'FETCH_BOOKING';
const ERROR_FETCHING_OCCUPANCIES = 'ERROR_FETCHING_OCCUPANCIES';
const FETCH_OCCUPANCIES_SUCCESSFUL = 'FETCH_OCCUPANCIES_SUCCESSFUL';
const SET_LANGUAGE = 'SET_LANGUAGE';
const GET_LANGUAGE = 'GET_LANGUAGE';
const GET_LANGUAGE_SUCCESSFUL = 'GET_LANGUAGE_SUCCESSFUL';
const SET_LANGUAGE_SUCCESSFUL = 'SET_LANGUAGE_SUCCESSFUL';
const FETCHED_REDIRECT_URL = 'FETCHED_REDIRECT_URL';
const ERROR_FETCHING_REDIRECT_URL = 'ERROR_FETCHING_REDIRECT_URL';
const CONTACT_US = 'CONTACT_US';
const ERROR_CONTACTING_US = 'ERROR_CONTACTING_US';
const CONTACT_US_SUCCESSFUL = 'CONTACT_US_SUCCESSFUL';
const UPDATE_ONLINE_STATUS = 'UPDATE_ONLINE_STATUS';
const UPDATE_RESERVATION_STATUS = 'UPDATE_RESERVATION_STATUS';
const ERROR_UPDATING_ONLINE_STATUS = 'ERROR_UPDATING_ONLINE_STATUS';
const UPDATE_ONLINE_STATUS_SUCCESSFUL = 'UPDATE_ONLINE_STATUS_SUCCESSFUL';
const ERROR_UPDATING_RESERVATION_STATUS = 'ERROR_UPDATING_RESERVATION_STATUS';
const UPDATE_RESERVATION_STATUS_SUCCESSFUL =
    'UPDATE_RESERVATION_STATUS_SUCCESSFUL';
const LOG_OUT = 'LOG_OUT';
const ENABLE_TABLE = 'ENABLE_TABLE';
const DISABLE_TABLE = 'DISABLE_TABLE';
const REPORT_SENTRY_ERROR = 'REPORT_SENTRY_ERROR';
const CANCEL_RESERVATION_SUCCESSFUL = 'CANCEL_RESERVATION_SUCCESSFUL';
const ERROR_CANCELLING_RESERVATION = 'ERROR_CANCELLING_RESERVATION';
const CANCEL_RESERVATION = 'CANCEL_RESERVATION';
const ENABLE_TABLE_SUCCESSFUL = 'ENABLE_TABLE_SUCCESSFUL';
const ERROR_ENABLING_TABLE = 'ERROR_ENABLING_TABLE';
const DISABLE_TABLE_SUCCESSFUL = 'DISABLE_TABLE_SUCCESSFUL';
const ERROR_DISABLING_TABLE = 'ERROR_DISABLING_TABLE';
const FETCH_TABLES = 'FETCH_TABLES';
const UPDATE_TABLE_DETAILS = 'UPDATE_TABLE_DETAILS';
const UPDATE_TABLE_DETAILS_SUCCESSFUL = 'UPDATE_TABLE_DETAILS_SUCCESSFUL';
const ERROR_UPDATING_TABLE_DETAILS = 'ERROR_UPDATING_TABLE_DETAILS';
const UPDATE_PHONE_NUMBER = 'UPDATE_PHONE_NUMBER';
const ERROR_UPDATING_PHONE_NUMBER = 'ERROR_UPDATING_PHONE_NUMBER';
const UPDATE_PHONE_NUMBER_SUCCESSFUL = 'UPDATE_PHONE_NUMBER_SUCCESSFUL';
const UPDATE_MENU_ITEM_ORDER = 'UPDATE_MENU_ITEM_ORDER';
const UPDATE_MENU_ITEM_ORDER_SUCCESSFUL = 'UPDATE_MENU_ITEM_ORDER_SUCCESSFUL';
const UPDATE_MENU_ITEM_ORDER_FAILED = 'UPDATE_MENU_ITEM_ORDER_FAILED';
const UPDATE_SECTION_ORDER_FAILED = 'UPDATE_SECTION_ORDER_FAILED';
const UPDATE_SECTION_ORDER_SUCCESSFUL = 'UPDATE_SECTION_ORDER_SUCCESSFUL';
const UPDATE_SECTION_ORDER = 'UPDATE_SECTION_ORDER';
const UPDATE_IS_DRINKS_SUCCESSFUL = 'UPDATE_IS_DRINKS_SUCCESSFUL';
const UPDATE_IS_DRINKS_FAILED = 'UPDATE_IS_DRINKS_FAILED';
const UPDATE_IS_DRINKS = 'UPDATE_IS_DRINKS';
const UPDATE_ROOM_NUMBER = 'UPDATE_ROOM_NUMBER';
const UPDATE_ROOM_NUMBER_SUCCESSFUL = 'UPDATE_ROOM_NUMBER_SUCCESSFUL';
const ERROR_UPDATING_ROOM_NUMBER = 'ERROR_UPDATING_ROOM_NUMBER';
const UPDATE_COLORS = 'UPDATE_COLORS';
const ERROR_UPDATING_COLORS = 'ERROR_UPDATING_COLORS';
const UPDATE_COLORS_SUCCESSFUL = 'UPDATE_COLORS_SUCCESSFUL';
const UPDATE_ROOM_COUNT = 'UPDATE_ROOM_COUNT';
const ERROR_UPDATING_ROOM_COUNT = 'ERROR_UPDATING_ROOM_COUNT';
const UPDATE_ROOM_COUNT_SUCCESSFUL = 'UPDATE_ROOM_COUNT_SUCCESSFUL';
const SET_MENU_LANGUAGE = 'SET_MENU_LANGUAGE';
const GET_MENU_LANGUAGE = 'GET_MENU_LANGUAGE';
const GET_MENU_LANGUAGE_SUCCESSFUL = 'GET_MENU_LANGUAGE_SUCCESSFUL';
const SET_MENU_LANGUAGE_SUCCESSFUL = 'SET_MENU_LANGUAGE_SUCCESSFUL';
const SET_MENU_LANGUAGE_OPTIONS = 'SET_MENU_LANGUAGE_OPTIONS';
const GET_MENU_LANGUAGE_OPTIONS = 'GET_MENU_LANGUAGE_OPTIONS';
const GET_MENU_LANGUAGE_OPTIONS_SUCCESSFUL =
    'GET_MENU_LANGUAGE_OPTIONS_SUCCESSFUL';
const SET_MENU_LANGUAGE_OPTIONS_SUCCESSFUL =
    'SET_MENU_LANGUAGE_OPTIONS_SUCCESSFUL';
const UPDATE_ORDER_FEE = 'UPDATE_ORDER_FEE';
const ERROR_UPDATING_ORDER_FEE = 'ERROR_UPDATING_ORDER_FEE';
const UPDATE_ORDER_FEE_SUCCESSFUL = 'UPDATE_ORDER_FEE_SUCCESSFUL';
const FETCHED_SUBSCRIPTIONS = 'FETCHED_SUBSCRIPTIONS';
const ERROR_FETCHING_SUBSCRIPTIONS = 'ERROR_FETCHING_SUBSCRIPTIONS';
const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION';
const UPDATE_SUBSCRIPTION_SUCCESSFUL = 'UPDATE_SUBSCRIPTION_SUCCESSFUL';
const ERROR_UPDATING_SUBSCRIPTION = 'ERROR_UPDATING_SUBSCRIPTION';
const UPDATE_SUBSCRIPTION_SUCCESS_FALSE = 'UPDATE_SUBSCRIPTION_SUCCESS_FALSE';
const UPDATE_PICTURE_SUCCESS_FALSE = 'UPDATE_PICTURE_SUCCESS_FALSE';
const CREATE_SUBSCRIPTION_CHECKOUT_SESSION =
    'CREATE_SUBSCRIPTION_CHECKOUT_SESSION';
const ERROR_CREATING_SUBSCRIPTION_CHECKOUT_SESSION =
    'ERROR_CREATING_SUBSCRIPTION_CHECKOUT_SESSION';
const ERROR_FETCHING_SUBSCRIPTION_CHECKOUT_SESSION =
    'ERROR_FETCHING_SUBSCRIPTION_CHECKOUT_SESSION';
const FETCH_CHECKOUT_SUBSCRIPTION_SESSION_SUCCESSFUL =
    'FETCH_CHECKOUT_SUBSCRIPTION_SESSION_SUCCESSFUL';
const FETCH_SUBSCRIPTION_DETAILS = 'FETCH_SUBSCRIPTION_DETAILS';
const FETCH_SUBSCRIPTION_DETAILS_SUCCESS = 'FETCH_SUBSCRIPTION_DETAILS';
const ERROR_FETCHING_SUBSCRIPTION_DETAILS =
    'ERROR_FETCHING_SUBSCRIPTION_DETAILS';
const ADD_OFFER = 'ADD_OFFER';
const ADD_OFFER_SUCCESSFUL = 'ADD_OFFER_SUCCESSFUL';
const ERROR_ADDING_OFFER = 'ERROR_ADDING_OFFER';
const FETCHED_OFFERS = 'FETCHED_OFFERS';
const ERROR_FETCHING_OFFERS = 'ERROR_FETCHING_OFFERS';
const TOGGLE_OFFER_AVAILABILITY_FAILED = 'TOGGLE_OFFER_AVAILABILITY_FAILED';
const TOGGLE_OFFER_AVAILABILITY_SUCCESSFUL =
    'TOGGLE_OFFER_AVAILABILITY_SUCCESSFUL';
const TOGGLE_OFFER_AVAILABILITY = 'TOGGLE_OFFER_AVAILABILITY';
const DELETE_OFFER = 'DELETE_OFFER';
const DELETE_OFFER_FAILED = 'DELETE_OFFER_FAILED';
const DELETE_OFFER_SUCCESSFUL = 'DELETE_OFFER_SUCCESSFUL';
const FETCHED_PUBLIC_OFFERS = 'FETCHED_PUBLIC_OFFERS';
const ERROR_FETCHING_PUBLIC_OFFERS = 'ERROR_FETCHING_PUBLIC_OFFERS';
const CHECK_IF_USER_EXISTS_SUCCESSFUL = 'CHECK_IF_USER_EXISTS_SUCCESSFUL';
const CHECK_IF_USER_EXISTS_FAILED = 'CHECK_IF_USER_EXISTS_FAILED';
const CHECK_IF_USER_EXISTS = 'CHECK_IF_USER_EXISTS';
const CHECK_IF_CODE_VALIDITY = 'CHECK_IF_CODE_VALIDITY';
const CHECK_IF_CODE_VALIDITY_FAILED = 'CHECK_IF_CODE_VALIDITY_FAILED';
const CHECK_IF_CODE_VALIDITY_SUCCESSFUL = 'CHECK_IF_CODE_VALIDITY_SUCCESSFUL';
const USER_SIGN_UP_SUCCESSFUL = 'USER_SIGN_UP_SUCCESSFUL';
const USER_SIGN_UP_FAILED = 'USER_SIGN_UP_FAILED';
const USER_SIGN_UP = 'USER_SIGN_UP';
const FETCHED_ORDERS_FOR_USER = 'FETCHED_ORDERS_FOR_USER';
const ERROR_FETCHING_ORDERS_FOR_USER = 'ERROR_FETCHING_ORDERS_FOR_USER';
const FETCH_ORDERS_FOR_USER = 'FETCH_ORDERS_FOR_USER';
const CHECK_USER_EMAIL_FOR_CHECKOUT_SESSION_SUCCESSFUL =
    'CHECK_USER_EMAIL_FOR_CHECKOUT_SESSION_SUCCESSFUL';
const FETCHED_CUSTOMER_USER = 'FETCHED_CUSTOMER_USER';
const ERROR_FETCHING_CUSTOMER_USER = 'ERROR_FETCHING_CUSTOMER_USER';
const FETCHED_OFFER_REDEMPTIONS = 'FETCHED_OFFER_REDEMPTIONS';
const ERROR_FETCHING_OFFER_REDEMPTIONS = 'ERROR_FETCHING_OFFER_REDEMPTIONS';
const SET_EMAIL_EXISTS_UNDEFINED = 'SET_EMAIL_EXISTS_UNDEFINED';
const FETCHED_CUSTOMER_USER_REWARDS = 'FETCHED_CUSTOMER_USER_REWARDS';
const ERROR_FETCHING_CUSTOMER_USER_REWARDS =
    'ERROR_FETCHING_CUSTOMER_USER_REWARDS';
const FETCHED_CUSTOMER_USER_OFFERS = 'FETCHED_CUSTOMER_USER_OFFERS';
const ERROR_FETCHING_CUSTOMER_USER_OFFERS =
    'ERROR_FETCHING_CUSTOMER_USER_OFFERS';
const UPDATE_REVIEW_SETTINGS = 'UPDATE_REVIEW_SETTINGS';
const UPDATE_REVIEW_SETTINGS_SUCCESSFUL = 'UPDATE_REVIEW_SETTINGS_SUCCESSFUL';
const ERROR_UPDATING_REVIEW_SETTINGS = 'ERROR_UPDATING_REVIEW_SETTINGS';
const FETCHED_REQUESTS = 'FETCHED_REQUESTS';
const ERROR_FETCHING_REQUESTS = 'ERROR_FETCHING_REQUESTS';
const DELETE_REQUEST = 'DELETE_REQUEST';
const DELETE_REQUEST_FAILED = 'DELETE_REQUEST_FAILED';
const DELETE_REQUEST_SUCCESSFUL = 'DELETE_REQUEST_SUCCESSFUL';
const FETCHED_PAY_LATER = 'FETCHED_PAY_LATER';
const ERROR_FETCHING_PAY_LATER = 'ERROR_FETCHING_PAY_LATER';
const NEW_SOCKET_PAYMENT = 'NEW_SOCKET_PAYMENT';
const UPDATE_REVIEW_SETTINGS_SAMPLE_EMAIL =
    'UPDATE_REVIEW_SETTINGS_SAMPLE_EMAIL';
const UPDATE_REVIEW_SETTINGS_SAMPLE_EMAIL_SUCCESSFUL =
    'UPDATE_REVIEW_SETTINGS_SAMPLE_EMAIL_SUCCESSFUL';
const UPDATE_PAY_LATER_SETTINGS = 'UPDATE_PAY_LATER_SETTINGS';
const UPDATE_PAY_LATER_SETTINGS_SUCCESSFUL =
    'UPDATE_PAY_LATER_SETTINGS_SUCCESSFUL';
const ERROR_UPDATING_PAY_LATER_SETTINGS = 'ERROR_UPDATING_PAY_LATER_SETTINGS';
const SET_COOKIE_CONSENT = 'SET_COOKIE_CONSENT';
const GET_COOKIE_CONSENT = 'GET_COOKIE_CONSENT';
const GET_COOKIE_CONSENT_SUCCESSFUL = 'GET_COOKIE_CONSENT_SUCCESSFUL';
const SET_COOKIE_CONSENT_SUCCESSFUL = 'SET_COOKIE_CONSENT_SUCCESSFUL';
const DISMISS_WAITER_BOX = 'DISMISS_WAITER_BOX';
const FETCHED_HOTEL_AMENITIES = 'FETCHED_HOTEL_AMENITIES';
const ERROR_FETCHING_HOTEL_AMENITIES = 'ERROR_FETCHING_HOTEL_AMENITIES';
const TOGGLE_AMENITY_AVAILABILITY_SUCCESSFUL =
    'TOGGLE_AMENITY_AVAILABILITY_SUCCESSFUL';
const TOGGLE_AMENITY_AVAILABILITY_FAILED = 'TOGGLE_AMENITY_AVAILABILITY_FAILED';
const TOGGLE_AMENITY_AVAILABILITY = 'TOGGLE_AMENITY_AVAILABILITY';
const CREATE_AMENITY = 'CREATE_AMENITY';
const CREATE_AMENITY_FAILED = 'CREATE_AMENITY_FAILED';
const CREATE_AMENITY_SUCCESSFUL = 'CREATE_AMENITY_SUCCESSFUL';
const EDIT_AMENITY = 'EDIT_AMENITY';
const EDIT_AMENITY_SUCCESSFUL = 'EDIT_AMENITY_SUCCESSFUL';
const ERROR_EDITING_AMENITY = 'ERROR_EDITING_AMENITY';
const DELETE_AMENITY = 'DELETE_AMENITY';
const DELETE_AMENITY_FAILED = 'DELETE_AMENITY_FAILED';
const DELETE_AMENITY_SUCCESSFUL = 'DELETE_AMENITY_SUCCESSFUL';
const DELETE_AMENITY_TYPE = 'DELETE_AMENITY_TYPE';
const DELETE_AMENITY_TYPE_SUCCESSFUL = 'DELETE_AMENITY_TYPE_SUCCESSFUL';
const DELETE_AMENITY_TYPE_FAILED = 'DELETE_AMENITY_TYPE_FAILED';
const TOGGLE_AMENITY_TYPE_AVAILABILITY = 'TOGGLE_AMENITY_TYPE_AVAILABILITY';
const TOGGLE_AMENITY_TYPE_AVAILABILITY_SUCCESSFUL =
    'TOGGLE_AMENITY_TYPE_AVAILABILITY_SUCCESSFUL';
const TOGGLE_AMENITY_TYPE_AVAILABILITY_FAILED =
    'TOGGLE_AMENITY_TYPE_AVAILABILITY_FAILED';
const CREATE_AMENITY_TYPE = 'CREATE_AMENITY_TYPE';
const CREATE_AMENITY_TYPE_FAILED = 'CREATE_AMENITY_TYPE_FAILED';
const CREATE_AMENITY_TYPE_SUCCESSFUL = 'CREATE_AMENITY_TYPE_SUCCESSFUL';
const FETCHED_PUBLIC_AMENITIES = 'FETCHED_PUBLIC_AMENITIES';
const ERROR_FETCHING_PUBLIC_AMENITIES = 'ERROR_FETCHING_PUBLIC_AMENITIES';
const CREATE_AMENITY_REQUEST = 'CREATE_AMENITY_REQUEST';
const CREATE_AMENITY_REQUEST_SUCCESSFUL = 'CREATE_AMENITY_REQUEST_SUCCESSFUL';
const ERROR_CREATING_AMENITY_REQUEST = 'ERROR_CREATING_AMENITY_REQUEST';
const SIGN_IN_TO_ROOM_PLUS = 'SIGN_IN_TO_ROOM_PLUS';
const ERROR_SIGNING_IN_TO_ROOM_PLUS = 'ERROR_SIGNING_IN_TO_ROOM_PLUS';
const SIGN_IN_TO_ROOM_PLUS_SUCCESSFUL = 'SIGN_IN_TO_ROOM_PLUS_SUCCESSFUL';
const UPDATE_USER_NAME = 'UPDATE_USER_NAME';
const UPDATE_USER_NAME_SUCCESSFUL = 'UPDATE_USER_NAME_SUCCESSFUL';
const ERROR_UPDATING_USER_NAME = 'ERROR_UPDATING_USER_NAME';
const FETCHED_ROOM_PLUS_USER = 'FETCHED_ROOM_PLUS_USER';
const ERROR_FETCHING_ROOM_PLUS_USER = 'ERROR_FETCHING_ROOM_PLUS_USER';
const FETCHED_AMENITY_REQUESTS = 'FETCHED_AMENITY_REQUESTS';
const ERROR_FETCHING_AMENITY_REQUESTS = 'ERROR_FETCHING_AMENITY_REQUESTS';
const FETCHED_HOTEL_AMENITY_REQUESTS = 'FETCHED_HOTEL_AMENITY_REQUESTS';
const ERROR_FETCHING_HOTEL_AMENITY_REQUESTS =
    'ERROR_FETCHING_HOTEL_AMENITY_REQUESTS';
const CANCEL_AMENITY_REQUEST = 'CANCEL_AMENITY_REQUEST';
const CANCEL_AMENITY_REQUEST_SUCCESSFUL = 'CANCEL_AMENITY_REQUEST_SUCCESSFUL';
const ERROR_CANCELLING_AMENITY_REQUEST = 'ERROR_CANCELLING_AMENITY_REQUEST';
const MARK_AMENITY_REQUEST_AS_COMPLETED = 'MARK_AMENITY_REQUEST_AS_COMPLETED';
const MARK_AMENITY_REQUEST_AS_COMPLETED_SUCCESSFUL =
    'MARK_AMENITY_REQUEST_AS_COMPLETED_SUCCESSFUL';
const ERROR_MARKING_AMENITY_REQUEST_AS_COMPLETE =
    'ERROR_MARKING_AMENITY_REQUEST_AS_COMPLETE';
const ERROR_CREATING_NEW_USER_MESSAGE = 'ERROR_CREATING_NEW_USER_MESSAGE';
const CREATE_NEW_USER_MESSAGE_SUCCESSFUL = 'CREATE_NEW_USER_MESSAGE_SUCCESSFUL';
const CREATE_NEW_USER_MESSAGE = 'CREATE_NEW_USER_MESSAGE';
const FETCHED_USER_CHAT = 'FETCHED_USER_CHAT';
const ERROR_FETCHING_USER_CHAT = 'ERROR_FETCHING_USER_CHAT';
const FETCHED_HOTEL_CHAT = 'FETCHED_HOTEL_CHAT';
const ERROR_FETCHING_HOTEL_CHAT = 'ERROR_FETCHING_HOTEL_CHAT';
const NEW_CHAT_MESSAGE = 'NEW_CHAT_MESSAGE';
const FETCHED_HOTEL_DELIVERY_LOCATIONS = 'FETCHED_HOTEL_DELIVERY_LOCATIONS';
const ERROR_FETCHING_HOTEL_DELIVERY_LOCATIONS =
    'ERROR_FETCHING_HOTEL_DELIVERY_LOCATIONS';
const TOGGLE_DELIVERY_LOCATION_AVAILABILITY =
    'TOGGLE_DELIVERY_LOCATION_AVAILABILITY';
const TOGGLE_DELIVERY_LOCATION_AVAILABILITY_SUCCESSFUL =
    'TOGGLE_DELIVERY_LOCATION_AVAILABILITY_SUCCESSFUL';
const TOGGLE_DELIVERY_LOCATION_AVAILABILITY_FAILED =
    'TOGGLE_DELIVERY_LOCATION_AVAILABILITY_FAILED';
const DELETE_DELIVERY_LOCATION = 'DELETE_DELIVERY_LOCATION';
const DELETE_DELIVERY_LOCATION_SUCCESSFUL =
    'DELETE_DELIVERY_LOCATION_SUCCESSFUL';
const DELETE_DELIVERY_LOCATION_FAILED = 'DELETE_DELIVERY_LOCATION_FAILED';
const CREATE_DELIVERY_LOCATION = 'CREATE_DELIVERY_LOCATION';
const CREATE_DELIVERY_LOCATION_FAILED = 'CREATE_DELIVERY_LOCATION_FAILED';
const CREATE_DELIVERY_LOCATION_SUCCESSFUL =
    'CREATE_DELIVERY_LOCATION_SUCCESSFUL';
const UPDATE_DELIVERY_LOCATION_SETTINGS = 'UPDATE_DELIVERY_LOCATION_SETTINGS';
const UPDATE_DELIVERY_LOCATION_SETTINGS_SUCCESSFUL =
    'UPDATE_DELIVERY_LOCATION_SETTINGS_SUCCESSFUL';
const ERROR_UPDATING_DELIVERY_LOCATION_SETTINGS =
    'ERROR_UPDATING_DELIVERY_LOCATION_SETTINGS';
const UPDATE_IS_VISITOR = 'UPDATE_IS_VISITOR';
const ERROR_UPDATING_IS_VISITOR = 'ERROR_UPDATING_IS_VISITOR';
const UPDATE_IS_VISITOR_SUCCESSFUL = 'UPDATE_IS_VISITOR_SUCCESSFUL';
const UPDATE_ORDER_INFORMATION_SETTINGS = 'UPDATE_ORDER_INFORMATION_SETTINGS';
const UPDATE_ORDER_INFORMATION_SETTINGS_SUCCESSFUL =
    'UPDATE_ORDER_INFORMATION_SETTINGS_SUCCESSFUL';
const ERROR_UPDATING_ORDER_INFORMATION_SETTINGS =
    'ERROR_UPDATING_ORDER_INFORMATION_SETTINGS';
const FETCHED_REVIEW_TRACKS = 'FETCHED_REVIEW_TRACKS';
const ERROR_FETCHING_REVIEW_TRACKS = 'ERROR_FETCHING_REVIEW_TRACKS';
const CREATE_MODIFIER_GROUP = 'CREATE_MODIFIER_GROUP';
const CREATE_MODIFIER_GROUP_SUCCESSFUL = 'CREATE_MODIFIER_GROUP_SUCCESSFUL';
const CREATE_MODIFIER_GROUP_FAILED = 'CREATE_MODIFIER_GROUP_FAILED';
const FETCHED_MODIFIER_GROUPS = 'FETCHED_MODIFIER_GROUPS';
const ERROR_FETCHING_MODIFIER_GROUPS = 'ERROR_FETCHING_MODIFIER_GROUPS';
const DELETE_MODIFIER_GROUP = 'DELETE_MODIFIER_GROUP';
const DELETE_MODIFIER_GROUP_FAILED = 'DELETE_MODIFIER_GROUP_FAILED';
const DELETE_MODIFIER_GROUP_SUCCESSFUL = 'DELETE_MODIFIER_GROUP_SUCCESSFUL';
const ADD_MODIFIER = 'ADD_MODIFIER';
const ADD_MODIFIER_SUCCESSFUL = 'ADD_MODIFIER_SUCCESSFUL';
const ERROR_ADDING_MODIFIER = 'ERROR_ADDING_MODIFIER';
const EDIT_MODIFIER = 'EDIT_MODIFIER';
const EDIT_MODIFIER_SUCCESSFUL = 'EDIT_MODIFIER_SUCCESSFUL';
const ERROR_EDITING_MODIFIER = 'ERROR_EDITING_MODIFIER';
const DELETE_MODIFIER_SUCCESSFUL = 'DELETE_MODIFIER_SUCCESSFUL';
const DELETE_MODIFIER_FAILED = 'DELETE_MODIFIER_FAILED';
const DELETE_MODIFIER = 'DELETE_MODIFIER';
const TOGGLE_MODIFIER_AVAILABILITY = 'TOGGLE_MODIFIER_AVAILABILITY';
const TOGGLE_MODIFIER_AVAILABILITY_SUCCESSFUL =
    'TOGGLE_MODIFIER_AVAILABILITY_SUCCESSFUL';
const TOGGLE_MODIFIER_AVAILABILITY_FAILED =
    'TOGGLE_MODIFIER_AVAILABILITY_FAILED';
const EDIT_MODIFIER_GROUP = 'EDIT_MODIFIER_GROUP';
const EDIT_MODIFIER_GROUP_SUCCESSFUL = 'EDIT_MODIFIER_GROUP_SUCCESSFUL';
const EDIT_MODIFIER_GROUP_FAILED = 'EDIT_MODIFIER_GROUP_FAILED';
const ADD_MODIFIER_TO_ITEM = 'ADD_MODIFIER_TO_ITEM';
const ADD_MODIFIER_TO_ITEM_SUCCESSFUL = 'ADD_MODIFIER_TO_ITEM_SUCCESSFUL';
const ADD_MODIFIER_TO_ITEM_FAILED = 'ADD_MODIFIER_TO_ITEM_FAILED';
const REMOVE_MODIFIER_FROM_ITEM = 'REMOVE_MODIFIER_FROM_ITEM';
const REMOVE_MODIFIER_FROM_ITEM_FAILED = 'REMOVE_MODIFIER_FROM_ITEM_FAILED';
const REMOVE_MODIFIER_FROM_ITEM_SUCCESSFUL =
    'REMOVE_MODIFIER_FROM_ITEM_SUCCESSFUL';
const SYNC_MENU_WITH_POS = 'SYNC_MENU_WITH_POS';
const SYNC_MENU_WITH_POS_FAILED = 'SYNC_MENU_WITH_POS_FAILED';
const SYNC_MENU_WITH_POS_SUCCESSFUL = 'SYNC_MENU_WITH_POS_SUCCESSFUL';
const UPDATE_PICK_UP_SETTINGS = 'UPDATE_PICK_UP_SETTINGS';
const UPDATE_PICK_UP_SETTINGS_SUCCESSFUL = 'UPDATE_PICK_UP_SETTINGS_SUCCESSFUL';
const ERROR_UPDATING_PICK_UP_SETTINGS = 'ERROR_UPDATING_PICK_UP_SETTINGS';
const ERROR_FETCHING_PICK_UP_TABLE = 'ERROR_FETCHING_PICK_UP_TABLE';
const FETCH_PICK_UP_TABLE_SUCCESSFUL = 'FETCH_PICK_UP_TABLE_SUCCESSFUL';
const ERROR_FETCHING_TABLE_DETAILS = 'ERROR_FETCHING_TABLE_DETAILS';
const FETCH_TABLE_DETAILS_SUCCESSFUL = 'FETCH_TABLE_DETAILS_SUCCESSFUL';
const SEND_ORDER_SMS_SUCCESSFUL = 'SEND_ORDER_SMS_SUCCESSFUL';
const SEND_SMS_ORDER = 'SEND_SMS_ORDER';
const ERROR_SENDING_SMS_ORDER = 'ERROR_SENDING_SMS_ORDER';
const FETCHED_REPORTS = 'FETCHED_REPORTS';
const ERROR_FETCHING_REPORTS = 'ERROR_FETCHING_REPORTS';
const FETCH_REPORTS = 'FETCH_REPORTS';
const UPDATE_REMOTE_PICK_UP_SETTINGS = 'UPDATE_REMOTE_PICK_UP_SETTINGS';
const UPDATE_REMOTE_PICK_UP_SETTINGS_SUCCESSFUL =
    'UPDATE_REMOTE_PICK_UP_SETTINGS_SUCCESSFUL';
const ERROR_UPDATING_REMOTE_PICK_UP_SETTINGS =
    'ERROR_UPDATING_REMOTE_PICK_UP_SETTINGS';
const FETCHED_LOCAL_OPENING_TIMES = 'FETCHED_LOCAL_OPENING_TIMES';
const ERROR_FETCHING_LOCAL_OPENING_TIMES = 'ERROR_FETCHING_LOCAL_OPENING_TIMES';
const AUTH_WITH_ORACLE = 'AUTH_WITH_ORACLE';
const AUTH_WITH_ORACLE_SUCCESSFUL = 'AUTH_WITH_ORACLE_SUCCESSFUL';
const ERROR_AUTH_WITH_ORACLE = 'ERROR_AUTH_WITH_ORACLE';
const SOCKET_ORDER_COMPLETED = 'order-completed';
const SOCKET_ORDER_CANCELLED = 'order_cancelled';
const SOCKET_DELIVERECT_ORDER_FAILURE = 'DELIVERECT_ORDER_FAILURE';
const SOCKET_DELIVERY_UPDATE_RECEIVED = 'DELIVERY_UPDATE_RECEIVED';
const UPDATE_WAITER_REQUEST_SETTINGS_SUCCESSFUL =
    'UPDATE_WAITER_REQUEST_SETTINGS_SUCCESSFUL';
const ERROR_UPDATING_WAITER_REQUEST_SETTINGS =
    'ERROR_UPDATING_WAITER_REQUEST_SETTINGS';
const CREATE_EMPLOYEE = 'CREATE_EMPLOYEE';
const CREATE_EMPLOYEE_SUCCESSFUL = 'CREATE_EMPLOYEE_SUCCESSFUL';
const CREATE_EMPLOYEE_FAILED = 'CREATE_EMPLOYEE_FAILED';
const FETCHED_EMPLOYEES = 'FETCHED_EMPLOYEES';
const ERROR_FETCHING_EMPLOYEES = 'ERROR_FETCHING_EMPLOYEES';
const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE';
const DELETE_EMPLOYEE_FAILED = 'DELETE_EMPLOYEE_FAILED';
const DELETE_EMPLOYEE_SUCCESSFUL = 'DELETE_EMPLOYEE_SUCCESSFUL';
const EDIT_EMPLOYEE = 'EDIT_EMPLOYEE';
const EDIT_EMPLOYEE_SUCCESSFUL = 'EDIT_EMPLOYEE_SUCCESSFUL';
const EDIT_EMPLOYEE_FAILED = 'EDIT_EMPLOYEE_FAILED';
const ASSIGN_EMPLOYEE_TO_ORDER = 'ASSIGN_EMPLOYEE_TO_ORDER';
const ASSIGN_EMPLOYEE_TO_ORDER_SUCCESSFUL =
    'ASSIGN_EMPLOYEE_TO_ORDER_SUCCESSFUL';
const ASSIGN_EMPLOYEE_TO_ORDER_FAILED = 'ASSIGN_EMPLOYEE_TO_ORDER_FAILED';
const UPDATE_REQUIRES_EMPLOYEE_ASSIGNMENT =
    'UPDATE_REQUIRES_EMPLOYEE_ASSIGNMENT';
const UPDATE_REQUIRES_EMPLOYEE_ASSIGNMENT_FAILED =
    'UPDATE_REQUIRES_EMPLOYEE_ASSIGNMENT_FAILED';
const UPDATE_REQUIRES_EMPLOYEE_ASSIGNMENT_SUCCESSFUL =
    'UPDATE_REQUIRES_EMPLOYEE_ASSIGNMENT_SUCCESSFUL';
const SOCKET_EMPLOYEE_ID_ASSIGNED = 'EMPLOYEE_ID_ASSIGNED';
const POS_TABLE_SYNC_SUCCESSFUL = 'POS_TABLE_SYNC_SUCCESSFUL';
const ERROR_SYNCING_POS_TABLES = 'ERROR_SYNCING_POS_TABLES';
const POS_TABLE_SYNC = 'POS_TABLE_SYNC';
const UPDATE_AUTO_CLOSE_SETTINGS = 'UPDATE_AUTO_CLOSE_SETTINGS';
const UPDATE_AUTO_CLOSE_SETTINGS_SUCCESSFUL =
    'UPDATE_AUTO_CLOSE_SETTINGS_SUCCESSFUL';
const ERROR_UPDATING_AUTO_CLOSE_SETTINGS = 'ERROR_UPDATING_AUTO_CLOSE_SETTINGS';
const UPDATE_SOCIAL_SETTINGS = 'UPDATE_SOCIAL_SETTINGS';
const UPDATE_SOCIAL_SETTINGS_SUCCESSFUL = 'UPDATE_SOCIAL_SETTINGS_SUCCESSFUL';
const ERROR_UPDATING_SOCIAL_SETTINGS = 'ERROR_UPDATING_SOCIAL_SETTINGS';
const FETCHED_SOCIAL_URL = 'FETCHED_SOCIAL_URL';
const FETCHED_SOCIAL_TRACKS = 'FETCHED_SOCIAL_TRACKS';
const ERROR_FETCHING_SOCIAL_TRACKS = 'ERROR_FETCHING_SOCIAL_TRACKS';
const ERROR_FETCHING_SOCIAL_URL = 'ERROR_FETCHING_SOCIAL_URL';
const CREATE_TAG = 'CREATE_TAG';
const CREATE_TAG_SUCCESSFUL = 'CREATE_TAG_SUCCESSFUL';
const CREATE_TAG_FAILED = 'CREATE_TAG_FAILED';
const FETCHED_TAGS = 'FETCHED_TAGS';
const ERROR_FETCHING_TAGS = 'ERROR_FETCHING_TAGS';
const DELETE_TAG = 'DELETE_TAG';
const DELETE_TAG_FAILED = 'DELETE_TAG_FAILED';
const DELETE_TAG_SUCCESSFUL = 'DELETE_TAG_SUCCESSFUL';
const EDIT_TAG = 'EDIT_TAG';
const EDIT_TAG_SUCCESSFUL = 'EDIT_TAG_SUCCESSFUL';
const EDIT_TAG_FAILED = 'EDIT_TAG_FAILED';
const ADD_TAG_TO_ITEM = 'ADD_TAG_TO_ITEM';
const ADD_TAG_TO_ITEM_SUCCESSFUL = 'ADD_TAG_TO_ITEM_SUCCESSFUL';
const ADD_TAG_TO_ITEM_FAILED = 'ADD_TAG_TO_ITEM_FAILED';
const REMOVE_TAG_FROM_ITEM = 'REMOVE_TAG_FROM_ITEM';
const REMOVE_TAG_FROM_ITEM_FAILED = 'REMOVE_TAG_FROM_ITEM_FAILED';
const REMOVE_TAG_FROM_ITEM_SUCCESSFUL = 'REMOVE_TAG_FROM_ITEM_SUCCESSFUL';
const FETCHED_FILTER_OPTIONS = 'FETCHED_FILTER_OPTIONS';
const ERROR_FETCHING_FILTER_OPTIONS = 'ERROR_FETCHING_FILTER_OPTIONS';
const UPDATE_FILTER_OPTIONS = 'UPDATE_FILTER_OPTIONS';
const GET_FILTER_OPTIONS = 'GET_FILTER_OPTIONS';
const ERROR_UPDATING_FILTER_OPTIONS = 'ERROR_UPDATING_FILTER_OPTIONS';
const UPDATE_FILTER_OPTIONS_SUCCESSFUL = 'UPDATE_FILTER_OPTIONS_SUCCESSFUL';
const GET_FILTER_OPTIONS_SUCCESSFUL = 'GET_FILTER_OPTIONS_SUCCESSFUL';
const FETCHED_AVAILABILITY_DAYS = 'FETCHED_AVAILABILITY_DAYS';
const ERROR_FETCHING_AVAILABILITY_DAYS = 'ERROR_FETCHING_AVAILABILITY_DAYS';
const EDIT_AVAILABILITY_DAY = 'EDIT_AVAILABILITY_DAY';
const EDIT_AVAILABILITY_DAY_SUCCESSFUL = 'EDIT_AVAILABILITY_DAY_SUCCESSFUL';
const ERROR_EDITING_AVAILABILITY_DAY = 'ERROR_EDITING_AVAILABILITY_DAY';
const FETCHED_DISCOUNT_DAYS = 'FETCHED_DISCOUNT_DAYS';
const ERROR_FETCHING_DISCOUNT_DAYS = 'ERROR_FETCHING_DISCOUNT_DAYS';
const EDIT_DISCOUNT_DAY = 'EDIT_DISCOUNT_DAY';
const EDIT_DISCOUNT_DAY_SUCCESSFUL = 'EDIT_DISCOUNT_DAY_SUCCESSFUL';
const ERROR_EDITING_DISCOUNT_DAY = 'ERROR_EDITING_DISCOUNT_DAY';
const TOGGLE_AVAILABILITY_SUCCESS = 'TOGGLE_AVAILABILITY_SUCCESS';
const CREATE_ALLERGEN = 'CREATE_ALLERGEN';
const CREATE_ALLERGEN_SUCCESSFUL = 'CREATE_ALLERGEN_SUCCESSFUL';
const CREATE_ALLERGEN_FAILED = 'CREATE_ALLERGEN_FAILED';
const FETCHED_ALLERGENS = 'FETCHED_ALLERGENS';
const ERROR_FETCHING_ALLERGENS = 'ERROR_FETCHING_ALLERGENS';
const DELETE_ALLERGEN = 'DELETE_ALLERGEN';
const DELETE_ALLERGEN_FAILED = 'DELETE_ALLERGEN_FAILED';
const DELETE_ALLERGEN_SUCCESSFUL = 'DELETE_ALLERGEN_SUCCESSFUL';
const EDIT_ALLERGEN = 'EDIT_ALLERGEN';
const EDIT_ALLERGEN_SUCCESSFUL = 'EDIT_ALLERGEN_SUCCESSFUL';
const EDIT_ALLERGEN_FAILED = 'EDIT_ALLERGEN_FAILED';
const ADD_ALLERGEN_TO_ITEM = 'ADD_ALLERGEN_TO_ITEM';
const ADD_ALLERGEN_TO_ITEM_SUCCESSFUL = 'ADD_ALLERGEN_TO_ITEM_SUCCESSFUL';
const ADD_ALLERGEN_TO_ITEM_FAILED = 'ADD_ALLERGEN_TO_ITEM_FAILED';
const REMOVE_ALLERGEN_FROM_ITEM = 'REMOVE_ALLERGEN_FROM_ITEM';
const REMOVE_ALLERGEN_FROM_ITEM_FAILED = 'REMOVE_ALLERGEN_FROM_ITEM_FAILED';
const REMOVE_ALLERGEN_FROM_ITEM_SUCCESSFUL =
    'REMOVE_ALLERGEN_FROM_ITEM_SUCCESSFUL';
const UPDATE_TAX_SETTINGS = 'UPDATE_TAX_SETTINGS';
const UPDATE_TAX_SETTINGS_SUCCESSFUL = 'UPDATE_TAX_SETTINGS_SUCCESSFUL';
const ERROR_UPDATING_TAX_SETTINGS = 'ERROR_UPDATING_TAX_SETTINGS';
const SAVE_DELIVERECT_CREDS_SUCCESSFUL = 'SAVE_DELIVERECT_CREDS_SUCCESSFUL';
const SAVE_DELIVERECT_CREDS = 'SAVE_DELIVERECT_CREDS';
const ERROR_SAVE_DELIVERECT_CREDS = 'ERROR_SAVE_DELIVERECT_CREDS';
const SAVE_NEW_POS_SUCCESSFUL = 'SAVE_NEW_POS_SUCCESSFUL';
const ERROR_SAVE_NEW_POS = 'ERROR_SAVE_NEW_POS';
const SAVE_NEW_POS_SYSTEM = 'SAVE_NEW_POS_SYSTEM';
const SAVE_CLORDER_CREDS_SUCCESSFUL = 'SAVE_CLORDER_CREDS_SUCCESSFUL';
const ERROR_SAVE_CLORDER_CREDS = 'ERROR_SAVE_CLORDER_CREDS';
const SAVE_CLORDER_CREDS = 'SAVE_CLORDER_CREDS';
const UPDATE_DEFAULT_TIP = 'UPDATE_DEFAULT_TIP';
const UPDATE_DEFAULT_TIP_SUCCESSFUL = 'UPDATE_DEFAULT_TIP_SUCCESSFUL';
const ERROR_UPDATING_DEFAULT_TIP = 'ERROR_UPDATING_DEFAULT_TIP';
const REPORT_INSIGHT = 'REPORT_INSIGHT';
const REPORT_INSIGHT_SUCCESSFUL = 'REPORT_INSIGHT_SUCCESSFUL';
const ERROR_REPORTING_INSIGHT = 'ERROR_REPORTING_INSIGHT';
const FETCHED_MENU_INSIGHTS = 'FETCHED_MENU_INSIGHTS';
const ERROR_FETCHING_MENU_INSIGHTS = 'ERROR_FETCHING_MENU_INSIGHTS';
const FETCH_MENU_INSIGHTS = 'FETCH_MENU_INSIGHTS';
const FETCH_SECTION_INSIGHTS = 'FETCH_SECTION_INSIGHTS';
const ERROR_FETCHING_SECTION_INSIGHTS = 'ERROR_FETCHING_SECTION_INSIGHTS';
const FETCHED_SECTION_INSIGHTS = 'FETCHED_SECTION_INSIGHTS';
const FETCH_ITEM_INSIGHTS = 'FETCH_ITEM_INSIGHTS';
const ERROR_FETCHING_ITEM_INSIGHTS = 'ERROR_FETCHING_ITEM_INSIGHTS';
const FETCHED_ITEM_INSIGHTS = 'FETCHED_ITEM_INSIGHTS';
const FETCH_ORDER_INSIGHTS = 'FETCH_ORDER_INSIGHTS';
const ERROR_FETCHING_ORDER_INSIGHTS = 'ERROR_FETCHING_ORDER_INSIGHTS';
const FETCHED_ORDER_INSIGHTS = 'FETCHED_ORDER_INSIGHTS';
const FETCHED_TIPS_INSIGHTS = 'FETCHED_TIPS_INSIGHTS';
const ERROR_FETCHING_TIPS_INSIGHTS = 'ERROR_FETCHING_TIPS_INSIGHTS';
const FETCH_TIPS_INSIGHTS = 'FETCH_TIPS_INSIGHTS';
const EDIT_SECTION_SUCCESSFUL = 'EDIT_SECTION_SUCCESSFUL';
const EDIT_SECTION = 'EDIT_SECTION';
const EDIT_SECTION_FAILED = 'EDIT_SECTION_FAILED';
const ADD_RECOMMENDED_ITEM_TO_ITEM = 'ADD_RECOMMENDED_ITEM_TO_ITEM';
const ADD_RECOMMENDED_ITEM_TO_ITEM_SUCCESSFUL =
    'ADD_RECOMMENDED_ITEM_TO_ITEM_SUCCESSFUL';
const ADD_RECOMMENDED_ITEM_TO_ITEM_FAILED =
    'ADD_RECOMMENDED_ITEM_TO_ITEM_FAILED';
const REMOVE_RECOMMENDED_ITEM_FROM_ITEM = 'REMOVE_RECOMMENDED_ITEM_FROM_ITEM';
const REMOVE_RECOMMENDED_ITEM_FROM_ITEM_FAILED =
    'REMOVE_RECOMMENDED_ITEM_FROM_ITEM_FAILED';
const REMOVE_RECOMMENDED_ITEM_FROM_ITEM_SUCCESSFUL =
    'REMOVE_RECOMMENDED_ITEM_FROM_ITEM_SUCCESSFUL';
const UPDATE_CTA_TEXT = 'UPDATE_CTA_TEXT';
const UPDATE_CTA_TEXT_SUCCESSFUL = 'UPDATE_CTA_TEXT_SUCCESSFUL';
const ERROR_UPDATING_CTA_TEXT = 'ERROR_UPDATING_CTA_TEXT';
const UPDATE_ADMIN_SETTINGS = 'UPDATE_ADMIN_SETTINGS';
const UPDATE_ADMIN_SETTINGS_SUCCESSFUL = 'UPDATE_ADMIN_SETTINGS_SUCCESSFUL';
const UPDATE_ADMIN_SETTINGS_FAILED = 'UPDATE_ADMIN_SETTINGS_FAILED';
const FETCHED_ADMIN_SETTINGS = 'FETCHED_ADMIN_SETTINGS';
const ERROR_FETCHING_ADMIN_SETTINGS = 'ERROR_FETCHING_ADMIN_SETTINGS';
const CREATE_FEATURED_ITEM = 'CREATE_FEATURED_ITEM';
const CREATE_FEATURED_ITEM_SUCCESSFUL = 'CREATE_FEATURED_ITEM_SUCCESSFUL';
const CREATE_FEATURED_ITEM_FAILED = 'CREATE_FEATURED_ITEM_FAILED';
const FETCHED_FEATURED_ITEMS = 'FETCHED_FEATURED_ITEMS';
const ERROR_FETCHING_FEATURED_ITEMS = 'ERROR_FETCHING_FEATURED_ITEMS';
const DELETE_FEATURED_ITEM = 'DELETE_FEATURED_ITEM';
const DELETE_FEATURED_ITEM_FAILED = 'DELETE_FEATURED_ITEM_FAILED';
const DELETE_FEATURED_ITEM_SUCCESSFUL = 'DELETE_FEATURED_ITEM_SUCCESSFUL';
const UPDATE_FEATURED_ITEMS_SETTINGS = 'UPDATE_FEATURED_ITEMS_SETTINGS';
const UPDATE_FEATURED_ITEMS_SETTINGS_SUCCESSFUL =
    'UPDATE_FEATURED_ITEMS_SETTINGS_SUCCESSFUL';
const ERROR_UPDATING_FEATURED_ITEMS_SETTINGS =
    'ERROR_UPDATING_FEATURED_ITEMS_SETTINGS';
const UPDATE_EMAIL_COLLECTION_SETTINGS = 'UPDATE_EMAIL_COLLECTION_SETTINGS';
const UPDATE_EMAIL_COLLECTION_SETTINGS_SUCCESSFUL =
    'UPDATE_EMAIL_COLLECTION_SETTINGS_SUCCESSFUL';
const ERROR_UPDATING_EMAIL_COLLECTION_SETTINGS =
    'ERROR_UPDATING_EMAIL_COLLECTION_SETTINGS';
const UPDATE_EMAIL_COLLECTION_SETTINGS_SAMPLE_EMAIL =
    'UPDATE_EMAIL_COLLECTION_SETTINGS_SAMPLE_EMAIL';
const UPDATE_EMAIL_COLLECTION_SETTINGS_SAMPLE_EMAIL_SUCCESSFUL =
    'UPDATE_EMAIL_COLLECTION_SETTINGS_SAMPLE_EMAIL_SUCCESSFUL';
const SEND_USER_DISCOUNT_CODE_EMAIL = 'SEND_USER_DISCOUNT_CODE_EMAIL';
const SEND_USER_DISCOUNT_CODE_EMAIL_SUCCESSFUL =
    'SEND_USER_DISCOUNT_CODE_EMAIL_SUCCESSFUL';
const ERROR_SENDING_USER_DISCOUNT_CODE_EMAIL =
    'ERROR_SENDING_USER_DISCOUNT_CODE_EMAIL';
const FETCHED_USER_DISCOUNT_EMAILS = 'FETCHED_USER_DISCOUNT_EMAILS';
const ERROR_FETCHING_USER_DISCOUNT_EMAILS =
    'ERROR_FETCHING_USER_DISCOUNT_EMAILS';
const ADD_BLOG = 'ADD_BLOG';
const ADD_BLOG_SUCCESSFUL = 'ADD_BLOG_SUCCESSFUL';
const ERROR_ADDING_BLOG = 'ERROR_ADDING_BLOG';
const FETCHED_BLOGS = 'FETCHED_BLOGS';
const ERROR_FETCHING_BLOGS = 'ERROR_FETCHING_BLOGS';
const FETCHED_ADMIN_REPORTS = 'FETCHED_ADMIN_REPORTS';
const ERROR_FETCHING_ADMIN_REPORTS = 'ERROR_FETCHING_ADMIN_REPORTS';
const FETCH_ADMIN_REPORTS = 'FETCH_ADMIN_REPORTS';
const UPDATE_MODIFIER_SETTINGS = 'UPDATE_MODIFIER_SETTINGS';
const UPDATE_MODIFIER_SETTINGS_SUCCESSFUL =
    'UPDATE_MODIFIER_SETTINGS_SUCCESSFUL';
const ERROR_UPDATING_MODIFIER_SETTINGS = 'ERROR_UPDATING_MODIFIER_SETTINGS';
const UPDATE_ORDER_EMAIL_NOTIFICATION_SETTINGS =
    'UPDATE_ORDER_EMAIL_NOTIFICATION_SETTINGS';
const UPDATE_ORDER_EMAIL_NOTIFICATION_SETTINGS_SUCCESSFUL =
    'UPDATE_ORDER_EMAIL_NOTIFICATION_SETTINGS_SUCCESSFUL';
const ERROR_UPDATING_ORDER_EMAIL_NOTIFICATION_SETTINGS =
    'ERROR_UPDATING_ORDER_EMAIL_NOTIFICATION_SETTINGS';
const EDIT_WALLET_PASS_SETTINGS_SUCCESSFUL =
    'EDIT_WALLET_PASS_SETTINGS_SUCCESSFUL';
const ERROR_EDITING_WALLET_PASS_SETTINGS = 'ERROR_EDITING_WALLET_PASS_SETTINGS';
const EDIT_WALLET_PASS_SETTINGS = 'EDIT_WALLET_PASS_SETTINGS';
const CREATE_LOYALTY_CAMPAIGN = 'CREATE_LOYALTY_CAMPAIGN';
const CREATE_LOYALTY_CAMPAIGN_SUCCESSFUL = 'CREATE_LOYALTY_CAMPAIGN_SUCCESSFUL';
const ERROR_CREATING_LOYALTY_CAMPAIGN = 'ERROR_CREATING_LOYALTY_CAMPAIGN';
const FETCHED_LOYALTY_CAMPAIGNS = 'FETCHED_LOYALTY_CAMPAIGNS';
const ERROR_FETCHING_LOYALTY_CAMPAIGNS = 'ERROR_FETCHING_LOYALTY_CAMPAIGNS';
const TOGGLE_LOYALTY_CAMPAIGN_AVAILABILITY =
    'TOGGLE_LOYALTY_CAMPAIGN_AVAILABILITY';
const TOGGLE_LOYALTY_CAMPAIGN_AVAILABILITY_FAILED =
    'TOGGLE_LOYALTY_CAMPAIGN_AVAILABILITY_FAILED';
const TOGGLE_LOYALTY_CAMPAIGN_AVAILABILITY_SUCCESSFUL =
    'TOGGLE_LOYALTY_CAMPAIGN_AVAILABILITY_SUCCESSFUL';
const DELETE_LOYALTY_CAMPAIGN_SUCCESSFUL = 'DELETE_LOYALTY_CAMPAIGN_SUCCESSFUL';
const DELETE_LOYALTY_CAMPAIGN = 'DELETE_LOYALTY_CAMPAIGN';
const DELETE_LOYALTY_CAMPAIGN_FAILED = 'DELETE_LOYALTY_CAMPAIGN_FAILED';
const SUBSCRIBE_LOYALTY_CAMPAIGN = 'SUBSCRIBE_LOYALTY_CAMPAIGN';
const SUBSCRIBE_LOYALTY_CAMPAIGN_SUCCESSFUL =
    'SUBSCRIBE_LOYALTY_CAMPAIGN_SUCCESSFUL';
const ERROR_SUBSCRIBING_LOYALTY_CAMPAIGN = 'ERROR_SUBSCRIBING_LOYALTY_CAMPAIGN';
const FETCHED_LOYALTY_PASS = 'FETCHED_LOYALTY_PASS';
const ERROR_FETCHING_LOYALTY_PASS = 'ERROR_FETCHING_LOYALTY_PASS';
const CREATE_LOYALTY_PASS_QUALIFICATION = 'CREATE_LOYALTY_PASS_QUALIFICATION';
const SUCCESS_CREATING_LOYALTY_PASS_QUALIFICATION =
    'SUCCESS_CREATING_LOYALTY_PASS_QUALIFICATION';
const ERROR_CREATING_LOYALTY_PASS_QUALIFICATION =
    'ERROR_CREATING_LOYALTY_PASS_QUALIFICATION';
const CREATE_LOYALTY_PASS_CANCELLATION = 'CREATE_LOYALTY_PASS_CANCELLATION';
const SUCCESS_CREATING_LOYALTY_PASS_CANCELLATION =
    'SUCCESS_CREATING_LOYALTY_PASS_CANCELLATION';
const ERROR_CREATING_LOYALTY_PASS_CANCELLATION =
    'ERROR_CREATING_LOYALTY_PASS_CANCELLATION';
const SUCCESS_CREATING_LOYALTY_PASS_REDEMPTION =
    'SUCCESS_CREATING_LOYALTY_PASS_REDEMPTION';
const ERROR_CREATING_LOYALTY_PASS_REDEMPTION =
    'ERROR_CREATING_LOYALTY_PASS_REDEMPTION';
const CREATE_LOYALTY_PASS_REDEMPTION = 'CREATE_LOYALTY_PASS_REDEMPTION';
const CREATE_FREE_LOYALTY_PASS_REDEMPTION =
    'CREATE_FREE_LOYALTY_PASS_REDEMPTION';
const SUCCESS_CREATING_FREE_LOYALTY_PASS_REDEMPTION =
    'SUCCESS_CREATING_FREE_LOYALTY_PASS_REDEMPTION';
const ERROR_CREATING_FREE_LOYALTY_PASS_REDEMPTION =
    'ERROR_CREATING_FREE_LOYALTY_PASS_REDEMPTION';
const FETCHED_USER_CRM_STATS = 'FETCHED_USER_CRM_STATS';
const ERROR_FETCHING_USER_CRM_STATS = 'ERROR_FETCHING_USER_CRM_STATS';
const FETCH_USER_CRM_STATS = 'FETCH_USER_CRM_STATS';
const FETCH_BANNER_INSIGHTS = 'FETCH_BANNER_INSIGHTS';
const FETCHED_BANNER_INSIGHTS = 'FETCHED_BANNER_INSIGHTS';
const ERROR_FETCHING_BANNER_INSIGHTS = 'ERROR_FETCHING_BANNER_INSIGHTS';
const UPDATE_DELITEFUL_AI_SETTINGS = 'UPDATE_DELITEFUL_AI_SETTINGS';
const UPDATE_DELITEFUL_AI_SETTINGS_SUCCESSFUL =
    'UPDATE_DELITEFUL_AI_SETTINGS_SUCCESSFUL';
const ERROR_UPDATING_DELITEFUL_AI_SETTINGS =
    'ERROR_UPDATING_DELITEFUL_AI_SETTINGS';
const GENERATE_AI_EMAIL_CAMPAIGN_SUCCESSFUL =
    'GENERATE_AI_EMAIL_CAMPAIGN_SUCCESSFUL';
const ERROR_GENERATING_AI_EMAIL_CAMPAIGN = 'ERROR_GENERATING_AI_EMAIL_CAMPAIGN';
const GENERATE_AI_EMAIL_CAMPAIGN = 'GENERATE_AI_EMAIL_CAMPAIGN';
const FETCHED_EMAIL_CAMPAIGNS = 'FETCHED_EMAIL_CAMPAIGNS';
const ERROR_FETCHING_EMAIL_CAMPAIGNS = 'ERROR_FETCHING_EMAIL_CAMPAIGNS';
const ADD_EMAIL_CAMPAIGN = 'ADD_EMAIL_CAMPAIGN';
const ADD_EMAIL_CAMPAIGN_SUCCESSFUL = 'ADD_EMAIL_CAMPAIGN_SUCCESSFUL';
const ERROR_ADDING_EMAIL_CAMPAIGN = 'ERROR_ADDING_EMAIL_CAMPAIGN';
const EDIT_EMAIL_CAMPAIGN = 'EDIT_EMAIL_CAMPAIGN';
const EDIT_EMAIL_CAMPAIGN_SUCCESSFUL = 'EDIT_EMAIL_CAMPAIGN_SUCCESSFUL';
const ERROR_EDITING_EMAIL_CAMPAIGN = 'ERROR_EDITING_EMAIL_CAMPAIGN';
const DELETE_EMAIL_CAMPAIGN_SUCCESSFUL = 'DELETE_EMAIL_CAMPAIGN_SUCCESSFUL';
const DELETE_EMAIL_CAMPAIGN_FAILED = 'DELETE_EMAIL_CAMPAIGN_FAILED';
const DELETE_EMAIL_CAMPAIGN = 'DELETE_EMAIL_CAMPAIGN';
const TOGGLE_EMAIL_CAMPAIGN_AVAILABILITY = 'TOGGLE_EMAIL_CAMPAIGN_AVAILABILITY';
const TOGGLE_EMAIL_CAMPAIGN_AVAILABILITY_SUCCESSFUL =
    'TOGGLE_EMAIL_CAMPAIGN_AVAILABILITY_SUCCESSFUL';
const TOGGLE_EMAIL_CAMPAIGN_AVAILABILITY_FAILED =
    'TOGGLE_EMAIL_CAMPAIGN_AVAILABILITY_FAILED';
const FETCHED_EMAIL_SEQUENCE_LIVES = 'FETCHED_EMAIL_SEQUENCE_LIVES';
const ERROR_FETCHING_EMAIL_SEQUENCE_LIVES =
    'ERROR_FETCHING_EMAIL_SEQUENCE_LIVES';
const ADD_EMAIL_SEQUENCE_LIVE = 'ADD_EMAIL_SEQUENCE_LIVE';
const ADD_EMAIL_SEQUENCE_LIVE_SUCCESSFUL = 'ADD_EMAIL_SEQUENCE_LIVE_SUCCESSFUL';
const ERROR_ADDING_EMAIL_SEQUENCE_LIVE = 'ERROR_ADDING_EMAIL_SEQUENCE_LIVE';
const EDIT_EMAIL_SEQUENCE_LIVE = 'EDIT_EMAIL_SEQUENCE_LIVE';
const EDIT_EMAIL_SEQUENCE_LIVE_SUCCESSFUL =
    'EDIT_EMAIL_SEQUENCE_LIVE_SUCCESSFUL';
const ERROR_EDITING_EMAIL_SEQUENCE_LIVE = 'ERROR_EDITING_EMAIL_SEQUENCE_LIVE';
const DELETE_EMAIL_SEQUENCE_LIVE_SUCCESSFUL =
    'DELETE_EMAIL_SEQUENCE_LIVE_SUCCESSFUL';
const DELETE_EMAIL_SEQUENCE_LIVE_FAILED = 'DELETE_EMAIL_SEQUENCE_LIVE_FAILED';
const DELETE_EMAIL_SEQUENCE_LIVE = 'DELETE_EMAIL_SEQUENCE_LIVE';
const UPDATE_AUTOMATED_EMAIL_SEQUENCE_LIVE =
    'UPDATE_AUTOMATED_EMAIL_SEQUENCE_LIVE';
const ERROR_UPDATING_AUTOMATED_EMAIL_SEQUENCE_LIVE =
    'ERROR_UPDATING_AUTOMATED_EMAIL_SEQUENCE_LIVE';
const UPDATE_AUTOMATED_EMAIL_SEQUENCE_LIVE_SUCCESSFUL =
    'UPDATE_AUTOMATED_EMAIL_SEQUENCE_LIVE_SUCCESSFUL';
const UPDATE_BOTTOM_MENU_TEXT = 'UPDATE_BOTTOM_MENU_TEXT';
const UPDATE_BOTTOM_MENU_TEXT_SUCCESSFUL = 'UPDATE_BOTTOM_MENU_TEXT_SUCCESSFUL';
const ERROR_UPDATING_BOTTOM_MENU_TEXT = 'ERROR_UPDATING_BOTTOM_MENU_TEXT';
const GENERATE_AI_MENU_DESCRIPTION = 'GENERATE_AI_MENU_DESCRIPTION';
const GENERATE_AI_MENU_DESCRIPTION_SUCCESSFUL =
    'GENERATE_AI_MENU_DESCRIPTION_SUCCESSFUL';
const ERROR_GENERATING_AI_MENU_DESCRIPTION =
    'ERROR_GENERATING_AI_MENU_DESCRIPTION';
const SUBSCRIBE_STICKY_HEADER = 'SUBSCRIBE_STICKY_HEADER';
const SUBSCRIBE_STICKY_HEADER_SUCCESSFUL = 'SUBSCRIBE_STICKY_HEADER_SUCCESSFUL';
const ERROR_SUBSCRIBING_STICKY_HEADER = 'ERROR_SUBSCRIBING_STICKY_HEADER';
const UPLOADED_CSV_DATA_SUCCESSFULLY = 'UPLOADED_CSV_DATA_SUCCESSFULLY';
const ERROR_UPLOADING_CSV_DATA = 'ERROR_UPLOADING_CSV_DATA';
const UPLOAD_CSV_DATA = 'UPLOAD_CSV_DATA';
const FETCH_WEBSITE_CONFIG = 'FETCH_WEBSITE_CONFIG';
const UPDATE_WEBSITE_CONFIG = 'UPDATE_WEBSITE_CONFIG';
const ERROR_FETCHING_WEBSITE_CONFIG = 'ERROR_FETCHING_WEBSITE_CONFIG';
const ERROR_UPDATING_WEBSITE_CONFIG = 'ERROR_UPDATING_WEBSITE_CONFIG';
const UPLOAD_WEBSITE_IMAGE = 'UPLOAD_WEBSITE_IMAGE';
const UPDATE_WEBSITE_SETTINGS = 'UPDATE_WEBSITE_SETTINGS';
const UPDATE_WEBSITE_SETTINGS_SUCCESSFUL = 'UPDATE_WEBSITE_SETTINGS_SUCCESSFUL';
const ERROR_UPDATING_WEBSITE_SETTINGS = 'ERROR_UPDATING_WEBSITE_SETTINGS';
const UPDATE_MENU_VIEW_SETTINGS = 'UPDATE_MENU_VIEW_SETTINGS';
const UPDATE_MENU_VIEW_SETTINGS_SUCCESSFUL =
    'UPDATE_MENU_VIEW_SETTINGS_SUCCESSFUL';
const ERROR_UPDATING_MENU_VIEW_SETTINGS = 'ERROR_UPDATING_MENU_VIEW_SETTINGS';
const UNSUBSCRIBE_SUCCESSFUL = 'UNSUBSCRIBE_SUCCESSFUL';
const ERROR_UNSUBSCRIBING = 'ERROR_UNSUBSCRIBING';
const UNSUBSCRIBE_USER = 'UNSUBSCRIBE_USER';
const RESUB_SUCCESSFUL = 'RESUB_SUCCESSFUL';
const ERROR_RESUB = 'ERROR_RESUB';
const RESUB_USER = 'RESUB_USER';
const UPDATE_MENU_SORT_SETTINGS = 'UPDATE_MENU_SORT_SETTINGS';
const UPDATE_MENU_SORT_SETTINGS_SUCCESSFUL =
    'UPDATE_MENU_SORT_SETTINGS_SUCCESSFUL';
const ERROR_UPDATING_MENU_SORT_SETTINGS = 'ERROR_UPDATING_MENU_SORT_SETTINGS';
const CLONE_SECTION_SUCCESSFUL = 'CLONE_SECTION_SUCCESSFUL';
const CLONE_SECTION = 'CLONE_SECTION';
const CLONE_SECTION_FAILED = 'CLONE_SECTION_FAILED';
const CLONE_SECTION_ITEM = 'CLONE_SECTION_ITEM';
const CLONE_SECTION_ITEM_SUCCESSFUL = 'CLONE_SECTION_ITEM_SUCCESSFUL';
const CLONE_SECTION_ITEM_FAILED = 'CLONE_SECTION_ITEM_FAILED';
const UPDATE_USER_ADDRESS = 'UPDATE_USER_ADDRESS';
const UPDATE_USER_ADDRESS_SUCCESSFUL = 'UPDATE_USER_ADDRESS_SUCCESSFUL';
const ERROR_UPDATING_USER_ADDRESS = 'ERROR_UPDATING_USER_ADDRESS';
const UPDATE_DELIVERY_SETTINGS = 'UPDATE_DELIVERY_SETTINGS';
const UPDATE_DELIVERY_SETTINGS_SUCCESSFUL =
    'UPDATE_DELIVERY_SETTINGS_SUCCESSFUL';
const ERROR_UPDATING_DELIVERY_SETTINGS = 'ERROR_UPDATING_DELIVERY_SETTINGS';
const UPDATE_PROMO_BANNER_SETTINGS = 'UPDATE_PROMO_BANNER_SETTINGS';
const UPDATE_PROMO_BANNER_SETTINGS_SUCCESSFUL =
    'UPDATE_PROMO_BANNER_SETTINGS_SUCCESSFUL';
const ERROR_UPDATING_PROMO_BANNER_SETTINGS =
    'ERROR_UPDATING_PROMO_BANNER_SETTINGS';
const ADD_DELITEPAY_GIFT_CARD = 'ADD_DELITEPAY_GIFT_CARD';
const ADD_DELITEPAY_GIFT_CARD_SUCCESSFUL = 'ADD_DELITEPAY_GIFT_CARD_SUCCESSFUL';
const ERROR_ADDING_DELITEPAY_GIFT_CARD = 'ERROR_ADDING_DELITEPAY_GIFT_CARD';
const FETCHED_DELITEPAY_GIFT_CARDS = 'FETCHED_DELITEPAY_GIFT_CARDS';
const ERROR_FETCHING_DELITEPAY_GIFT_CARDS =
    'ERROR_FETCHING_DELITEPAY_GIFT_CARDS';
const TOGGLE_DELITEPAY_GIFT_CARD_AVAILABILITY_FAILED =
    'TOGGLE_DELITEPAY_GIFT_CARD_AVAILABILITY_FAILED';
const TOGGLE_DELITEPAY_GIFT_CARD_AVAILABILITY_SUCCESSFUL =
    'TOGGLE_DELITEPAY_GIFT_CARD_AVAILABILITY_SUCCESSFUL';
const TOGGLE_DELITEPAY_GIFT_CARD_AVAILABILITY =
    'TOGGLE_DELITEPAY_GIFT_CARD_AVAILABILITY';
const DELETE_DELITEPAY_GIFT_CARD = 'DELETE_DELITEPAY_GIFT_CARD';
const DELETE_DELITEPAY_GIFT_CARD_FAILED = 'DELETE_DELITEPAY_GIFT_CARD_FAILED';
const DELETE_DELITEPAY_GIFT_CARD_SUCCESSFUL =
    'DELETE_DELITEPAY_GIFT_CARD_SUCCESSFUL';
const FETCHED_PUBLIC_DELITEPAY_GIFT_CARDS =
    'FETCHED_PUBLIC_DELITEPAY_GIFT_CARDS';
const ERROR_FETCHING_PUBLIC_DELITEPAY_GIFT_CARDS =
    'ERROR_FETCHING_PUBLIC_DELITEPAY_GIFT_CARDS';
const FETCH_DELITEPAY_INSIGHTS = 'FETCH_DELITEPAY_INSIGHTS';
const FETCHED_DELITEPAY_INSIGHTS = 'FETCHED_DELITEPAY_INSIGHTS';
const ERROR_FETCHING_DELITEPAY_INSIGHTS = 'ERROR_FETCHING_DELITEPAY_INSIGHTS';
const SUBSCRIBE_POPUP = 'SUBSCRIBE_POPUP';
const SUBSCRIBE_POPUP_SUCCESSFUL = 'SUBSCRIBE_POPUP_SUCCESSFUL';
const ERROR_SUBSCRIBING_POPUP = 'ERROR_SUBSCRIBING_POPUP';
// SOCKET EVENTS
const NEW_ACCESS_TOKEN = 'new-access-token';
const NEW_ORDER_RECEIVED = 'NEW_ORDER_RECEIVED';
const NEW_RESERVATION_RECEIVED = 'NEW_RESERVATION_RECEIVED';
const CALL_WAITER_RECEIVED = 'CALL_WAITER_RECEIVED';
const FETCH_YOUR_TABLES_SUCCESSFUL = 'FETCH_YOUR_TABLES_SUCCESSFUL';
const ERROR_FETCHING_YOUR_TABLES = 'ERROR_FETCHING_YOUR_TABLES';
const NEW_WAITER_REQUEST = 'NEW_WAITER_REQUEST';
const NEW_AMENITY_REQUEST = 'NEW_AMENITY_REQUEST';
const NEW_PAYMENT_FOR_PAYLATER_ORDER_RECEIVED =
    'NEW_PAYMENT_FOR_PAYLATER_ORDER_RECEIVED';
const SOCKET_DELIVERECT_ORDER_FAILURE_ACTION =
    'SOCKET_DELIVERECT_ORDER_FAILURE_ACTION';
const ADD_ALLERGEN_SUCCESSFUL = 'ADD_ALLERGEN_SUCCESSFUL';
const UPDATE_WAITER_REQUEST_SETTINGS = 'UPDATE_WAITER_REQUEST_SETTINGS';
const NEW_PAYMENT_FOR_PAY_LATER = 'NEW_PAYMENT_FOR_PAY_LATER';
const AMENITY_REQUEST_RECEIVED = 'AMENITY_REQUEST_RECEIVED';
const NEW_CHAT_MESSAGE_RECEIVED = 'NEW_CHAT_MESSAGE_RECEIVED';
const TOGGLE_SECTION_AVAILABILITY = 'TOGGLE_SECTION_AVAILABILITY';
const TOGGLE_SECTION_AVAILABILITY_SUCCESSFUL =
    'TOGGLE_SECTION_AVAILABILITY_SUCCESSFUL';
const TOGGLE_SECTION_AVAILABILITY_FAILED = 'TOGGLE_SECTION_AVAILABILITY_FAILED';
const CHECK_DELIVERY_QUOTE_SUCCESSFUL = 'CHECK_DELIVERY_QUOTE_SUCCESSFUL';
const UPDATE_WELCOME_SCREEN_SETTINGS_SUCCESSFUL =
    'UPDATE_WELCOME_SCREEN_SETTINGS_SUCCESSFUL';
const UPDATE_WELCOME_SCREEN_SETTINGS = 'UPDATE_WELCOME_SCREEN_SETTINGS';
const ERROR_UPDATING_WELCOME_SCREEN_SETTINGS =
    'ERROR_UPDATING_WELCOME_SCREEN_SETTINGS';
const UPDATE_DELITEPAY_SETTINGS_SUCCESSFUL =
    'UPDATE_DELITEPAY_SETTINGS_SUCCESSFUL';
const UPDATE_DELITEPAY_SETTINGS = 'UPDATE_DELITEPAY_SETTINGS';
const ERROR_UPDATING_DELITEPAY_SETTINGS = 'ERROR_UPDATING_DELITEPAY_SETTINGS';
const ADD_DELITECLUB_SUBSCRIPTION = 'ADD_DELITECLUB_SUBSCRIPTION';
const ADD_DELITECLUB_SUBSCRIPTION_SUCCESSFUL =
    'ADD_DELITECLUB_SUBSCRIPTION_SUCCESSFUL';
const ERROR_ADDING_DELITECLUB_SUBSCRIPTION =
    'ERROR_ADDING_DELITECLUB_SUBSCRIPTION';
const FETCHED_DELITECLUB_SUBSCRIPTIONS = 'FETCHED_DELITECLUB_SUBSCRIPTIONS';
const ERROR_FETCHING_DELITECLUB_SUBSCRIPTIONS =
    'ERROR_FETCHING_DELITECLUB_SUBSCRIPTIONS';
const TOGGLE_DELITECLUB_SUBSCRIPTION_AVAILABILITY_FAILED =
    'TOGGLE_DELITECLUB_SUBSCRIPTION_AVAILABILITY_FAILED';
const TOGGLE_DELITECLUB_SUBSCRIPTION_AVAILABILITY_SUCCESSFUL =
    'TOGGLE_DELITECLUB_SUBSCRIPTION_AVAILABILITY_SUCCESSFUL';
const TOGGLE_DELITECLUB_SUBSCRIPTION_AVAILABILITY =
    'TOGGLE_DELITECLUB_SUBSCRIPTION_AVAILABILITY';
const DELETE_DELITECLUB_SUBSCRIPTION = 'DELETE_DELITECLUB_SUBSCRIPTION';
const UPDATE_POPUP_SETTINGS = 'UPDATE_POPUP_SETTINGS';
const UPDATE_POPUP_SETTINGS_SUCCESSFUL = 'UPDATE_POPUP_SETTINGS_SUCCESSFUL';
const ERROR_UPDATING_POPUP_SETTINGS = 'ERROR_UPDATING_POPUP_SETTINGS';
const DELETE_DELITECLUB_SUBSCRIPTION_FAILED =
    'DELETE_DELITECLUB_SUBSCRIPTION_FAILED';
const DELETE_DELITECLUB_SUBSCRIPTION_SUCCESSFUL =
    'DELETE_DELITECLUB_SUBSCRIPTION_SUCCESSFUL';
const FETCHED_PUBLIC_DELITECLUB_SUBSCRIPTIONS =
    'FETCHED_PUBLIC_DELITECLUB_SUBSCRIPTIONS';
const ERROR_FETCHING_PUBLIC_DELITECLUB_SUBSCRIPTIONS =
    'ERROR_FETCHING_PUBLIC_DELITECLUB_SUBSCRIPTIONS';
const UPDATE_DELITECLUB_SETTINGS_SUCCESSFUL =
    'UPDATE_DELITECLUB_SETTINGS_SUCCESSFUL';
const UPDATE_DELITECLUB_SETTINGS = 'UPDATE_DELITECLUB_SETTINGS';
const ERROR_UPDATING_DELITECLUB_SETTINGS = 'ERROR_UPDATING_DELITECLUB_SETTINGS';
const CANCEL_DELITECLUB_SUBSCRIPTION = 'CANCEL_DELITECLUB_SUBSCRIPTION';
const CANCEL_DELITECLUB_SUBSCRIPTION_FAILED =
    'CANCEL_DELITECLUB_SUBSCRIPTION_FAILED';
const CANCEL_DELITECLUB_SUBSCRIPTION_SUCCESSFUL =
    'CANCEL_DELITECLUB_SUBSCRIPTION_SUCCESSFUL';
const FETCHED_TRENDS = 'FETCHED_TRENDS';
const ERROR_FETCHING_TRENDS = 'ERROR_FETCHING_TRENDS';
const FETCHED_REVIEW_HUB_REVIEWS = 'FETCHED_REVIEW_HUB_REVIEWS';
const ERROR_FETCHING_REVIEW_HUB_REVIEWS = 'ERROR_FETCHING_REVIEW_HUB_REVIEWS';
const GET_REVIEW_RECOMMENDATION = 'GET_REVIEW_RECOMMENDATION';
const GET_REVIEW_RECOMMENDATION_FAILED = 'GET_REVIEW_RECOMMENDATION_FAILED';
const GET_REVIEW_RECOMMENDATION_SUCCESSFUL =
    'GET_REVIEW_RECOMMENDATION_SUCCESSFUL';
const GET_REVIEW_RESPONSE_SUCCESSFUL = 'GET_REVIEW_RESPONSE_SUCCESSFUL';
const GET_REVIEW_RESPONSE_FAILED = 'GET_REVIEW_RESPONSE_FAILED';
const GET_REVIEW_RESPONSE = 'GET_REVIEW_RESPONSE';
const FETCH_CUMULATIVE_AVERAGE_INTERPRETATION =
    'FETCH_CUMULATIVE_AVERAGE_INTERPRETATION';
const FETCHED_KEYWORD_ANALYSIS = 'FETCHED_KEYWORD_ANALYSIS';
const ERROR_FETCHING_KEYWORD_ANALYSIS = 'ERROR_FETCHING_KEYWORD_ANALYSIS';
const FETCHED_HIGHLIGHTED_REVIEWS = 'FETCHED_HIGHLIGHTED_REVIEWS';
const ERROR_FETCHING_HIGHLIGHTED_REVIEWS = 'ERROR_FETCHING_HIGHLIGHTED_REVIEWS';
const UPDATE_USER_ACTIONS_SETTINGS = 'UPDATE_USER_ACTIONS_SETTINGS';
const UPDATE_USER_ACTIONS_SETTINGS_SUCCESSFUL =
    'UPDATE_USER_ACTIONS_SETTINGS_SUCCESSFUL';
const ERROR_UPDATING_USER_ACTIONS_SETTINGS =
    'ERROR_UPDATING_USER_ACTIONS_SETTINGS';
const SUBMIT_USER_ACTION_REQUEST = 'SUBMIT_USER_ACTION_REQUEST';
const SUBMIT_USER_ACTION_REQUEST_SUCCESSFUL =
    'SUBMIT_USER_ACTION_REQUEST_SUCCESSFUL';
const ERROR_SUBMITTING_USER_ACTION_REQUEST =
    'ERROR_SUBMITTING_USER_ACTION_REQUEST';
const APP_REGISTRATION = 'APP_REGISTRATION';
const APP_REGISTRATION_SUCCESSFUL = 'APP_REGISTRATION_SUCCESSFUL';
const ERROR_REGISTERING_LOYALTY_APP = 'ERROR_REGISTERING_LOYALTY_APP';
const SIGN_UP_LOYALTY_USER = 'SIGN_UP_LOYALTY_USER';
const SIGN_UP_LOYALTY_USER_SUCCESSFUL = 'SIGN_UP_LOYALTY_USER_SUCCESSFUL';
const ERROR_SIGNING_UP_LOYALTY_USER = 'ERROR_SIGNING_UP_LOYALTY_USER';
// SOCKET EMIT EVENTS
const SOCKET_DELIVERY_UPDATE_RECEIVED_ACTION =
    'SOCKET_DELIVERY_UPDATE_RECEIVED_ACTION';
const NEW_DELIVERY_STATUS_UPDATE_CONF_PAGE =
    'NEW_DELIVERY_STATUS_UPDATE_CONF_PAGE';
const NEW_DELIVERY_STATUS_UPDATE = 'NEW_DELIVERY_STATUS_UPDATE';
const UPDATE_MERCHANT_DIRECTORY_SETTINGS = 'UPDATE_MERCHANT_DIRECTORY_SETTINGS';
const UPDATE_MERCHANT_DIRECTORY_SETTINGS_SUCCESSFUL =
    'UPDATE_MERCHANT_DIRECTORY_SETTINGS_SUCCESSFUL';
const ERROR_UPDATING_MERCHANT_DIRECTORY_SETTINGS =
    'ERROR_UPDATING_MERCHANT_DIRECTORY_SETTINGS';
const UPDATE_LOYALTY_APP_SETTINGS = 'UPDATE_LOYALTY_APP_SETTINGS';
const UPDATE_LOYALTY_APP_SETTINGS_SUCCESSFUL =
    'UPDATE_LOYALTY_APP_SETTINGS_SUCCESSFUL';
const ERROR_UPDATING_LOYALTY_APP_SETTINGS =
    'ERROR_UPDATING_LOYALTY_APP_SETTINGS';
const ADD_DISCOUNT_SUCCESSFUL = 'ADD_DISCOUNT_SUCCESSFUL';
const ERROR_ADDING_DISCOUNT = 'ERROR_ADDING_DISCOUNT';
const ADD_DISCOUNT = 'ADD_DISCOUNT';
const FETCHED_DISCOUNTS = 'FETCHED_DISCOUNTS';
const ERROR_FETCHING_DISCOUNTS = 'ERROR_FETCHING_DISCOUNTS';
const TOGGLE_DISCOUNT_AVAILABILITY_SUCCESSFUL =
    'TOGGLE_DISCOUNT_AVAILABILITY_SUCCESSFUL';
const TOGGLE_DISCOUNT_AVAILABILITY = 'TOGGLE_DISCOUNT_AVAILABILITY';
const DELETE_DISCOUNT_SUCCESSFUL = 'DELETE_DISCOUNT_SUCCESSFUL';
const DELETE_DISCOUNT_FAILED = 'DELETE_DISCOUNT_FAILED';
const DELETE_DISCOUNT = 'DELETE_DISCOUNT';
const UPDATE_RECOMMENDED_ITEM_SETTINGS_SUCCESSFUL =
    'UPDATE_RECOMMENDED_ITEM_SETTINGS_SUCCESSFUL';
const ERROR_UPDATING_RECOMMENDED_ITEM_SETTINGS =
    'ERROR_UPDATING_RECOMMENDED_ITEM_SETTINGS';
const UPDATE_RECOMMENDED_ITEM_SETTINGS = 'UPDATE_RECOMMENDED_ITEM_SETTINGS';
const SUBSCRIBE_TO_LOYALTY = 'SUBSCRIBE_TO_LOYALTY';
const SUBSCRIBE_TO_LOYALTY_SUCCESSFUL = 'SUBSCRIBE_TO_LOYALTY_SUCCESSFUL';
const ERROR_SUBSCRIBING_TO_LOYALTY = 'ERROR_SUBSCRIBING_TO_LOYALTY';
const FETCH_LOYALTY_USER_INFO = 'FETCH_LOYALTY_USER_INFO';
const FETCH_LOYALTY_USER_INFO_SUCCESSFUL = 'FETCH_LOYALTY_USER_INFO_SUCCESSFUL';
const ERROR_FETCHING_LOYALTY_USER_INFO = 'ERROR_FETCHING_LOYALTY_USER_INFO';
const UPDATE_ORDER_GROUPING_SETTINGS = 'UPDATE_ORDER_GROUPING_SETTINGS';
const UPDATE_ORDER_GROUPING_SETTINGS_SUCCESSFUL =
    'UPDATE_ORDER_GROUPING_SETTINGS_SUCCESSFUL';
const ERROR_UPDATING_ORDER_GROUPING_SETTINGS =
    'ERROR_UPDATING_ORDER_GROUPING_SETTINGS';
const MARK_AS_COMPLETED_SUCCESSFUL_TABLE = 'MARK_AS_COMPLETED_SUCCESSFUL_TABLE';
const ERROR_MARKING_AS_COMPLETE_TABLE = 'ERROR_MARKING_AS_COMPLETE_TABLE';
const MARK_AS_COMPLETED_FOR_TABLE = 'MARK_AS_COMPLETED_FOR_TABLE';
// types.js
const UPDATE_FEATURED_ITEM_ORDER = 'UPDATE_FEATURED_ITEM_ORDER';
const UPDATE_FEATURED_ITEM_ORDER_SUCCESSFUL =
    'UPDATE_FEATURED_ITEM_ORDER_SUCCESSFUL';
const UPDATE_FEATURED_ITEM_ORDER_FAILED = 'UPDATE_FEATURED_ITEM_ORDER_FAILED';
const UPDATE_MODIFIER_ORDER = 'UPDATE_MODIFIER_ORDER';
const UPDATE_MODIFIER_ORDER_SUCCESSFUL = 'UPDATE_MODIFIER_ORDER_SUCCESSFUL';
const UPDATE_MODIFIER_ORDER_FAILED = 'UPDATE_MODIFIER_ORDER_FAILED';
const SET_MENU_DATA = 'SET_MENU_DATA';
const UPLOAD_MENU_FROM_IMAGE = 'UPLOAD_MENU_FROM_IMAGE';
const ERROR_CREATING_STRIPE_PORTAL_SESSION =
    'ERROR_CREATING_STRIPE_PORTAL_SESSION';
const CREATE_STRIPE_PORTAL_SESSION = 'CREATE_STRIPE_PORTAL_SESSION';
const CREATE_STRIPE_PORTAL_SESSION_SUCCESSFUL =
    'CREATE_STRIPE_PORTAL_SESSION_SUCCESSFUL';
const FETCH_PLATFORM_RESTAURANTS = 'FETCH_PLATFORM_RESTAURANTS';
const FETCH_PLATFORM_RESTAURANTS_SUCCESSFUL =
    'FETCH_PLATFORM_RESTAURANTS_SUCCESSFUL';
const ERROR_FETCHING_PLATFORM_RESTAURANTS =
    'ERROR_FETCHING_PLATFORM_RESTAURANTS';
const REDEEM_LOYALTY_DISCOUNT_SUCCESSFUL = 'REDEEM_LOYALTY_DISCOUNT_SUCCESSFUL';
const ERROR_REDEEMING_LOYALTY_DISCOUNT = 'ERROR_REDEEMING_LOYALTY_DISCOUNT';
const UPDATE_PAYMENT_SETTINGS = 'UPDATE_PAYMENT_SETTINGS';
const UPDATE_PAYMENT_SETTINGS_SUCCESSFUL = 'UPDATE_PAYMENT_SETTINGS_SUCCESSFUL';
const ERROR_UPDATING_PAYMENT_SETTINGS = 'ERROR_UPDATING_PAYMENT_SETTINGS';
const TABLE_VIEW_ADD_ITEMS_COMPLETE = 'TABLE_VIEW_ADD_ITEMS_COMPLETE';
const TABLE_VIEW_COMPLETE_ORDERS_COMPLETE =
    'TABLE_VIEW_COMPLETE_ORDERS_COMPLETE';
const WIPE_TABLE_VIEW_STATUSES = 'WIPE_TABLE_VIEW_STATUSES';
const WIPE_BOOKING = 'WIPE_BOOKING';
module.exports = {
    UPDATE_RECOMMENDED_ITEM_SETTINGS_SUCCESSFUL,
    ERROR_UPDATING_RECOMMENDED_ITEM_SETTINGS,
    UPDATE_RECOMMENDED_ITEM_SETTINGS,
    CANCEL_ORDER,
    DELETE_DISCOUNT,
    UPDATE_IS_DRINKS,
    UPDATE_IS_DRINKS_SUCCESSFUL,
    UPDATE_IS_DRINKS_FAILED,
    DELETE_DISCOUNT_FAILED,
    CANCEL_RESERVATION,
    MARK_AS_COMPLETED,
    TOGGLE_DISCOUNT_AVAILABILITY,
    SET_ACTIVE,
    GET_DYNAMIC_CONTENT,
    FETCHED_DYNAMIC_CONTENT,
    ERROR_FETCHING_DYNAMIC_CONTENT,
    SIGN_UP,
    SIGN_UP_SUCCESSFUL,
    SIGN_UP_FAILED,
    LOADING,
    END_LOADING,
    SET_ORDERCARD_HEIGHT,
    ERROR_FETCHING_ORDERS,
    FETCHED_ORDERS,
    UPLOAD_RESTAURANT_PICTURE,
    UPLOAD_PICTURE_SUCCESSFUL,
    ERROR_UPLOADING_PICTURE,
    UPDATE_PICTURE,
    DISMISS_PICTURE_SUCCESS,
    FETCHED_MENU_ITEMS,
    ERROR_FETCHING_MENU_ITEMS,
    FETCHED_SECTIONS,
    ERROR_FETCHING_SECTIONS,
    CREATE_SECTION,
    CREATE_SECTION_SUCCESSFUL,
    CREATE_SECTION_FAILED,
    ADD_MENU_ITEM,
    ADD_MENU_ITEM_SUCCESSFUL,
    ERROR_ADDING_MENU_ITEM,
    EDIT_MENU_ITEM,
    EDIT_MENU_ITEM_SUCCESSFUL,
    ERROR_EDITING_MENU_ITEM,
    DELETE_LOADING,
    DELETE_SECTION,
    DELETE_SECTION_FAILED,
    FETCH_TABLES,
    DELETE_SECTION_SUCCESSFUL,
    DELETE_ITEM_SUCCESSFUL,
    DELETE_ITEM_FAILED,
    DELETE_ITEM,
    TOGGLE_ITEM_AVAILABILITY,
    TOGGLE_ITEM_AVAILABILITY_SUCCESSFUL,
    TOGGLE_ITEM_AVAILABILITY_FAILED,
    FETCHED_OPENING_HOURS,
    ERROR_FETCHING_OPENING_HOURS,
    EDIT_OPENING_HOUR,
    EDIT_OPENING_HOUR_SUCCESSFUL,
    ERROR_EDITING_OPENING_HOUR,
    SIGN_IN_SUCCESSFUL,
    SIGN_IN_FAILED,
    SIGN_IN,
    UPDATE_TOKENS,
    FETCHED_RESTAURANT,
    ERROR_FETCHING_RESTAURANT,
    UPDATE_PRICE_LEVEL,
    ERROR_UPDATING_PRICE_LEVEL,
    UPDATE_PRICE_LEVEL_SUCCESSFUL,
    RESET_PASSWORD_EMAIL,
    ERROR_RESETTING_PASSWORD_EMAIL,
    RESET_PASSWORD_EMAIL_SUCCESSFUL,
    RESET_PASSWORD,
    ERROR_RESETTING_PASSWORD,
    RESET_PASSWORD_SUCCESSFUL,
    CREATING_UPDATING_TABLES_SUCCESSFUL,
    ERROR_CREATING_UPDATING_TABLES,
    CREATE_UPDATE_TABLES,
    ERROR_FETCHING_TABLES,
    FETCH_TABLES_SUCCESSFUL,
    FETCHED_RESERVATIONS,
    ERROR_FETCHING_RESERVATIONS,
    NEW_SOCKET_ORDER,
    CANCEL_ORDER_SUCCESSFUL,
    ERROR_CANCELLING_ORDER,
    MARK_AS_COMPLETED_SUCCESSFUL,
    ERROR_MARKING_AS_COMPLETE,
    FETCHED_STRIPE_ACCOUNT,
    ERROR_FETCHING_STRIPE_ACCOUNT,
    FETCHED_PUBLIC_RESTAURANT,
    ERROR_FETCHING_PUBLIC_RESTAURANT,
    FETCHED_MENU_BASKET_ITEMS,
    ERROR_FETCHING_MENU_BASKET_ITEMS,
    ADD_MENU_BASKET_ITEM,
    ADD_MENU_BASKET_ITEM_SUCCESSFUL,
    ERROR_ADDING_MENU_BASKET_ITEM,
    DELETE_BASKET_ITEM_SUCCESSFUL,
    DELETE_BASKET_ITEM_FAILED,
    DELETE_BASKET_ITEM,
    CREATE_CHECKOUT_SESSION,
    CREATE_CHECKOUT_SESSION_SUCCESSFUL,
    ERROR_CREATING_CHECKOUT_SESSION,
    UPDATE_TIP_AMOUNT_SUCCESSFUL,
    ERROR_UPDATING_TIP_AMOUNT,
    UPDATE_TABLE_CODE_SUCCESSFUL,
    ERROR_UPDATING_TABLE_CODE,
    UPDATE_USER_EMAIL_SUCCESSFUL,
    ERROR_UPDATING_USER_EMAIL,
    UPDATE_TIP_AMOUNT,
    UPDATE_TABLE_CODE,
    UPDATE_USER_EMAIL,
    FETCHED_RESTAURANTS_FOR_SEARCH,
    ERROR_FETCHING_RESTAURANTS_FOR_SEARCH,
    FETCH_CHECKOUT_SESSION_SUCCESSFUL,
    ERROR_FETCHING_CHECKOUT_SESSION,
    UPDATE_USER_REQUEST,
    UPDATE_USER_REQUEST_SUCCESSFUL,
    ERROR_UPDATING_USER_REQUEST,
    CREATE_USER_WAITER_REQUEST,
    CREATE_USER_WAITER_REQUEST_SUCCESSFUL,
    ERROR_CREATING_USER_WAITER_REQUEST,
    UPDATE_ORDER_NOTES,
    ERROR_UPDATING_ORDER_NOTES,
    UPDATE_ORDER_NOTES_SUCCESSFUL,
    FETCHED_USER,
    ERROR_FETCHING_USER,
    ERROR_EDITING_DETAILS,
    EDIT_DETAILS,
    EDIT_DETAILS_SUCCESSFUL,
    FETCHED_BOOKINGS,
    ERROR_FETCHING_BOOKINGS,
    CREATE_BOOKING,
    CREATE_BOOKING_SUCCESSFUL,
    ERROR_CREATING_BOOKING,
    FETCH_BOOKING_SUCCESSFUL,
    FETCH_BOOKING,
    ERROR_FETCHING_BOOKING,
    NEW_SOCKET_RESERVATION,
    ERROR_FETCHING_OCCUPANCIES,
    FETCH_OCCUPANCIES_SUCCESSFUL,
    SET_LANGUAGE,
    GET_LANGUAGE,
    GET_LANGUAGE_SUCCESSFUL,
    SET_LANGUAGE_SUCCESSFUL,
    FETCHED_REDIRECT_URL,
    ERROR_FETCHING_REDIRECT_URL,
    CONTACT_US,
    ERROR_CONTACTING_US,
    CONTACT_US_SUCCESSFUL,
    UPDATE_ONLINE_STATUS,
    UPDATE_RESERVATION_STATUS,
    LOG_OUT,
    ERROR_UPDATING_ONLINE_STATUS,
    UPDATE_ONLINE_STATUS_SUCCESSFUL,
    ERROR_UPDATING_RESERVATION_STATUS,
    UPDATE_RESERVATION_STATUS_SUCCESSFUL,
    REPORT_SENTRY_ERROR,
    CANCEL_RESERVATION_SUCCESSFUL,
    ERROR_CANCELLING_RESERVATION,
    FETCH_YOUR_TABLES_SUCCESSFUL,
    ERROR_FETCHING_YOUR_TABLES,
    ENABLE_TABLE,
    DISABLE_TABLE,
    ENABLE_TABLE_SUCCESSFUL,
    ERROR_ENABLING_TABLE,
    DISABLE_TABLE_SUCCESSFUL,
    ERROR_DISABLING_TABLE,
    UPDATE_TABLE_DETAILS,
    UPDATE_TABLE_DETAILS_SUCCESSFUL,
    ERROR_UPDATING_TABLE_DETAILS,
    UPDATE_PHONE_NUMBER,
    ERROR_UPDATING_PHONE_NUMBER,
    UPDATE_PHONE_NUMBER_SUCCESSFUL,
    UPDATE_MENU_ITEM_ORDER,
    UPDATE_MENU_ITEM_ORDER_SUCCESSFUL,
    UPDATE_MENU_ITEM_ORDER_FAILED,
    UPDATE_SECTION_ORDER_FAILED,
    UPDATE_SECTION_ORDER_SUCCESSFUL,
    UPDATE_SECTION_ORDER,
    UPDATE_ROOM_NUMBER,
    UPDATE_ROOM_NUMBER_SUCCESSFUL,
    ERROR_UPDATING_ROOM_NUMBER,
    ADD_ALLERGEN_SUCCESSFUL,
    UPDATE_COLORS,
    ERROR_UPDATING_COLORS,
    UPDATE_COLORS_SUCCESSFUL,
    UPDATE_ROOM_COUNT,
    ERROR_UPDATING_ROOM_COUNT,
    UPDATE_ROOM_COUNT_SUCCESSFUL,
    SET_MENU_LANGUAGE,
    GET_MENU_LANGUAGE,
    GET_MENU_LANGUAGE_SUCCESSFUL,
    SET_MENU_LANGUAGE_SUCCESSFUL,
    SET_MENU_LANGUAGE_OPTIONS,
    GET_MENU_LANGUAGE_OPTIONS,
    GET_MENU_LANGUAGE_OPTIONS_SUCCESSFUL,
    SET_MENU_LANGUAGE_OPTIONS_SUCCESSFUL,
    UPDATE_ORDER_FEE,
    ERROR_UPDATING_ORDER_FEE,
    UPDATE_ORDER_FEE_SUCCESSFUL,
    FETCHED_SUBSCRIPTIONS,
    ERROR_FETCHING_SUBSCRIPTIONS,
    UPDATE_SUBSCRIPTION,
    UPDATE_SUBSCRIPTION_SUCCESSFUL,
    ERROR_UPDATING_SUBSCRIPTION,
    UPDATE_SUBSCRIPTION_SUCCESS_FALSE,
    UPDATE_PICTURE_SUCCESS_FALSE,
    CREATE_SUBSCRIPTION_CHECKOUT_SESSION,
    ERROR_CREATING_SUBSCRIPTION_CHECKOUT_SESSION,
    ERROR_FETCHING_SUBSCRIPTION_CHECKOUT_SESSION,
    FETCH_CHECKOUT_SUBSCRIPTION_SESSION_SUCCESSFUL,
    FETCH_SUBSCRIPTION_DETAILS,
    FETCH_SUBSCRIPTION_DETAILS_SUCCESS,
    ERROR_FETCHING_SUBSCRIPTION_DETAILS,
    ADD_OFFER,
    ADD_OFFER_SUCCESSFUL,
    ERROR_ADDING_OFFER,
    FETCHED_OFFERS,
    ERROR_FETCHING_OFFERS,
    TOGGLE_OFFER_AVAILABILITY_FAILED,
    TOGGLE_OFFER_AVAILABILITY_SUCCESSFUL,
    TOGGLE_OFFER_AVAILABILITY,
    DELETE_OFFER,
    DELETE_OFFER_FAILED,
    DELETE_OFFER_SUCCESSFUL,
    FETCHED_PUBLIC_OFFERS,
    ERROR_FETCHING_PUBLIC_OFFERS,
    CHECK_IF_USER_EXISTS_SUCCESSFUL,
    CHECK_IF_USER_EXISTS_FAILED,
    CHECK_IF_USER_EXISTS,
    CHECK_IF_CODE_VALIDITY,
    CHECK_IF_CODE_VALIDITY_FAILED,
    CHECK_IF_CODE_VALIDITY_SUCCESSFUL,
    USER_SIGN_UP_SUCCESSFUL,
    USER_SIGN_UP_FAILED,
    USER_SIGN_UP,
    FETCHED_ORDERS_FOR_USER,
    ERROR_FETCHING_ORDERS_FOR_USER,
    FETCH_ORDERS_FOR_USER,
    FETCHED_CUSTOMER_USER,
    ERROR_FETCHING_CUSTOMER_USER,
    CHECK_USER_EMAIL_FOR_CHECKOUT_SESSION_SUCCESSFUL,
    FETCHED_OFFER_REDEMPTIONS,
    ERROR_FETCHING_OFFER_REDEMPTIONS,
    SET_EMAIL_EXISTS_UNDEFINED,
    FETCHED_CUSTOMER_USER_REWARDS,
    ERROR_FETCHING_CUSTOMER_USER_REWARDS,
    FETCHED_CUSTOMER_USER_OFFERS,
    ERROR_FETCHING_CUSTOMER_USER_OFFERS,
    UPDATE_REVIEW_SETTINGS,
    UPDATE_REVIEW_SETTINGS_SUCCESSFUL,
    ERROR_UPDATING_REVIEW_SETTINGS,
    FETCHED_REQUESTS,
    ERROR_FETCHING_REQUESTS,
    DELETE_REQUEST,
    DELETE_REQUEST_FAILED,
    DELETE_REQUEST_SUCCESSFUL,
    NEW_WAITER_REQUEST,
    FETCHED_PAY_LATER,
    ERROR_FETCHING_PAY_LATER,
    NEW_SOCKET_PAYMENT,
    UPDATE_REVIEW_SETTINGS_SAMPLE_EMAIL,
    UPDATE_REVIEW_SETTINGS_SAMPLE_EMAIL_SUCCESSFUL,
    UPDATE_PAY_LATER_SETTINGS,
    UPDATE_PAY_LATER_SETTINGS_SUCCESSFUL,
    ERROR_UPDATING_PAY_LATER_SETTINGS,
    SET_COOKIE_CONSENT,
    GET_COOKIE_CONSENT,
    GET_COOKIE_CONSENT_SUCCESSFUL,
    SET_COOKIE_CONSENT_SUCCESSFUL,
    DISMISS_WAITER_BOX,
    FETCHED_HOTEL_AMENITIES,
    ERROR_FETCHING_HOTEL_AMENITIES,
    TOGGLE_AMENITY_AVAILABILITY_SUCCESSFUL,
    TOGGLE_AMENITY_AVAILABILITY_FAILED,
    TOGGLE_AMENITY_AVAILABILITY,
    CREATE_AMENITY,
    CREATE_AMENITY_FAILED,
    CREATE_AMENITY_SUCCESSFUL,
    EDIT_AMENITY,
    EDIT_AMENITY_SUCCESSFUL,
    ERROR_EDITING_AMENITY,
    DELETE_AMENITY,
    DELETE_AMENITY_FAILED,
    DELETE_AMENITY_SUCCESSFUL,
    DELETE_AMENITY_TYPE,
    DELETE_AMENITY_TYPE_SUCCESSFUL,
    DELETE_AMENITY_TYPE_FAILED,
    TOGGLE_AMENITY_TYPE_AVAILABILITY,
    TOGGLE_AMENITY_TYPE_AVAILABILITY_SUCCESSFUL,
    TOGGLE_AMENITY_TYPE_AVAILABILITY_FAILED,
    CREATE_AMENITY_TYPE,
    CREATE_AMENITY_TYPE_FAILED,
    CREATE_AMENITY_TYPE_SUCCESSFUL,
    FETCHED_PUBLIC_AMENITIES,
    ERROR_FETCHING_PUBLIC_AMENITIES,
    CREATE_AMENITY_REQUEST,
    CREATE_AMENITY_REQUEST_SUCCESSFUL,
    ERROR_CREATING_AMENITY_REQUEST,
    SIGN_IN_TO_ROOM_PLUS,
    ERROR_SIGNING_IN_TO_ROOM_PLUS,
    SIGN_IN_TO_ROOM_PLUS_SUCCESSFUL,
    UPDATE_USER_NAME,
    UPDATE_USER_NAME_SUCCESSFUL,
    ERROR_UPDATING_USER_NAME,
    FETCHED_ROOM_PLUS_USER,
    ERROR_FETCHING_ROOM_PLUS_USER,
    FETCHED_AMENITY_REQUESTS,
    ERROR_FETCHING_AMENITY_REQUESTS,
    FETCHED_HOTEL_AMENITY_REQUESTS,
    ERROR_FETCHING_HOTEL_AMENITY_REQUESTS,
    CANCEL_AMENITY_REQUEST,
    CANCEL_AMENITY_REQUEST_SUCCESSFUL,
    ERROR_CANCELLING_AMENITY_REQUEST,
    MARK_AMENITY_REQUEST_AS_COMPLETED,
    MARK_AMENITY_REQUEST_AS_COMPLETED_SUCCESSFUL,
    ERROR_MARKING_AMENITY_REQUEST_AS_COMPLETE,
    ERROR_CREATING_NEW_USER_MESSAGE,
    CREATE_NEW_USER_MESSAGE_SUCCESSFUL,
    CREATE_NEW_USER_MESSAGE,
    NEW_AMENITY_REQUEST,
    FETCHED_USER_CHAT,
    ERROR_FETCHING_USER_CHAT,
    FETCHED_HOTEL_CHAT,
    ERROR_FETCHING_HOTEL_CHAT,
    NEW_CHAT_MESSAGE,
    FETCHED_HOTEL_DELIVERY_LOCATIONS,
    ERROR_FETCHING_HOTEL_DELIVERY_LOCATIONS,
    TOGGLE_DELIVERY_LOCATION_AVAILABILITY,
    TOGGLE_DELIVERY_LOCATION_AVAILABILITY_SUCCESSFUL,
    TOGGLE_DELIVERY_LOCATION_AVAILABILITY_FAILED,
    DELETE_DELIVERY_LOCATION,
    DELETE_DELIVERY_LOCATION_SUCCESSFUL,
    DELETE_DELIVERY_LOCATION_FAILED,
    CREATE_DELIVERY_LOCATION,
    CREATE_DELIVERY_LOCATION_FAILED,
    CREATE_DELIVERY_LOCATION_SUCCESSFUL,
    UPDATE_DELIVERY_LOCATION_SETTINGS,
    UPDATE_DELIVERY_LOCATION_SETTINGS_SUCCESSFUL,
    ERROR_UPDATING_DELIVERY_LOCATION_SETTINGS,
    UPDATE_IS_VISITOR,
    ERROR_UPDATING_IS_VISITOR,
    UPDATE_IS_VISITOR_SUCCESSFUL,
    UPDATE_ORDER_INFORMATION_SETTINGS,
    UPDATE_ORDER_INFORMATION_SETTINGS_SUCCESSFUL,
    ERROR_UPDATING_ORDER_INFORMATION_SETTINGS,
    FETCHED_REVIEW_TRACKS,
    ERROR_FETCHING_REVIEW_TRACKS,
    CREATE_MODIFIER_GROUP,
    CREATE_MODIFIER_GROUP_SUCCESSFUL,
    CREATE_MODIFIER_GROUP_FAILED,
    FETCHED_MODIFIER_GROUPS,
    ERROR_FETCHING_MODIFIER_GROUPS,
    DELETE_MODIFIER_GROUP,
    DELETE_MODIFIER_GROUP_FAILED,
    DELETE_MODIFIER_GROUP_SUCCESSFUL,
    ADD_MODIFIER,
    ADD_MODIFIER_SUCCESSFUL,
    ERROR_ADDING_MODIFIER,
    EDIT_MODIFIER,
    EDIT_MODIFIER_SUCCESSFUL,
    ERROR_EDITING_MODIFIER,
    DELETE_MODIFIER_SUCCESSFUL,
    DELETE_MODIFIER_FAILED,
    DELETE_MODIFIER,
    TOGGLE_MODIFIER_AVAILABILITY,
    TOGGLE_MODIFIER_AVAILABILITY_SUCCESSFUL,
    TOGGLE_MODIFIER_AVAILABILITY_FAILED,
    EDIT_MODIFIER_GROUP,
    EDIT_MODIFIER_GROUP_SUCCESSFUL,
    EDIT_MODIFIER_GROUP_FAILED,
    ADD_MODIFIER_TO_ITEM,
    ADD_MODIFIER_TO_ITEM_SUCCESSFUL,
    ADD_MODIFIER_TO_ITEM_FAILED,
    REMOVE_MODIFIER_FROM_ITEM,
    REMOVE_MODIFIER_FROM_ITEM_FAILED,
    REMOVE_MODIFIER_FROM_ITEM_SUCCESSFUL,
    SYNC_MENU_WITH_POS,
    SYNC_MENU_WITH_POS_FAILED,
    SYNC_MENU_WITH_POS_SUCCESSFUL,
    UPDATE_PICK_UP_SETTINGS,
    UPDATE_PICK_UP_SETTINGS_SUCCESSFUL,
    ERROR_UPDATING_PICK_UP_SETTINGS,
    ERROR_FETCHING_PICK_UP_TABLE,
    FETCH_PICK_UP_TABLE_SUCCESSFUL,
    ERROR_FETCHING_TABLE_DETAILS,
    FETCH_TABLE_DETAILS_SUCCESSFUL,
    SEND_ORDER_SMS_SUCCESSFUL,
    SEND_SMS_ORDER,
    ERROR_SENDING_SMS_ORDER,
    FETCHED_REPORTS,
    ERROR_FETCHING_REPORTS,
    FETCH_REPORTS,
    UPDATE_REMOTE_PICK_UP_SETTINGS,
    UPDATE_REMOTE_PICK_UP_SETTINGS_SUCCESSFUL,
    ERROR_UPDATING_REMOTE_PICK_UP_SETTINGS,
    FETCHED_LOCAL_OPENING_TIMES,
    ERROR_FETCHING_LOCAL_OPENING_TIMES,
    AUTH_WITH_ORACLE_SUCCESSFUL,
    ERROR_AUTH_WITH_ORACLE,
    AUTH_WITH_ORACLE,
    SOCKET_ORDER_CANCELLED,
    SOCKET_ORDER_COMPLETED,
    UPDATE_WAITER_REQUEST_SETTINGS,
    UPDATE_WAITER_REQUEST_SETTINGS_SUCCESSFUL,
    ERROR_UPDATING_WAITER_REQUEST_SETTINGS,
    CREATE_EMPLOYEE,
    CREATE_EMPLOYEE_SUCCESSFUL,
    CREATE_EMPLOYEE_FAILED,
    FETCHED_EMPLOYEES,
    ERROR_FETCHING_EMPLOYEES,
    DELETE_EMPLOYEE,
    DELETE_EMPLOYEE_FAILED,
    DELETE_EMPLOYEE_SUCCESSFUL,
    EDIT_EMPLOYEE,
    EDIT_EMPLOYEE_SUCCESSFUL,
    EDIT_EMPLOYEE_FAILED,
    ASSIGN_EMPLOYEE_TO_ORDER,
    ASSIGN_EMPLOYEE_TO_ORDER_SUCCESSFUL,
    ASSIGN_EMPLOYEE_TO_ORDER_FAILED,
    UPDATE_REQUIRES_EMPLOYEE_ASSIGNMENT,
    UPDATE_REQUIRES_EMPLOYEE_ASSIGNMENT_FAILED,
    UPDATE_REQUIRES_EMPLOYEE_ASSIGNMENT_SUCCESSFUL,
    SOCKET_EMPLOYEE_ID_ASSIGNED,
    POS_TABLE_SYNC_SUCCESSFUL,
    ERROR_SYNCING_POS_TABLES,
    POS_TABLE_SYNC,
    UPDATE_AUTO_CLOSE_SETTINGS,
    UPDATE_AUTO_CLOSE_SETTINGS_SUCCESSFUL,
    ERROR_UPDATING_AUTO_CLOSE_SETTINGS,
    UPDATE_SOCIAL_SETTINGS,
    UPDATE_SOCIAL_SETTINGS_SUCCESSFUL,
    ERROR_UPDATING_SOCIAL_SETTINGS,
    FETCHED_SOCIAL_URL,
    ERROR_FETCHING_SOCIAL_URL,
    FETCHED_SOCIAL_TRACKS,
    ERROR_FETCHING_SOCIAL_TRACKS,
    CREATE_TAG,
    CREATE_TAG_SUCCESSFUL,
    CREATE_TAG_FAILED,
    FETCHED_TAGS,
    ERROR_FETCHING_TAGS,
    DELETE_TAG,
    DELETE_TAG_FAILED,
    DELETE_TAG_SUCCESSFUL,
    EDIT_TAG,
    EDIT_TAG_SUCCESSFUL,
    EDIT_TAG_FAILED,
    ADD_TAG_TO_ITEM,
    ADD_TAG_TO_ITEM_SUCCESSFUL,
    ADD_TAG_TO_ITEM_FAILED,
    REMOVE_TAG_FROM_ITEM,
    REMOVE_TAG_FROM_ITEM_FAILED,
    REMOVE_TAG_FROM_ITEM_SUCCESSFUL,
    FETCHED_FILTER_OPTIONS,
    ERROR_FETCHING_FILTER_OPTIONS,
    UPDATE_FILTER_OPTIONS,
    GET_FILTER_OPTIONS,
    ERROR_UPDATING_FILTER_OPTIONS,
    UPDATE_FILTER_OPTIONS_SUCCESSFUL,
    GET_FILTER_OPTIONS_SUCCESSFUL,
    FETCHED_AVAILABILITY_DAYS,
    ERROR_FETCHING_AVAILABILITY_DAYS,
    EDIT_AVAILABILITY_DAY,
    EDIT_AVAILABILITY_DAY_SUCCESSFUL,
    ERROR_EDITING_AVAILABILITY_DAY,
    FETCHED_DISCOUNT_DAYS,
    ERROR_FETCHING_DISCOUNT_DAYS,
    EDIT_DISCOUNT_DAY,
    EDIT_DISCOUNT_DAY_SUCCESSFUL,
    ERROR_EDITING_DISCOUNT_DAY,
    TOGGLE_AVAILABILITY_SUCCESS,
    CREATE_ALLERGEN,
    CREATE_ALLERGEN_SUCCESSFUL,
    CREATE_ALLERGEN_FAILED,
    FETCHED_ALLERGENS,
    ERROR_FETCHING_ALLERGENS,
    DELETE_ALLERGEN,
    DELETE_ALLERGEN_FAILED,
    DELETE_ALLERGEN_SUCCESSFUL,
    EDIT_ALLERGEN,
    EDIT_ALLERGEN_SUCCESSFUL,
    EDIT_ALLERGEN_FAILED,
    ADD_ALLERGEN_TO_ITEM,
    ADD_ALLERGEN_TO_ITEM_SUCCESSFUL,
    ADD_ALLERGEN_TO_ITEM_FAILED,
    REMOVE_ALLERGEN_FROM_ITEM,
    REMOVE_ALLERGEN_FROM_ITEM_FAILED,
    REMOVE_ALLERGEN_FROM_ITEM_SUCCESSFUL,
    SOCKET_DELIVERECT_ORDER_FAILURE,
    SOCKET_DELIVERECT_ORDER_FAILURE_ACTION,
    SOCKET_DELIVERY_UPDATE_RECEIVED,
    UPDATE_TAX_SETTINGS,
    UPDATE_TAX_SETTINGS_SUCCESSFUL,
    ERROR_UPDATING_TAX_SETTINGS,
    SAVE_DELIVERECT_CREDS_SUCCESSFUL,
    SAVE_DELIVERECT_CREDS,
    ERROR_SAVE_DELIVERECT_CREDS,
    SAVE_NEW_POS_SUCCESSFUL,
    ERROR_SAVE_NEW_POS,
    SAVE_NEW_POS_SYSTEM,
    SAVE_CLORDER_CREDS_SUCCESSFUL,
    ERROR_SAVE_CLORDER_CREDS,
    SAVE_CLORDER_CREDS,
    UPDATE_DEFAULT_TIP,
    UPDATE_DEFAULT_TIP_SUCCESSFUL,
    ERROR_UPDATING_DEFAULT_TIP,
    REPORT_INSIGHT,
    REPORT_INSIGHT_SUCCESSFUL,
    ERROR_REPORTING_INSIGHT,
    FETCHED_MENU_INSIGHTS,
    ERROR_FETCHING_MENU_INSIGHTS,
    FETCH_MENU_INSIGHTS,
    FETCH_SECTION_INSIGHTS,
    ERROR_FETCHING_SECTION_INSIGHTS,
    FETCHED_SECTION_INSIGHTS,
    FETCH_ITEM_INSIGHTS,
    ERROR_FETCHING_ITEM_INSIGHTS,
    FETCHED_ITEM_INSIGHTS,
    FETCH_ORDER_INSIGHTS,
    ERROR_FETCHING_ORDER_INSIGHTS,
    FETCHED_ORDER_INSIGHTS,
    FETCHED_TIPS_INSIGHTS,
    ERROR_FETCHING_TIPS_INSIGHTS,
    FETCH_TIPS_INSIGHTS,
    EDIT_SECTION_SUCCESSFUL,
    EDIT_SECTION,
    EDIT_SECTION_FAILED,
    ADD_RECOMMENDED_ITEM_TO_ITEM,
    ADD_RECOMMENDED_ITEM_TO_ITEM_SUCCESSFUL,
    ADD_RECOMMENDED_ITEM_TO_ITEM_FAILED,
    REMOVE_RECOMMENDED_ITEM_FROM_ITEM,
    REMOVE_RECOMMENDED_ITEM_FROM_ITEM_FAILED,
    REMOVE_RECOMMENDED_ITEM_FROM_ITEM_SUCCESSFUL,
    UPDATE_CTA_TEXT,
    UPDATE_CTA_TEXT_SUCCESSFUL,
    ERROR_UPDATING_CTA_TEXT,
    UPDATE_ADMIN_SETTINGS,
    UPDATE_ADMIN_SETTINGS_SUCCESSFUL,
    UPDATE_ADMIN_SETTINGS_FAILED,
    FETCHED_ADMIN_SETTINGS,
    ERROR_FETCHING_ADMIN_SETTINGS,
    CREATE_FEATURED_ITEM,
    CREATE_FEATURED_ITEM_SUCCESSFUL,
    CREATE_FEATURED_ITEM_FAILED,
    FETCHED_FEATURED_ITEMS,
    ERROR_FETCHING_FEATURED_ITEMS,
    DELETE_FEATURED_ITEM,
    DELETE_FEATURED_ITEM_FAILED,
    DELETE_FEATURED_ITEM_SUCCESSFUL,
    UPDATE_FEATURED_ITEMS_SETTINGS,
    UPDATE_FEATURED_ITEMS_SETTINGS_SUCCESSFUL,
    ERROR_UPDATING_FEATURED_ITEMS_SETTINGS,
    UPDATE_EMAIL_COLLECTION_SETTINGS,
    UPDATE_EMAIL_COLLECTION_SETTINGS_SUCCESSFUL,
    ERROR_UPDATING_EMAIL_COLLECTION_SETTINGS,
    UPDATE_EMAIL_COLLECTION_SETTINGS_SAMPLE_EMAIL,
    UPDATE_EMAIL_COLLECTION_SETTINGS_SAMPLE_EMAIL_SUCCESSFUL,
    SEND_USER_DISCOUNT_CODE_EMAIL,
    SEND_USER_DISCOUNT_CODE_EMAIL_SUCCESSFUL,
    ERROR_SENDING_USER_DISCOUNT_CODE_EMAIL,
    FETCHED_USER_DISCOUNT_EMAILS,
    ERROR_FETCHING_USER_DISCOUNT_EMAILS,
    ADD_BLOG,
    ADD_BLOG_SUCCESSFUL,
    ERROR_ADDING_BLOG,
    FETCHED_BLOGS,
    ERROR_FETCHING_BLOGS,
    FETCHED_ADMIN_REPORTS,
    ERROR_FETCHING_ADMIN_REPORTS,
    FETCH_ADMIN_REPORTS,
    UPDATE_MODIFIER_SETTINGS,
    UPDATE_MODIFIER_SETTINGS_SUCCESSFUL,
    ERROR_UPDATING_MODIFIER_SETTINGS,
    UPDATE_ORDER_EMAIL_NOTIFICATION_SETTINGS,
    UPDATE_ORDER_EMAIL_NOTIFICATION_SETTINGS_SUCCESSFUL,
    ERROR_UPDATING_ORDER_EMAIL_NOTIFICATION_SETTINGS,
    EDIT_WALLET_PASS_SETTINGS_SUCCESSFUL,
    ERROR_EDITING_WALLET_PASS_SETTINGS,
    EDIT_WALLET_PASS_SETTINGS,
    CREATE_LOYALTY_CAMPAIGN,
    CREATE_LOYALTY_CAMPAIGN_SUCCESSFUL,
    ERROR_CREATING_LOYALTY_CAMPAIGN,
    FETCHED_LOYALTY_CAMPAIGNS,
    ERROR_FETCHING_LOYALTY_CAMPAIGNS,
    TOGGLE_LOYALTY_CAMPAIGN_AVAILABILITY,
    TOGGLE_LOYALTY_CAMPAIGN_AVAILABILITY_FAILED,
    TOGGLE_LOYALTY_CAMPAIGN_AVAILABILITY_SUCCESSFUL,
    DELETE_LOYALTY_CAMPAIGN_SUCCESSFUL,
    DELETE_LOYALTY_CAMPAIGN,
    DELETE_LOYALTY_CAMPAIGN_FAILED,
    SUBSCRIBE_LOYALTY_CAMPAIGN,
    SUBSCRIBE_LOYALTY_CAMPAIGN_SUCCESSFUL,
    ERROR_SUBSCRIBING_LOYALTY_CAMPAIGN,
    FETCHED_LOYALTY_PASS,
    ERROR_FETCHING_LOYALTY_PASS,
    CREATE_LOYALTY_PASS_QUALIFICATION,
    SUCCESS_CREATING_LOYALTY_PASS_QUALIFICATION,
    ERROR_CREATING_LOYALTY_PASS_QUALIFICATION,
    CREATE_LOYALTY_PASS_CANCELLATION,
    SUCCESS_CREATING_LOYALTY_PASS_CANCELLATION,
    ERROR_CREATING_LOYALTY_PASS_CANCELLATION,
    SUCCESS_CREATING_LOYALTY_PASS_REDEMPTION,
    ERROR_CREATING_LOYALTY_PASS_REDEMPTION,
    CREATE_LOYALTY_PASS_REDEMPTION,
    CREATE_FREE_LOYALTY_PASS_REDEMPTION,
    SUCCESS_CREATING_FREE_LOYALTY_PASS_REDEMPTION,
    FETCHED_USER_CRM_STATS,
    ERROR_FETCHING_USER_CRM_STATS,
    ERROR_CREATING_FREE_LOYALTY_PASS_REDEMPTION,
    FETCH_USER_CRM_STATS,
    FETCH_BANNER_INSIGHTS,
    FETCHED_BANNER_INSIGHTS,
    ERROR_FETCHING_BANNER_INSIGHTS,
    UPDATE_DELITEFUL_AI_SETTINGS,
    UPDATE_DELITEFUL_AI_SETTINGS_SUCCESSFUL,
    ERROR_UPDATING_DELITEFUL_AI_SETTINGS,
    GENERATE_AI_EMAIL_CAMPAIGN_SUCCESSFUL,
    ERROR_GENERATING_AI_EMAIL_CAMPAIGN,
    GENERATE_AI_EMAIL_CAMPAIGN,
    FETCHED_EMAIL_CAMPAIGNS,
    ERROR_FETCHING_EMAIL_CAMPAIGNS,
    ADD_EMAIL_CAMPAIGN,
    ADD_EMAIL_CAMPAIGN_SUCCESSFUL,
    ERROR_ADDING_EMAIL_CAMPAIGN,
    NEW_DELIVERY_STATUS_UPDATE,
    EDIT_EMAIL_CAMPAIGN,
    EDIT_EMAIL_CAMPAIGN_SUCCESSFUL,
    ERROR_EDITING_EMAIL_CAMPAIGN,
    DELETE_EMAIL_CAMPAIGN_SUCCESSFUL,
    DELETE_EMAIL_CAMPAIGN_FAILED,
    DELETE_EMAIL_CAMPAIGN,
    TOGGLE_EMAIL_CAMPAIGN_AVAILABILITY,
    TOGGLE_EMAIL_CAMPAIGN_AVAILABILITY_SUCCESSFUL,
    TOGGLE_EMAIL_CAMPAIGN_AVAILABILITY_FAILED,
    FETCHED_EMAIL_SEQUENCE_LIVES,
    ERROR_FETCHING_EMAIL_SEQUENCE_LIVES,
    ADD_EMAIL_SEQUENCE_LIVE,
    ADD_EMAIL_SEQUENCE_LIVE_SUCCESSFUL,
    ERROR_ADDING_EMAIL_SEQUENCE_LIVE,
    EDIT_EMAIL_SEQUENCE_LIVE,
    EDIT_EMAIL_SEQUENCE_LIVE_SUCCESSFUL,
    ERROR_EDITING_EMAIL_SEQUENCE_LIVE,
    DELETE_EMAIL_SEQUENCE_LIVE_SUCCESSFUL,
    DELETE_EMAIL_SEQUENCE_LIVE_FAILED,
    DELETE_EMAIL_SEQUENCE_LIVE,
    UPDATE_AUTOMATED_EMAIL_SEQUENCE_LIVE,
    ERROR_UPDATING_AUTOMATED_EMAIL_SEQUENCE_LIVE,
    UPDATE_AUTOMATED_EMAIL_SEQUENCE_LIVE_SUCCESSFUL,
    UPDATE_BOTTOM_MENU_TEXT,
    UPDATE_BOTTOM_MENU_TEXT_SUCCESSFUL,
    ERROR_UPDATING_BOTTOM_MENU_TEXT,
    GENERATE_AI_MENU_DESCRIPTION,
    GENERATE_AI_MENU_DESCRIPTION_SUCCESSFUL,
    ERROR_GENERATING_AI_MENU_DESCRIPTION,
    SUBSCRIBE_STICKY_HEADER,
    SUBSCRIBE_STICKY_HEADER_SUCCESSFUL,
    ERROR_SUBSCRIBING_STICKY_HEADER,
    UPLOADED_CSV_DATA_SUCCESSFULLY,
    ERROR_UPLOADING_CSV_DATA,
    UPLOAD_CSV_DATA,
    FETCH_WEBSITE_CONFIG,
    UPDATE_WEBSITE_CONFIG,
    ERROR_FETCHING_WEBSITE_CONFIG,
    ERROR_UPDATING_WEBSITE_CONFIG,
    UPLOAD_WEBSITE_IMAGE,
    UPDATE_WEBSITE_SETTINGS,
    UPDATE_WEBSITE_SETTINGS_SUCCESSFUL,
    ERROR_UPDATING_WEBSITE_SETTINGS,
    UPDATE_MENU_VIEW_SETTINGS,
    UPDATE_MENU_VIEW_SETTINGS_SUCCESSFUL,
    ERROR_UPDATING_MENU_VIEW_SETTINGS,
    UNSUBSCRIBE_SUCCESSFUL,
    ERROR_UNSUBSCRIBING,
    UNSUBSCRIBE_USER,
    RESUB_SUCCESSFUL,
    ERROR_RESUB,
    RESUB_USER,
    UPDATE_MENU_SORT_SETTINGS,
    UPDATE_MENU_SORT_SETTINGS_SUCCESSFUL,
    ERROR_UPDATING_MENU_SORT_SETTINGS,
    TOGGLE_SECTION_AVAILABILITY,
    TOGGLE_SECTION_AVAILABILITY_FAILED,
    TOGGLE_SECTION_AVAILABILITY_SUCCESSFUL,
    CLONE_SECTION_SUCCESSFUL,
    CLONE_SECTION,
    CLONE_SECTION_FAILED,
    CLONE_SECTION_ITEM,
    CLONE_SECTION_ITEM_SUCCESSFUL,
    CLONE_SECTION_ITEM_FAILED,
    UPDATE_USER_ADDRESS,
    UPDATE_USER_ADDRESS_SUCCESSFUL,
    ERROR_UPDATING_USER_ADDRESS,
    CHECK_DELIVERY_QUOTE_SUCCESSFUL,
    UPDATE_DELIVERY_SETTINGS,
    UPDATE_DELIVERY_SETTINGS_SUCCESSFUL,
    ERROR_UPDATING_DELIVERY_SETTINGS,
    SOCKET_DELIVERY_UPDATE_RECEIVED_ACTION,
    UPDATE_WELCOME_SCREEN_SETTINGS_SUCCESSFUL,
    UPDATE_WELCOME_SCREEN_SETTINGS,
    ERROR_UPDATING_WELCOME_SCREEN_SETTINGS,
    UPDATE_PROMO_BANNER_SETTINGS,
    UPDATE_PROMO_BANNER_SETTINGS_SUCCESSFUL,
    ERROR_UPDATING_PROMO_BANNER_SETTINGS,
    ADD_DELITEPAY_GIFT_CARD,
    ADD_DELITEPAY_GIFT_CARD_SUCCESSFUL,
    ERROR_ADDING_DELITEPAY_GIFT_CARD,
    FETCHED_DELITEPAY_GIFT_CARDS,
    ERROR_FETCHING_DELITEPAY_GIFT_CARDS,
    TOGGLE_DELITEPAY_GIFT_CARD_AVAILABILITY_FAILED,
    TOGGLE_DELITEPAY_GIFT_CARD_AVAILABILITY_SUCCESSFUL,
    TOGGLE_DELITEPAY_GIFT_CARD_AVAILABILITY,
    DELETE_DELITEPAY_GIFT_CARD,
    DELETE_DELITEPAY_GIFT_CARD_FAILED,
    DELETE_DELITEPAY_GIFT_CARD_SUCCESSFUL,
    FETCHED_PUBLIC_DELITEPAY_GIFT_CARDS,
    ERROR_FETCHING_PUBLIC_DELITEPAY_GIFT_CARDS,
    UPDATE_DELITEPAY_SETTINGS_SUCCESSFUL,
    UPDATE_DELITEPAY_SETTINGS,
    ERROR_UPDATING_DELITEPAY_SETTINGS,
    FETCH_DELITEPAY_INSIGHTS,
    FETCHED_DELITEPAY_INSIGHTS,
    ERROR_FETCHING_DELITEPAY_INSIGHTS,
    ADD_DELITECLUB_SUBSCRIPTION,
    ADD_DELITECLUB_SUBSCRIPTION_SUCCESSFUL,
    ERROR_ADDING_DELITECLUB_SUBSCRIPTION,
    FETCHED_DELITECLUB_SUBSCRIPTIONS,
    ERROR_FETCHING_DELITECLUB_SUBSCRIPTIONS,
    TOGGLE_DELITECLUB_SUBSCRIPTION_AVAILABILITY_FAILED,
    TOGGLE_DELITECLUB_SUBSCRIPTION_AVAILABILITY_SUCCESSFUL,
    TOGGLE_DELITECLUB_SUBSCRIPTION_AVAILABILITY,
    DELETE_DELITECLUB_SUBSCRIPTION,
    DELETE_DELITECLUB_SUBSCRIPTION_FAILED,
    DELETE_DELITECLUB_SUBSCRIPTION_SUCCESSFUL,
    FETCHED_PUBLIC_DELITECLUB_SUBSCRIPTIONS,
    ERROR_FETCHING_PUBLIC_DELITECLUB_SUBSCRIPTIONS,
    UPDATE_DELITECLUB_SETTINGS_SUCCESSFUL,
    UPDATE_DELITECLUB_SETTINGS,
    ERROR_UPDATING_DELITECLUB_SETTINGS,
    CANCEL_DELITECLUB_SUBSCRIPTION,
    CANCEL_DELITECLUB_SUBSCRIPTION_FAILED,
    CANCEL_DELITECLUB_SUBSCRIPTION_SUCCESSFUL,
    UPDATE_POPUP_SETTINGS,
    UPDATE_POPUP_SETTINGS_SUCCESSFUL,
    ERROR_UPDATING_POPUP_SETTINGS,
    SUBSCRIBE_POPUP,
    SUBSCRIBE_POPUP_SUCCESSFUL,
    ERROR_SUBSCRIBING_POPUP,
    FETCHED_TRENDS,
    ERROR_FETCHING_TRENDS,
    FETCHED_REVIEW_HUB_REVIEWS,
    ERROR_FETCHING_REVIEW_HUB_REVIEWS,
    GET_REVIEW_RECOMMENDATION,
    GET_REVIEW_RECOMMENDATION_FAILED,
    GET_REVIEW_RECOMMENDATION_SUCCESSFUL,
    GET_REVIEW_RESPONSE_SUCCESSFUL,
    GET_REVIEW_RESPONSE_FAILED,
    GET_REVIEW_RESPONSE,
    FETCH_CUMULATIVE_AVERAGE_INTERPRETATION,
    FETCHED_KEYWORD_ANALYSIS,
    ERROR_FETCHING_KEYWORD_ANALYSIS,
    FETCHED_HIGHLIGHTED_REVIEWS,
    ERROR_FETCHING_HIGHLIGHTED_REVIEWS,
    UPDATE_USER_ACTIONS_SETTINGS,
    UPDATE_USER_ACTIONS_SETTINGS_SUCCESSFUL,
    ERROR_UPDATING_USER_ACTIONS_SETTINGS,
    SUBMIT_USER_ACTION_REQUEST,
    SUBMIT_USER_ACTION_REQUEST_SUCCESSFUL,
    ERROR_SUBMITTING_USER_ACTION_REQUEST,
    UPDATE_MERCHANT_DIRECTORY_SETTINGS,
    UPDATE_MERCHANT_DIRECTORY_SETTINGS_SUCCESSFUL,
    ERROR_UPDATING_MERCHANT_DIRECTORY_SETTINGS,
    APP_REGISTRATION,
    APP_REGISTRATION_SUCCESSFUL,
    ERROR_REGISTERING_LOYALTY_APP,
    UPDATE_LOYALTY_APP_SETTINGS,
    UPDATE_LOYALTY_APP_SETTINGS_SUCCESSFUL,
    ERROR_UPDATING_LOYALTY_APP_SETTINGS,
    ADD_DISCOUNT_SUCCESSFUL,
    ERROR_ADDING_DISCOUNT,
    ADD_DISCOUNT,
    FETCHED_DISCOUNTS,
    ERROR_FETCHING_DISCOUNTS,
    TOGGLE_DISCOUNT_AVAILABILITY_SUCCESSFUL,
    DELETE_DISCOUNT_SUCCESSFUL,
    UPDATE_FEATURED_ITEM_ORDER,
    UPDATE_FEATURED_ITEM_ORDER_FAILED,
    UPDATE_FEATURED_ITEM_ORDER_SUCCESSFUL,
    UPDATE_MODIFIER_ORDER,
    UPDATE_MODIFIER_ORDER_FAILED,
    UPDATE_MODIFIER_ORDER_SUCCESSFUL,
    SET_MENU_DATA,
    UPLOAD_MENU_FROM_IMAGE,
    SIGN_UP_LOYALTY_USER,
    SIGN_UP_LOYALTY_USER_SUCCESSFUL,
    ERROR_SIGNING_UP_LOYALTY_USER,
    SUBSCRIBE_TO_LOYALTY,
    SUBSCRIBE_TO_LOYALTY_SUCCESSFUL,
    ERROR_SUBSCRIBING_TO_LOYALTY,
    FETCH_LOYALTY_USER_INFO,
    FETCH_LOYALTY_USER_INFO_SUCCESSFUL,
    ERROR_FETCHING_LOYALTY_USER_INFO,
    ERROR_CREATING_STRIPE_PORTAL_SESSION,
    CREATE_STRIPE_PORTAL_SESSION,
    CREATE_STRIPE_PORTAL_SESSION_SUCCESSFUL,
    FETCH_PLATFORM_RESTAURANTS,
    FETCH_PLATFORM_RESTAURANTS_SUCCESSFUL,
    ERROR_FETCHING_PLATFORM_RESTAURANTS,
    REDEEM_LOYALTY_DISCOUNT_SUCCESSFUL,
    ERROR_REDEEMING_LOYALTY_DISCOUNT,
    UPDATE_PAYMENT_SETTINGS,
    UPDATE_PAYMENT_SETTINGS_SUCCESSFUL,
    ERROR_UPDATING_PAYMENT_SETTINGS,
    UPDATE_ORDER_GROUPING_SETTINGS,
    UPDATE_ORDER_GROUPING_SETTINGS_SUCCESSFUL,
    ERROR_UPDATING_ORDER_GROUPING_SETTINGS,
    MARK_AS_COMPLETED_SUCCESSFUL_TABLE,
    ERROR_MARKING_AS_COMPLETE_TABLE,
    MARK_AS_COMPLETED_FOR_TABLE,
    TABLE_VIEW_ADD_ITEMS_COMPLETE,
    TABLE_VIEW_COMPLETE_ORDERS_COMPLETE,
    WIPE_TABLE_VIEW_STATUSES,
    WIPE_BOOKING,
    SOCKET_EVENTS: {
        NEW_ACCESS_TOKEN,
        NEW_ORDER_RECEIVED,
        NEW_RESERVATION_RECEIVED,
        CALL_WAITER_RECEIVED,
        NEW_PAYMENT_FOR_PAYLATER_ORDER_RECEIVED,
        NEW_PAYMENT_FOR_PAY_LATER,
        AMENITY_REQUEST_RECEIVED,
        NEW_CHAT_MESSAGE_RECEIVED,
        NEW_DELIVERY_STATUS_UPDATE_CONF_PAGE,
    },
};
