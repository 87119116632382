import {
    CREATE_TAG_SUCCESSFUL,
    CREATE_TAG_FAILED,
    FETCHED_TAGS,
    ERROR_FETCHING_TAGS,
    DELETE_TAG_FAILED,
    ADD_TAG_SUCCESSFUL,
    ERROR_ADDING_TAG,
    EDIT_TAG_SUCCESSFUL,
    TOGGLE_TAG_AVAILABILITY_SUCCESSFUL,
    DELETE_TAG_SUCCESSFUL,
    FETCHED_FILTER_OPTIONS,
    GET_FILTER_OPTIONS_SUCCESSFUL,
    UPDATE_FILTER_OPTIONS_SUCCESSFUL,
    EDIT_DISCOUNT_DAY_SUCCESSFUL,
    EDIT_AVAILABILITY_DAY_SUCCESSFUL,
} from '../types';

const initialState = {};

const tagReducer = (state = initialState, action) => {
    switch (action.type) {
        case DELETE_TAG_SUCCESSFUL:
            return {
                ...state,
                tags: [
                    ...state.tags.filter(
                        s => s.id !== parseInt(action.data.tagId, 10)
                    ),
                ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
                menuTypes: [
                    ...state.menuTypes.filter(
                        s => s.id !== parseInt(action.data.tagId, 10)
                    ),
                ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
            };
        case DELETE_TAG_FAILED:
            return state;
        case CREATE_TAG_SUCCESSFUL:
            return {
                ...state,
                tags: action.data.tag.isMenuType
                    ? state.tags
                    : [
                          ...state.tags,
                          { ...action.data.tag, tags: [] },
                      ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
                menuTypes: !action.data.tag.isMenuType
                    ? state.menuTypes
                    : [
                          ...state.menuTypes,
                          { ...action.data.tag, tags: [] },
                      ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
            };
        case CREATE_TAG_FAILED:
            return {
                ...state,
                createSectionError: action.error,
            };
        case FETCHED_TAGS:
            return {
                ...state,
                tags: action.tags
                    .filter(t => !t.isMenuType)
                    .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
                menuTypes: action.tags
                    .filter(t => t.isMenuType)
                    .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
            };
        case FETCHED_FILTER_OPTIONS:
            // console.log('fetched', action);
            return {
                ...state,
                filterOptions: action.filterOptions.sort((a, b) =>
                    a.createdAt > b.createdAt ? -1 : 1
                ),
            };
        case GET_FILTER_OPTIONS_SUCCESSFUL:
        case UPDATE_FILTER_OPTIONS_SUCCESSFUL:
            return {
                ...state,
                cachedFilterOptions: action.filterOptions,
            };
        case EDIT_DISCOUNT_DAY_SUCCESSFUL:
            return {
                ...state,
                tags: [
                    ...(state.tags || []).filter(t => t.id !== action.tagId),
                    {
                        ...(state.tags || []).find(t => t.id === action.tagId),
                        tagDiscountEnabled: action.item.tagDiscountEnabled,
                    },
                ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
                filterOptions: ((state.filterOptions || []).find(
                    t => t.id === action.tagId
                )
                    ? [
                          ...(state.filterOptions || []).filter(
                              t => t.id !== action.tagId
                          ),
                          {
                              ...(state.filterOptions || []).find(
                                  t => t.id === action.tagId
                              ),
                              tagDiscountEnabled:
                                  action.item.tagDiscountEnabled,
                          },
                      ]
                    : state.filterOptions || []
                ).sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
            };
        case EDIT_AVAILABILITY_DAY_SUCCESSFUL:
            return {
                ...state,
                tags: [
                    ...(state.tags || []).filter(t => t.id !== action.tagId),
                    {
                        ...(state.tags || []).find(t => t.id === action.tagId),
                        tagAvailabilityEnabled:
                            action.item.tagAvailabilityEnabled,
                    },
                ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
                filterOptions: ((state.filterOptions || []).find(
                    t => t.id === action.tagId
                )
                    ? [
                          ...(state.filterOptions || []).filter(
                              t => t.id !== action.tagId
                          ),
                          {
                              ...(state.filterOptions || []).find(
                                  t => t.id === action.tagId
                              ),
                              tagAvailabilityEnabled:
                                  action.item.tagAvailabilityEnabled,
                          },
                      ]
                    : state.filterOptions || []
                ).sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
            };
        case ERROR_FETCHING_TAGS:
            return {
                ...state,
                error: action.error,
            };
        // case EDIT_TAG_SUCCESSFUL:
        //     return {
        //         ...state,
        //         tags: [
        //             ...state.tags.filter(
        //                 g => g.id !== parseInt(action.data.id, 10)
        //             ),
        //             {
        //                 id: parseInt(action.data.id, 10),
        //                 min: parseInt(action.data.changes.min, 10),
        //                 max: parseInt(action.data.changes.max, 10),
        //                 name: action.data.changes.name,
        //             },
        //         ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
        //     };
        case ADD_TAG_SUCCESSFUL:
            const group = state.tags.find(g => g.id === action.item.tagId);
            return {
                ...state,
                tags: [
                    ...state.tags.filter(g => g.id !== action.item.tagId),
                    {
                        ...group,
                        tags: [
                            ...(group.tags || []),
                            { ...action.item },
                        ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
                    },
                ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
            };
        case ERROR_ADDING_TAG:
            // fix this
            return {
                ...state,
                createTagError: action.error,
            };
        case EDIT_TAG_SUCCESSFUL:
            return {
                ...state,
                menuTypes: [
                    ...state.menuTypes.filter(
                        g => g.id !== parseInt(action.data.id, 10)
                    ),
                    {
                        ...state.menuTypes.find(
                            g => g.id === parseInt(action.data.id, 10)
                        ),
                        id: parseInt(action.data.id, 10),
                        name: action.data.changes.name,
                        color: action.data.changes.color,
                    },
                ].sort((a, b) => {
                    if (a.position === null && b.position === null) {
                        return a.createdAt > b.createdAt ? 1 : -1;
                    }
                    if (a.position === null) {
                        return 1;
                    }
                    if (b.position === null) {
                        return -1;
                    }
                    if (parseInt(a.position, 10) > parseInt(b.position, 10)) {
                        return 1;
                    }
                    return -1;
                }),
            };
        case TOGGLE_TAG_AVAILABILITY_SUCCESSFUL:
            const section2 = state.tags.find(
                section => section.id === action.item.sectionId
            );
            const item = section2.tags.find(
                menu => menu.id === action.item.itemId
            );
            return {
                ...state,
                tags: [
                    ...state.tags.filter(
                        section => section.id !== action.item.sectionId
                    ),
                    {
                        ...section2,
                        tags: [
                            ...section2.tags.filter(
                                item => item.id !== action.item.itemId
                            ),
                            { ...item, ...action.item.changes },
                        ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
                    },
                ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
            };
        default:
            return state;
    }
};

export default tagReducer;
