export default {
    en: {
        thank_you_fee:
            'Thank you for your order! A small fee helps us cover the cost of processing it smoothly.',
        service_fee: 'Service Fee',
        order_confirmation: 'Order Confirmation',
        confirm_delivery: 'Confirm Delivery',
        your_information: 'Your Information',
        empty_pound: '£0.00',
        enter_room_number_plz: 'Please enter your room number.',
        enter_table_codes: 'Please enter your table code.',
        specify_name_2: 'Please fill in your name.',
        enter_pickup_time: 'Please select your pickup time.',
        room_number_visitor: 'Please enter room number or checkout as visitor.',
        specify_name: 'Please fill in all fields.',
        define_fields: 'Please complete all the fields',
        any_allergies_short: 'Any Allergies or Notes?',
        notes: 'Notes',
        notes_descr:
            'Use this space to tell the restaurant about any allergies or special requests for your order.',
        cancel: 'Cancel',
        save: 'Save',
        continue: 'Continue',
        pay: 'Pay',
        go_back: 'Go Back',
        collection_order_time: 'When would you like to pickup your order?',
        enter_room_number: 'Enter Your Room Number:',
        enter_table_code: 'Table Code:',
        room_number: 'Room Number',
        incorrect_room_number: 'Incorrect Room Number',
        or_caps: 'OR',
        just_visiting: 'Checkout As A Visitor',
        where_should_we_deliver: 'Where Should We Deliver?',
        my_room: 'My Room',
        your_email: 'Enter Your Email:',
        email_expl:
            'This email will be used for your receipt as well as bonus points.',
        incorrect_email_format: 'Incorrect Email Format',
        your_address: 'Your Address',
        incorrect_address_format: 'Incorrect Address Format',
        your_phone_number_no_colon: 'Your Phone Number',
        we_will_text_del: "We'll text you with order updates!",
        we_will_text: "We'll text when your order is ready!",
        your_phone_number: 'Your Phone Number:',
        incorrect_phone_number_format: 'Incorrect Phone Number Format',
        your_full_name: 'Your Full Name:',
        incorrect_name_format: 'Incorrect Name Format',
        delivery_address: 'Delivery Address:',
        delivery_time: 'Estimated Delivery Time:',
        delivery_notes: 'Delivery Notes for Driver:',
        your_total_short: 'Your Total',
        your_delivery_fee: 'Delivery Fee',
        custom: 'Custom',
        tip: 'Tip',
    },
    es: {
        thank_you_fee:
            '¡Gracias por tu pedido! Una pequeña tarifa nos ayuda a cubrir el costo de procesarlo sin problemas.',
        service_fee: 'Tarifa de Servicio',
        order_confirmation: 'Confirmación de Pedido',
        confirm_delivery: 'Confirmar Entrega',
        your_information: 'Tu Información',
        empty_pound: '£0.00',
        enter_room_number_plz: 'Por favor, ingresa tu número de habitación.',
        enter_table_codes: 'Por favor, ingresa tu código de mesa.',
        specify_name_2: 'Por favor, ingresa tu nombre.',
        enter_pickup_time: 'Por favor, selecciona tu hora de recogida.',
        room_number_visitor:
            'Por favor, ingresa el número de habitación o continúa como visitante.',
        specify_name: 'Por favor, completa todos los campos.',
        define_fields: 'Por favor, completa todos los campos',
        any_allergies_short: '¿Alguna alergia o notas?',
        notes: 'Notas',
        notes_descr:
            'Usa este espacio para informar al restaurante sobre cualquier alergia o solicitud especial para tu pedido.',
        cancel: 'Cancelar',
        save: 'Guardar',
        continue: 'Continuar',
        pay: 'Pagar',
        go_back: 'Volver',
        collection_order_time: '¿Cuándo te gustaría recoger tu pedido?',
        enter_room_number: 'Ingresa tu número de habitación:',
        enter_table_code: 'Código de mesa:',
        room_number: 'Número de habitación',
        incorrect_room_number: 'Número de habitación incorrecto',
        or_caps: 'O',
        just_visiting: 'Continuar como visitante',
        where_should_we_deliver: '¿Dónde deberíamos entregar?',
        my_room: 'Mi habitación',
        your_email: 'Ingresa tu correo electrónico:',
        email_expl:
            'Este correo electrónico se utilizará para tu recibo y puntos de bonificación.',
        incorrect_email_format: 'Formato de correo incorrecto',
        your_address: 'Tu dirección',
        incorrect_address_format: 'Formato de dirección incorrecto',
        your_phone_number_no_colon: 'Tu número de teléfono',
        we_will_text_del:
            'Te enviaremos un mensaje de texto con las actualizaciones del pedido!',
        we_will_text:
            'Te enviaremos un mensaje de texto cuando tu pedido esté listo!',
        your_phone_number: 'Tu número de teléfono:',
        incorrect_phone_number_format:
            'Formato de número de teléfono incorrecto',
        your_full_name: 'Tu nombre completo:',
        incorrect_name_format: 'Formato de nombre incorrecto',
        delivery_address: 'Dirección de entrega:',
        delivery_time: 'Tiempo estimado de entrega:',
        delivery_notes: 'Notas de entrega para el conductor:',
        your_total_short: 'Tu total',
        your_delivery_fee: 'Tarifa de entrega',
        custom: 'Personalizado',
        tip: 'Propina',
    },
    zh: {
        thank_you_fee: '感谢您的订单！一小笔费用帮助我们顺利处理。',
        service_fee: '服务费',
        order_confirmation: '订单确认',
        confirm_delivery: '确认交付',
        your_information: '您的信息',
        empty_pound: '£0.00',
        enter_room_number_plz: '请输入您的房间号码。',
        enter_table_codes: '请输入您的桌号。',
        specify_name_2: '请填写您的姓名。',
        enter_pickup_time: '请选择您的取货时间。',
        room_number_visitor: '请输入房间号或以访客身份结账。',
        specify_name: '请填写所有字段。',
        define_fields: '请填写所有字段',
        any_allergies_short: '有任何过敏或备注？',
        notes: '备注',
        notes_descr: '请在此处告诉餐厅您是否有任何过敏或特殊要求。',
        cancel: '取消',
        save: '保存',
        continue: '继续',
        pay: '付款',
        go_back: '返回',
        collection_order_time: '您想什么时候取餐？',
        enter_room_number: '请输入您的房间号码：',
        enter_table_code: '桌号：',
        room_number: '房间号码',
        incorrect_room_number: '房间号码不正确',
        or_caps: '或者',
        just_visiting: '以访客身份结账',
        where_should_we_deliver: '我们应该送到哪里？',
        my_room: '我的房间',
        your_email: '请输入您的电子邮件：',
        email_expl: '此电子邮件将用于您的收据以及积分。',
        incorrect_email_format: '电子邮件格式错误',
        your_address: '您的地址',
        incorrect_address_format: '地址格式错误',
        your_phone_number_no_colon: '您的电话号码',
        we_will_text_del: '我们会通过短信向您发送订单更新！',
        we_will_text: '您的订单准备好时我们会通知您！',
        your_phone_number: '您的电话号码：',
        incorrect_phone_number_format: '电话号码格式错误',
        your_full_name: '您的全名：',
        incorrect_name_format: '姓名格式错误',
        delivery_address: '送货地址：',
        delivery_time: '预计送达时间：',
        delivery_notes: '司机送货说明：',
        your_total_short: '您的总额',
        your_delivery_fee: '送货费',
        custom: '自定义',
        tip: '小费',
    },
    ar: {
        thank_you_fee:
            'شكرًا على طلبك! تساعدنا رسوم صغيرة في تغطية تكاليف معالجته بسلاسة.',
        service_fee: 'رسوم الخدمة',
        order_confirmation: 'تأكيد الطلب',
        confirm_delivery: 'تأكيد التسليم',
        your_information: 'معلوماتك',
        empty_pound: '£0.00',
        enter_room_number_plz: 'يرجى إدخال رقم غرفتك.',
        enter_table_codes: 'يرجى إدخال رمز الطاولة.',
        specify_name_2: 'يرجى إدخال اسمك.',
        enter_pickup_time: 'يرجى تحديد وقت الاستلام الخاص بك.',
        room_number_visitor: 'يرجى إدخال رقم الغرفة أو السداد كزائر.',
        specify_name: 'يرجى ملء جميع الحقول.',
        define_fields: 'يرجى إكمال جميع الحقول',
        any_allergies_short: 'هل لديك أي حساسية أو ملاحظات؟',
        notes: 'ملاحظات',
        notes_descr:
            'استخدم هذه المساحة لإبلاغ المطعم بأي حساسية أو طلبات خاصة لطلبك.',
        cancel: 'إلغاء',
        save: 'حفظ',
        continue: 'متابعة',
        pay: 'دفع',
        go_back: 'العودة',
        collection_order_time: 'متى ترغب في استلام طلبك؟',
        enter_room_number: 'أدخل رقم غرفتك:',
        enter_table_code: 'رمز الطاولة:',
        room_number: 'رقم الغرفة',
        incorrect_room_number: 'رقم الغرفة غير صحيح',
        or_caps: 'أو',
        just_visiting: 'السداد كزائر',
        where_should_we_deliver: 'أين يجب أن نسلم؟',
        my_room: 'غرفتي',
        your_email: 'أدخل بريدك الإلكتروني:',
        email_expl:
            'سيتم استخدام هذا البريد الإلكتروني لإيصالك وكذلك نقاط المكافأة.',
        incorrect_email_format: 'تنسيق البريد الإلكتروني غير صحيح',
        your_address: 'عنوانك',
        incorrect_address_format: 'تنسيق العنوان غير صحيح',
        your_phone_number_no_colon: 'رقم هاتفك',
        we_will_text_del: 'سوف نرسل لك رسالة نصية مع تحديثات الطلب!',
        we_will_text: 'سوف نرسل لك رسالة نصية عندما يكون طلبك جاهزًا!',
        your_phone_number: 'رقم هاتفك:',
        incorrect_phone_number_format: 'تنسيق رقم الهاتف غير صحيح',
        your_full_name: 'اسمك الكامل:',
        incorrect_name_format: 'تنسيق الاسم غير صحيح',
        delivery_address: 'عنوان التسليم:',
        delivery_time: 'الوقت المقدر للتسليم:',
        delivery_notes: 'ملاحظات التسليم للسائق:',
        your_total_short: 'إجمالي السعر',
        your_delivery_fee: 'رسوم التسليم',
        custom: 'مخصص',
        tip: 'بقشيش',
    },
    ru: {
        thank_you_fee:
            'Спасибо за ваш заказ! Небольшая плата помогает нам покрывать расходы на его обработку.',
        service_fee: 'Плата за обслуживание',
        order_confirmation: 'Подтверждение заказа',
        confirm_delivery: 'Подтвердить доставку',
        your_information: 'Ваша информация',
        empty_pound: '£0.00',
        enter_room_number_plz: 'Пожалуйста, введите номер комнаты.',
        enter_table_codes: 'Пожалуйста, введите код стола.',
        specify_name_2: 'Пожалуйста, введите ваше имя.',
        enter_pickup_time: 'Пожалуйста, выберите время получения.',
        room_number_visitor:
            'Пожалуйста, введите номер комнаты или оформите заказ как гость.',
        specify_name: 'Пожалуйста, заполните все поля.',
        define_fields: 'Пожалуйста, заполните все поля',
        any_allergies_short: 'Какие-либо аллергии или примечания?',
        notes: 'Примечания',
        notes_descr:
            'Используйте это пространство, чтобы сообщить ресторану о любых аллергиях или особых запросах для вашего заказа.',
        cancel: 'Отмена',
        save: 'Сохранить',
        continue: 'Продолжить',
        pay: 'Оплатить',
        go_back: 'Назад',
        collection_order_time: 'Когда вы хотите забрать заказ?',
        enter_room_number: 'Введите номер комнаты:',
        enter_table_code: 'Код стола:',
        room_number: 'Номер комнаты',
        incorrect_room_number: 'Неправильный номер комнаты',
        or_caps: 'ИЛИ',
        just_visiting: 'Оформить как гость',
        where_should_we_deliver: 'Куда доставить?',
        my_room: 'Моя комната',
        your_email: 'Введите ваш email:',
        email_expl:
            'Этот email будет использован для квитанции и начисления бонусных баллов.',
        incorrect_email_format: 'Неверный формат email',
        your_address: 'Ваш адрес',
        incorrect_address_format: 'Неверный формат адреса',
        your_phone_number_no_colon: 'Ваш номер телефона',
        we_will_text_del: 'Мы отправим вам смс с обновлениями заказа!',
        we_will_text: 'Мы отправим смс, когда ваш заказ будет готов!',
        your_phone_number: 'Ваш номер телефона:',
        incorrect_phone_number_format: 'Неверный формат номера телефона',
        your_full_name: 'Ваше полное имя:',
        incorrect_name_format: 'Неверный формат имени',
        delivery_address: 'Адрес доставки:',
        delivery_time: 'Предполагаемое время доставки:',
        delivery_notes: 'Примечания для курьера:',
        your_total_short: 'Ваш общий счет',
        your_delivery_fee: 'Стоимость доставки',
        custom: 'Пользовательский',
        tip: 'Чаевые',
    },
    ja: {
        thank_you_fee:
            'ご注文ありがとうございます！少額の手数料でスムーズな処理を実現します。',
        service_fee: 'サービス料',
        order_confirmation: '注文確認',
        confirm_delivery: '配達を確認',
        your_information: 'あなたの情報',
        empty_pound: '£0.00',
        enter_room_number_plz: '部屋番号を入力してください。',
        enter_table_codes: 'テーブルコードを入力してください。',
        specify_name_2: 'お名前をご記入ください。',
        enter_pickup_time: '受取時間を選択してください。',
        room_number_visitor:
            '部屋番号を入力するか、訪問者としてチェックアウトしてください。',
        specify_name: 'すべてのフィールドに記入してください。',
        define_fields: 'すべてのフィールドに記入してください',
        any_allergies_short: 'アレルギーまたはメモはありますか？',
        notes: 'メモ',
        notes_descr:
            'このスペースを使用して、アレルギーや注文に関する特別なリクエストをレストランに伝えてください。',
        cancel: 'キャンセル',
        save: '保存',
        continue: '続行',
        pay: '支払う',
        go_back: '戻る',
        collection_order_time: '注文を受け取りたい時刻はいつですか？',
        enter_room_number: '部屋番号を入力してください:',
        enter_table_code: 'テーブルコード：',
        room_number: '部屋番号',
        incorrect_room_number: '部屋番号が間違っています',
        or_caps: 'または',
        just_visiting: '訪問者としてチェックアウト',
        where_should_we_deliver: 'どこに届ければいいですか？',
        my_room: '私の部屋',
        your_email: 'メールアドレスを入力してください：',
        email_expl: 'このメールは、領収書とボーナスポイントに使用されます。',
        incorrect_email_format: '不正なメール形式',
        your_address: 'あなたの住所',
        incorrect_address_format: '不正な住所形式',
        your_phone_number_no_colon: 'あなたの電話番号',
        we_will_text_del: '注文の更新をお知らせするためにSMSを送信します！',
        we_will_text: '注文の準備ができたらお知らせします！',
        your_phone_number: 'あなたの電話番号：',
        incorrect_phone_number_format: '不正な電話番号形式',
        your_full_name: 'あなたのフルネーム：',
        incorrect_name_format: '不正な名前形式',
        delivery_address: '配送先住所：',
        delivery_time: '推定配達時間：',
        delivery_notes: 'ドライバーへの配送メモ：',
        your_total_short: '合計金額',
        your_delivery_fee: '配達料金',
        custom: 'カスタム',
        tip: 'チップ',
    },
    ko: {
        thank_you_fee:
            '주문해 주셔서 감사합니다! 작은 수수료로 원활한 처리를 돕습니다.',
        service_fee: '서비스 요금',
        order_confirmation: '주문 확인',
        confirm_delivery: '배송 확인',
        your_information: '당신의 정보',
        empty_pound: '£0.00',
        enter_room_number_plz: '방 번호를 입력하세요.',
        enter_table_codes: '테이블 코드를 입력하세요.',
        specify_name_2: '이름을 입력하세요.',
        enter_pickup_time: '픽업 시간을 선택하세요.',
        room_number_visitor: '방 번호를 입력하거나 방문자로 체크아웃하세요.',
        specify_name: '모든 필드를 작성하세요.',
        define_fields: '모든 필드를 작성하세요',
        any_allergies_short: '알레르기 또는 메모가 있습니까?',
        notes: '메모',
        notes_descr:
            '이 공간을 사용하여 레스토랑에 알레르기나 주문에 대한 특별 요청을 알려주세요.',
        cancel: '취소',
        save: '저장',
        continue: '계속',
        pay: '지불',
        go_back: '뒤로 가기',
        collection_order_time: '주문을 픽업할 시간은 언제입니까?',
        enter_room_number: '방 번호를 입력하세요:',
        enter_table_code: '테이블 코드:',
        room_number: '방 번호',
        incorrect_room_number: '잘못된 방 번호',
        or_caps: '또는',
        just_visiting: '방문자로 체크아웃',
        where_should_we_deliver: '어디로 배송해야 합니까?',
        my_room: '내 방',
        your_email: '이메일을 입력하세요:',
        email_expl: '이 이메일은 영수증과 보너스 포인트에 사용됩니다.',
        incorrect_email_format: '잘못된 이메일 형식',
        your_address: '귀하의 주소',
        incorrect_address_format: '잘못된 주소 형식',
        your_phone_number_no_colon: '귀하의 전화번호',
        we_will_text_del: '주문 업데이트를 문자로 알려드리겠습니다!',
        we_will_text: '주문이 준비되면 알려드리겠습니다!',
        your_phone_number: '귀하의 전화번호:',
        incorrect_phone_number_format: '잘못된 전화번호 형식',
        your_full_name: '귀하의 성명:',
        incorrect_name_format: '잘못된 이름 형식',
        delivery_address: '배달 주소:',
        delivery_time: '예상 배달 시간:',
        delivery_notes: '운전자에게 전달할 배송 메모:',
        your_total_short: '총 금액',
        your_delivery_fee: '배송비',
        custom: '사용자 정의',
        tip: '팁',
    },
    de: {
        thank_you_fee:
            'Vielen Dank für Ihre Bestellung! Eine kleine Gebühr hilft uns, die Bearbeitung reibungslos abzuwickeln.',
        service_fee: 'Servicegebühr',
        order_confirmation: 'Bestellbestätigung',
        confirm_delivery: 'Lieferung bestätigen',
        your_information: 'Ihre Informationen',
        empty_pound: '£0.00',
        enter_room_number_plz: 'Bitte geben Sie Ihre Zimmernummer ein.',
        enter_table_codes: 'Bitte geben Sie Ihren Tischcode ein.',
        specify_name_2: 'Bitte geben Sie Ihren Namen ein.',
        enter_pickup_time: 'Bitte wählen Sie Ihre Abholzeit.',
        room_number_visitor:
            'Bitte geben Sie die Zimmernummer ein oder checken Sie als Besucher aus.',
        specify_name: 'Bitte füllen Sie alle Felder aus.',
        define_fields: 'Bitte füllen Sie alle Felder aus',
        any_allergies_short: 'Irgendwelche Allergien oder Notizen?',
        notes: 'Notizen',
        notes_descr:
            'Verwenden Sie diesen Bereich, um das Restaurant über etwaige Allergien oder besondere Wünsche für Ihre Bestellung zu informieren.',
        cancel: 'Abbrechen',
        save: 'Speichern',
        continue: 'Fortsetzen',
        pay: 'Bezahlen',
        go_back: 'Zurück',
        collection_order_time: 'Wann möchten Sie Ihre Bestellung abholen?',
        enter_room_number: 'Geben Sie Ihre Zimmernummer ein:',
        enter_table_code: 'Tischcode:',
        room_number: 'Zimmernummer',
        incorrect_room_number: 'Falsche Zimmernummer',
        or_caps: 'ODER',
        just_visiting: 'Als Besucher auschecken',
        where_should_we_deliver: 'Wohin sollen wir liefern?',
        my_room: 'Mein Zimmer',
        your_email: 'Geben Sie Ihre E-Mail-Adresse ein:',
        email_expl:
            'Diese E-Mail wird für Ihre Quittung und Bonuspunkte verwendet.',
        incorrect_email_format: 'Falsches E-Mail-Format',
        your_address: 'Ihre Adresse',
        incorrect_address_format: 'Falsches Adressformat',
        your_phone_number_no_colon: 'Ihre Telefonnummer',
        we_will_text_del:
            'Wir werden Ihnen SMS-Nachrichten mit Bestellaktualisierungen senden!',
        we_will_text:
            'Wir werden Sie benachrichtigen, wenn Ihre Bestellung fertig ist!',
        your_phone_number: 'Ihre Telefonnummer:',
        incorrect_phone_number_format: 'Falsches Telefonnummernformat',
        your_full_name: 'Ihr vollständiger Name:',
        incorrect_name_format: 'Falsches Namensformat',
        delivery_address: 'Lieferadresse:',
        delivery_time: 'Geschätzte Lieferzeit:',
        delivery_notes: 'Lieferhinweise für den Fahrer:',
        your_total_short: 'Ihr Gesamtbetrag',
        your_delivery_fee: 'Liefergebühr',
        custom: 'Benutzerdefiniert',
        tip: 'Trinkgeld',
    },
    fr: {
        thank_you_fee:
            'Merci pour votre commande ! Un petit frais nous aide à couvrir les coûts de traitement en douceur.',
        service_fee: 'Frais de service',
        order_confirmation: 'Confirmation de commande',
        confirm_delivery: 'Confirmer la livraison',
        your_information: 'Vos informations',
        empty_pound: '£0.00',
        enter_room_number_plz: 'Veuillez entrer votre numéro de chambre.',
        enter_table_codes: 'Veuillez entrer votre code de table.',
        specify_name_2: 'Veuillez entrer votre nom.',
        enter_pickup_time: 'Veuillez sélectionner votre heure de collecte.',
        room_number_visitor:
            'Veuillez entrer le numéro de la chambre ou passer à la caisse en tant que visiteur.',
        specify_name: 'Veuillez remplir tous les champs.',
        define_fields: 'Veuillez remplir tous les champs',
        any_allergies_short: 'Des allergies ou des notes?',
        notes: 'Remarques',
        notes_descr:
            'Utilisez cet espace pour informer le restaurant de toute allergie ou demande spéciale pour votre commande.',
        cancel: 'Annuler',
        save: 'Enregistrer',
        continue: 'Continuer',
        pay: 'Payer',
        go_back: 'Retourner',
        collection_order_time: 'Quand souhaitez-vous récupérer votre commande?',
        enter_room_number: 'Entrez votre numéro de chambre:',
        enter_table_code: 'Code de table:',
        room_number: 'Numéro de chambre',
        incorrect_room_number: 'Numéro de chambre incorrect',
        or_caps: 'OU',
        just_visiting: 'Passer en tant que visiteur',
        where_should_we_deliver: 'Où devrions-nous livrer?',
        my_room: 'Ma chambre',
        your_email: 'Entrez votre e-mail:',
        email_expl:
            'Cet e-mail sera utilisé pour votre reçu ainsi que pour les points bonus.',
        incorrect_email_format: "Format d'e-mail incorrect",
        your_address: 'Votre adresse',
        incorrect_address_format: "Format d'adresse incorrect",
        your_phone_number_no_colon: 'Votre numéro de téléphone',
        we_will_text_del:
            'Nous vous enverrons des SMS avec les mises à jour de la commande!',
        we_will_text:
            'Nous vous enverrons un SMS lorsque votre commande sera prête!',
        your_phone_number: 'Votre numéro de téléphone:',
        incorrect_phone_number_format:
            'Format de numéro de téléphone incorrect',
        your_full_name: 'Votre nom complet:',
        incorrect_name_format: 'Format de nom incorrect',
        delivery_address: 'Adresse de livraison:',
        delivery_time: 'Heure de livraison estimée:',
        delivery_notes: 'Notes de livraison pour le conducteur:',
        your_total_short: 'Votre total',
        your_delivery_fee: 'Frais de livraison',
        custom: 'Personnalisé',
        tip: 'Pourboire',
    },
    pl: {
        thank_you_fee:
            'Dziękujemy za zamówienie! Niewielka opłata pomaga nam pokryć koszty jego przetwarzania.',
        service_fee: 'Opłata za usługę',
        order_confirmation: 'Potwierdzenie zamówienia',
        confirm_delivery: 'Potwierdź dostawę',
        your_information: 'Twoje informacje',
        empty_pound: '£0.00',
        enter_room_number_plz: 'Proszę wprowadzić numer pokoju.',
        enter_table_codes: 'Proszę wprowadzić kod stołu.',
        specify_name_2: 'Proszę podać swoje imię.',
        enter_pickup_time: 'Proszę wybrać czas odbioru.',
        room_number_visitor:
            'Proszę wprowadzić numer pokoju lub dokonać płatności jako gość.',
        specify_name: 'Proszę wypełnić wszystkie pola.',
        define_fields: 'Proszę wypełnić wszystkie pola',
        any_allergies_short: 'Jakieś alergie lub uwagi?',
        notes: 'Notatki',
        notes_descr:
            'Użyj tej przestrzeni, aby poinformować restaurację o alergiach lub specjalnych prośbach dotyczących zamówienia.',
        cancel: 'Anuluj',
        save: 'Zapisz',
        continue: 'Kontynuuj',
        pay: 'Zapłać',
        go_back: 'Wróć',
        collection_order_time: 'Kiedy chciałbyś odebrać zamówienie?',
        enter_room_number: 'Wprowadź numer pokoju:',
        enter_table_code: 'Kod stołu:',
        room_number: 'Numer pokoju',
        incorrect_room_number: 'Nieprawidłowy numer pokoju',
        or_caps: 'LUB',
        just_visiting: 'Dokonaj płatności jako gość',
        where_should_we_deliver: 'Gdzie powinniśmy dostarczyć?',
        my_room: 'Mój pokój',
        your_email: 'Wprowadź swój email:',
        email_expl:
            'Ten e-mail będzie używany do otrzymywania paragonu oraz punktów bonusowych.',
        incorrect_email_format: 'Nieprawidłowy format e-maila',
        your_address: 'Twój adres',
        incorrect_address_format: 'Nieprawidłowy format adresu',
        your_phone_number_no_colon: 'Twój numer telefonu',
        we_will_text_del: 'Wyślemy Ci SMS-a z aktualizacjami zamówienia!',
        we_will_text: 'Wyślemy SMS, gdy Twoje zamówienie będzie gotowe!',
        your_phone_number: 'Twój numer telefonu:',
        incorrect_phone_number_format: 'Nieprawidłowy format numeru telefonu',
        your_full_name: 'Twoje pełne imię:',
        incorrect_name_format: 'Nieprawidłowy format imienia',
        delivery_address: 'Adres dostawy:',
        delivery_time: 'Szacowany czas dostawy:',
        delivery_notes: 'Notatki dla dostawcy:',
        your_total_short: 'Twój całkowity',
        your_delivery_fee: 'Opłata za dostawę',
        custom: 'Niestandardowy',
        tip: 'Napiwek',
    },
    pt: {
        thank_you_fee:
            'Obrigado pelo seu pedido! Uma pequena taxa nos ajuda a cobrir o custo de processamento.',
        service_fee: 'Taxa de Serviço',
        order_confirmation: 'Confirmação de Pedido',
        confirm_delivery: 'Confirmar Entrega',
        your_information: 'Suas informações',
        empty_pound: '£0.00',
        enter_room_number_plz: 'Por favor, insira seu número de quarto.',
        enter_table_codes: 'Por favor, insira o código da mesa.',
        specify_name_2: 'Por favor, insira seu nome.',
        enter_pickup_time: 'Por favor, selecione seu horário de coleta.',
        room_number_visitor:
            'Por favor, insira o número do quarto ou pague como visitante.',
        specify_name: 'Por favor, preencha todos os campos.',
        define_fields: 'Por favor, preencha todos os campos',
        any_allergies_short: 'Alguma alergia ou observações?',
        notes: 'Observações',
        notes_descr:
            'Use este espaço para informar o restaurante sobre quaisquer alergias ou pedidos especiais para seu pedido.',
        cancel: 'Cancelar',
        save: 'Salvar',
        continue: 'Continuar',
        pay: 'Pagar',
        go_back: 'Voltar',
        collection_order_time: 'Quando você gostaria de retirar seu pedido?',
        enter_room_number: 'Insira seu número de quarto:',
        enter_table_code: 'Código da mesa:',
        room_number: 'Número do quarto',
        incorrect_room_number: 'Número de quarto incorreto',
        or_caps: 'OU',
        just_visiting: 'Pagar como visitante',
        where_should_we_deliver: 'Onde devemos entregar?',
        my_room: 'Meu quarto',
        your_email: 'Insira seu e-mail:',
        email_expl: 'Este e-mail será usado para seu recibo e pontos de bônus.',
        incorrect_email_format: 'Formato de e-mail incorreto',
        your_address: 'Seu endereço',
        incorrect_address_format: 'Formato de endereço incorreto',
        your_phone_number_no_colon: 'Seu número de telefone',
        we_will_text_del:
            'Enviaremos uma mensagem de texto com atualizações do pedido!',
        we_will_text:
            'Enviaremos uma mensagem de texto quando seu pedido estiver pronto!',
        your_phone_number: 'Seu número de telefone:',
        incorrect_phone_number_format:
            'Formato de número de telefone incorreto',
        your_full_name: 'Seu nome completo:',
        incorrect_name_format: 'Formato de nome incorreto',
        delivery_address: 'Endereço de entrega:',
        delivery_time: 'Tempo estimado de entrega:',
        delivery_notes: 'Notas de entrega para o motorista:',
        your_total_short: 'Seu total',
        your_delivery_fee: 'Taxa de entrega',
        custom: 'Personalizado',
        tip: 'Gorjeta',
    },
    it: {
        thank_you_fee:
            'Grazie per il tuo ordine! Una piccola tassa ci aiuta a coprire il costo di elaborazione.',
        service_fee: 'Costo del servizio',
        order_confirmation: 'Conferma ordine',
        confirm_delivery: 'Conferma consegna',
        your_information: 'Le tue informazioni',
        empty_pound: '£0.00',
        enter_room_number_plz: 'Inserisci il numero della tua stanza.',
        enter_table_codes: 'Inserisci il codice del tavolo.',
        specify_name_2: 'Per favore inserisci il tuo nome.',
        enter_pickup_time: "Per favore seleziona l'orario di ritiro.",
        room_number_visitor:
            'Per favore inserisci il numero della stanza o effettua il pagamento come visitatore.',
        specify_name: 'Per favore compila tutti i campi.',
        define_fields: 'Per favore compila tutti i campi',
        any_allergies_short: 'Allergie o note?',
        notes: 'Note',
        notes_descr:
            'Usa questo spazio per informare il ristorante di eventuali allergie o richieste speciali per il tuo ordine.',
        cancel: 'Annulla',
        save: 'Salva',
        continue: 'Continua',
        pay: 'Paga',
        go_back: 'Indietro',
        collection_order_time: 'Quando vorresti ritirare il tuo ordine?',
        enter_room_number: 'Inserisci il numero della stanza:',
        enter_table_code: 'Codice del tavolo:',
        room_number: 'Numero della stanza',
        incorrect_room_number: 'Numero della stanza errato',
        or_caps: 'O',
        just_visiting: 'Effettua il pagamento come visitatore',
        where_should_we_deliver: 'Dove dovremmo consegnare?',
        my_room: 'La mia stanza',
        your_email: 'Inserisci il tuo indirizzo email:',
        email_expl:
            'Questa email sarà utilizzata per la tua ricevuta e per i punti bonus.',
        incorrect_email_format: 'Formato email errato',
        your_address: 'Il tuo indirizzo',
        incorrect_address_format: 'Formato indirizzo errato',
        your_phone_number_no_colon: 'Il tuo numero di telefono',
        we_will_text_del:
            "Ti invieremo un messaggio con gli aggiornamenti dell'ordine!",
        we_will_text: 'Ti avviseremo quando il tuo ordine sarà pronto!',
        your_phone_number: 'Il tuo numero di telefono:',
        incorrect_phone_number_format: 'Formato numero di telefono errato',
        your_full_name: 'Il tuo nome completo:',
        incorrect_name_format: 'Formato nome errato',
        delivery_address: 'Indirizzo di consegna:',
        delivery_time: 'Tempo di consegna stimato:',
        delivery_notes: 'Note di consegna per il conducente:',
        your_total_short: 'Il tuo totale',
        your_delivery_fee: 'Costo di consegna',
        custom: 'Personalizzato',
        tip: 'Mancia',
    },
    nl: {
        thank_you_fee:
            'Bedankt voor uw bestelling! Een kleine vergoeding helpt ons de verwerkingskosten soepel te dekken.',
        service_fee: 'Servicekosten',
        order_confirmation: 'Bestelling Bevestigen',
        confirm_delivery: 'Bevestig Levering',
        your_information: 'Uw Informatie',
        empty_pound: '£0.00',
        enter_room_number_plz: 'Voer uw kamernummer in.',
        enter_table_codes: 'Voer uw tafelcode in.',
        specify_name_2: 'Voer uw naam in.',
        enter_pickup_time: 'Selecteer uw afhaaltijd.',
        room_number_visitor: 'Voer uw kamernummer in of betaal als bezoeker.',
        specify_name: 'Vul alle velden in.',
        define_fields: 'Vul alle velden in',
        any_allergies_short: 'Allergieën of opmerkingen?',
        notes: 'Opmerkingen',
        notes_descr:
            'Gebruik deze ruimte om het restaurant te informeren over eventuele allergieën of speciale verzoeken voor uw bestelling.',
        cancel: 'Annuleren',
        save: 'Opslaan',
        continue: 'Doorgaan',
        pay: 'Betalen',
        go_back: 'Terug',
        collection_order_time: 'Wanneer wilt u uw bestelling afhalen?',
        enter_room_number: 'Voer uw kamernummer in:',
        enter_table_code: 'Tafelcode:',
        room_number: 'Kamernummer',
        incorrect_room_number: 'Onjuist kamernummer',
        or_caps: 'OF',
        just_visiting: 'Afrekenen als bezoeker',
        where_should_we_deliver: 'Waar moeten we bezorgen?',
        my_room: 'Mijn kamer',
        your_email: 'Voer uw e-mailadres in:',
        email_expl:
            'Dit e-mailadres wordt gebruikt voor uw bon en bonuspunten.',
        incorrect_email_format: 'Onjuist e-mailformaat',
        your_address: 'Uw adres',
        incorrect_address_format: 'Onjuist adresformaat',
        your_phone_number_no_colon: 'Uw telefoonnummer',
        we_will_text_del: 'We sturen je een sms met bestellingsupdates!',
        we_will_text: 'We laten het je weten als je bestelling klaar is!',
        your_phone_number: 'Uw telefoonnummer:',
        incorrect_phone_number_format: 'Onjuist telefoonnummerformaat',
        your_full_name: 'Uw volledige naam:',
        incorrect_name_format: 'Onjuist naamformaat',
        delivery_address: 'Bezorgadres:',
        delivery_time: 'Geschatte bezorgtijd:',
        delivery_notes: 'Bezorgnotities voor de chauffeur:',
        your_total_short: 'Uw totaal',
        your_delivery_fee: 'Bezorgkosten',
        custom: 'Aangepast',
        tip: 'Fooi',
    },
    hi: {
        thank_you_fee:
            'आपके आदेश के लिए धन्यवाद! एक छोटी सी फीस हमें इसे सुचारू रूप से संसाधित करने में मदद करती है।',
        service_fee: 'सेवा शुल्क',
        order_confirmation: 'आदेश की पुष्टि',
        confirm_delivery: 'वितरण की पुष्टि करें',
        your_information: 'आपकी जानकारी',
        empty_pound: '£0.00',
        enter_room_number_plz: 'कृपया अपना कमरे का नंबर दर्ज करें।',
        enter_table_codes: 'कृपया अपनी टेबल कोड दर्ज करें।',
        specify_name_2: 'कृपया अपना नाम दर्ज करें।',
        enter_pickup_time: 'कृपया अपना पिकअप समय चुनें।',
        room_number_visitor:
            'कृपया कमरे का नंबर दर्ज करें या विजिटर के रूप में चेकआउट करें।',
        specify_name: 'कृपया सभी फ़ील्ड भरें।',
        define_fields: 'कृपया सभी फ़ील्ड भरें',
        any_allergies_short: 'कोई एलर्जी या नोट्स?',
        notes: 'नोट्स',
        notes_descr:
            'इस स्थान का उपयोग करके रेस्तरां को किसी भी एलर्जी या आपके आदेश के लिए विशेष अनुरोधों के बारे में बताएं।',
        cancel: 'रद्द करें',
        save: 'सहेजें',
        continue: 'जारी रखें',
        pay: 'भुगतान करें',
        go_back: 'वापस जाएं',
        collection_order_time: 'आप अपना आदेश कब उठाना चाहेंगे?',
        enter_room_number: 'अपना कमरे का नंबर दर्ज करें:',
        enter_table_code: 'टेबल कोड:',
        room_number: 'कमरा नंबर',
        incorrect_room_number: 'गलत कमरे का नंबर',
        or_caps: 'या',
        just_visiting: 'विजिटर के रूप में चेकआउट करें',
        where_should_we_deliver: 'हम कहां पहुंचाएं?',
        my_room: 'मेरा कमरा',
        your_email: 'अपना ईमेल दर्ज करें:',
        email_expl: 'यह ईमेल आपके रसीद और बोनस अंक के लिए उपयोग किया जाएगा।',
        incorrect_email_format: 'गलत ईमेल फ़ॉर्मेट',
        your_address: 'आपका पता',
        incorrect_address_format: 'गलत पता फ़ॉर्मेट',
        your_phone_number_no_colon: 'आपका फोन नंबर',
        we_will_text_del: 'हम आपको ऑर्डर अपडेट्स के साथ एसएमएस भेजेंगे!',
        we_will_text: 'हम आपको तब सूचित करेंगे जब आपका आदेश तैयार हो जाएगा!',
        your_phone_number: 'आपका फोन नंबर:',
        incorrect_phone_number_format: 'गलत फोन नंबर फ़ॉर्मेट',
        your_full_name: 'आपका पूरा नाम:',
        incorrect_name_format: 'गलत नाम फ़ॉर्मेट',
        delivery_address: 'वितरण का पता:',
        delivery_time: 'अनुमानित वितरण समय:',
        delivery_notes: 'ड्राइवर के लिए वितरण नोट्स:',
        your_total_short: 'आपका कुल',
        your_delivery_fee: 'वितरण शुल्क',
        custom: 'कस्टम',
        tip: 'टिप',
    },
};
