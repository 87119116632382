export default {
    en: {
        your_offers: 'Offers',
        available_offers: 'Available Offers',
        qualified_offers_descr:
            'Please choose which offer you would like to redeem.',
        spend: 'Spend',
        buy: 'Buy',
        no_of_times: 'times',
        get: 'get',
        off: 'off',
        free: 'free',
        max: 'max',
        your_total_colon: 'Your Total:',
        including_fee: '(Incl. Order Fee)',
        continue: 'Continue',
        back: 'Back',
        qualified_offers: 'Qualified Offers',
        available_offers_descr:
            'By placing this order, you will qualify for the following offers.',
    },
    es: {
        your_offers: 'Ofertas',
        available_offers: 'Ofertas Disponibles',
        qualified_offers_descr:
            'Por favor, elige la oferta que te gustaría canjear.',
        spend: 'Gasta',
        buy: 'Comprar',
        no_of_times: 'veces',
        get: 'obtener',
        off: 'de descuento',
        free: 'gratis',
        max: 'máx',
        your_total_colon: 'Tu Total:',
        including_fee: '(Incl. Tarifa de Pedido)',
        continue: 'Continuar',
        back: 'Regresar',
        qualified_offers: 'Ofertas Calificadas',
        available_offers_descr:
            'Al realizar este pedido, calificarás para las siguientes ofertas.',
    },
    zh: {
        your_offers: '优惠',
        available_offers: '可用优惠',
        qualified_offers_descr: '请选择您想兑换的优惠。',
        spend: '消费',
        buy: '购买',
        no_of_times: '次',
        get: '获得',
        off: '折扣',
        free: '免费',
        max: '最大',
        your_total_colon: '您的总额:',
        including_fee: '(包括订单费用)',
        continue: '继续',
        back: '返回',
        qualified_offers: '符合条件的优惠',
        available_offers_descr: '下此订单后，您将符合以下优惠条件。',
    },
    ar: {
        your_offers: 'العروض',
        available_offers: 'العروض المتاحة',
        qualified_offers_descr: 'يرجى اختيار العرض الذي ترغب في استرداده.',
        spend: 'أنفق',
        buy: 'اشترِ',
        no_of_times: 'مرات',
        get: 'احصل على',
        off: 'خصم',
        free: 'مجاني',
        max: 'الحد الأقصى',
        your_total_colon: 'إجمالي السعر:',
        including_fee: '(تشمل رسوم الطلب)',
        continue: 'متابعة',
        back: 'العودة',
        qualified_offers: 'العروض المؤهلة',
        available_offers_descr:
            'بوضع هذا الطلب، ستكون مؤهلاً للحصول على العروض التالية.',
    },
    ru: {
        your_offers: 'Предложения',
        available_offers: 'Доступные предложения',
        qualified_offers_descr:
            'Пожалуйста, выберите предложение, которое хотите использовать.',
        spend: 'Потратьте',
        buy: 'Купить',
        no_of_times: 'раз',
        get: 'получить',
        off: 'скидка',
        free: 'бесплатно',
        max: 'макс',
        your_total_colon: 'Ваш общий счет:',
        including_fee: '(Включая плату за заказ)',
        continue: 'Продолжить',
        back: 'Назад',
        qualified_offers: 'Квалифицированные предложения',
        available_offers_descr:
            'Разместив этот заказ, вы получите право на следующие предложения.',
    },
    ja: {
        your_offers: 'オファー',
        available_offers: '利用可能なオファー',
        qualified_offers_descr: '利用したいオファーを選択してください。',
        spend: '支出',
        buy: '購入',
        no_of_times: '回',
        get: '取得',
        off: '割引',
        free: '無料',
        max: '最大',
        your_total_colon: '合計金額:',
        including_fee: '(注文料込み)',
        continue: '続行',
        back: '戻る',
        qualified_offers: '適格なオファー',
        available_offers_descr:
            'この注文を行うことで、次のオファーを利用できます。',
    },
    ko: {
        your_offers: '제안',
        available_offers: '사용 가능한 제안',
        qualified_offers_descr: '사용할 제안을 선택하십시오.',
        spend: '지출',
        buy: '구매',
        no_of_times: '회',
        get: '받다',
        off: '할인',
        free: '무료',
        max: '최대',
        your_total_colon: '총 금액:',
        including_fee: '(주문 수수료 포함)',
        continue: '계속',
        back: '뒤로 가기',
        qualified_offers: '자격 있는 제안',
        available_offers_descr: '이 주문을 하면 다음 제안을 받을 수 있습니다.',
    },
    de: {
        your_offers: 'Angebote',
        available_offers: 'Verfügbare Angebote',
        qualified_offers_descr:
            'Bitte wählen Sie das Angebot, das Sie einlösen möchten.',
        spend: 'Ausgeben',
        buy: 'Kaufen',
        no_of_times: 'Mal',
        get: 'Erhalten',
        off: 'Rabatt',
        free: 'kostenlos',
        max: 'max',
        your_total_colon: 'Ihr Gesamtbetrag:',
        including_fee: '(Inklusive Bestellgebühr)',
        continue: 'Fortsetzen',
        back: 'Zurück',
        qualified_offers: 'Qualifizierte Angebote',
        available_offers_descr:
            'Durch die Aufgabe dieser Bestellung qualifizieren Sie sich für die folgenden Angebote.',
    },
    fr: {
        your_offers: 'Offres',
        available_offers: 'Offres disponibles',
        qualified_offers_descr:
            'Veuillez choisir l’offre que vous souhaitez utiliser.',
        spend: 'Dépenser',
        buy: 'Acheter',
        no_of_times: 'fois',
        get: 'obtenir',
        off: 'de réduction',
        free: 'gratuit',
        max: 'max',
        your_total_colon: 'Votre Total:',
        including_fee: '(Incl. Frais de commande)',
        continue: 'Continuer',
        back: 'Retourner',
        qualified_offers: 'Offres qualifiées',
        available_offers_descr:
            'En passant cette commande, vous vous qualifierez pour les offres suivantes.',
    },
    pl: {
        your_offers: 'Oferty',
        available_offers: 'Dostępne oferty',
        qualified_offers_descr: 'Wybierz ofertę, którą chcesz wykorzystać.',
        spend: 'Wydać',
        buy: 'Kupić',
        no_of_times: 'razy',
        get: 'dostać',
        off: 'zniżka',
        free: 'za darmo',
        max: 'maks',
        your_total_colon: 'Twój całkowity:',
        including_fee: '(W tym opłata za zamówienie)',
        continue: 'Kontynuuj',
        back: 'Wróć',
        qualified_offers: 'Kwalifikowane oferty',
        available_offers_descr:
            'Składając to zamówienie, zakwalifikujesz się do następujących ofert.',
    },
    pt: {
        your_offers: 'Ofertas',
        available_offers: 'Ofertas Disponíveis',
        qualified_offers_descr:
            'Por favor, escolha a oferta que você gostaria de resgatar.',
        spend: 'Gastar',
        buy: 'Comprar',
        no_of_times: 'vezes',
        get: 'obter',
        off: 'de desconto',
        free: 'grátis',
        max: 'máx',
        your_total_colon: 'Seu Total:',
        including_fee: '(Incl. Taxa de Pedido)',
        continue: 'Continuar',
        back: 'Voltar',
        qualified_offers: 'Ofertas Qualificadas',
        available_offers_descr:
            'Ao fazer este pedido, você se qualificará para as seguintes ofertas.',
    },
    it: {
        your_offers: 'Offerte',
        available_offers: 'Offerte Disponibili',
        qualified_offers_descr:
            'Si prega di scegliere quale offerta riscattare.',
        spend: 'Spendere',
        buy: 'Acquistare',
        no_of_times: 'volte',
        get: 'ottenere',
        off: 'di sconto',
        free: 'gratuito',
        max: 'max',
        your_total_colon: 'Il tuo totale:',
        including_fee: '(Incl. Tassa di Ordine)',
        continue: 'Continua',
        back: 'Indietro',
        qualified_offers: 'Offerte Qualificate',
        available_offers_descr:
            'Effettuando questo ordine, ti qualificherai per le seguenti offerte.',
    },
    nl: {
        your_offers: 'Aanbiedingen',
        available_offers: 'Beschikbare aanbiedingen',
        qualified_offers_descr: 'Kies welke aanbieding je wilt inwisselen.',
        spend: 'Besteden',
        buy: 'Kopen',
        no_of_times: 'keer',
        get: 'krijgen',
        off: 'korting',
        free: 'gratis',
        max: 'max',
        your_total_colon: 'Uw totaal:',
        including_fee: '(Inclusief bestelkosten)',
        continue: 'Doorgaan',
        back: 'Terug',
        qualified_offers: 'Gekwalificeerde aanbiedingen',
        available_offers_descr:
            'Door deze bestelling te plaatsen, komt u in aanmerking voor de volgende aanbiedingen.',
    },
    hi: {
        your_offers: 'ऑफ़र',
        available_offers: 'उपलब्ध ऑफ़र',
        qualified_offers_descr:
            'कृपया उस ऑफ़र को चुनें जिसे आप रिडीम करना चाहते हैं।',
        spend: 'खर्च करना',
        buy: 'खरीदें',
        no_of_times: 'बार',
        get: 'प्राप्त करें',
        off: 'छूट',
        free: 'मुफ़्त',
        max: 'अधिकतम',
        your_total_colon: 'आपका कुल:',
        including_fee: '(आदेश शुल्क सहित)',
        continue: 'जारी रखें',
        back: 'वापस',
        qualified_offers: 'योग्य ऑफ़र',
        available_offers_descr:
            'इस ऑर्डर को देने पर, आप निम्नलिखित ऑफ़र्स के लिए योग्य हो जाएंगे।',
    },
};
