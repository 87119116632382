import { connect } from 'react-redux';
import _ from 'lodash';
import { IntlProvider } from 'react-intl';
import en_messages from '../../compiled-lang/en.json';
import es_messages from '../../compiled-lang/es.json';

function mapStateToProps(state) {
    const { language } = state.language;
    return {
        locale: language.language || 'en',
        messages: language.language === 'es' ? es_messages : en_messages,
    };
}

export default connect(mapStateToProps)(IntlProvider);
