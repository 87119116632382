import _ from 'lodash';
import {
    CREATE_UPDATE_TABLES,
    CREATING_UPDATING_TABLES_SUCCESSFUL,
    DISABLE_TABLE_SUCCESSFUL,
    ENABLE_TABLE_SUCCESSFUL,
    ERROR_CREATING_UPDATING_TABLES,
    ERROR_FETCHING_TABLES,
    FETCH_TABLES,
    FETCH_TABLES_SUCCESSFUL,
    FETCH_YOUR_TABLES_SUCCESSFUL,
    LOADING,
    POS_TABLE_SYNC_SUCCESSFUL,
    UPDATE_ROOM_COUNT,
    UPDATE_ROOM_COUNT_SUCCESSFUL,
    UPDATE_TABLE_DETAILS_SUCCESSFUL,
} from '../types';

const initialState = {};

const openingHoursReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADING:
            if (
                action.key === CREATE_UPDATE_TABLES ||
                action.key === UPDATE_ROOM_COUNT ||
                action.key === FETCH_TABLES
            ) {
                return {
                    ...state,
                    success: false,
                };
            }
            if (action.key === POS_TABLE_SYNC_SUCCESSFUL) {
                return {
                    ...state,
                    posTableSyncSuccess: false,
                };
            }
            return state;
        case ERROR_CREATING_UPDATING_TABLES:
            return {
                ...state,
                success: false,
            };
        case FETCH_TABLES_SUCCESSFUL:
            return {
                ...state,
                tables: action.tables,
            };
        case FETCH_YOUR_TABLES_SUCCESSFUL:
            return {
                ...state,
                yourTables: action.tables.sort((a, b) =>
                    a.id > b.id ? 1 : -1
                ),
                posTables: action.posTables,
            };
        case ENABLE_TABLE_SUCCESSFUL:
            return {
                ...state,
                yourTables: [
                    ..._.get(state, ['yourTables'], []).filter(
                        t => parseInt(t.id, 10) !== parseInt(action.tableId, 10)
                    ),
                    {
                        ..._.get(state, ['yourTables'], []).find(
                            t =>
                                parseInt(t.id, 10) ===
                                parseInt(action.tableId, 10)
                        ),
                        isActive: true,
                    },
                ].sort((a, b) => (a.id > b.id ? 1 : -1)),
            };
        case DISABLE_TABLE_SUCCESSFUL:
            return {
                ...state,
                yourTables: [
                    ..._.get(state, ['yourTables'], []).filter(
                        t => parseInt(t.id, 10) !== parseInt(action.tableId, 10)
                    ),
                    {
                        ..._.get(state, ['yourTables'], []).find(
                            t =>
                                parseInt(t.id, 10) ===
                                parseInt(action.tableId, 10)
                        ),
                        isActive: false,
                    },
                ].sort((a, b) => (a.id > b.id ? 1 : -1)),
            };
        case UPDATE_TABLE_DETAILS_SUCCESSFUL:
            return {
                ...state,
                yourTables: [
                    ..._.get(state, ['yourTables'], []).filter(
                        t => parseInt(t.id, 10) !== parseInt(action.tableId, 10)
                    ),
                    {
                        ..._.get(state, ['yourTables'], []).find(
                            t =>
                                parseInt(t.id, 10) ===
                                parseInt(action.tableId, 10)
                        ),
                        occupancy: action.occupancy,
                        tableDisplayId: action.displayId,
                        posTable: action.posTable,
                    },
                ].sort((a, b) => (a.id > b.id ? 1 : -1)),
            };
        case CREATING_UPDATING_TABLES_SUCCESSFUL:
            return {
                ...state,
                tables: action.tables,
                success: true,
            };
        case UPDATE_ROOM_COUNT_SUCCESSFUL:
            return {
                ...state,
                rooms: action.roomCounts,
                success: true,
            };
        case ERROR_FETCHING_TABLES:
            return {
                ...state,
                error: action.error,
            };
        case POS_TABLE_SYNC_SUCCESSFUL:
            return {
                ...state,
                posTableSyncSuccess: true,
            };
        default:
            return state;
    }
};

export default openingHoursReducer;
