export default {
    en: {
        add: 'Add',
        contains: 'Contains',
        nutrition_info: 'Nutrition Info',
        calories_min_kcal: 'Calories Min (kcal): ',
        calories_max_kcal: 'Calories Max (kcal): ',
        fat_max_g: 'Fat (g): ',
        sugar_max_g: 'Sugar (g): ',
        saturated_fat_g: 'Saturated Fat (g): ',
        protein_g: 'Protein (g): ',
        salt_g: 'Salt (g): ',
        carbohydrates_g: 'Carbohydrates (g): ',
        delete: 'Delete',
        ask_waiter: 'Ask Waiter',
        from_small: 'From ',
        dismiss: 'Dismiss',
    },
    es: {
        add: 'Añadir',
        contains: 'Contiene',
        nutrition_info: 'Información Nutricional',
        calories_min_kcal: 'Calorías Min (kcal): ',
        calories_max_kcal: 'Calorías Max (kcal): ',
        fat_max_g: 'Grasa (g): ',
        sugar_max_g: 'Azúcar (g): ',
        saturated_fat_g: 'Grasa Saturada (g): ',
        protein_g: 'Proteína (g): ',
        salt_g: 'Sal (g): ',
        carbohydrates_g: 'Carbohidratos (g): ',
        delete: 'Eliminar',
        ask_waiter: 'Preguntar al Camarero',
        from_small: 'Desde ',
        dismiss: 'Descartar',
    },
    zh: {
        add: '添加',
        contains: '含有',
        nutrition_info: '营养信息',
        calories_min_kcal: '最低热量 (千卡): ',
        calories_max_kcal: '最高热量 (千卡): ',
        fat_max_g: '脂肪 (克): ',
        sugar_max_g: '糖 (克): ',
        saturated_fat_g: '饱和脂肪 (克): ',
        protein_g: '蛋白质 (克): ',
        salt_g: '盐 (克): ',
        carbohydrates_g: '碳水化合物 (克): ',
        delete: '删除',
        ask_waiter: '询问服务员',
        from_small: '从 ',
        dismiss: '关闭',
    },
    ar: {
        add: 'إضافة',
        contains: 'يحتوي على',
        nutrition_info: 'معلومات التغذية',
        calories_min_kcal: 'أقل سعرات حرارية (كيلو كالوري): ',
        calories_max_kcal: 'أعلى سعرات حرارية (كيلو كالوري): ',
        fat_max_g: 'الدهون (جم): ',
        sugar_max_g: 'السكر (جم): ',
        saturated_fat_g: 'الدهون المشبعة (جم): ',
        protein_g: 'البروتين (جم): ',
        salt_g: 'الملح (جم): ',
        carbohydrates_g: 'الكربوهيدرات (جم): ',
        delete: 'حذف',
        ask_waiter: 'اسأل النادل',
        from_small: 'من ',
        dismiss: 'إغلاق',
    },
    ru: {
        add: 'Добавить',
        contains: 'Содержит',
        nutrition_info: 'Информация о питании',
        calories_min_kcal: 'Минимальная калорийность (ккал): ',
        calories_max_kcal: 'Максимальная калорийность (ккал): ',
        fat_max_g: 'Жиры (г): ',
        sugar_max_g: 'Сахар (г): ',
        saturated_fat_g: 'Насыщенные жиры (г): ',
        protein_g: 'Белок (г): ',
        salt_g: 'Соль (г): ',
        carbohydrates_g: 'Углеводы (г): ',
        delete: 'Удалить',
        ask_waiter: 'Спросите официанта',
        from_small: 'От ',
        dismiss: 'Закрыть',
    },
    ja: {
        add: '追加',
        contains: '含む',
        nutrition_info: '栄養情報',
        calories_min_kcal: '最小カロリー (kcal): ',
        calories_max_kcal: '最大カロリー (kcal): ',
        fat_max_g: '脂肪 (g): ',
        sugar_max_g: '砂糖 (g): ',
        saturated_fat_g: '飽和脂肪 (g): ',
        protein_g: 'タンパク質 (g): ',
        salt_g: '塩分 (g): ',
        carbohydrates_g: '炭水化物 (g): ',
        delete: '削除',
        ask_waiter: 'ウェイターに尋ねる',
        from_small: 'から ',
        dismiss: '閉じる',
    },
    ko: {
        add: '추가',
        contains: '포함',
        nutrition_info: '영양 정보',
        calories_min_kcal: '최소 칼로리 (kcal): ',
        calories_max_kcal: '최대 칼로리 (kcal): ',
        fat_max_g: '지방 (g): ',
        sugar_max_g: '설탕 (g): ',
        saturated_fat_g: '포화지방 (g): ',
        protein_g: '단백질 (g): ',
        salt_g: '소금 (g): ',
        carbohydrates_g: '탄수화물 (g): ',
        delete: '삭제',
        ask_waiter: '웨이터에게 물어보세요',
        from_small: '부터 ',
        dismiss: '닫기',
    },
    de: {
        add: 'Hinzufügen',
        contains: 'Enthält',
        nutrition_info: 'Nährwertangaben',
        calories_min_kcal: 'Mindestkalorien (kcal): ',
        calories_max_kcal: 'Maximale Kalorien (kcal): ',
        fat_max_g: 'Fett (g): ',
        sugar_max_g: 'Zucker (g): ',
        saturated_fat_g: 'Gesättigte Fettsäuren (g): ',
        protein_g: 'Eiweiß (g): ',
        salt_g: 'Salz (g): ',
        carbohydrates_g: 'Kohlenhydrate (g): ',
        delete: 'Löschen',
        ask_waiter: 'Kellner fragen',
        from_small: 'Ab ',
        dismiss: 'Schließen',
    },
    fr: {
        add: 'Ajouter',
        contains: 'Contient',
        nutrition_info: 'Informations nutritionnelles',
        calories_min_kcal: 'Calories Min (kcal): ',
        calories_max_kcal: 'Calories Max (kcal): ',
        fat_max_g: 'Graisse (g): ',
        sugar_max_g: 'Sucre (g): ',
        saturated_fat_g: 'Gras saturé (g): ',
        protein_g: 'Protéine (g): ',
        salt_g: 'Sel (g): ',
        carbohydrates_g: 'Glucides (g): ',
        delete: 'Supprimer',
        ask_waiter: 'Demander au serveur',
        from_small: 'À partir de ',
        dismiss: 'Fermer',
    },
    pl: {
        add: 'Dodaj',
        contains: 'Zawiera',
        nutrition_info: 'Informacje o wartościach odżywczych',
        calories_min_kcal: 'Kalorie Min (kcal): ',
        calories_max_kcal: 'Kalorie Max (kcal): ',
        fat_max_g: 'Tłuszcz (g): ',
        sugar_max_g: 'Cukier (g): ',
        saturated_fat_g: 'Tłuszcz nasycony (g): ',
        protein_g: 'Białko (g): ',
        salt_g: 'Sól (g): ',
        carbohydrates_g: 'Węglowodany (g): ',
        delete: 'Usuń',
        ask_waiter: 'Zapytaj kelnera',
        from_small: 'Od ',
        dismiss: 'Zamknij',
    },
    pt: {
        add: 'Adicionar',
        contains: 'Contém',
        nutrition_info: 'Informação Nutricional',
        calories_min_kcal: 'Calorias Mín (kcal): ',
        calories_max_kcal: 'Calorias Máx (kcal): ',
        fat_max_g: 'Gordura (g): ',
        sugar_max_g: 'Açúcar (g): ',
        saturated_fat_g: 'Gordura Saturada (g): ',
        protein_g: 'Proteína (g): ',
        salt_g: 'Sal (g): ',
        carbohydrates_g: 'Carboidratos (g): ',
        delete: 'Excluir',
        ask_waiter: 'Pergunte ao Garçom',
        from_small: 'A partir de ',
        dismiss: 'Fechar',
    },
    it: {
        add: 'Aggiungi',
        contains: 'Contiene',
        nutrition_info: 'Informazioni Nutrizionali',
        calories_min_kcal: 'Calorie Min (kcal): ',
        calories_max_kcal: 'Calorie Max (kcal): ',
        fat_max_g: 'Grasso (g): ',
        sugar_max_g: 'Zucchero (g): ',
        saturated_fat_g: 'Grassi Saturi (g): ',
        protein_g: 'Proteine (g): ',
        salt_g: 'Sale (g): ',
        carbohydrates_g: 'Carboidrati (g): ',
        delete: 'Elimina',
        ask_waiter: 'Chiedi al Cameriere',
        from_small: 'Da ',
        dismiss: 'Chiudi',
    },
    nl: {
        add: 'Toevoegen',
        contains: 'Bevat',
        nutrition_info: 'Voedingsinformatie',
        calories_min_kcal: 'Minimale calorieën (kcal): ',
        calories_max_kcal: 'Maximale calorieën (kcal): ',
        fat_max_g: 'Vet (g): ',
        sugar_max_g: 'Suiker (g): ',
        saturated_fat_g: 'Verzadigd vet (g): ',
        protein_g: 'Eiwit (g): ',
        salt_g: 'Zout (g): ',
        carbohydrates_g: 'Koolhydraten (g): ',
        delete: 'Verwijderen',
        ask_waiter: 'Vraag het aan de ober',
        from_small: 'Vanaf ',
        dismiss: 'Sluiten',
    },
    hi: {
        add: 'जोड़ें',
        contains: 'शामिल है',
        nutrition_info: 'पोषण जानकारी',
        calories_min_kcal: 'न्यूनतम कैलोरी (किलो कैलोरी): ',
        calories_max_kcal: 'अधिकतम कैलोरी (किलो कैलोरी): ',
        fat_max_g: 'वसा (ग्राम): ',
        sugar_max_g: 'चीनी (ग्राम): ',
        saturated_fat_g: 'संतृप्त वसा (ग्राम): ',
        protein_g: 'प्रोटीन (ग्राम): ',
        salt_g: 'नमक (ग्राम): ',
        carbohydrates_g: 'कार्बोहाइड्रेट (ग्राम): ',
        delete: 'हटाएं',
        ask_waiter: 'वेटर से पूछें',
        from_small: 'से ',
        dismiss: 'खारिज करें',
    },
};
