import { FETCHED_REDIRECT_URL, ERROR_FETCHING_REDIRECT_URL } from '../types';

const initialState = {};

const redirectUrl = (state = initialState, action) => {
    switch (action.type) {
        case FETCHED_REDIRECT_URL:
            return {
                ...state,
                redirectUrl: action.redirectUrl,
                error: null,
            };
        case ERROR_FETCHING_REDIRECT_URL:
            return {
                ...state,
                error: action.error,
                redirectUrl: null,
            };
        default:
            return state;
    }
};

export default redirectUrl;
