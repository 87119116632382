import { FETCHED_BLOGS, ERROR_FETCHING_BLOGS } from '../types';

const initialState = {};

const blogsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCHED_BLOGS:
            return {
                ...state,
                blogs: action.blogs.sort((a, b) =>
                    a.createdAt > b.createdAt ? -1 : 1
                ),
            };

        case ERROR_FETCHING_BLOGS:
            return {
                ...state,
                error: action.error,
            };

        default:
            return state;
    }
};

export default blogsReducer;
