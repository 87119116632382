import moment from 'moment';
import { LOG_OUT, UPDATE_TOKENS } from '../types';
import { secureStorage } from '../../utils/tokenFetcher';

const initialState = {};

const activeReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_TOKENS:
            const {
                accesstoken,
                accesstokenexpiry,
                refreshtoken,
            } = action.headers;
            if (accesstoken && accesstokenexpiry && refreshtoken) {
                const tokenExpiry = new Date(
                    new Date().getTime() + 10800 * 60000
                );

                localStorage.setItem('refreshToken', refreshtoken);
                localStorage.setItem('accessToken', accesstoken);
                localStorage.setItem(
                    'accessTokenExpiry',
                    `${tokenExpiry.getTime()}`
                );
                secureStorage.setItem(`lastUpdated`, new Date().getTime());
                return {
                    ...state,
                    accessToken: accesstoken,
                    accessTokenExpiry: tokenExpiry,
                    refreshToken: refreshtoken,
                };
            }
            return state;
        case LOG_OUT:
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('accessToken');
            localStorage.removeItem('accessTokenExpiry');
            return {
                ...state,
                accessToken: null,
                accessTokenExpiry: null,
                refreshToken: null,
            };
        default:
            return state;
    }
};

export default activeReducer;
