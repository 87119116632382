import {
    ERROR_FETCHING_OPENING_HOURS,
    FETCHED_OPENING_HOURS,
    EDIT_OPENING_HOUR_SUCCESSFUL,
    ERROR_EDITING_OPENING_HOUR,
    LOADING,
    EDIT_OPENING_HOUR,
} from '../types';

const initialState = {};

const openingHoursReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADING:
            if (action.key === EDIT_OPENING_HOUR) {
                return {
                    ...state,
                    openingHoursSuccess: null,
                };
            }
            return state;
        case FETCHED_OPENING_HOURS:
            return {
                ...state,
                openingHours: action.items,
            };
        case ERROR_FETCHING_OPENING_HOURS:
            return {
                ...state,
                error: action.error,
                openingHoursSuccess: false,
            };
        case EDIT_OPENING_HOUR_SUCCESSFUL:
            return {
                ...state,
                openingHours: action.item,
                openingHoursSuccess: true,
                error: null,
            };
        default:
            return state;
    }
};

export default openingHoursReducer;
