/* eslint-disable import/first */

const originalToLowerCase = String.prototype.toLowerCase;
const handler = {
    get: (target, prop) => {
        const value = target[prop];
        if (prop === 'toLowerCase' && typeof value !== 'function') {
            console.warn('Non-string accessed toLowerCase:', target);
            return () => '';
        }
        return typeof value === 'function' ? value.bind(target) : value;
    },
};

String.prototype.toLowerCase = function() {
    if (typeof this !== 'string') {
        const proxy = new Proxy(this, handler);
        return proxy.toLowerCase();
    }
    return originalToLowerCase.call(this);
};

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ReactNotifications, Store } from 'react-notifications-component';
import CssBaseline from '@mui/material/CssBaseline';
import App from './App';
import store from './utils/store';
import 'react-notifications-component/dist/theme.css';
import ConnectedIntlProvider from './components/Language/ConnectedIntlProvider';
import CookieConsentMessage from './components/CookieConsentMessage';
import Sentry from './Sentry';
import Footer from './components/Treact/footers/SimpleFiveColumn';
import ReactGA from './utils/reactGA';
import fonts from './css/fonts';

render(
    <Provider store={store}>
        {fonts.map(f => (
            <link href={f.url} rel="stylesheet" type="text/css" />
        ))}
        <ConnectedIntlProvider>
            <ReactNotifications />
            <App />
            <CookieConsentMessage />
        </ConnectedIntlProvider>
    </Provider>,
    // </LegacyBoundary>,
    document.getElementById('root')
);

// try {
//     serviceWorkerRegistration.register();

//     // If you want to start measuring performance in your app, pass a function
//     // to log results (for example: reportWebVitals(console.log))
//     // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//     reportWebVitals();
// } catch (ex) {
//     console.log(ex);
// }
