import Sentry from '../../Sentry';
// import appsignal from '../../appSignal';
import { REPORT_SENTRY_ERROR } from '../types';
import { loading, endLoading } from './loading';

export const reportErrorToSentry = (error, data) => async dispatch => {
    dispatch(loading(REPORT_SENTRY_ERROR));
    try {
        Sentry.withScope(scope => {
            scope.setExtra('data', JSON.stringify(data));
            Sentry.captureException(error);
        });
        // appsignal.sendError(error, span => {
        //     span.setNamespace(
        //         process.env.REACT_APP_API_URL.includes('hot-table-app')
        //             ? 'prod'
        //             : 'staging'
        //     );
        //     span.setTags({ data: JSON.stringify(data) });
        // });
        dispatch(endLoading(REPORT_SENTRY_ERROR));
    } catch (ex) {
        dispatch(endLoading(REPORT_SENTRY_ERROR));
        console.log('ERROR REPORTING TO SENTRY', ex);
    }
};

export const actions = {
    reportErrorToSentry,
};
