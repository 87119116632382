import {
    CREATE_ALLERGEN_SUCCESSFUL,
    CREATE_ALLERGEN_FAILED,
    FETCHED_ALLERGENS,
    ERROR_FETCHING_ALLERGENS,
    DELETE_ALLERGEN_FAILED,
    ADD_ALLERGEN_SUCCESSFUL,
    ERROR_ADDING_ALLERGEN,
    EDIT_ALLERGEN_SUCCESSFUL,
    TOGGLE_ALLERGEN_AVAILABILITY_SUCCESSFUL,
    DELETE_ALLERGEN_SUCCESSFUL,
} from '../types';

const initialState = {};

const allergenReducer = (state = initialState, action) => {
    switch (action.type) {
        case DELETE_ALLERGEN_SUCCESSFUL:
            return {
                ...state,
                allergens: [
                    ...state.allergens.filter(
                        s => s.id !== parseInt(action.data.allergenId, 10)
                    ),
                ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
                descriptors: [
                    ...state.descriptors.filter(
                        s => s.id !== parseInt(action.data.allergenId, 10)
                    ),
                ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
            };
        case DELETE_ALLERGEN_FAILED:
            return state;
        case CREATE_ALLERGEN_SUCCESSFUL:
            return {
                ...state,
                allergens: action.data.allergen.isDescriptor
                    ? state.allergens
                    : [
                          ...state.allergens,
                          { ...action.data.allergen, allergens: [] },
                      ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
                descriptors: !action.data.allergen.isDescriptor
                    ? state.descriptors
                    : [
                          ...state.descriptors,
                          { ...action.data.allergen, descriptors: [] },
                      ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
            };
        case CREATE_ALLERGEN_FAILED:
            return {
                ...state,
                createSectionError: action.error,
            };
        case FETCHED_ALLERGENS:
            return {
                ...state,
                allergens: action.allergens
                    .filter(a => !a.isDescriptor)
                    .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
                descriptors: action.allergens
                    .filter(a => a.isDescriptor)
                    .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
                deliverectAllergenMap: action.deliverectAllergenMap,
                deliverectDescriptorMap: action.deliverectDescriptorMap,
            };

        case ERROR_FETCHING_ALLERGENS:
            return {
                ...state,
                error: action.error,
            };
        case EDIT_ALLERGEN_SUCCESSFUL:
            return {
                ...state,
                allergens: [
                    ...state.allergens.filter(
                        g => g.id !== parseInt(action.data.id, 10)
                    ),
                    {
                        id: parseInt(action.data.id, 10),
                        min: parseInt(action.data.changes.min, 10),
                        max: parseInt(action.data.changes.max, 10),
                        name: action.data.changes.name,
                    },
                ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
            };
        case ADD_ALLERGEN_SUCCESSFUL:
            const group = state.allergens.find(
                g => g.id === action.item.allergenId
            );
            return {
                ...state,
                allergens: [
                    ...state.allergens.filter(
                        g => g.id !== action.item.allergenId
                    ),
                    {
                        ...group,
                        allergens: [
                            ...(group.allergens || []),
                            { ...action.item },
                        ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
                    },
                ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
            };
        case ERROR_ADDING_ALLERGEN:
            // fix this
            return {
                ...state,
                createAllergenError: action.error,
            };
        // case EDIT_ALLERGEN_SUCCESSFUL:
        case TOGGLE_ALLERGEN_AVAILABILITY_SUCCESSFUL:
            const section2 = state.allergens.find(
                section => section.id === action.item.sectionId
            );
            const item = section2.allergens.find(
                menu => menu.id === action.item.itemId
            );
            return {
                ...state,
                allergens: [
                    ...state.allergens.filter(
                        section => section.id !== action.item.sectionId
                    ),
                    {
                        ...section2,
                        allergens: [
                            ...section2.allergens.filter(
                                item => item.id !== action.item.itemId
                            ),
                            { ...item, ...action.item.changes },
                        ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
                    },
                ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
            };
        default:
            return state;
    }
};

export default allergenReducer;
