const axios = require('axios');
const { v4: uuidv4 } = require('uuid');

const axiosRetry = require('axios-retry');

axios.interceptors.request.use(
    config => {
        config.headers.mdRequestId = uuidv4();
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axiosRetry(axios, {
    retries: 3, // number of retries
    retryDelay: retryCount => {
        console.log(`retry attempt: ${retryCount}`);
        return retryCount * 500; // time interval between retries
    },
});

module.exports = { axios };
