import { SIGN_IN_SUCCESSFUL, SIGN_IN_FAILED } from '../types';

const initialState = {};

const signInReducer = (state = initialState, action) => {
    switch (action.type) {
        case SIGN_IN_SUCCESSFUL:
            return !action.isCustomerUser
                ? {
                      ...state,
                      restaurant: action.data ? action.data.restaurant : {},
                      user: action.data ? action.data.user : {},
                      signInComplete: true,
                  }
                : {
                      ...state,
                      signInComplete: true,
                  };
        case SIGN_IN_FAILED:
            return {
                ...state,
                signInError: action.error,
            };
        default:
            return state;
    }
};

export default signInReducer;
