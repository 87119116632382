import {
    FETCHED_RESTAURANTS_FOR_SEARCH,
    ERROR_FETCHING_RESTAURANTS_FOR_SEARCH,
} from '../types';

const initialState = {};

const restaurantSearchReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCHED_RESTAURANTS_FOR_SEARCH:
            return {
                ...state,
                restaurants: action.restaurants,
                restaurantSearchFetchComplete: true,
                restaurantSearchFetchError: false,
            };
        case ERROR_FETCHING_RESTAURANTS_FOR_SEARCH:
            return {
                ...state,
                error: action.error,
                restaurantSearchFetchComplete: false,
                restaurantSearchFetchError: true,
            };
        default:
            return state;
    }
};

export default restaurantSearchReducer;
