import {
    SET_COOKIE_CONSENT_SUCCESSFUL,
    GET_COOKIE_CONSENT_SUCCESSFUL,
} from '../types';

const initialState = {};

const activeReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_COOKIE_CONSENT_SUCCESSFUL:
            return {
                ...state,
                cookieConsent: true,
            };
        case GET_COOKIE_CONSENT_SUCCESSFUL:
            return {
                ...state,
                cookieConsent: action.cookieConsent.cookieConsent,
            };
        default:
            return state;
    }
};

export default activeReducer;
