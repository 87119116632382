import {
    UPDATE_ONLINE_STATUS_SUCCESSFUL,
    ERROR_UPDATING_ONLINE_STATUS,
    LOADING,
    UPDATE_ONLINE_STATUS,
    ERROR_UPDATING_RESERVATION_STATUS,
    UPDATE_RESERVATION_STATUS,
    UPDATE_RESERVATION_STATUS_SUCCESSFUL,
} from '../types';

const initialState = {};

const callWaiterReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADING:
            if (action.key === UPDATE_ONLINE_STATUS) {
                return {
                    ...state,
                    updateOnlineStatusComplete: false,
                    updateOnlineStatusError: null,
                };
            }
            if (action === UPDATE_RESERVATION_STATUS) {
                return {
                    ...state,
                    updateReservationStatusComplete: false,
                    updateReservationStatusError: null,
                };
            }
            return state;
        case UPDATE_ONLINE_STATUS_SUCCESSFUL:
            return {
                ...state,
                updateOnlineStatusComplete: true,
                updateOnlineStatusError: null,
            };
        case ERROR_UPDATING_ONLINE_STATUS:
            return {
                ...state,
                updateOnlineStatusComplete: false,
                updateOnlineStatusError: action.error,
            };
        case UPDATE_RESERVATION_STATUS_SUCCESSFUL:
            return {
                ...state,
                updateReservationStatusComplete: true,
                updateReservationStatusError: null,
            };
        case ERROR_UPDATING_RESERVATION_STATUS:
            return {
                ...state,
                updateReservationStatusComplete: false,
                updateReservationStatusError: action.error,
            };
        default:
            return state;
    }
};

export default callWaiterReducer;
