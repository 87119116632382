import {
    SET_COOKIE_CONSENT,
    GET_COOKIE_CONSENT,
    GET_COOKIE_CONSENT_SUCCESSFUL,
    SET_COOKIE_CONSENT_SUCCESSFUL,
} from '../types';
import { loading, endLoading } from './loading';
import { secureStorage } from '../../utils/tokenFetcher';
import { reportErrorToSentry } from './sentry';

const getCookieConsentSuccessful = cookieConsent => ({
    type: GET_COOKIE_CONSENT_SUCCESSFUL,
    cookieConsent,
});

const setCookieConsentSuccessful = cookieConsent => ({
    type: SET_COOKIE_CONSENT_SUCCESSFUL,
    cookieConsent,
});

const setCookieConsent = () => async dispatch => {
    dispatch(loading(SET_COOKIE_CONSENT));
    try {
        const updatedConsent = {
            cookieConsent: true,
        };
        secureStorage.setItem('cookieConsent', updatedConsent);
        secureStorage.setItem(`lastUpdated`, new Date().getTime());
        dispatch(setCookieConsentSuccessful({ cookieConsent: true }));
        dispatch(endLoading(SET_COOKIE_CONSENT));
    } catch (ex) {
        dispatch(endLoading(SET_COOKIE_CONSENT));
        dispatch(
            reportErrorToSentry(ex, {
                type: SET_COOKIE_CONSENT,
            })
        );
        console.log('ERROR SETTING COOKIE CONSENT', ex);
    }
};

const getCookieConsent = () => async (dispatch, getState) => {
    dispatch(loading(GET_COOKIE_CONSENT));
    try {
        const state = getState();
        const { cookie } = state;
        if (cookie) {
            const { cookieConsent } = cookie;
            if (cookieConsent) {
                dispatch(getCookieConsentSuccessful({ cookieConsent: true }));
                dispatch(endLoading(GET_COOKIE_CONSENT));
                return;
            }
        }
        const secureStorageLang = secureStorage.getItem('cookieConsent');
        dispatch(endLoading(GET_COOKIE_CONSENT));
        dispatch(
            getCookieConsentSuccessful(
                secureStorageLang || { cookieConsent: null }
            )
        );
    } catch (ex) {
        secureStorage.removeItem(`cookieConsent`);
        dispatch(endLoading(GET_COOKIE_CONSENT));
        dispatch(
            reportErrorToSentry(ex, {
                type: GET_COOKIE_CONSENT,
            })
        );
        console.log('ERROR GETTING COOKIE CONSENT', ex);
    }
};

export const actions = {
    getCookieConsent,
    setCookieConsent,
};
