/* eslint-disable no-unused-expressions */

import { axios } from './axiosInstance';
import { FETCHED_RESTAURANT, ERROR_FETCHING_RESTAURANT } from '../types';
import { getToken } from '../../utils/tokenFetcher';
import { updateTokens } from './auth';

const fetchedRestaurant = restaurant => ({
    type: FETCHED_RESTAURANT,
    restaurant,
});

const errorFetchingRestaurant = error => ({
    type: ERROR_FETCHING_RESTAURANT,
    error,
});
// TODO: add restaurant to state, if no restaurant, redirect to signin
const getRestaurant = () => async (dispatch, getState) => {
    const token = getToken(getState());
    await axios
        .get(`${process.env.REACT_APP_API_URL}/api/restaurant/me`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(data => {
            if (data.status === 200) {
                dispatch(updateTokens(data.headers));
                dispatch(fetchedRestaurant(data.data));
            } else {
                dispatch(
                    errorFetchingRestaurant(
                        `Status code incorrect - ${data.status}`
                    )
                );
            }
        })
        .catch(error => {
            console.log('ERROR FETCHING RESTAURANT', error);
            dispatch(errorFetchingRestaurant(error));
        });
};

export const actions = {
    fetchedRestaurant,
    getRestaurant,
    errorFetchingRestaurant,
};
