import { REPORT_INSIGHT_SUCCESSFUL } from '../types';

const initialState = {};

const insightsReducer = (state = initialState, action) => {
    switch (action.type) {
        case REPORT_INSIGHT_SUCCESSFUL:
            return {
                ...state,
                reportedInsights: {
                    ...(state.reportedInsights || {}),
                    [action.eventType]: action.insightGuid,
                },
            };

        default:
            return state;
    }
};

export default insightsReducer;
