import {
    CREATE_USER_WAITER_REQUEST_SUCCESSFUL,
    ERROR_CREATING_USER_WAITER_REQUEST,
    LOADING,
    DISMISS_WAITER_BOX,
    CREATE_USER_WAITER_REQUEST,
} from '../types';

const initialState = {};

const callWaiterReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADING:
            if (action.key === CREATE_USER_WAITER_REQUEST) {
                return {
                    ...state,
                    complete: false,
                    callWaiterError: null,
                };
            }
            return state;
        // case END_LOADING:
        //     if (action.key === CREATE_USER_WAITER_REQUEST) {
        //         return {
        //             ...state,
        //             complete: false,
        //             callWaiterError: null,
        //         };
        //     }
        //     return state;
        case DISMISS_WAITER_BOX:
            return {
                ...state,
                complete: false,
                callWaiterError: null,
            };
        case CREATE_USER_WAITER_REQUEST_SUCCESSFUL:
            return {
                ...state,
                callWaiterError: null,
                complete: true,
            };
        case ERROR_CREATING_USER_WAITER_REQUEST:
            return {
                ...state,
                callWaiterError: action.error,
                complete: false,
            };
        default:
            return state;
    }
};

export default callWaiterReducer;
