export default [
    {
        name: 'Roboto',
        url: 'https://fonts.googleapis.com/css?family=Roboto',
    },
    {
        name: 'Open Sans',
        url: 'https://fonts.googleapis.com/css?family=Open+Sans',
    },
    {
        name: 'Lato',
        url: 'https://fonts.googleapis.com/css?family=Lato',
    },
    {
        name: 'Jacquard 12',
        url: 'https://fonts.googleapis.com/css?family=Jacquard+12',
    },
    {
        name: 'Montserrat',
        url: 'https://fonts.googleapis.com/css?family=Montserrat',
    },
    {
        name: 'Oswald',
        url: 'https://fonts.googleapis.com/css?family=Oswald',
    },
    {
        name: 'Roboto Condensed',
        url: 'https://fonts.googleapis.com/css?family=Roboto+Condensed',
    },
    {
        name: 'Source Sans Pro',
        url: 'https://fonts.googleapis.com/css?family=Source+Sans+Pro',
    },
    {
        name: 'Slabo 27px',
        url: 'https://fonts.googleapis.com/css?family=Slabo+27px',
    },
    {
        name: 'Raleway',
        url: 'https://fonts.googleapis.com/css?family=Raleway',
    },
    {
        name: 'PT Sans',
        url: 'https://fonts.googleapis.com/css?family=PT+Sans',
    },
    {
        name: 'Merriweather',
        url: 'https://fonts.googleapis.com/css?family=Merriweather',
    },
    {
        name: 'Noto Sans',
        url: 'https://fonts.googleapis.com/css?family=Noto+Sans',
    },
    {
        name: 'Playfair Display',
        url: 'https://fonts.googleapis.com/css?family=Playfair+Display',
    },
    {
        name: 'Poppins',
        url: 'https://fonts.googleapis.com/css?family=Poppins',
    },
    {
        name: 'Ubuntu',
        url: 'https://fonts.googleapis.com/css?family=Ubuntu',
    },
    {
        name: 'Droid Sans',
        url: 'https://fonts.googleapis.com/css?family=Droid+Sans',
    },
    {
        name: 'PT Serif',
        url: 'https://fonts.googleapis.com/css?family=PT+Serif',
    },
    {
        name: 'Roboto Slab',
        url: 'https://fonts.googleapis.com/css?family=Roboto+Slab',
    },
    {
        name: 'Lora',
        url: 'https://fonts.googleapis.com/css?family=Lora',
    },
    {
        name: 'Muli',
        url: 'https://fonts.googleapis.com/css?family=Muli',
    },
    {
        name: 'Titillium Web',
        url: 'https://fonts.googleapis.com/css?family=Titillium+Web',
    },
    {
        name: 'Fira Sans',
        url: 'https://fonts.googleapis.com/css?family=Fira+Sans',
    },
    {
        name: 'Inconsolata',
        url: 'https://fonts.googleapis.com/css?family=Inconsolata',
    },
    {
        name: 'Cabin',
        url: 'https://fonts.googleapis.com/css?family=Cabin',
    },
    {
        name: 'Bitter',
        url: 'https://fonts.googleapis.com/css?family=Bitter',
    },
    {
        name: 'Nunito',
        url: 'https://fonts.googleapis.com/css?family=Nunito',
    },
    {
        name: 'Work Sans',
        url: 'https://fonts.googleapis.com/css?family=Work+Sans',
    },
    {
        name: 'Arimo',
        url: 'https://fonts.googleapis.com/css?family=Arimo',
    },
    {
        name: 'Hind',
        url: 'https://fonts.googleapis.com/css?family=Hind',
    },
    {
        name: 'Quicksand',
        url: 'https://fonts.googleapis.com/css?family=Quicksand',
    },
    {
        name: 'Bree Serif',
        url: 'https://fonts.googleapis.com/css?family=Bree+Serif',
    },
    {
        name: 'Yanone Kaffeesatz',
        url: 'https://fonts.googleapis.com/css?family=Yanone+Kaffeesatz',
    },
    {
        name: 'Asap',
        url: 'https://fonts.googleapis.com/css?family=Asap',
    },
    {
        name: 'Anton',
        url: 'https://fonts.googleapis.com/css?family=Anton',
    },
    {
        name: 'Dosis',
        url: 'https://fonts.googleapis.com/css?family=Dosis',
    },
    {
        name: 'Libre Franklin',
        url: 'https://fonts.googleapis.com/css?family=Libre+Franklin',
    },
    {
        name: 'Rubik',
        url: 'https://fonts.googleapis.com/css?family=Rubik',
    },
    {
        name: 'Dancing Script',
        url: 'https://fonts.googleapis.com/css?family=Dancing+Script',
    },
    {
        name: 'Abril Fatface',
        url: 'https://fonts.googleapis.com/css?family=Abril+Fatface',
    },
    {
        name: 'Rokkitt',
        url: 'https://fonts.googleapis.com/css?family=Rokkitt',
    },
    {
        name: 'Questrial',
        url: 'https://fonts.googleapis.com/css?family=Questrial',
    },
    {
        name: 'Assistant',
        url: 'https://fonts.googleapis.com/css?family=Assistant',
    },
    {
        name: 'Space Mono',
        url: 'https://fonts.googleapis.com/css?family=Space+Mono',
    },
    {
        name: 'Varela Round',
        url: 'https://fonts.googleapis.com/css?family=Varela+Round',
    },
    {
        name: 'Karla',
        url: 'https://fonts.googleapis.com/css?family=Karla',
    },
    {
        name: 'Catamaran',
        url: 'https://fonts.googleapis.com/css?family=Catamaran',
    },
    {
        name: 'Signika',
        url: 'https://fonts.googleapis.com/css?family=Signika',
    },
    {
        name: 'Mukta',
        url: 'https://fonts.googleapis.com/css?family=Mukta',
    },
    {
        name: 'Vollkorn',
        url: 'https://fonts.googleapis.com/css?family=Vollkorn',
    },
    {
        name: 'Zilla Slab',
        url: 'https://fonts.googleapis.com/css?family=Zilla+Slab',
    },
    {
        name: 'Baloo',
        url: 'https://fonts.googleapis.com/css?family=Baloo',
    },
    {
        name: 'Barlow',
        url: 'https://fonts.googleapis.com/css?family=Barlow',
    },
    {
        name: 'Archivo Narrow',
        url: 'https://fonts.googleapis.com/css?family=Archivo+Narrow',
    },
    {
        name: 'Crete Round',
        url: 'https://fonts.googleapis.com/css?family=Crete+Round',
    },
    {
        name: 'Comfortaa',
        url: 'https://fonts.googleapis.com/css?family=Comfortaa',
    },
    {
        name: 'Teko',
        url: 'https://fonts.googleapis.com/css?family=Teko',
    },
    {
        name: 'Abel',
        url: 'https://fonts.googleapis.com/css?family=Abel',
    },
    {
        name: 'Domine',
        url: 'https://fonts.googleapis.com/css?family=Domine',
    },
    {
        name: 'Maven Pro',
        url: 'https://fonts.googleapis.com/css?family=Maven+Pro',
    },
    {
        name: 'Bangers',
        url: 'https://fonts.googleapis.com/css?family=Bangers',
    },
    {
        name: 'Kalam',
        url: 'https://fonts.googleapis.com/css?family=Kalam',
    },
    {
        name: 'Martel',
        url: 'https://fonts.googleapis.com/css?family=Martel',
    },
    {
        name: 'Cuprum',
        url: 'https://fonts.googleapis.com/css?family=Cuprum',
    },
    {
        name: 'Tajawal',
        url: 'https://fonts.googleapis.com/css?family=Tajawal',
    },
    {
        name: 'Oxygen',
        url: 'https://fonts.googleapis.com/css?family=Oxygen',
    },
    {
        name: 'Noto Serif',
        url: 'https://fonts.googleapis.com/css?family=Noto+Serif',
    },
    {
        name: 'Chivo',
        url: 'https://fonts.googleapis.com/css?family=Chivo',
    },
    {
        name: 'Exo 2',
        url: 'https://fonts.googleapis.com/css?family=Exo+2',
    },
    {
        name: 'Crimson Text',
        url: 'https://fonts.googleapis.com/css?family=Crimson+Text',
    },
    {
        name: 'Ovo',
        url: 'https://fonts.googleapis.com/css?family=Ovo',
    },
    {
        name: 'IBM Plex Sans',
        url: 'https://fonts.googleapis.com/css?family=IBM+Plex+Sans',
    },
    {
        name: 'Ruda',
        url: 'https://fonts.googleapis.com/css?family=Ruda',
    },
    {
        name: 'Heebo',
        url: 'https://fonts.googleapis.com/css?family=Heebo',
    },
    {
        name: 'Yantramanav',
        url: 'https://fonts.googleapis.com/css?family=Yantramanav',
    },
    {
        name: 'Kanit',
        url: 'https://fonts.googleapis.com/css?family=Kanit',
    },
    {
        name: 'Courgette',
        url: 'https://fonts.googleapis.com/css?family=Courgette',
    },
    {
        name: 'Bowlby One SC',
        url: 'https://fonts.googleapis.com/css?family=Bowlby+One+SC',
    },
    {
        name: 'Alfa Slab One',
        url: 'https://fonts.googleapis.com/css?family=Alfa+Slab+One',
    },
    {
        name: 'Caveat',
        url: 'https://fonts.googleapis.com/css?family=Caveat',
    },
    {
        name: 'Cairo',
        url: 'https://fonts.googleapis.com/css?family=Cairo',
    },
    {
        name: 'Josefin Slab',
        url: 'https://fonts.googleapis.com/css?family=Josefin+Slab',
    },
    {
        name: 'Concert One',
        url: 'https://fonts.googleapis.com/css?family=Concert+One',
    },
    {
        name: 'Pathway Gothic One',
        url: 'https://fonts.googleapis.com/css?family=Pathway+Gothic+One',
    },
    {
        name: 'Arsenal',
        url: 'https://fonts.googleapis.com/css?family=Arsenal',
    },
    {
        name: 'Sarala',
        url: 'https://fonts.googleapis.com/css?family=Sarala',
    },
    {
        name: 'Fjalla One',
        url: 'https://fonts.googleapis.com/css?family=Fjalla+One',
    },
    {
        name: 'Merriweather Sans',
        url: 'https://fonts.googleapis.com/css?family=Merriweather+Sans',
    },
    {
        name: 'Lemonada',
        url: 'https://fonts.googleapis.com/css?family=Lemonada',
    },
    {
        name: 'Allura',
        url: 'https://fonts.googleapis.com/css?family=Allura',
    },
    {
        name: 'Fira Mono',
        url: 'https://fonts.googleapis.com/css?family=Fira+Mono',
    },
    {
        name: 'Glegoo',
        url: 'https://fonts.googleapis.com/css?family=Glegoo',
    },
    {
        name: 'Palanquin',
        url: 'https://fonts.googleapis.com/css?family=Palanquin',
    },
    {
        name: 'El Messiri',
        url: 'https://fonts.googleapis.com/css?family=El+Messiri',
    },
    {
        name: 'Archivo',
        url: 'https://fonts.googleapis.com/css?family=Archivo',
    },
    {
        name: 'Rufina',
        url: 'https://fonts.googleapis.com/css?family=Rufina',
    },
    {
        name: 'Prata',
        url: 'https://fonts.googleapis.com/css?family=Prata',
    },
    {
        name: 'Vidaloka',
        url: 'https://fonts.googleapis.com/css?family=Vidaloka',
    },
    {
        name: 'Livvic',
        url: 'https://fonts.googleapis.com/css?family=Livvic',
    },
    {
        name: 'Griffy',
        url: 'https://fonts.googleapis.com/css?family=Griffy',
    },
    {
        name: 'Emilys Candy',
        url: 'https://fonts.googleapis.com/css?family=Emilys+Candy',
    },
    {
        name: 'Flavors',
        url: 'https://fonts.googleapis.com/css?family=Flavors',
    },
    {
        name: 'Gochi Hand',
        url: 'https://fonts.googleapis.com/css?family=Gochi+Hand',
    },
    {
        name: 'Fredericka the Great',
        url: 'https://fonts.googleapis.com/css?family=Fredericka+the+Great',
    },
    {
        name: 'Rock Salt',
        url: 'https://fonts.googleapis.com/css?family=Rock+Salt',
    },
    {
        name: 'Rye',
        url: 'https://fonts.googleapis.com/css?family=Rye',
    },
    {
        name: 'Slackey',
        url: 'https://fonts.googleapis.com/css?family=Slackey',
    },
    {
        name: 'Special Elite',
        url: 'https://fonts.googleapis.com/css?family=Special+Elite',
    },
    {
        name: 'Amiri',
        url: 'https://fonts.googleapis.com/css?family=Amiri',
    },
    {
        name: 'Cairo',
        url: 'https://fonts.googleapis.com/css?family=Cairo',
    },
    {
        name: 'Lateef',
        url: 'https://fonts.googleapis.com/css?family=Lateef',
    },

    // Asian-themed fonts
    {
        name: 'Noto Sans JP',
        url: 'https://fonts.googleapis.com/css?family=Noto+Sans+JP',
    },
    {
        name: 'Noto Serif JP',
        url: 'https://fonts.googleapis.com/css?family=Noto+Serif+JP',
    },
    {
        name: 'Sawarabi Mincho',
        url: 'https://fonts.googleapis.com/css?family=Sawarabi+Mincho',
    },
    {
        name: 'Sawarabi Gothic',
        url: 'https://fonts.googleapis.com/css?family=Sawarabi+Gothic',
    },

    // Mexican-themed fonts
    {
        name: 'Courgette',
        url: 'https://fonts.googleapis.com/css?family=Courgette',
    },
    {
        name: 'Cabin Sketch',
        url: 'https://fonts.googleapis.com/css?family=Cabin+Sketch',
    },
    {
        name: 'Permanent Marker',
        url: 'https://fonts.googleapis.com/css?family=Permanent+Marker',
    },

    // Italian-themed fonts
    {
        name: 'Great Vibes',
        url: 'https://fonts.googleapis.com/css?family=Great+Vibes',
    },
    {
        name: 'Lobster',
        url: 'https://fonts.googleapis.com/css?family=Lobster',
    },
    {
        name: 'Cinzel',
        url: 'https://fonts.googleapis.com/css?family=Cinzel',
    },
    {
        name: 'Pacifico',
        url: 'https://fonts.googleapis.com/css?family=Pacifico',
    },
];
