const CryptoJS = require('crypto-js');
const SecureStorage = require('secure-web-storage');
const { default: Sentry } = require('../Sentry');

const secureStorage = new SecureStorage(localStorage, {
    hash: function hash(key) {
        const shaKey = CryptoJS.SHA256(key, process.env.REACT_APP_REFRESH_KEY);

        return shaKey.toString();
    },
    encrypt: function encrypt(data) {
        let retData = CryptoJS.AES.encrypt(
            data,
            process.env.REACT_APP_REFRESH_KEY
        );

        retData = retData.toString();

        return retData;
    },
    decrypt: function decrypt(data) {
        let retData = CryptoJS.AES.decrypt(
            data,
            process.env.REACT_APP_REFRESH_KEY
        );

        retData = retData.toString(CryptoJS.enc.Utf8);

        return retData;
    },
});
const getToken = state => {
    const { auth } = state;
    if (auth) {
        const { accessToken, accessTokenExpiry, refreshToken } = auth;

        // console.log('ACCESS TOKEN EXPIRY 1', accessTokenExpiry);

        if (accessTokenExpiry > Date.now()) {
            return accessToken;
        }
        if (refreshToken) {
            return refreshToken;
        }
    }
    try {
        const accessTokenStorage = localStorage.getItem('accessToken');
        const accessTokenExpiryStorage = new Date(
            parseInt(localStorage.getItem('accessTokenExpiry'), 10)
        );
        if (accessTokenStorage && accessTokenExpiryStorage) {
            // console.log('ACCESS TOKEN EXPIRY 2', accessTokenExpiryStorage);

            if (accessTokenExpiryStorage > Date.now()) {
                return accessTokenStorage;
            }
        }
    } catch (ex) {
        console.log('EX', ex);
    }

    let refreshToken;

    try {
        refreshToken = localStorage.getItem('refreshToken');
    } catch (ex) {
        console.log(ex);
        Sentry.captureException(ex);
    }

    // if (!refreshToken) {
    //     try {
    //         refreshToken = secureStorage.getItem('refreshToken');
    //     } catch (ex) {
    //         console.log(ex);
    //         Sentry.captureException(ex);
    //     }
    // }

    return refreshToken || '';
};

module.exports = { getToken, secureStorage };
