import Sentry from '../../Sentry';
import {
    FETCHED_REQUESTS,
    ERROR_FETCHING_REQUESTS,
    DELETE_REQUEST_SUCCESSFUL,
    DELETE_REQUEST_FAILED,
    NEW_WAITER_REQUEST,
} from '../types';

const initialState = {};

const requestsReducer = (state = initialState, action) => {
    switch (action.type) {
        case DELETE_REQUEST_SUCCESSFUL:
            return {
                ...state,
                requests: [
                    ...state.requests.filter(
                        s => s.id !== parseInt(action.data.requestId, 10)
                    ),
                ].sort((a, b) => {
                    return new Date(b.createdAt) - new Date(a.createdAt);
                }),
            };
        case DELETE_REQUEST_FAILED:
            return { ...state };
        case FETCHED_REQUESTS:
            return {
                ...state,
                requests: action.requests.sortedRequests.sort((a, b) => {
                    return new Date(b.createdAt) - new Date(a.createdAt);
                }),
            };
        case NEW_WAITER_REQUEST:
            // try {
            //     const audio = new Audio(
            //         'http://hot-table-app.herokuapp.com/api/fetchStorage/Order_Notification.wav'
            //     );
            //     audio.play();
            // } catch (ex) {
            //     console.log(ex);
            //     Sentry.captureException(ex);
            // }
            return {
                ...state,
                requests: [
                    ...(state.requests || []),
                    {
                        createdAt: new Date().toISOString(),
                        notes: action.request.notes,
                        restaurantTable: {
                            tableDisplayId: action.request.tableDisplayId,
                        },
                        restaurantTableId: action.request.tableId,
                        id: action.request.requestId,
                    },
                ].sort((a, b) => {
                    return new Date(b.createdAt) - new Date(a.createdAt);
                }),
            };
        case ERROR_FETCHING_REQUESTS:
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
};

export default requestsReducer;
