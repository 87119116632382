import { FETCHED_USER, ERROR_FETCHING_USER } from '../types';

const initialState = {};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCHED_USER:
            return {
                ...state,
                user: action.user,
                userFetchComplete: true,
                userFetchError: false,
            };
        case ERROR_FETCHING_USER:
            return {
                ...state,
                error: action.error,
                userFetchComplete: false,
                userFetchError: true,
            };
        default:
            return state;
    }
};

export default userReducer;
