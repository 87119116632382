import {
    ERROR_FETCHING_AVAILABILITY_DAYS,
    FETCHED_AVAILABILITY_DAYS,
    EDIT_AVAILABILITY_DAY_SUCCESSFUL,
    LOADING,
    EDIT_AVAILABILITY_DAY,
    TOGGLE_AVAILABILITY_SUCCESS,
} from '../types';

const initialState = {};

const availabilityDaysReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADING:
            if (action.key === EDIT_AVAILABILITY_DAY) {
                return {
                    ...state,
                    availabilityDaysSuccess: null,
                };
            }
            return state;
        case TOGGLE_AVAILABILITY_SUCCESS:
            return {
                ...state,
                availabilityDaysSuccess: null,
            };
        case FETCHED_AVAILABILITY_DAYS:
            return {
                ...state,
                availabilityDays: action.items,
            };
        case ERROR_FETCHING_AVAILABILITY_DAYS:
            return {
                ...state,
                error: action.error,
                availabilityDaysSuccess: false,
            };
        case EDIT_AVAILABILITY_DAY_SUCCESSFUL:
            return {
                ...state,
                availabilityDays: [
                    ...(state.availabilityDays || []).filter(
                        d => d.tagId !== action.tagId
                    ),
                    ...action.item.availability,
                ],
                availabilityDaysSuccess: true,
                error: null,
            };
        default:
            return state;
    }
};

export default availabilityDaysReducer;
