import {
  ERROR_FETCHING_DYNAMIC_CONTENT,
  FETCHED_DYNAMIC_CONTENT,
} from '../types';

const initialState = {};

const dynamicContentReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHED_DYNAMIC_CONTENT:
      return {
        ...state,
        [action.key]: action.content,
      };
    case ERROR_FETCHING_DYNAMIC_CONTENT:
      return state;
    default:
      return state;
  }
};

export default dynamicContentReducer;
