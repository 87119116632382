import {
    FETCHED_OFFER_REDEMPTIONS,
    ERROR_FETCHING_OFFER_REDEMPTIONS,
} from '../types';

const initialState = {};

const offerRedemptionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCHED_OFFER_REDEMPTIONS:
            return {
                ...state,
                offerRedemptions: action.offerRedemptions.offerRedemptions,
            };
        case ERROR_FETCHING_OFFER_REDEMPTIONS:
            return {
                ...state,
                offerRedemptionFetchError: action.error,
            };
        default:
            return state;
    }
};

export default offerRedemptionsReducer;
