import { combineReducers } from 'redux';
import activeReducer from './active';
import dynamicContentReducer from './dynamicContent';
import signUpReducer from './signUp';
import loadingReducer from './loading';
import cssReducer from './css';
import ordersReducer from './orders';
import pictureUploadReducer from './pictureUpload';
import menuReducer from './menu';
import sectionReducer from './section';
import openingHoursReducer from './openingHours';
import signInReducer from './signIn';
import authReducer from './auth';
import restaurantReducer from './restaurant';
import tableReducer from './tables';
import reservationsReducer from './reservations';
import stripeAccountReducer from './stripe';
import basketReducer from './basket';
import restaurantSearchReducer from './restaurantSearch';
import checkoutReducer from './checkout';
import callWaiterReducer from './callWaiter';
import userReducer from './user';
import bookingsReducer from './bookings';
import languageReducer from './language';
import redirectReducer from './redirect';
import contactUsReducer from './contactUs';
import resetPasswordReducer from './resetPassword';
import onlineStatusReducer from './onlineStatus';
import subscriptionsReducer from './subscriptions';
import offersReducer from './offers';
import customerUserReducer from './customerUser';
import offerRedemptionsReducer from './offerRedemptions';
import requestsReducer from './waiterRequests';
import payLaterReducer from './payLater';
import cookieConsentReducer from './cookie';
import amenityReducer from './amenity';
import hotelUserReducer from './hotelUser';
import roomPlusReducer from './roomPlus';
import deliveryLocationReducer from './deliveryLocations';
import reviewTracksReducer from './reviewTracks';
import modifierGroupReducer from './modifierGroup';
import reportsReducer from './reports';
import employeeReducer from './employees';
import socialTracksReducer from './socialTracks';
import tagReducer from './tag';
import availabilityDaysReducer from './availabilityDays';
import discountDaysReducer from './discountDays';
import allergenReducer from './allergen';
import insightsReducer from './insights';
import insightsSummaryReducer from './insightSummary';
import adminSettingsReducer from './adminSettings';
import discountEmailsReducer from './discountEmails';
import blogsReducer from './blog';
import WalletPassReducer from './walletPass';
import userStatisticsReducer from './userStatistics';
import campaignsReducer from './campaigns';
import websiteReducer from './website';
import unsubscribeUser from './unsub';
import delitePaysReducer from './delitePay';
import deliteClubReducer from './deliteClub';
// import reviewHubReducer from './reviewHub';
import appRegistrationReducer from './appRegistration';
import discountsReducer from './discounts';

const rootReducer = combineReducers({
    active: activeReducer,
    dynamicContent: dynamicContentReducer,
    signUp: signUpReducer,
    loading: loadingReducer,
    css: cssReducer,
    orders: ordersReducer,
    pictureUpload: pictureUploadReducer,
    menu: menuReducer,
    sections: sectionReducer,
    openingHours: openingHoursReducer,
    signIn: signInReducer,
    auth: authReducer,
    restaurant: restaurantReducer,
    tables: tableReducer,
    reservations: reservationsReducer,
    stripe: stripeAccountReducer,
    basket: basketReducer,
    restaurantSearch: restaurantSearchReducer,
    checkout: checkoutReducer,
    callWaiter: callWaiterReducer,
    user: userReducer,
    bookings: bookingsReducer,
    language: languageReducer,
    redirect: redirectReducer,
    contactUs: contactUsReducer,
    resetPassword: resetPasswordReducer,
    onlineStatus: onlineStatusReducer,
    subscriptions: subscriptionsReducer,
    offers: offersReducer,
    customer: customerUserReducer,
    offerRedemptions: offerRedemptionsReducer,
    requests: requestsReducer,
    payLater: payLaterReducer,
    cookie: cookieConsentReducer,
    amenity: amenityReducer,
    hotelUser: hotelUserReducer,
    roomPlus: roomPlusReducer,
    deliveryLocation: deliveryLocationReducer,
    reviewTracks: reviewTracksReducer,
    modifierGroups: modifierGroupReducer,
    reports: reportsReducer,
    employees: employeeReducer,
    socialTracks: socialTracksReducer,
    tags: tagReducer,
    availabilityDays: availabilityDaysReducer,
    discountDays: discountDaysReducer,
    allergens: allergenReducer,
    insights: insightsReducer,
    insightsSummary: insightsSummaryReducer,
    adminSettings: adminSettingsReducer,
    discountEmails: discountEmailsReducer,
    blogs: blogsReducer,
    loyalty: WalletPassReducer,
    userStatistics: userStatisticsReducer,
    campaigns: campaignsReducer,
    website: websiteReducer,
    unsub: unsubscribeUser,
    delitePay: delitePaysReducer,
    deliteClub: deliteClubReducer,
    // reviewHub: reviewHubReducer,
    appRegistration: appRegistrationReducer,
    discounts: discountsReducer,
});

export default rootReducer;
