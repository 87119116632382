import _ from 'lodash';
import {
    CANCEL_RESERVATION_SUCCESSFUL,
    ERROR_FETCHING_RESERVATIONS,
    FETCHED_RESERVATIONS,
    NEW_SOCKET_RESERVATION,
} from '../types';

const initialState = {};

const reservationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCHED_RESERVATIONS:
            return {
                ...state,
                reservations: {
                    ...state.reservations,
                    [action.period]:
                        (action.reservations &&
                            action.reservations.tableReservations.sort((a, b) =>
                                a.createdAt < b.createdAt ? 1 : -1
                            )) ||
                        [],
                },
            };
        case CANCEL_RESERVATION_SUCCESSFUL:
            return {
                ...state,
                reservations: {
                    ...(state.reservations || []),
                    [action.period]: [
                        ...(state.reservations[action.period] || []).filter(
                            r => r.id !== action.reservationId
                        ),
                    ].sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1)),
                    cancelled: [
                        ...(state.reservations.cancelled || []),
                        {
                            ...(state.reservations[action.period] || []).find(
                                r => r.id === action.reservationId
                            ),
                            status: 'cancelled',
                        },
                    ].sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1)),
                },
            };
        case NEW_SOCKET_RESERVATION:
            return {
                ...state,
                reservations: {
                    ...(state.reservations || []),
                    [action.reservation.reservation.period]: [
                        ...(_.get(state, ['reservations'], {})[
                            action.reservation.reservation.period
                        ] || []),
                        action.reservation.reservation,
                    ].sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1)),
                },
            };
        case ERROR_FETCHING_RESERVATIONS:
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
};

export default reservationsReducer;
