import { SET_ACTIVE } from '../types';

const initialState = {};

const activeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE:
      state[action.key] = action.value;
      return { [action.key]: action.value };
    default:
      return state;
  }
};

export default activeReducer;
