import Cookie from 'js-cookie';
import {
    SET_LANGUAGE_SUCCESSFUL,
    GET_LANGUAGE_SUCCESSFUL,
    SET_MENU_LANGUAGE_SUCCESSFUL,
    GET_MENU_LANGUAGE_SUCCESSFUL,
    SET_MENU_LANGUAGE_OPTIONS_SUCCESSFUL,
    GET_MENU_LANGUAGE_OPTIONS_SUCCESSFUL,
} from '../types';
import { secureStorage } from '../../utils/tokenFetcher';

let currentLanguage;

try {
    currentLanguage =
        (secureStorage.getItem('language') || {}).language ||
        Cookie.get('locale') ||
        'en';
} catch (ex) {
    currentLanguage = 'en';
}

const initialState = { language: currentLanguage };

const languageReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LANGUAGE_SUCCESSFUL:
        case GET_LANGUAGE_SUCCESSFUL: {
            return {
                ...state,
                language: action.language,
            };
        }
        case SET_MENU_LANGUAGE_SUCCESSFUL:
        case GET_MENU_LANGUAGE_SUCCESSFUL:
            return {
                ...state,
                menuLanguage: action.language,
            };
        case SET_MENU_LANGUAGE_OPTIONS_SUCCESSFUL:
        case GET_MENU_LANGUAGE_OPTIONS_SUCCESSFUL:
            return {
                ...state,
                menuLanguageOptions: action.languageOptions,
            };
        default:
            return state;
    }
};

export default languageReducer;
