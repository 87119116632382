import {
    CREATE_EMPLOYEE_SUCCESSFUL,
    CREATE_EMPLOYEE_FAILED,
    FETCHED_EMPLOYEES,
    ERROR_FETCHING_EMPLOYEES,
    DELETE_EMPLOYEE_SUCCESSFUL,
    DELETE_EMPLOYEE_FAILED,
    EDIT_EMPLOYEE_SUCCESSFUL,
} from '../types';

const initialState = {};

const employeeReducer = (state = initialState, action) => {
    switch (action.type) {
        case DELETE_EMPLOYEE_SUCCESSFUL:
            return {
                ...state,
                employees: [
                    ...state.employees.filter(
                        s => s.id !== parseInt(action.data.employeeId, 10)
                    ),
                ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
            };
        case DELETE_EMPLOYEE_FAILED:
            return state;
        case CREATE_EMPLOYEE_SUCCESSFUL:
            return {
                ...state,
                employees: [
                    ...state.employees,
                    { ...action.data.employee },
                ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
            };
        case CREATE_EMPLOYEE_FAILED:
            return {
                ...state,
                createSectionError: action.error,
            };
        case FETCHED_EMPLOYEES:
            return {
                ...state,
                employees: action.employees.sort((a, b) =>
                    a.createdAt > b.createdAt ? -1 : 1
                ),
            };
        case ERROR_FETCHING_EMPLOYEES:
            return {
                ...state,
                error: action.error,
            };
        case EDIT_EMPLOYEE_SUCCESSFUL:
            const employee = state.employees.find(
                g => g.id === parseInt(action.data.id, 10)
            );
            return {
                ...state,
                employees: [
                    ...state.employees.filter(
                        g => g.id !== parseInt(action.data.id, 10)
                    ),
                    {
                        id: parseInt(action.data.id, 10),
                        name: action.data.changes.name || employee.name,
                        displayId: action.data.changes.displayId
                            ? parseInt(action.data.changes.displayId, 10)
                            : employee.displayId,
                        pin: action.data.changes.pin || employee.pin,
                    },
                ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
            };
        default:
            return state;
    }
};

export default employeeReducer;
