export default {
    en: {
        login_to_access_discounts: 'Login to Your Account',
        login_to_access_discounts_descr:
            'Login to your account to access your membership points and restaurant offers.',
        login: 'Login',
        back: 'Back',
        email: 'Email',
        password: 'Password',
        incorrect_email_format: 'Incorrect Email Format',
        just_checkout: 'Skip & Checkout',
    },
    es: {
        login_to_access_discounts: 'Inicia sesión en tu cuenta',
        login_to_access_discounts_descr:
            'Inicia sesión en tu cuenta para acceder a tus puntos de membresía y ofertas de restaurantes.',
        login: 'Iniciar sesión',
        back: 'Atrás',
        email: 'Correo electrónico',
        password: 'Contraseña',
        incorrect_email_format: 'Formato de correo electrónico incorrecto',
        just_checkout: 'Omitir y pagar',
    },
    fr: {
        login_to_access_discounts: 'Connectez-vous à votre compte',
        login_to_access_discounts_descr:
            'Connectez-vous à votre compte pour accéder à vos points de fidélité et aux offres de restaurant.',
        login: 'Connexion',
        back: 'Retour',
        email: 'Email',
        password: 'Mot de passe',
        incorrect_email_format: 'Format de courriel incorrect',
        just_checkout: 'Passer & Payer',
    },
    zh: {
        login_to_access_discounts: '登录到您的帐户',
        login_to_access_discounts_descr:
            '登录到您的帐户，以访问您的会员积分和餐厅优惠。',
        login: '登录',
        back: '返回',
        email: '电子邮件',
        password: '密码',
        incorrect_email_format: '电子邮件格式错误',
        just_checkout: '跳过并结帐',
    },
    ar: {
        login_to_access_discounts: 'تسجيل الدخول إلى حسابك',
        login_to_access_discounts_descr:
            'قم بتسجيل الدخول إلى حسابك للوصول إلى نقاط العضوية الخاصة بك وعروض المطاعم.',
        login: 'تسجيل الدخول',
        back: 'رجوع',
        email: 'البريد الإلكتروني',
        password: 'كلمة المرور',
        incorrect_email_format: 'تنسيق البريد الإلكتروني غير صحيح',
        just_checkout: 'تخطي والدفع',
    },
    ru: {
        login_to_access_discounts: 'Войдите в свой аккаунт',
        login_to_access_discounts_descr:
            'Войдите в свой аккаунт, чтобы получить доступ к вашим баллам и предложениям ресторана.',
        login: 'Войти',
        back: 'Назад',
        email: 'Электронная почта',
        password: 'Пароль',
        incorrect_email_format: 'Неверный формат электронной почты',
        just_checkout: 'Пропустить и оформить заказ',
    },
    ja: {
        login_to_access_discounts: 'アカウントにログインする',
        login_to_access_discounts_descr:
            'アカウントにログインして、メンバーシップポイントとレストランオファーにアクセスしてください。',
        login: 'ログイン',
        back: '戻る',
        email: 'メール',
        password: 'パスワード',
        incorrect_email_format: 'メール形式が正しくありません',
        just_checkout: 'スキップしてチェックアウト',
    },
    ko: {
        login_to_access_discounts: '계정에 로그인하세요',
        login_to_access_discounts_descr:
            '계정에 로그인하여 멤버십 포인트 및 레스토랑 혜택에 액세스하십시오.',
        login: '로그인',
        back: '뒤로',
        email: '이메일',
        password: '비밀번호',
        incorrect_email_format: '잘못된 이메일 형식',
        just_checkout: '건너뛰고 결제',
    },
    de: {
        login_to_access_discounts: 'Melden Sie sich bei Ihrem Konto an',
        login_to_access_discounts_descr:
            'Melden Sie sich bei Ihrem Konto an, um auf Ihre Mitgliedspunkte und Restaurantangebote zuzugreifen.',
        login: 'Anmelden',
        back: 'Zurück',
        email: 'E-Mail',
        password: 'Passwort',
        incorrect_email_format: 'Falsches E-Mail-Format',
        just_checkout: 'Überspringen & Bezahlen',
    },
    fr: {
        login_to_access_discounts: 'Connectez-vous à votre compte',
        login_to_access_discounts_descr:
            'Connectez-vous à votre compte pour accéder à vos points de fidélité et aux offres de restaurant.',
        login: 'Connexion',
        back: 'Retour',
        email: 'Email',
        password: 'Mot de passe',
        incorrect_email_format: 'Format de courriel incorrect',
        just_checkout: 'Passer & Payer',
    },
    pl: {
        login_to_access_discounts: 'Zaloguj się do swojego konta',
        login_to_access_discounts_descr:
            'Zaloguj się na swoje konto, aby uzyskać dostęp do punktów członkowskich i ofert restauracji.',
        login: 'Zaloguj się',
        back: 'Wróć',
        email: 'E-mail',
        password: 'Hasło',
        incorrect_email_format: 'Nieprawidłowy format e-maila',
        just_checkout: 'Pomiń i zapłać',
    },
    pt: {
        login_to_access_discounts: 'Faça login na sua conta',
        login_to_access_discounts_descr:
            'Faça login na sua conta para acessar seus pontos de associação e ofertas de restaurantes.',
        login: 'Login',
        back: 'Voltar',
        email: 'Email',
        password: 'Senha',
        incorrect_email_format: 'Formato de email incorreto',
        just_checkout: 'Pular & Pagar',
    },
    it: {
        login_to_access_discounts: 'Accedi al tuo account',
        login_to_access_discounts_descr:
            'Accedi al tuo account per accedere ai tuoi punti e offerte del ristorante.',
        login: 'Accedi',
        back: 'Indietro',
        email: 'Email',
        password: 'Password',
        incorrect_email_format: 'Formato email non corretto',
        just_checkout: 'Salta & Checkout',
    },
    nl: {
        login_to_access_discounts: 'Log in op uw account',
        login_to_access_discounts_descr:
            'Log in op uw account om toegang te krijgen tot uw ledenpunten en restaurantaanbiedingen.',
        login: 'Inloggen',
        back: 'Terug',
        email: 'E-mail',
        password: 'Wachtwoord',
        incorrect_email_format: 'Onjuist e-mailformaat',
        just_checkout: 'Overslaan & Afrekenen',
    },
    hi: {
        login_to_access_discounts: 'अपने खाते में लॉगिन करें',
        login_to_access_discounts_descr:
            'अपने सदस्यता अंकों और रेस्तरां ऑफ़र तक पहुंचने के लिए अपने खाते में लॉगिन करें।',
        login: 'लॉगिन',
        back: 'वापस',
        email: 'ईमेल',
        password: 'पासवर्ड',
        incorrect_email_format: 'गलत ईमेल प्रारूप',
        just_checkout: 'छोड़ें और चेकआउट करें',
    },
};
