import _ from 'lodash';
import { axios } from './axiosInstance';
import {
    FETCHED_CUSTOMER_USER,
    ERROR_FETCHING_CUSTOMER_USER,
    FETCHED_CUSTOMER_USER_REWARDS,
    ERROR_FETCHING_CUSTOMER_USER_REWARDS,
    FETCHED_CUSTOMER_USER_OFFERS,
    ERROR_FETCHING_CUSTOMER_USER_OFFERS,
} from '../types';
import { loading, endLoading } from './loading';
import { updateTokens } from './auth';
import { reportErrorToSentry } from './sentry';
import { getToken } from '../../utils/tokenFetcher';

const fetchedCustomer = customerUser => ({
    type: FETCHED_CUSTOMER_USER,
    customerUser,
});

const errorFetchingCustomer = error => ({
    type: ERROR_FETCHING_CUSTOMER_USER,
    error,
});

const fetchedCustomerOffers = offers => ({
    type: FETCHED_CUSTOMER_USER_OFFERS,
    offers,
});

const errorFetchingCustomerOffers = error => ({
    type: ERROR_FETCHING_CUSTOMER_USER_OFFERS,
    error,
});

const fetchedCustomerRewards = rewards => ({
    type: FETCHED_CUSTOMER_USER_REWARDS,
    rewards,
});

const errorFetchingCustomerRewards = error => ({
    type: ERROR_FETCHING_CUSTOMER_USER_REWARDS,
    error,
});

// TODO: add customerUser to state, if no customerUser, redirect to signin
const getCustomerUser = () => async (dispatch, getState) => {
    const token = getToken(getState());
    await axios
        .get(`${process.env.REACT_APP_API_URL}/api/customer/me`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(data => {
            if (data.status === 200) {
                dispatch(updateTokens(data.headers));
                dispatch(fetchedCustomer(data.data));
            } else {
                dispatch(
                    errorFetchingCustomer(
                        `Status code incorrect - ${data.status}`
                    )
                );
            }
        })
        .catch(error => {
            console.log('ERROR FETCHING CUSTOMER_USER', error);
            dispatch(errorFetchingCustomer(error));
        });
};

const getCustomerUserOffers = () => async (dispatch, getState) => {
    const token = getToken(getState());
    await axios
        .get(`${process.env.REACT_APP_API_URL}/api/customer/me/offers`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(data => {
            if (data.status === 200) {
                dispatch(updateTokens(data.headers));
                dispatch(fetchedCustomerOffers(data.data));
            } else {
                dispatch(
                    errorFetchingCustomerOffers(
                        `Status code incorrect - ${data.status}`
                    )
                );
            }
        })
        .catch(error => {
            console.log('ERROR FETCHING CUSTOMER_USER OFFERS', error);
            dispatch(errorFetchingCustomerOffers(error));
        });
};

const getCustomerUserRewards = () => async (dispatch, getState) => {
    const token = getToken(getState());
    await axios
        .get(`${process.env.REACT_APP_API_URL}/api/customer/me/rewards`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(data => {
            if (data.status === 200) {
                dispatch(updateTokens(data.headers));
                dispatch(fetchedCustomerRewards(data.data));
            } else {
                dispatch(
                    errorFetchingCustomerRewards(
                        `Status code incorrect - ${data.status}`
                    )
                );
            }
        })
        .catch(error => {
            console.log('ERROR FETCHING CUSTOMER_USER REWARDS', error);
            dispatch(errorFetchingCustomerRewards(error));
        });
};

export const actions = {
    getCustomerUser,
    getCustomerUserOffers,
    getCustomerUserRewards,
};
