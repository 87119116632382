export default {
    en: {
        current_items: 'Current Table Bill',
        select_items_to_pay_for:
            'Please tap on the items you wish to pay for or use the "Split Bill" button.',
        your_total_colon: 'Your Total:',
        including_fee: '(Incl. Order Fee)',
        cover_the_whole_bill: 'Cover The Whole Bill',
        pay_now: 'Pay Now',
        dismiss: 'Dismiss',
        split_bill: 'Split Bill',
        split_bill_for: 'Split the bill for',
        total_bill: 'Total Bill',
        total_amount: 'Total Amount',
        total_tips: 'Total Tips',
        amount_paid_up_till_now: 'Amount Paid Up Till Now',
        amount_remaining: 'Amount Remaining',
        error_min_amount: 'Minimum amount is 1.00',
        error_remaining_amount:
            'You cannot leave an amount remaining smaller than 1.00',
        out_of: 'out of',
        people: 'people',
        pay: 'Pay',
        of: 'of',
        bill_details: 'Bill Details',
        payment_details: 'Payment Details',
        total_due: 'Total Due',
        minimum_amount_message: 'Minimum amount is 1.00',
        remaining_amount_message:
            'Remaining amount must be zero or at least 1.00',
        tip: 'Tip',
    },
    es: {
        current_items: 'Órdenes Actuales',
        select_items_to_pay_for:
            'Por favor, toque los artículos que desea pagar.',
        your_total_colon: 'Tu Total:',
        including_fee: '(Incl. Tarifa de Pedido)',
        cover_the_whole_bill: 'Cubrir Toda La Cuenta',
        pay_now: 'Pagar Ahora',
        dismiss: 'Descartar',
        split_bill: 'Dividir la cuenta',
        split_bill_for: 'Dividir la cuenta para',
        total_bill: 'Cuenta Total',
        total_amount: 'Monto Total',
        total_tips: 'Propinas Totales',
        amount_paid_up_till_now: 'Cantidad Pagada Hasta Ahora',
        amount_remaining: 'Cantidad Restante',
        error_min_amount: 'El monto mínimo es 1.00',
        error_remaining_amount:
            'No puede dejar un monto restante menor que 1.00',
        out_of: 'de',
        people: 'personas',
        pay: 'Pagar',
        of: 'de',
        bill_details: 'Detalles de la Factura',
        payment_details: 'Detalles del Pago',
        total_due: 'Total a Pagar',
        minimum_amount_message: 'El monto mínimo es 1.00',
        remaining_amount_message:
            'El monto restante debe ser cero o al menos 1.00',
        tip: 'Propina',
    },
    zh: {
        current_items: '当前订单',
        select_items_to_pay_for: '请选择您要支付的商品。',
        your_total_colon: '你的总计:',
        including_fee: '(包括订单费用)',
        cover_the_whole_bill: '支付全部账单',
        pay_now: '立即支付',
        dismiss: '关闭',
        split_bill: '分开支付账单',
        split_bill_for: '分账为',
        total_bill: '总账单',
        total_amount: '总金额',
        total_tips: '小费总额',
        amount_paid_up_till_now: '到目前为止已支付金额',
        amount_remaining: '剩余金额',
        error_min_amount: '最低金额为1.00',
        error_remaining_amount: '剩余金额不能少于1.00',
        out_of: '的',
        people: '人',
        pay: '支付',
        of: '的',
        bill_details: '账单详情',
        payment_details: '付款详情',
        total_due: '应付总额',
        minimum_amount_message: '最低金额为1.00',
        remaining_amount_message: '剩余金额必须为零或至少为1.00',
        tip: '小费',
    },
    ar: {
        current_items: 'الطلبات الحالية',
        select_items_to_pay_for:
            'يرجى النقر على العناصر التي ترغب في دفع ثمنها.',
        your_total_colon: 'مجموعك:',
        including_fee: '(يشمل رسوم الطلب)',
        cover_the_whole_bill: 'تغطية الفاتورة الكاملة',
        pay_now: 'ادفع الآن',
        dismiss: 'رفض',
        split_bill: 'قسّم الفاتورة',
        split_bill_for: 'تقسيم الفاتورة ل',
        total_bill: 'إجمالي الفاتورة',
        total_amount: 'المبلغ الإجمالي',
        total_tips: 'مجموع الإكراميات',
        amount_paid_up_till_now: 'المبلغ المدفوع حتى الآن',
        amount_remaining: 'المبلغ المتبقي',
        error_min_amount: 'الحد الأدنى للمبلغ هو 1.00',
        error_remaining_amount: 'لا يمكنك ترك مبلغ أقل من 1.00',
        out_of: 'من',
        people: 'أشخاص',
        pay: 'ادفع',
        of: 'من',
        bill_details: 'تفاصيل الفاتورة',
        payment_details: 'تفاصيل الدفع',
        total_due: 'المبلغ المستحق',
        minimum_amount_message: 'الحد الأدنى للمبلغ هو 1.00',
        remaining_amount_message:
            'يجب أن يكون المبلغ المتبقي صفراً أو لا يقل عن 1.00',
        tip: 'إكرامية',
    },
    ru: {
        current_items: 'Текущие заказы',
        select_items_to_pay_for: 'Пожалуйста, выберите товары для оплаты.',
        your_total_colon: 'Ваш итог:',
        including_fee: '(Включая плату за заказ)',
        cover_the_whole_bill: 'Оплатить весь счет',
        pay_now: 'Заплатить сейчас',
        dismiss: 'Отклонить',
        split_bill: 'Разделить счет',
        split_bill_for: 'Разделить счет для',
        total_bill: 'Общий счет',
        total_amount: 'Общая сумма',
        total_tips: 'Общая сумма чаевых',
        amount_paid_up_till_now: 'Сумма, уплаченная до сих пор',
        amount_remaining: 'Оставшаяся сумма',
        error_min_amount: 'Минимальная сумма 1.00',
        error_remaining_amount: 'Вы не можете оставить остаток меньше 1.00',
        out_of: 'из',
        people: 'человек',
        pay: 'Заплатить',
        of: 'из',
        bill_details: 'Детали счета',
        payment_details: 'Детали платежа',
        total_due: 'Итого к оплате',
        minimum_amount_message: 'Минимальная сумма 1.00',
        remaining_amount_message:
            'Оставшаяся сумма должна быть нулевой или не менее 1.00',
        tip: 'Чаевые',
    },
    ja: {
        current_items: '現在の注文',
        select_items_to_pay_for: 'お支払い希望のアイテムをタップしてください。',
        your_total_colon: '合計:',
        including_fee: '(注文料込み)',
        cover_the_whole_bill: '全ての請求をカバーする',
        pay_now: '今払う',
        dismiss: '閉じる',
        split_bill: '請求を分ける',
        split_bill_for: '分割するため',
        total_bill: '合計請求額',
        total_amount: '総額',
        total_tips: '総チップ額',
        amount_paid_up_till_now: 'これまでに支払われた金額',
        amount_remaining: '残額',
        error_min_amount: '支払金額は少なくとも1.00である必要があります',
        error_remaining_amount:
            '残額はゼロまたは少なくとも1.00である必要があります',
        out_of: 'の',
        people: '人',
        pay: '支払う',
        of: 'の',
        bill_details: '請求詳細',
        payment_details: '支払い詳細',
        total_due: '支払うべき合計',
        minimum_amount_message: '支払金額は少なくとも1.00である必要があります',
        remaining_amount_message:
            '残額はゼロまたは少なくとも1.00である必要があります',
        tip: 'チップ',
    },
    ko: {
        current_items: '현재 주문',
        select_items_to_pay_for: '지불하려는 항목을 눌러주세요.',
        your_total_colon: '총액:',
        including_fee: '(주문 수수료 포함)',
        cover_the_whole_bill: '전체 청구서 커버',
        pay_now: '지금 결제',
        dismiss: '닫기',
        split_bill: '청구서 나누기',
        split_bill_for: '계산서를 나누기 위해',
        total_bill: '총 청구서',
        total_amount: '총 금액',
        total_tips: '총 팁',
        amount_paid_up_till_now: '지금까지 지불된 금액',
        amount_remaining: '남은 금액',
        error_min_amount: '지불 금액은 최소 1.00이어야 합니다',
        error_remaining_amount: '남은 금액은 0이거나 최소 1.00이어야 합니다',
        out_of: '중',
        people: '명',
        pay: '지불',
        of: '의',
        bill_details: '청구서 세부 정보',
        payment_details: '결제 세부 정보',
        total_due: '총 지급액',
        minimum_amount_message: '최소 금액은 1.00입니다',
        remaining_amount_message: '남은 금액은 0이거나 최소 1.00이어야 합니다',
        tip: '팁',
    },
    de: {
        current_items: 'Aktuelle Bestellungen',
        select_items_to_pay_for:
            'Bitte tippen Sie auf die Artikel, die Sie bezahlen möchten.',
        your_total_colon: 'Ihr Gesamtbetrag:',
        including_fee: '(Inklusive Bestellgebühr)',
        cover_the_whole_bill: 'Die gesamte Rechnung decken',
        pay_now: 'Jetzt bezahlen',
        dismiss: 'Schließen',
        split_bill: 'Rechnung teilen',
        split_bill_for: 'Rechnung teilen für',
        total_bill: 'Gesamtrechnung',
        total_amount: 'Gesamtbetrag',
        total_tips: 'Gesamttipps',
        amount_paid_up_till_now: 'Bis jetzt bezahlter Betrag',
        amount_remaining: 'Verbleibender Betrag',
        error_min_amount: 'Zu zahlender Betrag muss mindestens 1.00 sein',
        error_remaining_amount:
            'Verbleibender Betrag muss mindestens 1.00 sein',
        out_of: 'von',
        people: 'Personen',
        pay: 'Bezahlen',
        of: 'von',
        bill_details: 'Rechnungsdetails',
        payment_details: 'Zahlungsdetails',
        total_due: 'Gesamtfälligkeit',
        minimum_amount_message: 'Mindestbetrag ist 1.00',
        remaining_amount_message:
            'Der verbleibende Betrag muss null oder mindestens 1.00 sein',
        tip: 'Trinkgeld',
    },
    fr: {
        current_items: 'Commandes en cours',
        select_items_to_pay_for:
            'Veuillez appuyer sur les articles que vous souhaitez payer.',
        your_total_colon: 'Votre Total:',
        including_fee: '(Incl. Frais de commande)',
        cover_the_whole_bill: 'Couvrir toute la facture',
        pay_now: 'Payer maintenant',
        dismiss: 'Fermer',
        split_bill: 'Partager la facture',
        split_bill_for: 'Partager la facture pour',
        total_bill: 'Facture Totale',
        total_amount: 'Montant total',
        total_tips: 'Total des pourboires',
        amount_paid_up_till_now: "Montant payé jusqu'à présent",
        amount_remaining: 'Montant restant',
        error_min_amount: 'Le montant minimum est de 1.00',
        error_remaining_amount:
            'Le montant restant ne peut pas être inférieur à 1.00',
        out_of: 'de',
        people: 'personnes',
        pay: 'Payer',
        of: 'de',
        bill_details: 'Détails de la facture',
        payment_details: 'Détails du paiement',
        total_due: 'Total dû',
        minimum_amount_message: 'Le montant minimum est de 1.00',
        remaining_amount_message:
            'Le montant restant doit être zéro ou au moins 1.00',
        tip: 'Pourboire',
    },
    pl: {
        current_items: 'Aktualne zamówienia',
        select_items_to_pay_for:
            'Dotknij przedmiotów, za które chcesz zapłacić.',
        your_total_colon: 'Twój Całkowity:',
        including_fee: '(W tym opłata za zamówienie)',
        cover_the_whole_bill: 'Pokryj cały rachunek',
        pay_now: 'Zapłać teraz',
        dismiss: 'Zamknij',
        split_bill: 'Podziel rachunek',
        split_bill_for: 'Podziel rachunek na',
        total_bill: 'Całkowity rachunek',
        total_amount: 'Łączna kwota',
        total_tips: 'Łączne napiwki',
        amount_paid_up_till_now: 'Kwota zapłacona do tej pory',
        amount_remaining: 'Pozostała kwota',
        error_min_amount: 'Kwota do zapłaty musi wynosić co najmniej 1.00',
        error_remaining_amount: 'Pozostała kwota musi być co najmniej 1.00',
        out_of: 'z',
        people: 'osób',
        pay: 'Zapłać',
        of: 'z',
        bill_details: 'Szczegóły rachunku',
        payment_details: 'Szczegóły płatności',
        total_due: 'Łączna należność',
        minimum_amount_message: 'Kwota minimalna to 1.00',
        remaining_amount_message:
            'Pozostała kwota musi wynosić zero lub co najmniej 1.00',
        tip: 'Napiwek',
    },
    pt: {
        current_items: 'Pedidos atuais',
        select_items_to_pay_for: 'Toque nos itens que deseja pagar.',
        your_total_colon: 'Seu Total:',
        including_fee: '(Incl. Taxa de Pedido)',
        cover_the_whole_bill: 'Cobrir toda a conta',
        pay_now: 'Pague agora',
        dismiss: 'Fechar',
        split_bill: 'Dividir a conta',
        split_bill_for: 'Dividir a conta para',
        total_bill: 'Conta Total',
        total_amount: 'Valor total',
        total_tips: 'Total de gorjetas',
        amount_paid_up_till_now: 'Valor Pago Até Agora',
        amount_remaining: 'Valor Restante',
        error_min_amount: 'O valor mínimo é 1.00',
        error_remaining_amount: 'O valor restante deve ser pelo menos 1.00',
        out_of: 'de',
        people: 'pessoas',
        pay: 'Pagar',
        of: 'de',
        bill_details: 'Detalhes da conta',
        payment_details: 'Detalhes do pagamento',
        total_due: 'Total devido',
        minimum_amount_message: 'O valor mínimo é 1.00',
        remaining_amount_message:
            'O valor restante deve ser zero ou pelo menos 1.00',
        tip: 'Gorjeta',
    },
    it: {
        current_items: 'Ordini correnti',
        select_items_to_pay_for: 'Tocca gli articoli che desideri pagare.',
        your_total_colon: 'Il tuo totale:',
        including_fee: '(Incl. Tassa di Ordine)',
        cover_the_whole_bill: 'Copri tutto il conto',
        pay_now: 'Paga ora',
        dismiss: 'Chiudi',
        split_bill: 'Dividi il conto',
        split_bill_for: 'Dividi il conto per',
        total_bill: 'Conto Totale',
        total_amount: 'Importo totale',
        total_tips: 'Totale mance',
        amount_paid_up_till_now: 'Importo Pagato Fino Ad Ora',
        amount_remaining: 'Importo Rimanente',
        error_min_amount: "L'importo minimo è 1.00",
        error_remaining_amount: "L'importo rimanente deve essere almeno 1.00",
        out_of: 'di',
        people: 'persone',
        pay: 'Paga',
        of: 'di',
        bill_details: 'Dettagli del conto',
        payment_details: 'Dettagli del pagamento',
        total_due: 'Totale dovuto',
        minimum_amount_message: "L'importo minimo è 1.00",
        remaining_amount_message:
            "L'importo rimanente deve essere zero o almeno 1.00",
        tip: 'Mancia',
    },
    nl: {
        current_items: 'Huidige bestellingen',
        select_items_to_pay_for: 'Tik op de items die je wilt betalen.',
        your_total_colon: 'Uw totaal:',
        including_fee: '(Inclusief bestelkosten)',
        cover_the_whole_bill: 'De hele rekening dekken',
        pay_now: 'Nu betalen',
        dismiss: 'Sluiten',
        split_bill: 'Rekening delen',
        split_bill_for: 'Rekening delen voor',
        total_bill: 'Totale rekening',
        total_amount: 'Totaalbedrag',
        total_tips: 'Totaal fooien',
        amount_paid_up_till_now: 'Tot nu toe betaalde bedrag',
        amount_remaining: 'Resterend bedrag',
        error_min_amount: 'Te betalen bedrag moet minstens 1.00 zijn',
        error_remaining_amount: 'Resterend bedrag moet minstens 1.00 zijn',
        out_of: 'van',
        people: 'mensen',
        pay: 'Betalen',
        of: 'van',
        bill_details: 'Factuurdetails',
        payment_details: 'Betalingsgegevens',
        total_due: 'Totaal verschuldigd',
        minimum_amount_message: 'Minimum bedrag is 1.00',
        remaining_amount_message:
            'Het resterende bedrag moet nul zijn of ten minste 1.00',
        tip: 'Fooi',
    },
    hi: {
        current_items: 'वर्तमान ऑर्डर',
        select_items_to_pay_for:
            'कृपया उन आइटम्स पर टैप करें जिनका भुगतान करना चाहते हैं।',
        your_total_colon: 'आपका कुल:',
        including_fee: '(आदेश शुल्क सहित)',
        cover_the_whole_bill: 'पूरा बिल कवर करें',
        pay_now: 'अभी भुगतान करें',
        dismiss: 'बंद करें',
        split_bill: 'बिल बांटें',
        split_bill_for: 'बिल बांटें के लिए',
        total_bill: 'कुल बिल',
        total_amount: 'कुल राशि',
        total_tips: 'कुल टिप्स',
        amount_paid_up_till_now: 'अब तक भुगतान की गई राशि',
        amount_remaining: 'शेष राशि',
        error_min_amount: 'भुगतान की राशि कम से कम 1.00 होनी चाहिए',
        error_remaining_amount: 'शेष राशि कम से कम 1.00 होनी चाहिए',
        out_of: 'में से',
        people: 'लोग',
        pay: 'भुगतान करें',
        of: 'का',
        bill_details: 'बिल विवरण',
        payment_details: 'भुगतान विवरण',
        total_due: 'कुल देय राशि',
        minimum_amount_message: 'न्यूनतम राशि 1.00 है',
        remaining_amount_message: 'शेष राशि शून्य या कम से कम 1.00 होनी चाहिए',
        tip: 'टिप',
    },
};
