import {
    FETCHED_HOTEL_AMENITIES,
    ERROR_FETCHING_HOTEL_AMENITIES,
    TOGGLE_AMENITY_AVAILABILITY_SUCCESSFUL,
    CREATE_AMENITY_SUCCESSFUL,
    CREATE_AMENITY_FAILED,
    EDIT_AMENITY_SUCCESSFUL,
    DELETE_AMENITY_SUCCESSFUL,
    TOGGLE_AMENITY_TYPE_AVAILABILITY_SUCCESSFUL,
    CREATE_AMENITY_TYPE_SUCCESSFUL,
    DELETE_AMENITY_TYPE_SUCCESSFUL,
    FETCHED_PUBLIC_AMENITIES,
} from '../types';

const initialState = {};

const amenityReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCHED_HOTEL_AMENITIES:
            return {
                ...state,
                amenities: action.amenities
                    .map(amenity => ({
                        ...amenity,
                        amenities: amenity.amenities.sort((a, b) =>
                            a.createdAt > b.createdAt
                                ? 1
                                : a.createdAt === b.createdAt
                                ? a.name > b.name
                                    ? 1
                                    : -1
                                : -1
                        ),
                    }))
                    .sort((a, b) =>
                        a.createdAt > b.createdAt
                            ? 1
                            : a.createdAt === b.createdAt
                            ? a.name > b.name
                                ? 1
                                : -1
                            : -1
                    ),
            };
        case TOGGLE_AMENITY_AVAILABILITY_SUCCESSFUL:
        case EDIT_AMENITY_SUCCESSFUL:
            const amenityType = state.amenities.find(
                type =>
                    type.id ===
                    (action.type === EDIT_AMENITY_SUCCESSFUL
                        ? action.item.sectionId
                        : action.sectionId)
            );
            const item = amenityType.amenities.find(
                menu =>
                    menu.id ===
                    (action.type === EDIT_AMENITY_SUCCESSFUL
                        ? action.item.itemId
                        : action.itemId)
            );
            return {
                ...state,
                amenities: [
                    ...state.amenities.filter(
                        type =>
                            type.id !==
                            (action.type === EDIT_AMENITY_SUCCESSFUL
                                ? action.item.sectionId
                                : action.sectionId)
                    ),
                    {
                        ...amenityType,
                        amenities: [
                            ...amenityType.amenities.filter(
                                amenity =>
                                    amenity.id !==
                                    (action.type === EDIT_AMENITY_SUCCESSFUL
                                        ? action.item.itemId
                                        : action.itemId)
                            ),
                            action.type ===
                            TOGGLE_AMENITY_AVAILABILITY_SUCCESSFUL
                                ? { ...item, isAvailable: action.isAvailable }
                                : { ...item, ...action.item.changes },
                        ].sort((a, b) =>
                            a.createdAt > b.createdAt
                                ? 1
                                : a.createdAt === b.createdAt
                                ? a.name > b.name
                                    ? 1
                                    : -1
                                : -1
                        ),
                    },
                ].sort((a, b) =>
                    a.createdAt > b.createdAt
                        ? 1
                        : a.createdAt === b.createdAt
                        ? a.name > b.name
                            ? 1
                            : -1
                        : -1
                ),
            };

        case TOGGLE_AMENITY_TYPE_AVAILABILITY_SUCCESSFUL:
            const amenityType3 = state.amenities.find(
                type => type.id === action.itemId
            );

            return {
                ...state,
                amenities: [
                    ...state.amenities.filter(
                        type => type.id !== action.itemId
                    ),
                    {
                        ...amenityType3,
                        isAvailable: action.isAvailable,
                    },
                ].sort((a, b) =>
                    a.createdAt > b.createdAt
                        ? 1
                        : a.createdAt === b.createdAt
                        ? a.name > b.name
                            ? 1
                            : -1
                        : -1
                ),
            };
        case DELETE_AMENITY_TYPE_SUCCESSFUL:
            return {
                ...state,
                amenities: [
                    ...state.amenities.filter(
                        s => s.id !== parseInt(action.data.sectionId, 10)
                    ),
                ].sort((a, b) =>
                    a.createdAt > b.createdAt
                        ? 1
                        : a.createdAt === b.createdAt
                        ? a.name > b.name
                            ? 1
                            : -1
                        : -1
                ),
            };
        case DELETE_AMENITY_SUCCESSFUL:
            const s = state.amenities.find(
                s => s.id === parseInt(action.data.sectionId, 10)
            );
            return {
                ...state,
                amenities: [
                    ...state.amenities.filter(
                        s => s.id !== parseInt(action.data.sectionId, 10)
                    ),
                    {
                        ...s,

                        amenities: [
                            ...s.amenities.filter(
                                i => i.id !== parseInt(action.data.itemId, 10)
                            ),
                        ].sort((a, b) =>
                            a.createdAt > b.createdAt
                                ? 1
                                : a.createdAt === b.createdAt
                                ? a.name > b.name
                                    ? 1
                                    : -1
                                : -1
                        ),
                    },
                ].sort((a, b) =>
                    a.createdAt > b.createdAt
                        ? 1
                        : a.createdAt === b.createdAt
                        ? a.name > b.name
                            ? 1
                            : -1
                        : -1
                ),
            };
        case ERROR_FETCHING_HOTEL_AMENITIES:
            return {
                ...state,
                error: action.error,
            };
        case CREATE_AMENITY_SUCCESSFUL:
            const amenityType2 = state.amenities.find(
                type => type.id === action.data.amenityTypeId
            );
            return {
                ...state,
                amenities: [
                    ...state.amenities.filter(
                        type => type.id !== action.data.amenityTypeId
                    ),
                    {
                        ...amenityType2,
                        amenities: [
                            ...amenityType2.amenities,
                            { ...action.data },
                        ].sort((a, b) =>
                            a.createdAt > b.createdAt
                                ? 1
                                : a.createdAt === b.createdAt
                                ? a.name > b.name
                                    ? 1
                                    : -1
                                : -1
                        ),
                    },
                ].sort((a, b) =>
                    a.createdAt > b.createdAt
                        ? 1
                        : a.createdAt === b.createdAt
                        ? a.name > b.name
                            ? 1
                            : -1
                        : -1
                ),
            };
        case CREATE_AMENITY_TYPE_SUCCESSFUL:
            return {
                ...state,
                amenities: [
                    ...state.amenities,
                    { ...action.data.amenityType, amenities: [] },
                ].sort((a, b) =>
                    a.createdAt > b.createdAt
                        ? 1
                        : a.createdAt === b.createdAt
                        ? a.name > b.name
                            ? 1
                            : -1
                        : -1
                ),
            };
        case FETCHED_PUBLIC_AMENITIES:
            return {
                ...state,
                publicAmenities: {
                    ...state.publicAmenities,
                    [action.restaurantId]: action.amenities,
                },
            };
        case CREATE_AMENITY_FAILED:
            // fix this
            return {
                ...state,
                createAmenityError: action.error,
            };

        default:
            return state;
    }
};

export default amenityReducer;
