import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import rootReducer from '../state/reducers';

const initialState = {
    active: {},
};

const logger = createLogger({
    // ...options
});

const middleware =
    window.location.origin === 'https://www.menudelite.io'
        ? [thunk]
        : [thunk, logger];

const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(...middleware)
);

export default store;
