import {
    FETCHED_DISCOUNTS,
    ERROR_FETCHING_DISCOUNTS,
    TOGGLE_DISCOUNT_AVAILABILITY_SUCCESSFUL,
    DELETE_DISCOUNT_SUCCESSFUL,
    ADD_DISCOUNT_SUCCESSFUL,
} from '../types';

const initialState = {};

const discountsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCHED_DISCOUNTS:
            return {
                ...state,
                discounts: action.discounts.sort((a, b) =>
                    a.createdAt > b.createdAt ? -1 : 1
                ),
                totalListingViews: action.totalListingViews,
                totalMenuClicks: action.totalMenuClicks,
                totalReservationClicks: action.totalReservationClicks,
            };
        case ADD_DISCOUNT_SUCCESSFUL:
            return {
                ...state,
                discounts: [
                    ...(state.discounts || []),
                    action.item,
                ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
            };
        case ERROR_FETCHING_DISCOUNTS:
            return {
                ...state,
                error: action.error,
            };
        case TOGGLE_DISCOUNT_AVAILABILITY_SUCCESSFUL:
            const discount = (state.discounts || []).find(
                o => o.id === action.discount.offerId
            );
            return {
                ...state,
                discounts: [
                    ...(state.discounts || []).filter(
                        o => o.id !== action.discount.offerId
                    ),
                    {
                        ...discount,
                        isActive: action.discount.changes.isActive,
                    },
                ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
            };
        case DELETE_DISCOUNT_SUCCESSFUL:
            const discount2 = (state.discounts || []).find(
                o => o.id === action.discount.offerId
            );
            return {
                ...state,
                discounts: [
                    ...(state.discounts || []).filter(
                        o => o.id !== action.discount.offerId
                    ),
                    {
                        ...discount2,
                        isDeleted: true,
                    },
                ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
            };
        default:
            return state;
    }
};

export default discountsReducer;
