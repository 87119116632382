import _ from 'lodash';
import { FETCHED_REVIEW_TRACKS, ERROR_FETCHING_REVIEW_TRACKS } from '../types';

const initialState = {};

const reviewTracksReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCHED_REVIEW_TRACKS:
            return {
                ...state,
                reviewStats: action.reviewStats,
            };
        case ERROR_FETCHING_REVIEW_TRACKS:
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
};

export default reviewTracksReducer;
