import {
    LOADING,
    GENERATE_AI_EMAIL_CAMPAIGN,
    GENERATE_AI_EMAIL_CAMPAIGN_SUCCESSFUL,
    ERROR_GENERATING_AI_EMAIL_CAMPAIGN,
    DELETE_EMAIL_CAMPAIGN_SUCCESSFUL,
    DELETE_EMAIL_CAMPAIGN_FAILED,
    TOGGLE_EMAIL_CAMPAIGN_AVAILABILITY_SUCCESSFUL,
    ADD_EMAIL_CAMPAIGN_SUCCESSFUL,
    ERROR_ADDING_EMAIL_CAMPAIGN,
    FETCHED_EMAIL_CAMPAIGNS,
    ERROR_FETCHING_EMAIL_CAMPAIGNS,
    EDIT_EMAIL_CAMPAIGN_SUCCESSFUL,
    FETCHED_EMAIL_SEQUENCE_LIVES,
    ERROR_FETCHING_EMAIL_SEQUENCE_LIVES,
    ADD_EMAIL_SEQUENCE_LIVE_SUCCESSFUL,
    DELETE_EMAIL_SEQUENCE_LIVE_SUCCESSFUL,
    UPDATE_AUTOMATED_EMAIL_SEQUENCE_LIVE_SUCCESSFUL,
    ERROR_UPDATING_AUTOMATED_EMAIL_SEQUENCE_LIVE,
    UPDATE_AUTOMATED_EMAIL_SEQUENCE_LIVE,
} from '../types';

const initialState = {};

const campaignsReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADING:
            if (action.key === GENERATE_AI_EMAIL_CAMPAIGN) {
                return {
                    ...state,
                    generateAiEmailCampaignSuccessful: false,
                    aiEmailCampaignError: null,
                };
            }
            if (action.key === UPDATE_AUTOMATED_EMAIL_SEQUENCE_LIVE) {
                return {
                    ...state,
                    updatedSequence: false,
                    updatedSequenceError: false,
                };
            }
            return state;
        case GENERATE_AI_EMAIL_CAMPAIGN_SUCCESSFUL:
            return {
                ...state,
                generateAiEmailCampaignSuccessful: true,
                aiEmailCampaignError: null,
                aiResponse: {
                    heading: action.heading,
                    subject: action.subject,
                    text: action.text,
                },
            };
        case ERROR_GENERATING_AI_EMAIL_CAMPAIGN:
            return {
                ...state,
                generateAiEmailCampaignSuccessful: false,
                aiEmailCampaignError: action.error,
            };
        case DELETE_EMAIL_CAMPAIGN_SUCCESSFUL:
            return {
                ...state,
                emailCampaigns: [
                    ...state.emailCampaigns.map(s =>
                        s.id !== parseInt(action.data.emailCampaignId, 10)
                            ? s
                            : { ...s, isDeleted: true }
                    ),
                ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
            };
        case DELETE_EMAIL_CAMPAIGN_FAILED:
            return state;
        case UPDATE_AUTOMATED_EMAIL_SEQUENCE_LIVE_SUCCESSFUL:
            return {
                ...state,
                updatedSequence: true,
                updatedSequenceError: false,
            };
        case ERROR_UPDATING_AUTOMATED_EMAIL_SEQUENCE_LIVE:
            return {
                ...state,
                updatedSequenceError: true,
                updatedSequence: false,
            };
        case ADD_EMAIL_CAMPAIGN_SUCCESSFUL:
            if (action.isEdit) {
                return {
                    ...state,
                    emailCampaigns: [
                        ...state.emailCampaigns.filter(t => t.id !== action.id),
                        { ...action.emailCampaign.emailCampaign },
                    ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
                };
            }
            return {
                ...state,
                emailCampaigns: [
                    ...state.emailCampaigns,
                    { ...action.emailCampaign.emailCampaign },
                ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
            };
        case TOGGLE_EMAIL_CAMPAIGN_AVAILABILITY_SUCCESSFUL:
            const emailCampaign2 = (state.emailCampaigns || []).find(
                o => o.id === action.emailCampaign.emailCampaignId
            );
            return {
                ...state,
                emailCampaigns: [
                    ...(state.emailCampaigns || []).filter(
                        o => o.id !== action.emailCampaign.emailCampaignId
                    ),
                    {
                        ...emailCampaign2,
                        isAvailable: action.emailCampaign.changes.isAvailable,
                    },
                ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
            };
        case ERROR_ADDING_EMAIL_CAMPAIGN:
            return {
                ...state,
                createEmailCampaignError: action.error,
            };
        case FETCHED_EMAIL_CAMPAIGNS:
            return {
                ...state,
                emailCampaigns: action.emailCampaigns.emailCampaigns.sort(
                    (a, b) => (a.createdAt > b.createdAt ? -1 : 1)
                ),
                smsTokensRemaining: action.emailCampaigns.smsTokensRemaining,
                emailTokensRemaining:
                    action.emailCampaigns.emailTokensRemaining,
            };
        case ERROR_FETCHING_EMAIL_CAMPAIGNS:
            return {
                ...state,
                error: action.error,
            };
        case FETCHED_EMAIL_SEQUENCE_LIVES:
            return {
                ...state,
                emailSequences: action.emailSequenceLives.emailSequenceLives.sort(
                    (a, b) => (a.createdAt > b.createdAt ? -1 : 1)
                ),
                automatedEmailSequence: action.emailSequenceLives.automatedEmailSequence.sort(
                    (a, b) => (a.createdAt > b.createdAt ? -1 : 1)
                ),
            };
        case ADD_EMAIL_SEQUENCE_LIVE_SUCCESSFUL:
            return {
                ...state,
                emailSequences: [
                    {
                        ...action.emailSequenceLive.newestSequence,
                        opens: {},
                        delivered: {},
                        linkClicks: {},
                        undelivered: {},
                        linkClicksByLink: {},
                        opensByUser: {},
                    },
                    ...state.emailSequences,
                ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
            };
        case DELETE_EMAIL_SEQUENCE_LIVE_SUCCESSFUL:
            const offer2 = (state.emailSequences || []).find(
                o => o.id === action.data.emailSequenceLiveId
            );
            return {
                ...state,
                emailSequences: [
                    ...(state.emailSequences || []).filter(
                        o => o.id !== action.data.emailSequenceLiveId
                    ),
                    {
                        ...offer2,
                        isDeleted: true,
                    },
                ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
            };
        case ERROR_FETCHING_EMAIL_SEQUENCE_LIVES:
            return {
                ...state,
                error: action.error,
            };
        case EDIT_EMAIL_CAMPAIGN_SUCCESSFUL:
            const emailCampaign = state.emailCampaigns.find(
                g => g.id === parseInt(action.data.id, 10)
            );
            return {
                ...state,
                emailCampaigns: [
                    ...state.emailCampaigns.filter(
                        g => g.id !== parseInt(action.data.id, 10)
                    ),
                    {
                        id: parseInt(action.data.id, 10),
                        name: action.data.changes.name || emailCampaign.name,
                        displayId: action.data.changes.displayId
                            ? parseInt(action.data.changes.displayId, 10)
                            : emailCampaign.displayId,
                        pin: action.data.changes.pin || emailCampaign.pin,
                    },
                ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
            };
        default:
            return state;
    }
};

export default campaignsReducer;
