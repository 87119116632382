import {
    UPLOAD_PICTURE_SUCCESSFUL,
    ERROR_UPLOADING_PICTURE,
    UPDATE_PICTURE,
    DISMISS_PICTURE_SUCCESS,
    UPLOAD_RESTAURANT_PICTURE,
    LOADING,
    UPDATE_PICTURE_SUCCESS_FALSE,
} from '../types';

const initialState = {};

const pictureUploadReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADING:
            if (action.key === UPLOAD_RESTAURANT_PICTURE) {
                return {
                    ...state,
                    success: null,
                };
            }
            return state;

        case DISMISS_PICTURE_SUCCESS:
            return {
                ...state,
                success: null,
            };
        case UPDATE_PICTURE_SUCCESS_FALSE:
            return {
                ...state,
                success: null,
            };
        case UPDATE_PICTURE:
            return {
                ...state,
                picture: action.picture,
            };
        case UPLOAD_PICTURE_SUCCESSFUL:
            return {
                ...state,
                picture: null,
                success: true,
                error: null,
            };
        case ERROR_UPLOADING_PICTURE:
            return {
                ...state,
                error: `${action.error}`,
            };
        default:
            return state;
    }
};

export default pictureUploadReducer;
