import {
    ERROR_FETCHING_SUBSCRIPTIONS,
    FETCHED_SUBSCRIPTIONS,
    FETCH_CHECKOUT_SUBSCRIPTION_SESSION_SUCCESSFUL,
    FETCH_SUBSCRIPTION_DETAILS_SUCCESS,
} from '../types';

const initialState = {};

const subscriptionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCHED_SUBSCRIPTIONS:
            return {
                ...state,
                [action.key]: action.content,
            };
        case FETCH_CHECKOUT_SUBSCRIPTION_SESSION_SUCCESSFUL:
            return {
                ...state,
                ...action.data,
            };
        case FETCH_SUBSCRIPTION_DETAILS_SUCCESS:
            return { ...state, mySubscription: action.data };
        case ERROR_FETCHING_SUBSCRIPTIONS:
            return state;
        default:
            return state;
    }
};

export default subscriptionsReducer;
