import {
    FETCHED_MENU_ITEMS,
    ERROR_FETCHING_MENU_ITEMS,
    FETCHED_PUBLIC_RESTAURANT,
    FETCH_TABLE_DETAILS_SUCCESSFUL,
    FETCHED_LOCAL_OPENING_TIMES,
    FETCHED_FEATURED_ITEMS,
    CREATE_FEATURED_ITEM_SUCCESSFUL,
    DELETE_FEATURED_ITEM_SUCCESSFUL,
    ERROR_SENDING_USER_DISCOUNT_CODE_EMAIL,
    SEND_USER_DISCOUNT_CODE_EMAIL_SUCCESSFUL,
    ERROR_SUBSCRIBING_POPUP,
    SUBSCRIBE_POPUP_SUCCESSFUL,
    SET_MENU_DATA,
} from '../types';

const initialState = {};

const menuReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCHED_PUBLIC_RESTAURANT:
            return {
                ...state,
                publicRestaurants: {
                    ...state.publicRestaurants,
                    [action.id]: {
                        items: action.items,
                        restaurant: action.restaurant,
                        sections: action.sections,
                        modifiers: action.modifiers,
                        modifierGroups: action.modifierGroups,
                        allergens: action.allergens,
                        menuTypeMenus: action.menuTypeMenus,
                    },
                },
            };
        case FETCHED_LOCAL_OPENING_TIMES:
            return {
                ...state,
                localOpeningTimes: {
                    ...state.localOpeningTimes,
                    [action.id]: action.openingTimes,
                },
            };
        case SET_MENU_DATA:
            return {
                ...state,
                aiMenuData: action.menuData,
            };
        case FETCH_TABLE_DETAILS_SUCCESSFUL:
            return {
                ...state,
                tableDetails: action.data,
            };
        case FETCHED_MENU_ITEMS:
            return {
                ...state,
                menu: action.items,
            };
        case FETCHED_FEATURED_ITEMS:
            return {
                ...state,
                featuredItems: action.featuredItems,
            };
        case CREATE_FEATURED_ITEM_SUCCESSFUL:
            return {
                ...state,
                featuredItems: [
                    ...state.featuredItems,
                    action.data,
                ].sort((a, b) =>
                    a.createdAt === b.createdAt
                        ? a.updatedAt > b.updatedAt
                            ? -1
                            : 1
                        : a.createdAt > b.createdAt
                        ? -1
                        : 1
                ),
            };
        case DELETE_FEATURED_ITEM_SUCCESSFUL:
            return {
                ...state,
                featuredItems: [
                    ...state.featuredItems.filter(i => i.id !== action.menuId),
                ].sort((a, b) =>
                    a.createdAt === b.createdAt
                        ? a.updatedAt > b.updatedAt
                            ? -1
                            : 1
                        : a.createdAt > b.createdAt
                        ? -1
                        : 1
                ),
            };
        case ERROR_SENDING_USER_DISCOUNT_CODE_EMAIL:
            return {
                ...state,
                userDiscountEmailError: action.error,
                userDiscountEmailSuccess: false,
            };
        case ERROR_SUBSCRIBING_POPUP:
            return {
                ...state,
                popupError: action.popup,
                popupSuccess: false,
            };
        case SUBSCRIBE_POPUP_SUCCESSFUL:
            return {
                ...state,
                popupError: null,
                popupSuccess: true,
            };
        case SEND_USER_DISCOUNT_CODE_EMAIL_SUCCESSFUL:
            return {
                ...state,
                userDiscountEmailSuccess: true,
                userDiscountEmailError: null,
            };
        case ERROR_FETCHING_MENU_ITEMS:
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
};

export default menuReducer;
