import {
    CREATE_CHECKOUT_SESSION,
    CREATE_CHECKOUT_SESSION_SUCCESSFUL,
    ERROR_CREATING_CHECKOUT_SESSION,
    FETCH_CHECKOUT_SESSION_SUCCESSFUL,
    CHECK_USER_EMAIL_FOR_CHECKOUT_SESSION_SUCCESSFUL,
    LOADING,
    CHECK_DELIVERY_QUOTE_SUCCESSFUL,
    NEW_DELIVERY_STATUS_UPDATE,
    ERROR_FETCHING_CHECKOUT_SESSION,
} from '../types';

const initialState = {};

const checkoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADING:
            if (action.key === CREATE_CHECKOUT_SESSION) {
                return {
                    ...state,
                    checkoutError: null,
                };
            }
            return state;
        case CREATE_CHECKOUT_SESSION_SUCCESSFUL:
            return {
                ...state,
                checkoutError: null,
            };
        case ERROR_CREATING_CHECKOUT_SESSION:
            return {
                ...state,
                checkoutError: action.error,
            };
        case ERROR_FETCHING_CHECKOUT_SESSION:
            return {
                ...state,
                checkoutFetchError: action.error,
            };
        case FETCH_CHECKOUT_SESSION_SUCCESSFUL:
            return {
                ...state,
                successfulCheckouts: {
                    ...state.successfulCheckouts,
                    [action.restaurantId]: {
                        ...(state.successfulCheckouts || {})[
                            action.restaurantId
                        ],
                        [action.checkoutRetainmentId]: action.data.order,
                    },
                },
            };
        case NEW_DELIVERY_STATUS_UPDATE:
            return {
                ...state,
                successfulCheckouts: {
                    ...state.successfulCheckouts,
                    [action.restaurantId]: {
                        ...(state.successfulCheckouts || {})[
                            action.restaurantId
                        ],
                        [action.checkoutRetainmentId]: {
                            ...state.successfulCheckouts[action.restaurantId][
                                action.checkoutRetainmentId
                            ],
                            deliveryQuote: [
                                ...state.successfulCheckouts[
                                    action.restaurantId
                                ][
                                    action.checkoutRetainmentId
                                ].deliveryQuote.filter(
                                    d =>
                                        d.deliveryId !==
                                        action.params.externalDeliveryId
                                ),
                                {
                                    ...state.successfulCheckouts[
                                        action.restaurantId
                                    ][
                                        action.checkoutRetainmentId
                                    ].deliveryQuote.find(
                                        d =>
                                            d.deliveryId ===
                                            action.params.externalDeliveryId
                                    ),
                                    ...action.params,
                                },
                            ],
                        },
                    },
                },
            };
        case CHECK_USER_EMAIL_FOR_CHECKOUT_SESSION_SUCCESSFUL:
            return {
                ...state,
                email: action.email,
                exists: action.exists,
                isRestaurant: action.isRestaurant,
            };
        case CHECK_DELIVERY_QUOTE_SUCCESSFUL:
            return {
                ...state,
                deliveryStatus: action.deliveryStatus,
                pickupTimeEstimated: action.pickupTimeEstimated,
                dropoffTimeEstimated: action.dropoffTimeEstimated,
                currency: action.currency,
                fee: action.fee,
                externalDeliveryId: action.externalDeliveryId,
                pickupAddress: action.pickupAddress,
                dropoffAddress: action.dropoffAddress,
            };
        default:
            return state;
    }
};

export default checkoutReducer;
