import {
    SIGN_UP_SUCCESSFUL,
    SIGN_UP_FAILED,
    CHECK_IF_USER_EXISTS_SUCCESSFUL,
    CHECK_IF_USER_EXISTS_FAILED,
    CHECK_IF_CODE_VALIDITY_SUCCESSFUL,
    CHECK_IF_CODE_VALIDITY_FAILED,
    USER_SIGN_UP_SUCCESSFUL,
    SET_EMAIL_EXISTS_UNDEFINED,
} from '../types';
import { secureStorage } from '../../utils/tokenFetcher';

const initialState = {};

const signUpReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_SIGN_UP_SUCCESSFUL:
            const refreshToken =
                action.data &&
                action.data.user &&
                action.data.user.refreshToken;
            if (refreshToken) {
                localStorage.removeItem('accessToken');
                localStorage.removeItem('accessTokenExpiry');
                localStorage.setItem('refreshToken', refreshToken);
                secureStorage.setItem(`lastUpdated`, new Date().getTime());
            }
            return {
                ...state,
                user: action.data ? action.data.user : {},
                signUpComplete: true,
            };
        case SIGN_UP_SUCCESSFUL:
            const refreshToken2 =
                action.data &&
                action.data.user &&
                action.data.user.refreshToken;
            if (refreshToken2) {
                localStorage.removeItem('accessToken');
                localStorage.removeItem('accessTokenExpiry');
                localStorage.setItem('refreshToken', refreshToken2);
                secureStorage.setItem(`lastUpdated`, new Date().getTime());
            }
            return {
                ...state,
                restaurant: action.data ? action.data.restaurant : {},
                user: action.data ? action.data.user : {},
                signUpComplete: true,
            };
        case SIGN_UP_FAILED:
            return {
                ...state,
                signUpError: action.error,
            };
        case CHECK_IF_USER_EXISTS_SUCCESSFUL:
            return {
                ...state,
                existingUsers: {
                    ...state.existingUsers,
                    [action.data.email]: action.data.exists,
                    shouldKeepView: false,
                },
            };
        case SET_EMAIL_EXISTS_UNDEFINED:
            return {
                ...state,
                existingUsers: {
                    ...state.existingUsers,
                    shouldKeepView: true,
                },
            };
        case CHECK_IF_USER_EXISTS_FAILED:
            return {
                ...state,
                existingUsers: {
                    ...state.existingUsers,
                    error: action.error,
                },
            };
        case CHECK_IF_CODE_VALIDITY_SUCCESSFUL:
            return {
                ...state,
                codeCheck: true,
            };
        case CHECK_IF_CODE_VALIDITY_FAILED:
            return {
                ...state,
                codeCheck: false,
            };
        default:
            return state;
    }
};

export default signUpReducer;
