import {
    FETCHED_HOTEL_DELIVERY_LOCATIONS,
    ERROR_FETCHING_HOTEL_DELIVERY_LOCATIONS,
    TOGGLE_DELIVERY_LOCATION_AVAILABILITY_SUCCESSFUL,
    CREATE_DELIVERY_LOCATION_SUCCESSFUL,
    CREATE_DELIVERY_LOCATION_FAILED,
    DELETE_DELIVERY_LOCATION_SUCCESSFUL,
} from '../types';

const initialState = {};

const deliveryLocationReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCHED_HOTEL_DELIVERY_LOCATIONS:
            return {
                ...state,
                deliveryLocations: action.deliveryLocations.sort((a, b) =>
                    a.createdAt > b.createdAt
                        ? 1
                        : a.createdAt === b.createdAt
                        ? a.name > b.name
                            ? 1
                            : -1
                        : -1
                ),
            };

        case TOGGLE_DELIVERY_LOCATION_AVAILABILITY_SUCCESSFUL:
            const deliveryLocationType3 = state.deliveryLocations.find(
                type => type.id === action.itemId
            );

            return {
                ...state,
                deliveryLocations: [
                    ...state.deliveryLocations.filter(
                        type => type.id !== action.itemId
                    ),
                    {
                        ...deliveryLocationType3,
                        isAvailable: action.isAvailable,
                    },
                ].sort((a, b) =>
                    a.createdAt > b.createdAt
                        ? 1
                        : a.createdAt === b.createdAt
                        ? a.name > b.name
                            ? 1
                            : -1
                        : -1
                ),
            };
        case DELETE_DELIVERY_LOCATION_SUCCESSFUL:
            return {
                ...state,
                deliveryLocations: [
                    ...state.deliveryLocations.filter(
                        s => s.id !== parseInt(action.data.sectionId, 10)
                    ),
                ].sort((a, b) =>
                    a.createdAt > b.createdAt
                        ? 1
                        : a.createdAt === b.createdAt
                        ? a.name > b.name
                            ? 1
                            : -1
                        : -1
                ),
            };

        case ERROR_FETCHING_HOTEL_DELIVERY_LOCATIONS:
            return {
                ...state,
                error: action.error,
            };

        case CREATE_DELIVERY_LOCATION_SUCCESSFUL:
            return {
                ...state,
                deliveryLocations: [
                    ...state.deliveryLocations,
                    {
                        ...action.data.deliveryLocation,
                    },
                ].sort((a, b) =>
                    a.createdAt > b.createdAt
                        ? 1
                        : a.createdAt === b.createdAt
                        ? a.name > b.name
                            ? 1
                            : -1
                        : -1
                ),
            };
        case CREATE_DELIVERY_LOCATION_FAILED:
            // fix this
            return {
                ...state,
                createAmenityError: action.error,
            };

        default:
            return state;
    }
};

export default deliveryLocationReducer;
