import {
    CREATE_LOYALTY_CAMPAIGN_SUCCESSFUL,
    DELETE_LOYALTY_CAMPAIGN_SUCCESSFUL,
    ERROR_CREATING_FREE_LOYALTY_PASS_REDEMPTION,
    ERROR_CREATING_LOYALTY_PASS_CANCELLATION,
    ERROR_CREATING_LOYALTY_PASS_QUALIFICATION,
    ERROR_CREATING_LOYALTY_PASS_REDEMPTION,
    ERROR_FETCHING_LOYALTY_CAMPAIGNS,
    ERROR_SUBSCRIBING_LOYALTY_CAMPAIGN,
    FETCHED_LOYALTY_CAMPAIGNS,
    FETCHED_LOYALTY_PASS,
    SUBSCRIBE_LOYALTY_CAMPAIGN_SUCCESSFUL,
    SUCCESS_CREATING_FREE_LOYALTY_PASS_REDEMPTION,
    SUCCESS_CREATING_LOYALTY_PASS_CANCELLATION,
    SUCCESS_CREATING_LOYALTY_PASS_QUALIFICATION,
    SUCCESS_CREATING_LOYALTY_PASS_REDEMPTION,
    TOGGLE_LOYALTY_CAMPAIGN_AVAILABILITY_SUCCESSFUL,
} from '../types';

const initialState = {};

const WalletPassReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCHED_LOYALTY_CAMPAIGNS:
            return {
                ...state,
                loyaltyCampaigns: action.loyaltyCampaigns,
            };
        case CREATE_LOYALTY_CAMPAIGN_SUCCESSFUL:
            return {
                ...state,
                loyaltyCampaigns: [
                    ...(state.loyaltyCampaigns || []),
                    action.loyaltyCampaign.loyaltyCampaign,
                ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
            };
        case TOGGLE_LOYALTY_CAMPAIGN_AVAILABILITY_SUCCESSFUL:
            const loyaltyCampaign = (state.loyaltyCampaigns || []).find(
                o => o.id === action.loyaltyCampaign.loyaltyCampaignId
            );
            return {
                ...state,
                loyaltyCampaigns: [
                    ...(state.loyaltyCampaigns || []).filter(
                        o => o.id !== action.loyaltyCampaign.loyaltyCampaignId
                    ),
                    {
                        ...loyaltyCampaign,
                        isActive: action.loyaltyCampaign.changes.isActive,
                    },
                ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
            };
        case DELETE_LOYALTY_CAMPAIGN_SUCCESSFUL:
            const loyaltyCampaign2 = (state.loyaltyCampaigns || []).find(
                o => o.id === action.loyaltyCampaign.loyaltyCampaignId
            );
            return {
                ...state,
                loyaltyCampaigns: [
                    ...(state.loyaltyCampaigns || []).filter(
                        o => o.id !== action.loyaltyCampaign.loyaltyCampaignId
                    ),
                    {
                        ...loyaltyCampaign2,
                        isDeleted: true,
                    },
                ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
            };
        case FETCHED_LOYALTY_PASS:
            return {
                ...state,
                loyaltyPasses: {
                    ...(state.loyaltyPasses || {}),
                    [action.loyaltyPass.passId]: action.loyaltyPass,
                },
            };
        case SUCCESS_CREATING_LOYALTY_PASS_QUALIFICATION:
            return {
                ...state,
                loyaltyPasses: {
                    ...(state.loyaltyPasses || {}),
                    [action.passId]: {
                        ...state.loyaltyPasses[action.passId],
                        loyaltyPassRedemptionsCount:
                            state.loyaltyPasses[action.passId]
                                .loyaltyPassRedemptionsCount + 1,
                    },
                },
                qualifySuccess: {
                    ...(state.qualifySuccess || {}),
                    [action.passId]: true,
                },
                cancelError: {
                    ...(state.cancelError || {}),
                    [action.passId]: null,
                },
                cancelSuccess: {
                    ...(state.cancelSuccess || {}),
                    [action.passId]: false,
                },
                redeemSuccess: {
                    ...(state.redeemSuccess || {}),
                    [action.passId]: false,
                },
                redeemFreeSuccess: {
                    ...(state.redeemFreeSuccess || {}),
                    [action.passId]: false,
                },
                redeemError: {
                    ...(state.redeemSuccess || {}),
                    [action.passId]: false,
                },
                qualifyError: {
                    ...(state.qualifyError || {}),
                    [action.passId]: null,
                },
            };
        case SUCCESS_CREATING_LOYALTY_PASS_CANCELLATION:
            return {
                ...state,
                loyaltyPasses: {
                    ...(state.loyaltyPasses || {}),
                    [action.passId]: {
                        ...state.loyaltyPasses[action.passId],
                        loyaltyPassRedemptionsCount:
                            state.loyaltyPasses[action.passId]
                                .loyaltyPassRedemptionsCount - 1,
                    },
                },
                cancelSuccess: {
                    ...(state.cancelSuccess || {}),
                    [action.passId]: true,
                },
                cancelError: {
                    ...(state.cancelError || {}),
                    [action.passId]: null,
                },
                redeemSuccess: {
                    ...(state.redeemSuccess || {}),
                    [action.passId]: false,
                },
                redeemFreeSuccess: {
                    ...(state.redeemFreeSuccess || {}),
                    [action.passId]: false,
                },
                redeemError: {
                    ...(state.redeemSuccess || {}),
                    [action.passId]: false,
                },
                qualifyError: {
                    ...(state.qualifyError || {}),
                    [action.passId]: null,
                },
                qualifySuccess: {
                    ...(state.qualifySuccess || {}),
                    [action.passId]: false,
                },
            };
        case SUCCESS_CREATING_LOYALTY_PASS_REDEMPTION:
            return {
                ...state,
                loyaltyPasses: {
                    ...(state.loyaltyPasses || {}),
                    [action.passId]: {
                        ...state.loyaltyPasses[action.passId],
                        loyaltyPassRedemptionsCount:
                            state.loyaltyPasses[action.passId]
                                .loyaltyPassRedemptionsCount -
                            state.loyaltyPasses[action.passId].loyaltyCampaign
                                .minSpendQuantity,
                        loyaltyPassGiftsCount:
                            (state.loyaltyPasses[action.passId]
                                .loyaltyPassGiftsCount || 0) + 1,
                    },
                },
                redeemSuccess: {
                    ...(state.redeemSuccess || {}),
                    [action.passId]: true,
                },
                redeemFreeSuccess: {
                    ...(state.redeemFreeSuccess || {}),
                    [action.passId]: false,
                },
                redeemError: {
                    ...(state.redeemSuccess || {}),
                    [action.passId]: false,
                },
                qualifyError: {
                    ...(state.qualifyError || {}),
                    [action.passId]: null,
                },
                qualifySuccess: {
                    ...(state.qualifySuccess || {}),
                    [action.passId]: false,
                },
                cancelError: {
                    ...(state.cancelError || {}),
                    [action.passId]: null,
                },
                cancelSuccess: {
                    ...(state.cancelSuccess || {}),
                    [action.passId]: false,
                },
            };
        case SUCCESS_CREATING_FREE_LOYALTY_PASS_REDEMPTION:
            return {
                ...state,
                loyaltyPasses: {
                    ...(state.loyaltyPasses || {}),
                    [action.passId]: {
                        ...state.loyaltyPasses[action.passId],
                        loyaltyPassRedemptionsCount:
                            (state.loyaltyPasses[action.passId]
                                .loyaltyPassRedemptionsCount || 0) +
                            parseInt(
                                (
                                    state.loyaltyPasses[action.passId]
                                        .loyaltyCampaign || {}
                                ).minSpendQuantity,
                                10
                            ),
                    },
                },
                redeemSuccess: {
                    ...(state.redeemSuccess || {}),
                    [action.passId]: false,
                },
                redeemFreeSuccess: {
                    ...(state.redeemFreeSuccess || {}),
                    [action.passId]: true,
                },
                redeemError: {
                    ...(state.redeemSuccess || {}),
                    [action.passId]: false,
                },
                qualifyError: {
                    ...(state.qualifyError || {}),
                    [action.passId]: null,
                },
                qualifySuccess: {
                    ...(state.qualifySuccess || {}),
                    [action.passId]: false,
                },
                cancelError: {
                    ...(state.cancelError || {}),
                    [action.passId]: null,
                },
                cancelSuccess: {
                    ...(state.cancelSuccess || {}),
                    [action.passId]: false,
                },
            };
        case ERROR_CREATING_LOYALTY_PASS_REDEMPTION:
        case ERROR_CREATING_FREE_LOYALTY_PASS_REDEMPTION:
            return {
                ...state,
                redeemError: {
                    ...(state.redeemSuccess || {}),
                    [action.passId]: action.error,
                },
                redeemFreeSuccess: {
                    ...(state.redeemFreeSuccess || {}),
                    [action.passId]: false,
                },
                redeemSuccess: {
                    ...(state.redeemSuccess || {}),
                    [action.passId]: false,
                },
                qualifyError: {
                    ...(state.qualifyError || {}),
                    [action.passId]: null,
                },
                qualifySuccess: {
                    ...(state.qualifySuccess || {}),
                    [action.passId]: false,
                },
                cancelError: {
                    ...(state.cancelError || {}),
                    [action.passId]: null,
                },
                cancelSuccess: {
                    ...(state.cancelSuccess || {}),
                    [action.passId]: false,
                },
            };
        case ERROR_CREATING_LOYALTY_PASS_QUALIFICATION:
            return {
                ...state,
                qualifyError: {
                    ...(state.qualifyError || {}),
                    [action.passId]: action.error,
                },
                qualifySuccess: {
                    ...(state.qualifySuccess || {}),
                    [action.passId]: false,
                },
                redeemError: {
                    ...(state.redeemSuccess || {}),
                    [action.passId]: null,
                },
                redeemFreeSuccess: {
                    ...(state.redeemFreeSuccess || {}),
                    [action.passId]: false,
                },
                redeemSuccess: {
                    ...(state.redeemSuccess || {}),
                    [action.passId]: false,
                },
                cancelError: {
                    ...(state.cancelError || {}),
                    [action.passId]: null,
                },
                cancelSuccess: {
                    ...(state.cancelSuccess || {}),
                    [action.passId]: false,
                },
            };
        case ERROR_CREATING_LOYALTY_PASS_CANCELLATION:
            return {
                ...state,
                cancelError: {
                    ...(state.cancelError || {}),
                    [action.passId]: action.error,
                },
                cancelSuccess: {
                    ...(state.cancelSuccess || {}),
                    [action.passId]: false,
                },
                qualifyError: {
                    ...(state.qualifyError || {}),
                    [action.passId]: null,
                },
                qualifySuccess: {
                    ...(state.qualifySuccess || {}),
                    [action.passId]: false,
                },
                redeemError: {
                    ...(state.redeemSuccess || {}),
                    [action.passId]: null,
                },
                redeemFreeSuccess: {
                    ...(state.redeemFreeSuccess || {}),
                    [action.passId]: false,
                },
                redeemSuccess: {
                    ...(state.redeemSuccess || {}),
                    [action.passId]: false,
                },
            };
        case SUBSCRIBE_LOYALTY_CAMPAIGN_SUCCESSFUL:
            return {
                ...state,
                subscribeSuccess: true,
                subscribeError: false,
            };
        case ERROR_SUBSCRIBING_LOYALTY_CAMPAIGN:
            return {
                ...state,
                subscribeSuccess: false,
                subscribeError: action.error,
            };
        case ERROR_FETCHING_LOYALTY_CAMPAIGNS:
            return state;
        default:
            return state;
    }
};

export default WalletPassReducer;
