import {
    SIGN_IN_TO_ROOM_PLUS_SUCCESSFUL,
    ERROR_SIGNING_IN_TO_ROOM_PLUS,
    FETCHED_ROOM_PLUS_USER,
    ERROR_FETCHING_ROOM_PLUS_USER,
    FETCHED_AMENITY_REQUESTS,
    CREATE_AMENITY_REQUEST_SUCCESSFUL,
    LOADING,
    CREATE_AMENITY_REQUEST,
    ERROR_CREATING_AMENITY_REQUEST,
    FETCHED_USER_CHAT,
    CREATE_NEW_USER_MESSAGE_SUCCESSFUL,
} from '../types';

const initialState = {};

const hotelUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADING:
            if (action.key === CREATE_AMENITY_REQUEST) {
                return {
                    ...state,
                    amenityRequestAdded: false,
                    amenityRequestError: null,
                };
            }
            return state;
        case SIGN_IN_TO_ROOM_PLUS_SUCCESSFUL:
            return action.data && action.data.user
                ? {
                      ...state,
                      user: action.data ? action.data.user : {},
                      customerFetchError: null,
                      roomNumber: action.data ? action.data.roomNumber : null,
                  }
                : state;

        case FETCHED_ROOM_PLUS_USER:
            return {
                ...state,
                user: action.data ? action.data.user : {},
            };
        case FETCHED_AMENITY_REQUESTS:
            return {
                ...state,
                amenityRequests: action.amenityRequests.amenityRequests.sort(
                    (a, b) => (a.createdAt > b.createdAt ? -1 : 1)
                ),
            };
        case FETCHED_USER_CHAT:
            return {
                ...state,
                chat: {
                    ...action.chat,
                    chatMessages: action.chat.chatMessages.sort((a, b) =>
                        a.createdAt > b.createdAt ? 1 : -1
                    ),
                },
            };
        case CREATE_NEW_USER_MESSAGE_SUCCESSFUL:
            return {
                ...state,
                chat: {
                    ...action.chat,
                    chatMessages: [
                        ...(state.chat.chatMessages || []),
                        {
                            ...action.message,
                            createdAt: new Date().toISOString(),
                        },
                    ].sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1)),
                },
            };
        case CREATE_AMENITY_REQUEST_SUCCESSFUL:
            return {
                ...state,
                amenityRequests: [
                    ...state.amenityRequests,
                    {
                        ...action.amenityRequest,
                        amenityRequestItems: action.amenityRequestItems,
                    },
                ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
                amenityRequestAdded: true,
                amenityRequestError: null,
            };
        case ERROR_CREATING_AMENITY_REQUEST:
            return { ...state, amenityRequestError: action.error };
        case ERROR_FETCHING_ROOM_PLUS_USER:
            return {
                ...state,
                customerFetchError: action.error,
            };
        case ERROR_SIGNING_IN_TO_ROOM_PLUS:
            return {
                ...state,
                signInError: action.error,
            };
        // case LOG_OUT:
        //     return {
        //         ...state,
        //         user: null,
        //     };
        default:
            return state;
    }
};

export default hotelUserReducer;
