import ReactGA from 'react-ga4';

const CURR_ENV =
    process.env.REACT_APP_API_URL === 'https://hot-table-app.herokuapp.com'
        ? 'production'
        : process.env.REACT_APP_API_URL === 'http://84.8.114.174:3000'
        ? 'mena'
        : process.env.REACT_APP_API_URL ===
          'https://safe-anchorage-44320.herokuapp.com'
        ? 'staging'
        : null;

const MEASUREMENT_ID =
    CURR_ENV === 'staging'
        ? 'G-RPLQ5L5KYX'
        : CURR_ENV === 'production'
        ? 'G-9NGYSFHPMN'
        : CURR_ENV === 'mena'
        ? 'G-VB4RX9J5EW'
        : null;
if (CURR_ENV) {
    // console.log('INITIALIZING GOOGLE ANALYTICS', MEASUREMENT_ID);
    ReactGA.initialize(MEASUREMENT_ID);
}

export default ReactGA;
