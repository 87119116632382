import {
    CREATE_MODIFIER_GROUP_SUCCESSFUL,
    CREATE_MODIFIER_GROUP_FAILED,
    FETCHED_MODIFIER_GROUPS,
    ERROR_FETCHING_MODIFIER_GROUPS,
    DELETE_MODIFIER_GROUP_SUCCESSFUL,
    DELETE_MODIFIER_GROUP_FAILED,
    ADD_MODIFIER_SUCCESSFUL,
    ERROR_ADDING_MODIFIER,
    EDIT_MODIFIER_SUCCESSFUL,
    TOGGLE_MODIFIER_AVAILABILITY_SUCCESSFUL,
    DELETE_MODIFIER_SUCCESSFUL,
    EDIT_MODIFIER_GROUP_SUCCESSFUL,
} from '../types';

const initialState = {};

const modifierGroupReducer = (state = initialState, action) => {
    switch (action.type) {
        case DELETE_MODIFIER_GROUP_SUCCESSFUL:
            return {
                ...state,
                modifierGroups: [
                    ...state.modifierGroups.filter(
                        s => s.id !== parseInt(action.data.modifierId, 10)
                    ),
                ].sort((a, b) =>
                    a.createdAt === b.createdAt
                        ? a.updatedAt > b.updatedAt
                            ? -1
                            : 1
                        : a.createdAt > b.createdAt
                        ? -1
                        : 1
                ),
            };
        case DELETE_MODIFIER_GROUP_FAILED:
            return state;
        case CREATE_MODIFIER_GROUP_SUCCESSFUL:
            return {
                ...state,
                modifierGroups: [
                    ...state.modifierGroups,
                    { ...action.data.modifierGroup, modifiers: [] },
                ].sort((a, b) =>
                    a.createdAt === b.createdAt
                        ? a.updatedAt > b.updatedAt
                            ? -1
                            : 1
                        : a.createdAt > b.createdAt
                        ? -1
                        : 1
                ),
            };
        case CREATE_MODIFIER_GROUP_FAILED:
            return {
                ...state,
                createSectionError: action.error,
            };
        case FETCHED_MODIFIER_GROUPS:
            return {
                ...state,
                modifierGroups: action.modifierGroups.sort((a, b) =>
                    a.createdAt === b.createdAt
                        ? a.updatedAt > b.updatedAt
                            ? -1
                            : 1
                        : a.createdAt > b.createdAt
                        ? -1
                        : 1
                ),
            };
        case ERROR_FETCHING_MODIFIER_GROUPS:
            return {
                ...state,
                error: action.error,
            };
        case EDIT_MODIFIER_GROUP_SUCCESSFUL:
            return {
                ...state,
                modifierGroups: [
                    ...state.modifierGroups.filter(
                        g => g.id !== parseInt(action.data.id, 10)
                    ),
                    {
                        ...state.modifierGroups.find(
                            g => g.id === parseInt(action.data.id, 10)
                        ),
                        id: parseInt(action.data.id, 10),
                        min: parseInt(action.data.changes.min, 10),
                        max: parseInt(action.data.changes.max, 10),
                        deliverectMultiMax:
                            parseInt(
                                action.data.changes.deliverectMultiMax,
                                10
                            ) || null,
                        name: action.data.changes.name,
                    },
                ].sort((a, b) =>
                    a.createdAt === b.createdAt
                        ? a.updatedAt > b.updatedAt
                            ? -1
                            : 1
                        : a.createdAt > b.createdAt
                        ? -1
                        : 1
                ),
            };

        case ADD_MODIFIER_SUCCESSFUL:
            const group = state.modifierGroups.find(
                g => g.id === action.item.modifierGroupId
            );
            return {
                ...state,
                modifierGroups: [
                    ...state.modifierGroups.filter(
                        g => g.id !== action.item.modifierGroupId
                    ),
                    {
                        ...group,
                        modifiers: [
                            ...(group.modifiers || []),
                            { ...action.item },
                        ].sort((a, b) =>
                            a.createdAt === b.createdAt
                                ? a.updatedAt > b.updatedAt
                                    ? -1
                                    : 1
                                : a.createdAt > b.createdAt
                                ? -1
                                : 1
                        ),
                    },
                ].sort((a, b) =>
                    a.createdAt === b.createdAt
                        ? a.updatedAt > b.updatedAt
                            ? -1
                            : 1
                        : a.createdAt > b.createdAt
                        ? -1
                        : 1
                ),
            };
        case ERROR_ADDING_MODIFIER:
            // fix this
            return {
                ...state,
                createModifierError: action.error,
            };
        case EDIT_MODIFIER_SUCCESSFUL:
        case TOGGLE_MODIFIER_AVAILABILITY_SUCCESSFUL:
            const section2 = state.modifierGroups.find(
                section => section.id === action.item.sectionId
            );
            const item = section2.modifiers.find(
                menu => menu.id === action.item.itemId
            );
            return {
                ...state,
                modifierGroups: [
                    ...state.modifierGroups.filter(
                        section => section.id !== action.item.sectionId
                    ),
                    {
                        ...section2,
                        modifiers: [
                            ...section2.modifiers.filter(
                                item => item.id !== action.item.itemId
                            ),
                            { ...item, ...action.item.changes },
                        ].sort((a, b) =>
                            a.createdAt === b.createdAt
                                ? a.updatedAt > b.updatedAt
                                    ? -1
                                    : 1
                                : a.createdAt > b.createdAt
                                ? -1
                                : 1
                        ),
                    },
                ].sort((a, b) =>
                    a.createdAt === b.createdAt
                        ? a.updatedAt > b.updatedAt
                            ? -1
                            : 1
                        : a.createdAt > b.createdAt
                        ? -1
                        : 1
                ),
            };
        case DELETE_MODIFIER_SUCCESSFUL:
            const s = state.modifierGroups.find(
                s => s.id === parseInt(action.data.sectionId, 10)
            );
            return {
                ...state,
                modifierGroups: [
                    ...state.modifierGroups.filter(
                        s => s.id !== parseInt(action.data.sectionId, 10)
                    ),
                    {
                        ...s,
                        modifiers: [
                            ...s.modifiers.filter(
                                i => i.id !== parseInt(action.data.itemId, 10)
                            ),
                        ],
                    },
                ].sort((a, b) =>
                    a.createdAt === b.createdAt
                        ? a.updatedAt > b.updatedAt
                            ? -1
                            : 1
                        : a.createdAt > b.createdAt
                        ? -1
                        : 1
                ),
            };
        default:
            return state;
    }
};

export default modifierGroupReducer;
