import {
    FETCHED_OFFERS,
    ERROR_FETCHING_OFFERS,
    TOGGLE_OFFER_AVAILABILITY_SUCCESSFUL,
    DELETE_OFFER_SUCCESSFUL,
    FETCHED_PUBLIC_OFFERS,
    ADD_OFFER_SUCCESSFUL,
    SIGN_UP_LOYALTY_USER_SUCCESSFUL,
    ERROR_SIGNING_UP_LOYALTY_USER,
    FETCH_LOYALTY_USER_INFO_SUCCESSFUL,
    ERROR_FETCHING_LOYALTY_USER_INFO,
    FETCH_PLATFORM_RESTAURANTS_SUCCESSFUL,
    ERROR_REDEEMING_LOYALTY_DISCOUNT,
    REDEEM_LOYALTY_DISCOUNT_SUCCESSFUL,
} from '../types';

const initialState = {};

const offersReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCHED_OFFERS:
            return {
                ...state,
                offers: action.offers.sort((a, b) =>
                    a.createdAt > b.createdAt ? -1 : 1
                ),
            };
        case ADD_OFFER_SUCCESSFUL:
            return {
                ...state,
                offers: [...(state.offers || []), action.item].sort((a, b) =>
                    a.createdAt > b.createdAt ? -1 : 1
                ),
            };
        case FETCH_PLATFORM_RESTAURANTS_SUCCESSFUL:
            return {
                ...state,
                appRestaurants: action.data,
            };
        case ERROR_REDEEMING_LOYALTY_DISCOUNT:
            return {
                ...state,
                errorRedeemingDiscount: true,
                redeemDiscountSuccess: false,
            };
        case REDEEM_LOYALTY_DISCOUNT_SUCCESSFUL:
            return {
                ...state,
                errorRedeemingDiscount: false,
                redeemDiscountSuccess: true,
            };
        case SIGN_UP_LOYALTY_USER_SUCCESSFUL:
            return {
                ...state,
                signUpLoyaltySuccess: true,
            };
        case ERROR_SIGNING_UP_LOYALTY_USER:
            return {
                ...state,
                signUpLoyaltySuccess: false,
                signUpLoyaltyUserError: action.error,
            };
        case FETCH_LOYALTY_USER_INFO_SUCCESSFUL:
            return {
                ...state,
                signUpLoyaltyUser: action.data,
                errorFetchingLoyaltyUser: false,
            };
        case ERROR_FETCHING_LOYALTY_USER_INFO:
            return {
                ...state,
                errorFetchingLoyaltyUser: true,
            };
        case FETCHED_PUBLIC_OFFERS:
            return {
                ...state,
                publicOffers: {
                    ...(state.publicOffers || []),
                    [action.restaurantId]: action.offers,
                },
                discountOffers: {
                    ...(state.discountOffers || []),
                    [action.restaurantId]: action.discountOffers,
                },
            };
        case ERROR_FETCHING_OFFERS:
            return {
                ...state,
                error: action.error,
            };
        case TOGGLE_OFFER_AVAILABILITY_SUCCESSFUL:
            const offer = (state.offers || []).find(
                o => o.id === action.offer.offerId
            );
            return {
                ...state,
                offers: [
                    ...(state.offers || []).filter(
                        o => o.id !== action.offer.offerId
                    ),
                    {
                        ...offer,
                        isActive: action.offer.changes.isActive,
                    },
                ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
            };
        case DELETE_OFFER_SUCCESSFUL:
            const offer2 = (state.offers || []).find(
                o => o.id === action.offer.offerId
            );
            return {
                ...state,
                offers: [
                    ...(state.offers || []).filter(
                        o => o.id !== action.offer.offerId
                    ),
                    {
                        ...offer2,
                        isDeleted: true,
                    },
                ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
            };
        default:
            return state;
    }
};

export default offersReducer;
