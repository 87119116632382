import { END_LOADING, LOADING, DELETE_LOADING } from '../types';

export const loading = key => ({ type: LOADING, key });
export const endLoading = key => ({ type: END_LOADING, key });

const deleteLoadingAction = key => ({ type: DELETE_LOADING, key });

const deleteLoading = key => async dispatch => {
    dispatch(deleteLoadingAction(key));
};

export const actions = { deleteLoading };
