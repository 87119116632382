import { FETCHED_PAY_LATER, ERROR_FETCHING_PAY_LATER } from '../types';

const initialState = {};

const payLaterReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCHED_PAY_LATER:
            return {
                ...state,
                payLater: action.payLater,
            };
        case ERROR_FETCHING_PAY_LATER:
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
};

export default payLaterReducer;
