import {
    RESET_PASSWORD_EMAIL_SUCCESSFUL,
    ERROR_RESETTING_PASSWORD_EMAIL,
    RESET_PASSWORD_EMAIL,
    LOADING,
    ERROR_RESETTING_PASSWORD,
    RESET_PASSWORD_SUCCESSFUL,
} from '../types';

const initialState = {};

const resetPasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADING:
            if (action.key === RESET_PASSWORD_EMAIL) {
                return {
                    ...state,
                    resetPasswordComplete: false,
                    resetPasswordError: null,
                };
            }
            return state;
        case RESET_PASSWORD_EMAIL_SUCCESSFUL:
            return {
                ...state,
                resetPasswordError: null,
                resetPasswordComplete: true,
            };
        case ERROR_RESETTING_PASSWORD_EMAIL:
            return {
                ...state,
                resetPasswordError: action.error,
                resetPasswordComplete: false,
            };
        case ERROR_RESETTING_PASSWORD:
            return {
                ...state,
                resetPasswordConfirmError: action.error,
                resetPasswordComplete: false,
            };
        case RESET_PASSWORD_SUCCESSFUL:
            return {
                ...state,
                resetPasswordConfirmError: null,
                resetPasswordComplete: true,
            };
        default:
            return state;
    }
};

export default resetPasswordReducer;
