export default {
    en: {
        your_order: 'Your Order',
        any_allergies_short: 'Any Allergies or Notes?',
        your_total_colon: 'Your Total:',
        including_fee: '(Incl. Order Fee)',
        pay_now: 'Pay Now',
        please_select_required_modifiers:
            'Please select the required customisations for',
        charge: 'Charge',
        to_room: 'To My Room',
        pay: 'Pay',
        later: 'Later',
        guests_only: '(Guests Only)',
        dismiss: 'Dismiss',
        minimum_order_value: 'Minimum order value for delivery orders: ',
    },
    es: {
        your_order: 'Tu Pedido',
        any_allergies_short: '¿Alergias o Notas?',
        your_total_colon: 'Tu Total:',
        including_fee: '(Incl. Tarifa de Pedido)',
        pay_now: 'Pagar Ahora',
        please_select_required_modifiers:
            'Por favor, selecciona las personalizaciones requeridas para',
        charge: 'Cargar',
        to_room: 'A Mi Habitación',
        pay: 'Pagar',
        later: 'Más Tarde',
        guests_only: '(Solo para Huéspedes)',
        dismiss: 'Descartar',
        minimum_order_value: 'Valor mínimo del pedido para entregas: ',
    },
    zh: {
        your_order: '您的订单',
        any_allergies_short: '任何过敏或备注？',
        your_total_colon: '您的总计：',
        including_fee: '（含订单费用）',
        pay_now: '立即支付',
        please_select_required_modifiers: '请选择所需的定制项',
        charge: '收费',
        to_room: '到我的房间',
        pay: '支付',
        later: '稍后',
        guests_only: '（仅限客人）',
        dismiss: '解散',
        minimum_order_value: '送货订单的最低订单金额：',
    },
    ar: {
        your_order: 'طلبك',
        any_allergies_short: 'أي حساسية أو ملاحظات؟',
        your_total_colon: 'إجمالي الخاص بك:',
        including_fee: '(يشمل رسوم الطلب)',
        pay_now: 'ادفع الآن',
        please_select_required_modifiers: 'يرجى تحديد التخصيصات المطلوبة لـ',
        charge: 'الشحن',
        to_room: 'إلى غرفتي',
        pay: 'ادفع',
        later: 'لاحقًا',
        guests_only: '(للضيوف فقط)',
        dismiss: 'رفض',
        minimum_order_value: 'الحد الأدنى لقيمة الطلب للتوصيل: ',
    },
    ru: {
        your_order: 'Ваш заказ',
        any_allergies_short: 'Любые аллергии или примечания?',
        your_total_colon: 'Ваш итог:',
        including_fee: '(Включая плату за заказ)',
        pay_now: 'Заплатить сейчас',
        please_select_required_modifiers:
            'Пожалуйста, выберите необходимые настройки для',
        charge: 'Заряд',
        to_room: 'В мою комнату',
        pay: 'Платить',
        later: 'Позже',
        guests_only: '(Только для гостей)',
        dismiss: 'Отклонить',
        minimum_order_value: 'Минимальная стоимость заказа для доставки: ',
    },
    ja: {
        your_order: 'あなたの注文',
        any_allergies_short: 'アレルギーやメモはありますか？',
        your_total_colon: 'あなたの合計：',
        including_fee: '（注文料込み）',
        pay_now: '今すぐ支払う',
        please_select_required_modifiers:
            '必要なカスタマイズを選択してください',
        charge: '充電',
        to_room: '私の部屋へ',
        pay: '支払う',
        later: '後で',
        guests_only: '（ゲストのみ）',
        dismiss: '閉じる',
        minimum_order_value: '配達注文の最低注文額：',
    },
    ko: {
        your_order: '당신의 주문',
        any_allergies_short: '알레르기 또는 메모가 있습니까?',
        your_total_colon: '총액:',
        including_fee: '(주문 수수료 포함)',
        pay_now: '지금 지불',
        please_select_required_modifiers: '필요한 사용자 지정을 선택하십시오',
        charge: '청구',
        to_room: '내 방으로',
        pay: '지불',
        later: '나중에',
        guests_only: '(게스트 전용)',
        dismiss: '닫기',
        minimum_order_value: '배달 주문의 최소 주문 금액: ',
    },
    de: {
        your_order: 'Ihre Bestellung',
        any_allergies_short: 'Irgendwelche Allergien oder Anmerkungen?',
        your_total_colon: 'Ihre Gesamtsumme:',
        including_fee: '(Inkl. Bestellgebühr)',
        pay_now: 'Jetzt bezahlen',
        please_select_required_modifiers:
            'Bitte wählen Sie die erforderlichen Anpassungen für',
        charge: 'Belasten',
        to_room: 'Zu meinem Zimmer',
        pay: 'Bezahlen',
        later: 'Später',
        guests_only: '(Nur für Gäste)',
        dismiss: 'Verwerfen',
        minimum_order_value: 'Mindestbestellwert für Lieferbestellungen: ',
    },
    fr: {
        your_order: 'Votre commande',
        any_allergies_short: 'Des allergies ou des notes?',
        your_total_colon: 'Votre Total :',
        including_fee: '(Incl. Frais de Commande)',
        pay_now: 'Payer maintenant',
        please_select_required_modifiers:
            'Veuillez sélectionner les personnalisations requises pour',
        charge: 'Facturer',
        to_room: 'À ma chambre',
        pay: 'Payer',
        later: 'Plus tard',
        guests_only: '(Réservé aux clients)',
        dismiss: 'Rejeter',
        minimum_order_value:
            'Valeur minimale de commande pour les livraisons : ',
    },
    pl: {
        your_order: 'Twoje zamówienie',
        any_allergies_short: 'Jakiekolwiek alergie lub notatki?',
        your_total_colon: 'Twój Całkowity:',
        including_fee: '(W tym opłata za zamówienie)',
        pay_now: 'Zapłać teraz',
        please_select_required_modifiers:
            'Proszę wybrać wymagane dostosowania dla',
        charge: 'Obciążyć',
        to_room: 'Do mojego pokoju',
        pay: 'Zapłacić',
        later: 'Później',
        guests_only: '(Tylko dla gości)',
        dismiss: 'Odrzucić',
        minimum_order_value: 'Minimalna wartość zamówienia na dostawę: ',
    },
    pt: {
        your_order: 'Seu pedido',
        any_allergies_short: 'Alguma alergia ou nota?',
        your_total_colon: 'Seu Total:',
        including_fee: '(Incl. Taxa de Pedido)',
        pay_now: 'Pague Agora',
        please_select_required_modifiers:
            'Por favor, selecione as personalizações necessárias para',
        charge: 'Cobrar',
        to_room: 'Para o Meu Quarto',
        pay: 'Pagar',
        later: 'Depois',
        guests_only: '(Somente para Hóspedes)',
        dismiss: 'Dispensar',
        minimum_order_value: 'Valor mínimo do pedido para entregas: ',
    },
    it: {
        your_order: 'Il tuo Ordine',
        any_allergies_short: 'Allergie o Note?',
        your_total_colon: 'Il Tuo Totale:',
        including_fee: "(Incl. Tassa d'Ordine)",
        pay_now: 'Paga Ora',
        please_select_required_modifiers:
            'Si prega di selezionare le personalizzazioni richieste per',
        charge: 'Addebitare',
        to_room: 'Alla Mia Stanza',
        pay: 'Paga',
        later: 'Più Tardi',
        guests_only: '(Solo per Ospiti)',
        dismiss: 'Rifiutare',
        minimum_order_value: "Valore minimo dell'ordine per le consegne: ",
    },
    nl: {
        your_order: 'Uw bestelling',
        any_allergies_short: 'Eventuele allergieën of opmerkingen?',
        your_total_colon: 'Uw Totaal:',
        including_fee: '(Incl. Bestelkosten)',
        pay_now: 'Betaal nu',
        please_select_required_modifiers:
            'Selecteer de vereiste aanpassingen voor',
        charge: 'Opladen',
        to_room: 'Naar mijn kamer',
        pay: 'Betalen',
        later: 'Later',
        guests_only: '(Alleen voor gasten)',
        dismiss: 'Afwijzen',
        minimum_order_value: 'Minimale bestelwaarde voor leveringen: ',
    },
};
