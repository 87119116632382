import React from 'react';
import { withRouter } from 'react-router-dom';
import ReactGA from './reactGA';

const CURR_ENV =
    process.env.REACT_APP_API_URL === 'https://hot-table-app.herokuapp.com'
        ? 'production'
        : process.env.REACT_APP_API_URL ===
          'https://safe-anchorage-44320.herokuapp.com'
        ? 'staging'
        : null;

const RouteChangeTracker = CURR_ENV
    ? ({ history }) => {
          //   console.log('CAPTURING PAGE VIEW EVENT', history);
          history.listen((location, action) => {
              //   console.log('CAPTURING PAGE VIEW EVENT', location.pathname);
              ReactGA.send({ hitType: 'pageview', page: location.pathname });
          });

          return <div />;
      }
    : () => <div />;

export default withRouter(RouteChangeTracker);
