import {
    APP_REGISTRATION_SUCCESSFUL,
    ERROR_REGISTERING_LOYALTY_APP,
    LOADING,
    APP_REGISTRATION,
} from '../types';

const initialState = {};

const appRegistrationReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADING:
            if (action.key === APP_REGISTRATION) {
                return {
                    ...state,
                    success: false,
                    error: null,
                };
            }

            return state;
        case APP_REGISTRATION_SUCCESSFUL:
            return {
                ...state,
                success: true,
                error: null,
            };
        case ERROR_REGISTERING_LOYALTY_APP:
            return {
                ...state,
                error: action.error,
                success: false,
            };
        default:
            return state;
    }
};

export default appRegistrationReducer;
