import {
    FETCHED_BANNER_INSIGHTS,
    FETCHED_DELITEPAY_INSIGHTS,
    FETCHED_ITEM_INSIGHTS,
    FETCHED_MENU_INSIGHTS,
    FETCHED_ORDER_INSIGHTS,
    FETCHED_SECTION_INSIGHTS,
    FETCHED_TIPS_INSIGHTS,
} from '../types';

const initialState = {};

const insightsSummaryReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCHED_MENU_INSIGHTS:
            return {
                ...state,
                insightsSummary: {
                    ...(state.insightsSummary || {}),
                    menuInsights: action.menuInsights,
                },
                from: action.from,
                to: action.to,
            };
        case FETCHED_SECTION_INSIGHTS:
            return {
                ...state,
                insightsSummary: {
                    ...(state.insightsSummary || {}),
                    sectionInsights: action.sectionInsights,
                },
                from: action.from,
                to: action.to,
            };
        case FETCHED_ITEM_INSIGHTS:
            return {
                ...state,
                insightsSummary: {
                    ...(state.insightsSummary || {}),
                    itemInsights: action.itemInsights,
                },
                from: action.from,
                to: action.to,
            };
        case FETCHED_ORDER_INSIGHTS:
            return {
                ...state,
                insightsSummary: {
                    ...(state.insightsSummary || {}),
                    orderInsights: action.orderInsights,
                },
                from: action.from,
                to: action.to,
            };
        case FETCHED_BANNER_INSIGHTS:
            return {
                ...state,
                insightsSummary: {
                    ...(state.insightsSummary || {}),
                    bannerInsights: action.bannerInsights,
                },
                from: action.from,
                to: action.to,
            };
        case FETCHED_DELITEPAY_INSIGHTS:
            return {
                ...state,
                insightsSummary: {
                    ...(state.insightsSummary || {}),
                    delitePayInsights: action.delitePayInsights,
                },
                from: action.from,
                to: action.to,
            };
        case FETCHED_TIPS_INSIGHTS:
            return {
                ...state,
                insightsSummary: {
                    ...(state.insightsSummary || {}),
                    tipsInsights: action.tipsInsights,
                },
                from: action.from,
                to: action.to,
            };
        default:
            return state;
    }
};

export default insightsSummaryReducer;
