import {
    SIGN_IN_SUCCESSFUL,
    SIGN_IN_FAILED,
    FETCHED_CUSTOMER_USER,
    ERROR_FETCHING_CUSTOMER_USER,
    FETCHED_CUSTOMER_USER_OFFERS,
    FETCHED_CUSTOMER_USER_REWARDS,
    LOG_OUT,
} from '../types';

const initialState = {};

const customerUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case SIGN_IN_SUCCESSFUL:
            return action.data && action.data.isCustomerUser
                ? {
                      ...state,
                      user: action.data ? action.data.user : {},
                      customerFetchError: null,
                  }
                : state;
        case FETCHED_CUSTOMER_USER_OFFERS:
            return {
                ...state,
                offers: action.offers,
            };
        case FETCHED_CUSTOMER_USER_REWARDS:
            return {
                ...state,
                rewards: action.rewards,
            };
        case FETCHED_CUSTOMER_USER:
            return {
                ...state,
                user: action.customerUser ? action.customerUser.user : {},
            };
        case ERROR_FETCHING_CUSTOMER_USER:
            return {
                ...state,
                customerFetchError: action.error,
            };
        case SIGN_IN_FAILED:
            return {
                ...state,
                signInError: action.error,
            };
        case LOG_OUT:
            return {
                ...state,
                user: null,
            };
        default:
            return state;
    }
};

export default customerUserReducer;
