import {
    ERROR_FETCHING_DISCOUNT_DAYS,
    FETCHED_DISCOUNT_DAYS,
    EDIT_DISCOUNT_DAY_SUCCESSFUL,
    LOADING,
    EDIT_DISCOUNT_DAY,
    TOGGLE_AVAILABILITY_SUCCESS,
} from '../types';

const initialState = {};

const discountDaysReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADING:
            if (action.key === EDIT_DISCOUNT_DAY) {
                return {
                    ...state,
                    discountDaysSuccess: null,
                };
            }
            return state;
        case FETCHED_DISCOUNT_DAYS:
            return {
                ...state,
                discountDays: action.items,
            };
        case ERROR_FETCHING_DISCOUNT_DAYS:
            return {
                ...state,
                error: action.error,
                discountDaysSuccess: false,
            };
        case TOGGLE_AVAILABILITY_SUCCESS:
            return {
                ...state,
                availabilityDaysSuccess: null,
                discountDaysSuccess: null,
            };
        case EDIT_DISCOUNT_DAY_SUCCESSFUL:
            return {
                ...state,
                discountDays: [
                    ...(state.discountDays || []).filter(
                        d => d.tagId !== action.tagId
                    ),
                    ...action.item.discounts,
                ],
                discountDaysSuccess: true,
                error: null,
            };
        default:
            return state;
    }
};

export default discountDaysReducer;
