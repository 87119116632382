import _ from 'lodash';
import Sentry from '../../Sentry';
import {
    ERROR_FETCHING_ORDERS,
    FETCHED_ORDERS,
    NEW_SOCKET_ORDER,
    MARK_AS_COMPLETED_SUCCESSFUL,
    CANCEL_ORDER_SUCCESSFUL,
    NEW_SOCKET_PAYMENT,
    SEND_ORDER_SMS_SUCCESSFUL,
    SOCKET_ORDER_COMPLETED,
    SOCKET_ORDER_CANCELLED,
    SOCKET_DELIVERECT_ORDER_FAILURE_ACTION,
    ASSIGN_EMPLOYEE_TO_ORDER_SUCCESSFUL,
    SOCKET_EMPLOYEE_ID_ASSIGNED,
    SOCKET_DELIVERY_UPDATE_RECEIVED_ACTION,
    MARK_AS_COMPLETED_SUCCESSFUL_TABLE,
    MARK_AS_COMPLETED_FOR_TABLE,
    LOADING,
    CREATE_CHECKOUT_SESSION,
    TABLE_VIEW_ADD_ITEMS_COMPLETE,
    TABLE_VIEW_COMPLETE_ORDERS_COMPLETE,
    WIPE_TABLE_VIEW_STATUSES,
} from '../types';

const initialState = {};

const ordersReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADING:
            if (action.key === MARK_AS_COMPLETED_FOR_TABLE) {
                return {
                    ...state,
                    markAsCompletedTable: false,
                };
            }
            if (action.key === CREATE_CHECKOUT_SESSION) {
                return {
                    ...state,
                    newSocketOrderSuccess: false,
                };
            }
            return state;
        case TABLE_VIEW_ADD_ITEMS_COMPLETE:
            return {
                ...state,
                newSocketOrderSuccess: true,
            };
        case TABLE_VIEW_COMPLETE_ORDERS_COMPLETE:
            return {
                ...state,
                markAsCompletedTable: true,
            };
        case WIPE_TABLE_VIEW_STATUSES:
            return {
                ...state,
                newSocketOrderSuccess: false,
                markAsCompletedTable: false,
            };
        case FETCHED_ORDERS:
            return {
                ...state,

                orders: _.uniqBy(
                    [...action.orders.order, ...(state.orders || [])],
                    'id'
                ).sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
                orderCounts: {
                    ...(state.orderCounts || {}),
                    [action.status]: action.orders.orderCount,
                },
                filteredOrders:
                    action.filterOptions && action.filterOptions.length > 0
                        ? {
                              ...state.filteredOrders,
                              [action.filterOptions]: _.uniqBy(
                                  [
                                      ...action.orders.order,
                                      ...((state.filteredOrders || {})[
                                          action.filterOptions
                                      ] || []),
                                  ],
                                  'id'
                              ).sort((a, b) =>
                                  a.createdAt > b.createdAt ? -1 : 1
                              ),
                          }
                        : null,
            };
        case MARK_AS_COMPLETED_SUCCESSFUL_TABLE:
            const orderFound00 = (state.orders || []).find(
                o => o.restaurantTableId === action.tableId
            );

            const filteredOrderKeysNeedingUpdate00 = Object.keys(
                state.filteredOrders || {}
            )
                .map(key => {
                    if (
                        state.filteredOrders[key].find(
                            o => o.restaurantTableId === action.tableId
                        )
                    ) {
                        return key;
                    }
                    return null;
                })
                .filter(k => k);
            const newFilteredOrders00 = state.filteredOrders;
            if (
                filteredOrderKeysNeedingUpdate00 &&
                filteredOrderKeysNeedingUpdate00.length > 0
            ) {
                filteredOrderKeysNeedingUpdate00.forEach(key => {
                    newFilteredOrders00[key] = _.uniqBy(
                        [
                            {
                                ...(newFilteredOrders00[key] || []).find(
                                    o => o.restaurantTableId === action.tableId
                                ),
                                status: 'completed',
                                completedTime: new Date().toISOString(),
                                start: null,
                                end: null,
                            },
                            ...(newFilteredOrders00[key] || [])
                                .filter(
                                    o =>
                                        o.restaurantTableId !==
                                            action.tableId &&
                                        o.status === 'completed'
                                )
                                .map(o => ({ ...o, start: null, end: null })),
                            ...(newFilteredOrders00[key] || []).filter(
                                o =>
                                    o.restaurantTableId !== action.tableId &&
                                    o.status !== 'completed'
                            ),
                        ],
                        'id'
                    ).sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
                });
            }
            return orderFound00 && orderFound00.status === 'new'
                ? {
                      ...state,
                      orders: _.uniqBy(
                          [
                              {
                                  ...(state.orders || []).find(
                                      o =>
                                          o.restaurantTableId === action.tableId
                                  ),
                                  status: 'completed',
                                  completedTime: new Date().toISOString(),
                                  start: null,
                                  end: null,
                              },
                              ...(state.orders || []).filter(
                                  o =>
                                      o.restaurantTableId !== action.tableId &&
                                      o.status !== 'completed'
                              ),
                              ...(state.orders || [])
                                  .filter(
                                      o =>
                                          o.restaurantTableId !==
                                              action.tableId &&
                                          o.status === 'completed'
                                  )
                                  .map(o => ({ ...o, start: null, end: null })),
                          ],
                          'id'
                      ).sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
                      filteredOrders: newFilteredOrders00,
                      orderCounts: {
                          ...(state.orderCounts || {}),
                          new: Math.max((state.orderCounts.new || 0) - 1, 0),
                          completed: (state.orderCounts.completed || 0) + 1,
                      },
                  }
                : state;
        case MARK_AS_COMPLETED_SUCCESSFUL:
        case SOCKET_ORDER_COMPLETED:
            const orderFound = (state.orders || []).find(
                o => o.id === action.orderId
            );

            const filteredOrderKeysNeedingUpdate = Object.keys(
                state.filteredOrders || {}
            )
                .map(key => {
                    if (
                        state.filteredOrders[key].find(
                            o => o.id === action.orderId
                        )
                    ) {
                        return key;
                    }
                    return null;
                })
                .filter(k => k);
            const newFilteredOrders = state.filteredOrders;
            if (
                filteredOrderKeysNeedingUpdate &&
                filteredOrderKeysNeedingUpdate.length > 0
            ) {
                filteredOrderKeysNeedingUpdate.forEach(key => {
                    newFilteredOrders[key] = _.uniqBy(
                        [
                            {
                                ...(newFilteredOrders[key] || []).find(
                                    o => o.id === action.orderId
                                ),
                                status: 'completed',
                                completedTime: new Date().toISOString(),
                                start: null,
                                end: null,
                            },
                            ...(newFilteredOrders[key] || [])
                                .filter(
                                    o =>
                                        o.id !== action.orderId &&
                                        o.status === 'completed'
                                )
                                .map(o => ({ ...o, start: null, end: null })),
                            ...(newFilteredOrders[key] || []).filter(
                                o =>
                                    o.id !== action.orderId &&
                                    o.status !== 'completed'
                            ),
                        ],
                        'id'
                    ).sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
                });
            }
            return orderFound && orderFound.status === 'new'
                ? {
                      ...state,
                      orders: _.uniqBy(
                          [
                              {
                                  ...(state.orders || []).find(
                                      o => o.id === action.orderId
                                  ),
                                  status: 'completed',
                                  completedTime: new Date().toISOString(),
                                  start: null,
                                  end: null,
                              },
                              ...(state.orders || []).filter(
                                  o =>
                                      o.id !== action.orderId &&
                                      o.status !== 'completed'
                              ),
                              ...(state.orders || [])
                                  .filter(
                                      o =>
                                          o.id !== action.orderId &&
                                          o.status === 'completed'
                                  )
                                  .map(o => ({ ...o, start: null, end: null })),
                          ],
                          'id'
                      ).sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
                      filteredOrders: newFilteredOrders,
                      orderCounts: {
                          ...(state.orderCounts || {}),
                          new: Math.max((state.orderCounts.new || 0) - 1, 0),
                          completed: (state.orderCounts.completed || 0) + 1,
                      },
                  }
                : state;
        case SEND_ORDER_SMS_SUCCESSFUL:
            return {
                ...state,
                orders: _.uniqBy(
                    [
                        ...(state.orders || []).filter(
                            o => o.id !== action.orderId
                        ),
                        {
                            ...(state.orders || []).find(
                                o => o.id === action.orderId
                            ),
                            smsSent: true,
                        },
                    ],
                    'id'
                ).sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
            };
        case CANCEL_ORDER_SUCCESSFUL:
        case SOCKET_ORDER_CANCELLED:
            const orderFound2 = (state.orders || []).find(
                o => o.id === parseInt(action.orderId.orderId, 10)
            );
            const filteredOrderKeysNeedingUpdate2 = Object.keys(
                state.filteredOrders || {}
            )
                .map(key => {
                    if (
                        state.filteredOrders[key].find(
                            o => o.id === parseInt(action.orderId.orderId, 10)
                        )
                    ) {
                        return key;
                    }
                    return null;
                })
                .filter(k => k);
            const newFilteredOrders2 = state.filteredOrders;

            if (
                filteredOrderKeysNeedingUpdate2 &&
                filteredOrderKeysNeedingUpdate2.length > 0
            ) {
                filteredOrderKeysNeedingUpdate2.forEach(key => {
                    newFilteredOrders2[key] = _.uniqBy(
                        [
                            ...(newFilteredOrders2[key] || [])
                                .filter(
                                    o =>
                                        o.id !==
                                            parseInt(
                                                action.orderId.orderId,
                                                10
                                            ) && o.status === 'cancelled'
                                )
                                .map(o => ({ ...o, start: null, end: null })),
                            ...(newFilteredOrders2[key] || []).filter(
                                o =>
                                    o.id !==
                                        parseInt(action.orderId.orderId, 10) &&
                                    o.status !== 'cancelled'
                            ),
                            {
                                ...(newFilteredOrders2[key] || []).find(
                                    o =>
                                        o.id ===
                                        parseInt(action.orderId.orderId, 10)
                                ),
                                status: 'cancelled',
                                start: null,
                                end: null,
                            },
                        ],
                        'id'
                    ).sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
                });
            }
            return orderFound2 && orderFound2.status === 'new'
                ? {
                      ...state,
                      orders: _.uniqBy(
                          [
                              ...(state.orders || [])
                                  .filter(
                                      o =>
                                          o.id !==
                                              parseInt(
                                                  action.orderId.orderId,
                                                  10
                                              ) && o.status === 'cancelled'
                                  )
                                  .map(o => ({ ...o, start: null, end: null })),

                              ...(state.orders || []).filter(
                                  o =>
                                      o.id !==
                                          parseInt(
                                              action.orderId.orderId,
                                              10
                                          ) && o.status !== 'cancelled'
                              ),
                              {
                                  ...(state.orders || []).find(
                                      o =>
                                          o.id ===
                                          parseInt(action.orderId.orderId, 10)
                                  ),
                                  status: 'cancelled',
                                  start: null,
                                  end: null,
                              },
                          ],
                          'id'
                      ).sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
                      orderCounts: {
                          ...(state.orderCounts || {}),
                          new: Math.max((state.orderCounts.new || 0) - 1, 0),
                          cancelled: (state.orderCounts.cancelled || 0) + 1,
                      },
                      filteredOrders: newFilteredOrders2,
                  }
                : state;
        case ASSIGN_EMPLOYEE_TO_ORDER_SUCCESSFUL:
        case SOCKET_EMPLOYEE_ID_ASSIGNED:
            return {
                ...state,
                orders: _.uniqBy(
                    [
                        ...(state.orders || []).filter(
                            o => o.id !== parseInt(action.data.orderId, 10)
                        ),
                        {
                            ...(state.orders || []).find(
                                o => o.id === parseInt(action.data.orderId, 10)
                            ),
                            employeeId: action.data.employeeId,
                        },
                    ],
                    'id'
                ).sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
            };
        case SOCKET_DELIVERECT_ORDER_FAILURE_ACTION:
            return {
                ...state,
                orders: _.uniqBy(
                    [
                        ...(state.orders || []).filter(
                            o => o.id !== parseInt(action.orderId.orderId, 10)
                        ),
                        {
                            ...(state.orders || []).find(
                                o =>
                                    o.id ===
                                    parseInt(action.orderId.orderId, 10)
                            ),
                            deliverectOrderFailure: true,
                        },
                    ],
                    'id'
                ).sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
            };
        case SOCKET_DELIVERY_UPDATE_RECEIVED_ACTION:
            return {
                ...state,
                orders: _.uniqBy(
                    [
                        ...(state.orders || []).filter(
                            o =>
                                o.externalDeliveryId !==
                                action.params.externalDeliveryId
                        ),
                        {
                            ...(state.orders || []).find(
                                o =>
                                    o.externalDeliveryId ===
                                    action.params.externalDeliveryId
                            ),
                            deliveryQuote: {
                                ...(state.orders || []).find(
                                    o =>
                                        o.externalDeliveryId ===
                                        action.params.externalDeliveryId
                                ).deliveryQuote,
                                ...action.params,
                            },
                        },
                    ],
                    'id'
                ).sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
            };
        case ERROR_FETCHING_ORDERS:
            return {
                ...state,
                error: action.error,
            };
        case NEW_SOCKET_ORDER:
            const otherOrders = (state.orders || [])
                .filter(o => o.status !== 'new')
                .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
            const firstPageOrders = (state.orders || [])
                .filter(
                    o =>
                        parseInt(o.start, 10) === 0 &&
                        parseInt(o.end, 10) === 20 &&
                        o.status === 'new'
                )
                .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
            if (firstPageOrders.length > 19) {
                firstPageOrders.pop();
            }
            const oldOrders = (state.orders || [])
                .filter(
                    o =>
                        parseInt(o.start, 10) !== 0 &&
                        parseInt(o.end, 10) !== 20 &&
                        o.status === 'new'
                )
                .map(o => ({ ...o, start: null, end: null }));

            let filteredFirstPageOrders = null;
            let filteredOldOrders = null;
            let otherFilteredOrders = null;
            if (action.filterOptions) {
                otherFilteredOrders = (
                    (state.filteredOrders || {})[action.filterOptions] || []
                ).filter(o => o.status !== 'new');
                filteredFirstPageOrders = (
                    (state.filteredOrders || {})[action.filterOptions] || []
                )
                    .filter(
                        o =>
                            parseInt(o.start, 10) === 0 &&
                            parseInt(o.end, 10) === 20 &&
                            o.status === 'new'
                    )
                    .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
                if (filteredFirstPageOrders.length > 19) {
                    filteredFirstPageOrders.pop();
                }
                filteredOldOrders = (
                    (state.filteredOrders || {})[action.filterOptions] || []
                )
                    .filter(
                        o =>
                            parseInt(o.start, 10) !== 0 &&
                            parseInt(o.end, 10) !== 20 &&
                            o.status === 'new'
                    )
                    .map(o => ({ ...o, start: null, end: null }));
            }

            return {
                ...state,
                orders: _.uniqBy(
                    [
                        action.order,
                        ...oldOrders,
                        ...firstPageOrders,
                        ...otherOrders,
                    ],
                    'id'
                ).sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
                orderCounts: {
                    ...(state.orderCounts || {}),
                    new: _.get(state, ['orderCounts', 'new'], 0) + 1,
                },
                filteredOrders: action.filterOptions
                    ? {
                          ...state.filteredOrders,
                          [action.filterOptions]: _.uniqBy(
                              [
                                  action.order,
                                  ...filteredOldOrders,
                                  ...filteredFirstPageOrders,
                                  ...otherFilteredOrders,
                              ],
                              'id'
                          ).sort((a, b) =>
                              a.createdAt > b.createdAt ? -1 : 1
                          ),
                      }
                    : state.filteredOrders,
            };
        case NEW_SOCKET_PAYMENT:
            const updatedOrderIds = action.payment.orders?.map(o => o.id) || [];

            const filteredOrderKeysNeedingUpdate3 = Object.keys(
                state.filteredOrders || {}
            )
                .map(key => {
                    if (
                        (state.filteredOrders?.[key] || []).find(o =>
                            updatedOrderIds.includes(o.id)
                        )
                    ) {
                        return key;
                    }
                    return null;
                })
                .filter(k => k);
            const newFilteredOrders3 = { ...state.filteredOrders };
            if (
                filteredOrderKeysNeedingUpdate3 &&
                filteredOrderKeysNeedingUpdate3.length > 0
            ) {
                filteredOrderKeysNeedingUpdate3.forEach(key => {
                    newFilteredOrders3[key] = _.uniqBy(
                        [
                            ...action.payment.orders.filter(
                                order =>
                                    (state.filteredOrders?.[key] || []) &&
                                    (state.filteredOrders?.[key] || [])

                                        .map(fo => fo.id)
                                        .includes(order.id)
                            ),
                            ...((
                                state.filteredOrders?.[key] ||
                                [] ||
                                []
                            ).filter(o => !updatedOrderIds.includes(o.id)) ||
                                []),
                        ],
                        'id'
                    ).sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
                });
            }

            return {
                ...state,
                orders: _.uniqBy(
                    [
                        ...action.payment.orders,
                        ...((state.orders || []).filter(
                            o => !updatedOrderIds.includes(o.id)
                        ) || []),
                    ],
                    'id'
                ).sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
                filteredOrders:
                    filteredOrderKeysNeedingUpdate3 &&
                    filteredOrderKeysNeedingUpdate3.length > 0
                        ? newFilteredOrders3
                        : state.filteredOrders,
            };
        default:
            return state;
    }
};

export default ordersReducer;
