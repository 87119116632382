import { FETCHED_REPORTS, FETCHED_ADMIN_REPORTS } from '../types';

const initialState = {};

const reportsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCHED_REPORTS:
            return {
                ...state,
                reports: action.reports,
                from: action.from,
                to: action.to,
            };
        case FETCHED_ADMIN_REPORTS:
            return {
                ...state,
                adminReports: action.reports,
                from: action.from,
                to: action.to,
            };
        default:
            return state;
    }
};

export default reportsReducer;
