export default {
    en: {
        your_rewards_redeem: 'Redeem Your Rewards',
        redeem_points_descr:
            'Redeem your membership rewards points to reduce your bill. Please note, the tip cannot be paid using membership rewards points.',
        pay: 'Pay',
        points: 'points',
        back: 'Back',
        your_total_short: 'Your Total',
        tip: 'Tip',
        available_balance: 'Available Balance',
        redeem_points: 'Redeem Points',
        all: 'All',
    },
    es: {
        your_rewards_redeem: 'Canjea tus Recompensas',
        redeem_points_descr:
            'Canjea tus puntos de recompensas de membresía para reducir tu factura. Tenga en cuenta que la propina no se puede pagar con puntos de recompensas.',
        pay: 'Pagar',
        points: 'puntos',
        back: 'Atrás',
        your_total_short: 'Tu Total',
        tip: 'Propina',
        available_balance: 'Saldo Disponible',
        redeem_points: 'Canjear Puntos',
        all: 'Todo',
    },
    zh: {
        your_rewards_redeem: '兑换您的奖励',
        redeem_points_descr:
            '兑换您的会员奖励积分以减少您的账单。 请注意，小费不能用会员奖励积分支付。',
        pay: '支付',
        points: '积分',
        back: '返回',
        your_total_short: '您的总计',
        tip: '小费',
        available_balance: '可用余额',
        redeem_points: '兑换积分',
        all: '全部',
    },
    ar: {
        your_rewards_redeem: 'استبدال مكافآتك',
        redeem_points_descr:
            'استبدل نقاط مكافآت العضوية الخاصة بك لتقليل فاتورتك. يرجى ملاحظة أنه لا يمكن دفع البقشيش باستخدام نقاط مكافآت العضوية.',
        pay: 'دفع',
        points: 'نقاط',
        back: 'رجوع',
        your_total_short: 'إجمالي حسابك',
        tip: 'إكرامية',
        available_balance: 'الرصيد المتاح',
        redeem_points: 'استبدال النقاط',
        all: 'الكل',
    },
    ru: {
        your_rewards_redeem: 'Использовать свои награды',
        redeem_points_descr:
            'Используйте свои бонусные баллы, чтобы уменьшить счет. Обратите внимание, что чаевые нельзя оплатить бонусными баллами.',
        pay: 'Оплатить',
        points: 'баллы',
        back: 'Назад',
        your_total_short: 'Ваш итог',
        tip: 'Чаевые',
        available_balance: 'Доступный баланс',
        redeem_points: 'Использовать баллы',
        all: 'Все',
    },
    fr: {
        your_rewards_redeem: 'Échangez vos Récompenses',
        redeem_points_descr:
            'Échangez vos points de récompenses de fidélité pour réduire votre facture. Veuillez noter que le pourboire ne peut pas être payé avec des points de récompense.',
        pay: 'Payer',
        points: 'points',
        back: 'Retour',
        your_total_short: 'Votre Total',
        tip: 'Pourboire',
        available_balance: 'Solde Disponible',
        redeem_points: 'Échanger des Points',
        all: 'Tout',
    },
    de: {
        your_rewards_redeem: 'Belohnungen einlösen',
        redeem_points_descr:
            'Lösen Sie Ihre Mitgliedschaftsprämienpunkte ein, um Ihre Rechnung zu reduzieren. Bitte beachten Sie, dass das Trinkgeld nicht mit Mitgliedschaftsprämienpunkten bezahlt werden kann.',
        pay: 'Bezahlen',
        points: 'Punkte',
        back: 'Zurück',
        your_total_short: 'Ihr Gesamtbetrag',
        tip: 'Trinkgeld',
        available_balance: 'Verfügbares Guthaben',
        redeem_points: 'Punkte einlösen',
        all: 'Alle',
    },
    it: {
        your_rewards_redeem: 'Riscatta le tue ricompense',
        redeem_points_descr:
            'Riscatta i tuoi punti fedeltà per ridurre il tuo conto. Si prega di notare che la mancia non può essere pagata utilizzando i punti fedeltà.',
        pay: 'Paga',
        points: 'punti',
        back: 'Indietro',
        your_total_short: 'Il tuo Totale',
        tip: 'Mancia',
        available_balance: 'Saldo Disponibile',
        redeem_points: 'Riscatta Punti',
        all: 'Tutti',
    },
    pt: {
        your_rewards_redeem: 'Resgatar suas Recompensas',
        redeem_points_descr:
            'Resgate seus pontos de recompensas de associação para reduzir sua conta. Observe que a gorjeta não pode ser paga usando pontos de recompensas de associação.',
        pay: 'Pagar',
        points: 'pontos',
        back: 'Voltar',
        your_total_short: 'Seu Total',
        tip: 'Gorjeta',
        available_balance: 'Saldo Disponível',
        redeem_points: 'Resgatar Pontos',
        all: 'Todos',
    },
    ja: {
        your_rewards_redeem: '報酬を交換する',
        redeem_points_descr:
            '会員報酬ポイントを交換して請求額を減らしましょう。 チップは会員報酬ポイントで支払うことはできません。',
        pay: '支払う',
        points: 'ポイント',
        back: '戻る',
        your_total_short: '合計金額',
        tip: 'チップ',
        available_balance: '利用可能な残高',
        redeem_points: 'ポイントを交換する',
        all: 'すべて',
    },
    ko: {
        your_rewards_redeem: '보상을 사용하세요',
        redeem_points_descr:
            '멤버십 리워드 포인트를 사용하여 청구서를 줄이세요. 팁은 멤버십 리워드 포인트로 지불할 수 없습니다.',
        pay: '결제',
        points: '포인트',
        back: '뒤로',
        your_total_short: '총액',
        tip: '팁',
        available_balance: '사용 가능한 잔액',
        redeem_points: '포인트 사용',
        all: '전체',
    },
    nl: {
        your_rewards_redeem: 'Uw beloningen inwisselen',
        redeem_points_descr:
            'Wissel uw lidmaatschapsbeloningspunten in om uw rekening te verlagen. Houd er rekening mee dat de fooi niet kan worden betaald met lidmaatschapsbeloningspunten.',
        pay: 'Betalen',
        points: 'punten',
        back: 'Terug',
        your_total_short: 'Uw Totaal',
        tip: 'Fooi',
        available_balance: 'Beschikbaar Saldo',
        redeem_points: 'Punten Inwisselen',
        all: 'Alles',
    },
    pl: {
        your_rewards_redeem: 'Zrealizuj swoje nagrody',
        redeem_points_descr:
            'Wykorzystaj punkty nagród członkowskich, aby obniżyć rachunek. Należy pamiętać, że napiwek nie może być opłacony punktami nagród członkowskich.',
        pay: 'Zapłać',
        points: 'punkty',
        back: 'Wróć',
        your_total_short: 'Twój Suma',
        tip: 'Napiwek',
        available_balance: 'Dostępne saldo',
        redeem_points: 'Wykorzystaj punkty',
        all: 'Wszystko',
    },
    hi: {
        your_rewards_redeem: 'अपने पुरस्कारों को भुनाएं',
        redeem_points_descr:
            'अपनी सदस्यता पुरस्कार अंकों का उपयोग करके अपने बिल को कम करें। कृपया ध्यान दें कि टिप का भुगतान सदस्यता पुरस्कार अंकों से नहीं किया जा सकता है।',
        pay: 'भुगतान करें',
        points: 'अंक',
        back: 'वापस',
        your_total_short: 'आपका कुल',
        tip: 'टिप',
        available_balance: 'उपलब्ध शेष',
        redeem_points: 'अंक भुनाएं',
        all: 'सभी',
    },
};
