import {
    UNSUBSCRIBE_SUCCESSFUL,
    ERROR_UNSUBSCRIBING,
    RESUB_SUCCESSFUL,
    ERROR_RESUB,
} from '../types';

const initialState = {};

const unsubReducer = (state = initialState, action) => {
    switch (action.type) {
        case UNSUBSCRIBE_SUCCESSFUL:
            return {
                ...state,
                success: true,
                error: null,
            };
        case ERROR_UNSUBSCRIBING:
            return {
                ...state,
                error: action.error,
                success: false,
            };
        case RESUB_SUCCESSFUL:
            return {
                ...state,
                success: false,
                resub: { success: true, error: null },
            };
        case ERROR_RESUB:
            return {
                ...state,
                success: false,
                resub: { success: false, error: action.error },
            };
        default:
            return state;
    }
};

export default unsubReducer;
