export default {
    en: {
        free_deliteclub: 'FREE with DeliteClub',
        free_deliteclub_2: 'with DeliteClub',
        ask_waiter: 'Ask Waiter',
        from_small: 'From ',
        contains: 'Contains ',
        allergens: 'allergens',
        including_fee: '(Incl. Order Fee)',
        pay_now: 'Pay Now',
        charge: 'Charge',
        to_room: 'To My Room',
        pay: 'Pay',
        later: 'Later',
        guests_only: '(Guests Only)',
        dismiss: 'Dismiss',
        add: 'Add',
        delete: 'Delete',
        paying_for: 'Paying For',
        add_ons: 'Extras',
        minimum_order_value: 'Minimum order value for delivery orders: ',
        your_order: 'Your Order',
        any_allergies_short: 'Any Allergies or Notes?',
        your_total_colon: 'Your Total:',
        why_not_sell: 'Why Not Also Try?',
    },
    es: {
        free_deliteclub: 'GRATIS con DeliteClub',
        free_deliteclub_2: 'con DeliteClub',
        ask_waiter: 'Preguntar al mesero',
        from_small: 'Desde ',
        contains: 'Contiene ',
        allergens: 'alérgenos',
        including_fee: '(Incl. Tarifa de Orden)',
        pay_now: 'Pagar ahora',
        charge: 'Cobrar',
        to_room: 'A mi habitación',
        pay: 'Pagar',
        later: 'Más tarde',
        guests_only: '(Solo huéspedes)',
        dismiss: 'Descartar',
        add: 'Añadir',
        delete: 'Eliminar',
        paying_for: 'Pagando por',
        add_ons: 'Extras',
        minimum_order_value: 'Valor mínimo de pedido para entregas: ',
        your_order: 'Tu Pedido',
        any_allergies_short: '¿Alguna alergia o notas?',
        your_total_colon: 'Tu Total:',
        why_not_sell: '¿Por qué no probar también?',
    },
    zh: {
        free_deliteclub: 'DeliteClub免费',
        free_deliteclub_2: '通过DeliteClub',
        ask_waiter: '问服务员',
        from_small: '起 ',
        contains: '包含 ',
        allergens: '过敏原',
        including_fee: '(包括订单费)',
        pay_now: '立即付款',
        charge: '收费',
        to_room: '到我的房间',
        pay: '付款',
        later: '稍后',
        guests_only: '(仅限客人)',
        dismiss: '关闭',
        add: '添加',
        delete: '删除',
        paying_for: '支付',
        add_ons: '额外',
        minimum_order_value: '配送订单的最低订单价值：',
        your_order: '您的订单',
        any_allergies_short: '有任何过敏或备注？',
        your_total_colon: '您的总计：',
        why_not_sell: '为什么不也试试？',
    },
    ar: {
        free_deliteclub: 'مجاني مع DeliteClub',
        free_deliteclub_2: 'مع DeliteClub',
        ask_waiter: 'اسأل النادل',
        from_small: 'من ',
        contains: 'يحتوي على ',
        allergens: 'مسببات الحساسية',
        including_fee: '(يشمل رسوم الطلب)',
        pay_now: 'ادفع الآن',
        charge: 'تحصيل',
        to_room: 'إلى غرفتي',
        pay: 'دفع',
        later: 'لاحقًا',
        guests_only: '(للنزلاء فقط)',
        dismiss: 'إغلاق',
        add: 'إضافة',
        delete: 'حذف',
        paying_for: 'الدفع مقابل',
        add_ons: 'الإضافات',
        minimum_order_value: 'الحد الأدنى لقيمة الطلب للتوصيل: ',
        your_order: 'طلبك',
        any_allergies_short: 'هل لديك أي حساسية أو ملاحظات؟',
        your_total_colon: 'إجمالي الطلب:',
        why_not_sell: 'لماذا لا تجرب أيضًا؟',
    },
    ru: {
        free_deliteclub: 'БЕСПЛАТНО с DeliteClub',
        free_deliteclub_2: 'с DeliteClub',
        ask_waiter: 'Спросите официанта',
        from_small: 'От ',
        contains: 'Содержит ',
        allergens: 'аллергены',
        including_fee: '(Включая плату за заказ)',
        pay_now: 'Оплатить сейчас',
        charge: 'Списать',
        to_room: 'На мой номер',
        pay: 'Оплатить',
        later: 'Позже',
        guests_only: '(Только для гостей)',
        dismiss: 'Закрыть',
        add: 'Добавить',
        delete: 'Удалить',
        paying_for: 'Оплата за',
        add_ons: 'Дополнения',
        minimum_order_value: 'Минимальная сумма заказа для доставки: ',
        your_order: 'Ваш заказ',
        any_allergies_short: 'Какие-либо аллергии или примечания?',
        your_total_colon: 'Ваш итог:',
        why_not_sell: 'Почему бы не попробовать еще?',
    },
    ja: {
        free_deliteclub: 'DeliteClubで無料',
        free_deliteclub_2: 'DeliteClubで',
        ask_waiter: 'ウェイターに聞く',
        from_small: 'から ',
        contains: '含む ',
        allergens: 'アレルゲン',
        including_fee: '(注文料込み)',
        pay_now: '今すぐ支払う',
        charge: '請求',
        to_room: '部屋に請求',
        pay: '支払う',
        later: '後で',
        guests_only: '(ゲストのみ)',
        dismiss: '閉じる',
        add: '追加',
        delete: '削除',
        paying_for: '支払う',
        add_ons: '追加',
        minimum_order_value: '配送注文の最低注文額：',
        your_order: 'あなたの注文',
        any_allergies_short: 'アレルギーまたはメモはありますか？',
        your_total_colon: '合計：',
        why_not_sell: 'なぜ他にも試さないのですか？',
    },
    ko: {
        free_deliteclub: 'DeliteClub으로 무료',
        free_deliteclub_2: 'DeliteClub으로',
        ask_waiter: '웨이터에게 물어보세요',
        from_small: '에서 ',
        contains: '포함 ',
        allergens: '알레르겐',
        including_fee: '(주문 수수료 포함)',
        pay_now: '지금 결제',
        charge: '청구',
        to_room: '내 방으로',
        pay: '지불',
        later: '나중에',
        guests_only: '(손님 전용)',
        dismiss: '닫기',
        add: '추가',
        delete: '삭제',
        paying_for: '결제 중',
        add_ons: '추가 항목',
        minimum_order_value: '배달 주문의 최소 주문 금액: ',
        your_order: '귀하의 주문',
        any_allergies_short: '알레르기 또는 메모가 있습니까?',
        your_total_colon: '총액:',
        why_not_sell: '왜 다른 것도 시도하지 않나요?',
    },
    de: {
        free_deliteclub: 'KOSTENLOS mit DeliteClub',
        free_deliteclub_2: 'mit DeliteClub',
        ask_waiter: 'Kellner fragen',
        from_small: 'Ab ',
        contains: 'Enthält ',
        allergens: 'Allergene',
        including_fee: '(Inkl. Bestellgebühr)',
        pay_now: 'Jetzt bezahlen',
        charge: 'Berechnen',
        to_room: 'Auf mein Zimmer',
        pay: 'Bezahlen',
        later: 'Später',
        guests_only: '(Nur für Gäste)',
        dismiss: 'Schließen',
        add: 'Hinzufügen',
        delete: 'Löschen',
        paying_for: 'Bezahlen für',
        add_ons: 'Extras',
        minimum_order_value: 'Mindestbestellwert für Lieferaufträge: ',
        your_order: 'Deine Bestellung',
        any_allergies_short: 'Irgendwelche Allergien oder Notizen?',
        your_total_colon: 'Dein Gesamt:',
        why_not_sell: 'Warum nicht auch versuchen?',
    },
    fr: {
        free_deliteclub: 'GRATUIT avec DeliteClub',
        free_deliteclub_2: 'avec DeliteClub',
        ask_waiter: 'Demander au serveur',
        from_small: 'À partir de ',
        contains: 'Contient ',
        allergens: 'allergènes',
        including_fee: '(Incl. Frais de Commande)',
        pay_now: 'Payer maintenant',
        charge: 'Facturer',
        to_room: 'À ma chambre',
        pay: 'Payer',
        later: 'Plus tard',
        guests_only: '(Réservé aux clients)',
        dismiss: 'Fermer',
        add: 'Ajouter',
        delete: 'Supprimer',
        paying_for: 'Payer pour',
        add_ons: 'Suppléments',
        minimum_order_value:
            'Valeur minimale de commande pour les livraisons : ',
        your_order: 'Votre commande',
        any_allergies_short: 'Des allergies ou des notes?',
        your_total_colon: 'Votre total:',
        why_not_sell: 'Pourquoi ne pas aussi essayer?',
    },
    pl: {
        free_deliteclub: 'ZA DARMO z DeliteClub',
        free_deliteclub_2: 'z DeliteClub',
        ask_waiter: 'Zapytaj kelnera',
        from_small: 'Od ',
        contains: 'Zawiera ',
        allergens: 'alergeny',
        including_fee: '(W tym opłata za zamówienie)',
        pay_now: 'Zapłać teraz',
        charge: 'Obciążyć',
        to_room: 'Na mój pokój',
        pay: 'Zapłać',
        later: 'Później',
        guests_only: '(Tylko dla gości)',
        dismiss: 'Zamknij',
        add: 'Dodaj',
        delete: 'Usuń',
        paying_for: 'Płacisz za',
        add_ons: 'Dodatki',
        minimum_order_value: 'Minimalna wartość zamówienia na dostawę: ',
        your_order: 'Twoje zamówienie',
        any_allergies_short: 'Jakieś alergie lub uwagi?',
        your_total_colon: 'Twój całkowity:',
        why_not_sell: 'Dlaczego by nie spróbować również?',
    },
    pt: {
        free_deliteclub: 'GRÁTIS com DeliteClub',
        free_deliteclub_2: 'com DeliteClub',
        ask_waiter: 'Pergunte ao garçom',
        from_small: 'A partir de ',
        contains: 'Contém ',
        allergens: 'alérgenos',
        including_fee: '(Incl. Taxa de Pedido)',
        pay_now: 'Pagar agora',
        charge: 'Cobrar',
        to_room: 'Para o meu quarto',
        pay: 'Pagar',
        later: 'Mais tarde',
        guests_only: '(Somente para hóspedes)',
        dismiss: 'Fechar',
        add: 'Adicionar',
        delete: 'Excluir',
        paying_for: 'Pagando por',
        add_ons: 'Adicionais',
        minimum_order_value: 'Valor mínimo do pedido para entregas: ',
        your_order: 'Seu pedido',
        any_allergies_short: 'Alguma alergia ou observação?',
        your_total_colon: 'Seu total:',
        why_not_sell: 'Por que não experimentar também?',
    },
    it: {
        free_deliteclub: 'GRATIS con DeliteClub',
        free_deliteclub_2: 'con DeliteClub',
        ask_waiter: 'Chiedi al cameriere',
        from_small: 'Da ',
        contains: 'Contiene ',
        allergens: 'allergeni',
        including_fee: "(Incl. Tasse d'ordine)",
        pay_now: 'Paga ora',
        charge: 'Addebita',
        to_room: 'Alla mia stanza',
        pay: 'Paga',
        later: 'Più tardi',
        guests_only: '(Solo ospiti)',
        dismiss: 'Chiudi',
        add: 'Aggiungi',
        delete: 'Elimina',
        paying_for: 'Pagando per',
        add_ons: 'Extra',
        minimum_order_value: "Valore minimo dell'ordine per le consegne: ",
        your_order: 'Il tuo ordine',
        any_allergies_short: 'Qualche allergia o nota?',
        your_total_colon: 'Il tuo totale:',
        why_not_sell: 'Perché non provare anche?',
    },
    nl: {
        free_deliteclub: 'GRATIS met DeliteClub',
        free_deliteclub_2: 'met DeliteClub',
        ask_waiter: 'Vraag het aan de ober',
        from_small: 'Vanaf ',
        contains: 'Bevat ',
        allergens: 'allergenen',
        including_fee: '(Incl. Bestelkosten)',
        pay_now: 'Nu betalen',
        charge: 'In rekening brengen',
        to_room: 'Naar mijn kamer',
        pay: 'Betalen',
        later: 'Later',
        guests_only: '(Alleen gasten)',
        dismiss: 'Sluiten',
        add: 'Toevoegen',
        delete: 'Verwijderen',
        paying_for: 'Betalen voor',
        add_ons: "Extra's",
        minimum_order_value: 'Minimale bestelwaarde voor leveringen: ',
        your_order: 'Uw bestelling',
        any_allergies_short: 'Eventuele allergieën of opmerkingen?',
        your_total_colon: 'Uw totaal:',
        why_not_sell: 'Waarom niet ook proberen?',
    },
    hi: {
        free_deliteclub: 'DeliteClub के साथ मुफ्त',
        free_deliteclub_2: 'DeliteClub के साथ',
        ask_waiter: 'वेटर से पूछें',
        from_small: 'से ',
        contains: 'शामिल है ',
        allergens: 'एलर्जेन',
        including_fee: '(ऑर्डर शुल्क सहित)',
        pay_now: 'अब भुगतान करें',
        charge: 'चार्ज करें',
        to_room: 'मेरे कमरे में',
        pay: 'भुगतान करें',
        later: 'बाद में',
        guests_only: '(केवल मेहमानों के लिए)',
        dismiss: 'बंद करें',
        add: 'जोड़ें',
        delete: 'हटाएं',
        paying_for: 'के लिए भुगतान',
        add_ons: 'अतिरिक्त',
        minimum_order_value: 'डिलीवरी ऑर्डर के लिए न्यूनतम ऑर्डर मूल्य: ',
        your_order: 'आपका ऑर्डर',
        any_allergies_short: 'कोई एलर्जी या नोट्स?',
        your_total_colon: 'आपका कुल:',
        why_not_sell: 'क्यों न इसे भी आजमाएँ?',
    },
};
