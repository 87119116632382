import {
    FETCHED_USER_DISCOUNT_EMAILS,
    ERROR_FETCHING_USER_DISCOUNT_EMAILS,
} from '../types';

const initialState = {};

const discountEmailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCHED_USER_DISCOUNT_EMAILS:
            return {
                ...state,
                emails: action.emails,
            };
        case ERROR_FETCHING_USER_DISCOUNT_EMAILS:
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
};

export default discountEmailsReducer;
