import { io } from 'socket.io-client';
import { getToken } from './tokenFetcher';
import store from './store';
import { SOCKET_EVENTS } from '../state/types';
import { updateSocketTokens } from '../state/actions/auth';

const createSocketConnection = () => {
    const socket = io(`${process.env.REACT_APP_API_URL}/`, {
        query: `Authorization=${getToken(store.getState())}&isRestaurant=true`,
        transports: ['websocket'],
        upgrade: false,
        reconnection: true,
        reconnectionAttempts: Infinity,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
    });

    let pingInterval = null;
    let manualReconnectTimeout = null;
    let isExplicitDisconnect = false;

    const startPing = () => {
        if (pingInterval) return;
        pingInterval = setInterval(() => {
            if (socket.connected) {
                socket.emit('ping');
            }
        }, 5000);
    };

    const attemptManualReconnect = () => {
        if (!socket.connected && !isExplicitDisconnect) {
            // console.log('Manual reconnection attempt...');
            socket.connect();
        }
    };

    socket.on('connect', () => {
        // console.log('Socket connected');
        startPing();
        if (manualReconnectTimeout) {
            clearTimeout(manualReconnectTimeout);
            manualReconnectTimeout = null;
        }
    });

    socket.on('disconnect', reason => {
        console.warn(`Socket disconnected: ${reason}.`);
        if (!isExplicitDisconnect && reason !== 'io client disconnect') {
            manualReconnectTimeout = setTimeout(attemptManualReconnect, 3000);
        }
    });

    socket.on('connect_error', error => {
        console.error('Connection error:', error);
        if (!isExplicitDisconnect) {
            manualReconnectTimeout = setTimeout(attemptManualReconnect, 3000);
        }
    });

    socket.on('error', error => {
        console.error('Socket error:', error);
        if (!isExplicitDisconnect) {
            manualReconnectTimeout = setTimeout(attemptManualReconnect, 3000);
        }
    });

    socket.on(SOCKET_EVENTS.NEW_ACCESS_TOKEN, params => {
        const { token, tokenExpiry, refreshToken } = params;
        updateSocketTokens({
            accesstoken: token,
            accesstokenxpiry: tokenExpiry,
            refreshtoken: refreshToken,
        });
    });

    socket.on('pong', () => {
        // console.log('Pong received from server');
    });

    const disconnectSocket = () => {
        isExplicitDisconnect = true;
        if (pingInterval) clearInterval(pingInterval);
        if (manualReconnectTimeout) clearTimeout(manualReconnectTimeout);
        socket.disconnect();
    };

    return { socket, disconnectSocket };
};

const { socket, disconnectSocket } = createSocketConnection();

export { socket, disconnectSocket };
export default socket;
