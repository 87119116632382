import Sentry from '../../Sentry';
import {
    FETCHED_REQUESTS,
    FETCH_WEBSITE_CONFIG,
    ERROR_FETCHING_WEBSITE_CONFIG,
    UPDATE_WEBSITE_CONFIG,
    ERROR_UPDATING_WEBSITE_CONFIG,
} from '../types';

const initialState = {};

const requestsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_WEBSITE_CONFIG:
            return {
                ...state,
                websiteConfig: action.websiteConfig,
            };
        case ERROR_FETCHING_WEBSITE_CONFIG:
            return { ...state };
        case FETCHED_REQUESTS:
            return {
                ...state,
                websiteConfig: action.websiteConfig,
            };
        case UPDATE_WEBSITE_CONFIG:
            return {
                ...state,
                websiteConfig: action.websiteConfig,
                updated: true,
            };
        case ERROR_UPDATING_WEBSITE_CONFIG:
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
};

export default requestsReducer;
