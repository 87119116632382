import {
    UPDATE_ADMIN_SETTINGS_FAILED,
    FETCHED_ADMIN_SETTINGS,
    ERROR_FETCHING_ADMIN_SETTINGS,
} from '../types';

const initialState = {};

const adminSettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_ADMIN_SETTINGS_FAILED:
            return {
                ...state,
                createSectionError: action.error,
            };
        case FETCHED_ADMIN_SETTINGS:
            return {
                ...state,
                adminSettings: action.adminSettings,
            };

        case ERROR_FETCHING_ADMIN_SETTINGS:
            return {
                ...state,
                error: action.error,
            };

        default:
            return state;
    }
};

export default adminSettingsReducer;
