export default {
    en: {
        any_allergies_short: 'Any Allergies or Notes?',
        notes: 'Notes',
        notes_descr:
            'Use this space to tell the restaurant about any allergies or special requests for your order.',
        cancel: 'Cancel',
        save: 'Save',
    },
    es: {
        any_allergies_short: '¿Alguna alergia o notas?',
        notes: 'Notas',
        notes_descr:
            'Usa este espacio para informar al restaurante sobre cualquier alergia o solicitud especial para tu pedido.',
        cancel: 'Cancelar',
        save: 'Guardar',
    },
    zh: {
        any_allergies_short: '有任何过敏或备注？',
        notes: '备注',
        notes_descr: '请在此处告诉餐厅您是否有任何过敏或特殊要求。',
        cancel: '取消',
        save: '保存',
    },
    ar: {
        any_allergies_short: 'هل لديك أي حساسية أو ملاحظات؟',
        notes: 'ملاحظات',
        notes_descr:
            'استخدم هذه المساحة لإخبار المطعم بأي حساسية أو طلبات خاصة لطلبك.',
        cancel: 'إلغاء',
        save: 'حفظ',
    },
    ru: {
        any_allergies_short: 'Какие-либо аллергии или примечания?',
        notes: 'Примечания',
        notes_descr:
            'Используйте это пространство, чтобы сообщить ресторану о любых аллергиях или особых запросах для вашего заказа.',
        cancel: 'Отмена',
        save: 'Сохранить',
    },
    ja: {
        any_allergies_short: 'アレルギーまたはメモはありますか？',
        notes: 'メモ',
        notes_descr:
            'このスペースを使用して、アレルギーや注文に関する特別なリクエストをレストランに伝えてください。',
        cancel: 'キャンセル',
        save: '保存',
    },
    ko: {
        any_allergies_short: '알레르기 또는 메모가 있습니까?',
        notes: '메모',
        notes_descr:
            '이 공간을 사용하여 레스토랑에 알레르기 또는 특별 요청 사항을 알려주세요.',
        cancel: '취소',
        save: '저장',
    },
    de: {
        any_allergies_short: 'Irgendwelche Allergien oder Notizen?',
        notes: 'Notizen',
        notes_descr:
            'Verwenden Sie diesen Bereich, um dem Restaurant über Allergien oder spezielle Wünsche für Ihre Bestellung zu informieren.',
        cancel: 'Abbrechen',
        save: 'Speichern',
    },
    fr: {
        any_allergies_short: 'Des allergies ou des notes?',
        notes: 'Notes',
        notes_descr:
            'Utilisez cet espace pour informer le restaurant de toute allergie ou demande spéciale pour votre commande.',
        cancel: 'Annuler',
        save: 'Enregistrer',
    },
    pl: {
        any_allergies_short: 'Jakieś alergie lub uwagi?',
        notes: 'Notatki',
        notes_descr:
            'Użyj tego miejsca, aby poinformować restaurację o wszelkich alergiach lub specjalnych życzeniach dotyczących zamówienia.',
        cancel: 'Anuluj',
        save: 'Zapisz',
    },
    pt: {
        any_allergies_short: 'Alguma alergia ou observação?',
        notes: 'Notas',
        notes_descr:
            'Use este espaço para informar o restaurante sobre qualquer alergia ou pedido especial para o seu pedido.',
        cancel: 'Cancelar',
        save: 'Salvar',
    },
    it: {
        any_allergies_short: 'Qualche allergia o nota?',
        notes: 'Note',
        notes_descr:
            'Usa questo spazio per comunicare al ristorante eventuali allergie o richieste speciali per il tuo ordine.',
        cancel: 'Annulla',
        save: 'Salva',
    },
    nl: {
        any_allergies_short: 'Eventuele allergieën of opmerkingen?',
        notes: 'Notities',
        notes_descr:
            'Gebruik deze ruimte om het restaurant op de hoogte te stellen van eventuele allergieën of speciale verzoeken voor uw bestelling.',
        cancel: 'Annuleren',
        save: 'Opslaan',
    },
    hi: {
        any_allergies_short: 'कोई एलर्जी या नोट्स?',
        notes: 'नोट्स',
        notes_descr:
            'किसी भी एलर्जी या आपके ऑर्डर के लिए विशेष अनुरोध के बारे में रेस्तरां को बताने के लिए इस स्थान का उपयोग करें।',
        cancel: 'रद्द करें',
        save: 'सेव करें',
    },
};
